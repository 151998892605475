import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./extraReducers";
import reducers from "./reducers";

export const utilitySlice = createSlice({
  name: "utility",
  initialState: {
    snackbar: {
      open: false,
      vertical: "bottom",
      horizontal: "center",
      text: "",
      duration: 4000,
      type:'success'
    },
    roles:{
      data:[],
      status:'idle'
    },
    avatars:{
      data:[],
      status:'idle'
    },
    strapiProducts:{
      data:[],
      status:'idle'
    },
    contentAnalyzedQueue:{
      queue:[],
    },
    pusherNotifications: {
      channel:null,
      creditsUpdate:null,
      analyzeUpdate:null,
    }
  },
  reducers,
  extraReducers,
});

export default utilitySlice;
