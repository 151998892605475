import * as React from "react";
import { LogoLoader, Table } from "../../../../../components";
import MetadataDisplayer from "./MetadataDisplayer";
import useContentNavigatorHandler from "../../../../../hooks/useContentNavigatorHandler";
import useContentMetadataHandler from "../../../../../hooks/useContentMetadataHandler";
import useContentMetadataFolderHandler from "../../../../../hooks/useContentMetadataFolderHandler";
import useContentUpdateMetadataHanlder from "../../../../../hooks/useContentUpdateMetadataHanlder";
import useAnalyzeContentHandler from "../../../../../hooks/useAnalyzeContentHandler";
import useSuggestionsContentHandler from "../../../../../hooks/useSuggestionsContentHandler";
import CurrentFolderBtn from "./CurrentFolderBtn";
import ColumnsTable from "./ColumnsTable";

export default function TableLibrary() {
  const [openMetadata, setOpenMetadata] = React.useState({
    open: false,
    params: null,
  });

  const { onResetCurrentContent } = useContentMetadataHandler();
  const { onResetSuggestionsContent } = useSuggestionsContentHandler();
  const { onResetCurrentContentFolder } = useContentMetadataFolderHandler();
  const { analyzeData } = useAnalyzeContentHandler(true);
  const { isSuccess: isSuccessContentUpdated, onResetUpdateContentMetadata } =
  useContentUpdateMetadataHanlder();
  const { folders, allBlobs, filesList, isSuccess, isLoading } =
    useContentNavigatorHandler({ readOnly: true });

  const toggleDrawer = React.useCallback(
    (newOpen, params) => {
      onResetCurrentContentFolder();
      onResetCurrentContent();
      onResetUpdateContentMetadata();
      onResetSuggestionsContent();
      setOpenMetadata({ open: newOpen, params });
    },
    [
      onResetCurrentContentFolder,
      onResetCurrentContent,
      onResetUpdateContentMetadata,
      onResetSuggestionsContent,
      setOpenMetadata,
    ]
  );

  React.useEffect(() => {
    if (filesList.length === 0 && openMetadata.open) {
      toggleDrawer(false, null);
      return
    }
    if (analyzeData?.message === "in queue") {
      toggleDrawer(false, null);
    }
  }, [filesList, openMetadata.open, analyzeData, toggleDrawer]);

  return (
    <div style={{ width: "100%" }}>
      <LogoLoader
        open={!openMetadata.open && isLoading && !isSuccessContentUpdated}
        text={"Refreshing Content Library"}
        needBackDrop={false}
      />
      <CurrentFolderBtn />
      {isSuccess || (openMetadata.open && openMetadata && allBlobs.length) ? (
        <Table
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "timestamp",
                  sort: "desc",
                },
              ],
            },
          }}
          rows={folders()}
          columns={ColumnsTable(toggleDrawer)}
          hideFooterPagination
        />
      ) : (
        ""
      )}
      <MetadataDisplayer
        metadataType={openMetadata?.params?.row?.isFile ? "files" : "folders"}
        toggleDrawer={toggleDrawer}
        open={openMetadata.open}
      />
    </div>
  );
}
