import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { AudienceIcon, BrandIcon } from "../../../../icons";
import BrandBuilder from "./components/BrandBuilder";
import useGetBrandsHandler from "../../../../hooks/useGetBrandsHandler";
import { useBrandBuilderModeHandler } from "../../../../hooks/useBrandBuilderModeHandler";
import useRemoveBrand from "../../../../hooks/useRemoveBrand";
import { useEffect } from "react";
import useAudienceByOrganizationHandler from "../../../../hooks/useAudienceByOrganizationHandler";
import { useNavigate } from "react-router-dom";
import useCurrentOrganizationHandler from "../../../../hooks/useCurrentOrganizationHandler";
import useGetOrgUserProfile from "../../../../hooks/useGetOrgUserProfileHanlder";
import useTextTransform from "../../../../hooks/useTextTransform";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  AccordionComponent,
  LeftPaneComponent,
  LogoLoader,
} from "../../../../components";
export default function Brand() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { snakeCaseToTitleCase } = useTextTransform();
  const { currentOrganization } = useCurrentOrganizationHandler();
  const { brand, onGetBrands, isLoading, isSuccess } = useGetBrandsHandler();
  const { onSetMode, builderMode } = useBrandBuilderModeHandler();
  const { data, isUninitialized, onGetActiveAudience } =
    useAudienceByOrganizationHandler();
  const {
    onDeleteBrand,
    isSuccess: isRemoved,
    onReseteDeleteBrand,
  } = useRemoveBrand();
  const { canManageBrand } = useGetOrgUserProfile();

  useEffect(() => {
    if (isRemoved) {
      onReseteDeleteBrand();
      onGetBrands();
    }
  }, [isRemoved, onGetBrands, onReseteDeleteBrand]);

  useEffect(() => {
    if (isUninitialized) {
      onGetActiveAudience();
    }
  }, [isUninitialized, onGetActiveAudience]);

  return (
    <>
    <LogoLoader open={isLoading} text={'Loading Brand...'} needBackDrop={true}></LogoLoader>
      {builderMode === "viewer" && brand && (
        <Grid container sx={{ height: "100vh" }}>
          <LeftPaneComponent>
            <Stack pt={"70px"} spacing={2}>
              <Stack
                sx={{
                  borderRadius: "10px",
                  background: theme.palette.dark.main,
                  padding: "10px 6px",
                }}
                justifyContent={"center"}
                alignItems={"center"}
                color={"#fff"}
              >
                <BrandIcon color="white" small={false} />
                <Typography fontWeight={"100"} textAlign={"left"} variant="h5">
                  {brand?.name}
                </Typography>
              </Stack>

              <Alert
                sx={{ textAlign: "left", fontSize: 12 }}
                variant="outlined"
                severity={data?.length > 0 ? "success" : "error"}
              >
                {data?.length > 0
                  ? "This brand profile has associated audience(s) and personas"
                  : "This brand profile does not have associated audience(s) or personas"}
              </Alert>
              <Button
                variant="contained"
                color={data?.length > 0 ? "success" : "error"}
                startIcon={<AudienceIcon small={true}></AudienceIcon>}
                onClick={() =>
                  navigate(`/organization/${currentOrganization.id}/audience`)
                }
              >
                View audience(s)
              </Button>
              <Button
                onClick={() => onSetMode("edit", brand)}
                variant="contained"
                disabled={!canManageBrand}
                startIcon={<AutoFixHighIcon />}
              >
                Edit attributes
              </Button>
              <Button
                onClick={() => onDeleteBrand()}
                color="error"
                variant="contained"
                disabled={!canManageBrand}
                startIcon={<HighlightOffIcon />}
              >
                Delete brand
              </Button>
            </Stack>
          </LeftPaneComponent>
          <Grid item xs={6}>
            <Box
              sx={{
                px: 10,
                pt: "70px",
              }}
            >
              <Typography textAlign={"left"} variant="h5">
                Brand Attributes
              </Typography>
              <Divider></Divider>
              <br></br>
              <Box sx={{ maxHeight: 600, overflowX: "auto" }}>
                {brand?.id &&
                  Object.entries(brand?.description_metadata).map(
                    ([name, value]) => (
                      <div key={name}>
                        {value !== "" && value.length > 0 && (
                          <AccordionComponent
                            expanded={true}
                            title={snakeCaseToTitleCase(name)}
                            content={
                              name === "products_services" ? (
                                brand.description_metadata?.products_services.map(
                                  (item, key) => {
                                    return (
                                      <Box>
                                        <Typography
                                          key={key}
                                          ml={4.5}
                                          textAlign={"left"}
                                          fontWeight={400}
                                          variant="body1"
                                        >
                                          - {item.name}
                                        </Typography>
                                        <Typography
                                          ml={8}
                                          textAlign={"left"}
                                          fontWeight={200}
                                          variant="body1"
                                        >
                                          {item.description}
                                        </Typography>
                                      </Box>
                                    );
                                  }
                                )
                              ) : (
                                <Typography
                                  ml={4.5}
                                  textAlign={"left"}
                                  fontWeight={200}
                                  variant="body1"
                                >
                                  {value}
                                </Typography>
                              )
                            }
                          ></AccordionComponent>
                        )}
                      </div>
                    )
                  )}
                {/*<List>
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <ChevronRightIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={snakeCaseToTitleCase(name)}
                      />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemIcon></ListItemIcon>
                      {name === "products_services" ? (
                        brand.description_metadata?.products_services.map(
                          (item, key) => {
                            return (
                              <Typography
                                key={key}
                                sx={{ pb: 2, fontSize: "18px" }}
                                textAlign={"left"}
                                variant="body1"
                              >
                                - {item.name}
                              </Typography>
                            );
                          }
                        )
                      ) : (
                        <Typography fontWeight={200} variant="body1">
                          {value}
                        </Typography>
                      )}
                    </ListItem>
                  </List>*/}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ pr: 10, pt: "70px" }}>
              <Typography textAlign={"left"} variant="h5">
                About {brand?.name}
              </Typography>
              <Divider></Divider>
              <br></br>
              <Alert variant="outlined" severity="warning">
                Changes to Brand Attributes will update the paragraph below
              </Alert>

              <Box mb={10} sx={{ height: 500, overflowX: "auto" }}>
                <Typography textAlign={"left"} fontWeight={200} variant="body1">
                  {brand?.summary}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
      { isSuccess && builderMode === "viewer" && brand === null && (
        <Grid container sx={{ height: "100vh" }}>
          <Grid item xs={2}>
            <Box sx={{ height: "100%", background: "#E9E8E3", pt: 10 }}>
              <Stack mx={5} spacing={2}>
                <Box
                  sx={{
                    width: 100,
                  }}
                >
                  <BrandIcon small={false} />
                </Box>
                <Typography fontWeight={"bold"} textAlign={"left"} variant="h4">
                  Brand Builder
                </Typography>
                <Typography textAlign={"left"} variant="body1" fontWeight={100}>
                  The Brand Builder looks at all facets of your brand to create
                  a complete and consistent brand identity, helping to connect
                  with and engage the audience effectively. Understanding these
                  facets is crucial for appreciating how a brand presents itself
                  and is perceived in the marketplace.
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
            >
              <Button
                startIcon={
                  <BrandIcon small={true} color={theme.palette.white.main} />
                }
                variant="contained"
                size="large"
                color="warning"
                disabled={!canManageBrand}
                onClick={() => onSetMode("create", null)}
              >
                Input my brand attributes{" "}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      )}
      {builderMode !== "viewer" && (
        <BrandBuilder callback={() => onSetMode("viewer", null)}></BrandBuilder>
      )}
    </>
  );
}
