export const deleteContentPending = (state, action) => {
  state.deleteContent.status = "pending";
};

export const deleteContentFulfilled = (state, action) => {
  state.deleteContent.status = "fulfilled";
  state.deleteContent.data = action.payload;
};

export const deleteContentRejected = (state, action) => {
  state.deleteContent.status = "rejected";
};
