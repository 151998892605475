import { Button, Typography, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText, LogoLoader, Modal } from "../../../../components";
import LoginIcon from "@mui/icons-material/Login";
import useGetStrapiProductHandler from "../../../../hooks/useGetStrapiProductHandler";
import { useSignUpAccountOwnerHandler } from "../../../../hooks/useSignupAccountOwnerHandler";
import TabSignUpHeader from "./tabSignUpHeader";

export default function SignupAccountOwner() {
  const [open, setOpen] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const {
    onSubmitForm,
    isSuccess,
    isLoading: creatingAccount,
    isError,
    error,
  } = useSignUpAccountOwnerHandler();

  const {
    isUninitialized,
    onGetStrapiProducts,
  } = useGetStrapiProductHandler();

  useEffect(() => {
    if (isError) {
      setOpen(isError);
    }
  }, [isError]);

  useEffect(() => {
    if (isUninitialized) {
      onGetStrapiProducts();
    }
  }, [isUninitialized, onGetStrapiProducts]);
  useEffect(() => {
    if (isSuccess) {
      navigate(`/signup/subscription/${params.product_id}`);
    }
  }, [isSuccess, navigate, params.product_id]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
      repeatPassword: "",
      name: "",
      last_name: "",
      organizationName: "",
      organizationDescription: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        "Invalid email address"
      ),
      password: Yup.string().required("Required"),
      passwordConfirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
      name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      organizationName: Yup.string().required("Required"),
      organizationDescription: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      onSubmitForm({
        ...values,
      });
    },
  });

  return (
    <Grid container spacing={1}>
      <Modal
        maxWidth="xs"
        open={open}
        title={"Sign Up"}
        handleClose={() => setOpen(false)}
        body={
          <>
            <Typography variant="subtitle1">
              There was an error while processing your data.
            </Typography>
            <Typography variant="subtitle2">Reason: </Typography>
            <Typography variant="caption" sx={{ color: "red !important" }}>
              {error?.error}{" "}
            </Typography>
          </>
        }
      ></Modal>
      <LogoLoader
        open={creatingAccount}
        needBackDrop={true}
        text={"Creating Account"}
      ></LogoLoader>
      <Grid item xs={12}>
        <TabSignUpHeader title={"Personal Details"} />
      </Grid>
      <Grid item md={6} xs={12}>
        <InputText
          id="name"
          name="name"
          placeholder="First Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name ? formik.errors.name : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <InputText
          id="last_name"
          name="last_name"
          value={formik.values.last_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.last_name && Boolean(formik.errors.last_name)}
          placeholder="Last Name"
          helperText={formik.touched.last_name ? formik.errors.last_name : ""}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <InputText
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          placeholder="Email"
          helperText={formik.touched.email ? formik.errors.email : ""}
        />
      </Grid>
      <Grid item spacing={1.5} container md={6} xs={12}>
        <Grid item md={6} xs={12}>
          <InputText
            type="password"
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password ? formik.errors.password : ""}
            placeholder="Password"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputText
            type="password"
            id="passwordConfirmation"
            name="passwordConfirmation"
            value={formik.values.passwordConfirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.passwordConfirmation &&
              Boolean(formik.errors.passwordConfirmation)
            }
            helperText={
              formik.touched.passwordConfirmation
                ? formik.errors.passwordConfirmation
                : ""
            }
            placeholder="Repeat Password"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mt={2}>
        <TabSignUpHeader title={"Organization Details"} />
      </Grid>
      <Grid item md={6} xs={12}>
        <InputText
          id="organization"
          name="organizationName"
          value={formik.values.organizationName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.organizationName &&
            Boolean(formik.errors.organizationName)
          }
          helperText={
            formik.touched.organizationName
              ? formik.errors.organizationName
              : ""
          }
          placeholder="Organization Name"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <InputText
          id="organizationDescription"
          name="organizationDescription"
          value={formik.values.organizationDescription}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.organizationDescription &&
            Boolean(formik.errors.organizationDescription)
          }
          helperText={
            formik.touched.organizationDescription
              ? formik.errors.organizationDescription
              : ""
          }
          placeholder="Organization Description"
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Button
          endIcon={<LoginIcon />}
          variant="contained"
          color="success"
          fullWidth
          onClick={formik.handleSubmit}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
}
