export const disableOrganizationUserPending = (state, action) => {
  state.disableOrganizationUser.status = "pending";
};

export const disableOrganizationUserFulfilled = (state, action) => {
  state.disableOrganizationUser.status = "fulfilled";
  state.disableOrganizationUser.data = action.payload.result;
};

export const disableOrganizationUserRejected = (state, action) => {
  state.disableOrganizationUser.status = "rejected";
};
