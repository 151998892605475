export const postSignupAccountOwnerPending = (state, action) => {
  state.signupAccountOwner.status = "pending";
  state.signupAccountOwner.error = null;
};

export const postSignupAccountOwnerFulfilled = (state, action) => {
  state.signupAccountOwner.status = "fulfilled";
  state.signupAccountOwner.data = action.payload;
};

export const postSignupAccountOwnerRejected = (state, action) => {
  state.signupAccountOwner.status = "rejected";
  state.signupAccountOwner.error = action.payload.response.data;
};
