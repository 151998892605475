export const postUploadContentTextPending = (state, action) => {
  state.uploadContentText.status = "pending";
};

export const postUploadContentTextFulfilled = (state, action) => {
  state.uploadContentText.status = "fulfilled";
  state.data = action.payload;
};

export const postUploadContentTextRejected = (state, action) => {
  state.uploadContentText.status = "rejected";
  state.uploadContentText.error = action.payload.response.data;
};
