import { Box, Button, Stack, Typography } from "@mui/material";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import WarningIcon from "@mui/icons-material/Warning";
import useCurrentOrganizationHandler from "../../../../../hooks/useCurrentOrganizationHandler";
import { usePersonaBuilderModeHandler } from "../../../../../hooks/usePersonaBuilderModeHandler";
import { useCurrentPersonaViewer } from "../../../../../hooks/useCurrentPersonaViewer";
import { useNavigate } from "react-router-dom";
import useGetOrgUserProfile from "../../../../../hooks/useGetOrgUserProfileHanlder";

export default function NoPersonasBtn() {
  const { canManageAudience } = useGetOrgUserProfile();
  const navigate = useNavigate();
  const { currentOrganization } = useCurrentOrganizationHandler();
  const { onSetMode } = usePersonaBuilderModeHandler();
  const { onResetPersona } = useCurrentPersonaViewer();

  const handleMakePersona = () => {
    if (currentOrganization?.id) {
      onSetMode("builder_option", null);
      onResetPersona();
      navigate(`/organization/${currentOrganization.id}/persona`);
    }
  };

  return (
    <Stack
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Box py={2}>
        <WarningIcon
          sx={{ color: "red", fontSize: "40px" }}
          aria-label="Warning"
        />
      </Box>
      <Typography width="220px" textAlign="center">
        You must have at least one persona generated to create an audience
      </Typography>
      <Button
        sx={{ width: "60%", my: 4 }}
        disabled={!canManageAudience}
        color="primary"
        size="large"
        variant="contained"
        startIcon={<SensorOccupiedIcon />}
        onClick={handleMakePersona}
        aria-label="Make a Persona"
      >
        Make a Persona
      </Button>
    </Stack>
  );
}
