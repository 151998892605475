export const getContentMetadataFolderPending = (state, action) => {
  state.contentMetadataFolder.status = "pending";
};

export const getContentMetadataFolderFulfilled = (state, action) => {
  state.contentMetadataFolder.status = "fulfilled";
  state.contentMetadataFolder.data = action.payload;
};

export const getContentMetadataFolderRejected = (state, action) => {
  state.contentMetadataFolder.status = "rejected";
};
