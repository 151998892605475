import resetAutoIngestion from "./cases/resetAutoIngestion";
import resetResetPassword from "./cases/resetResetPassword";
import resetUpdateProfile from "./cases/resetUpdateProfile";
import setAuth from "./cases/setAuth";
import setAuthPreLoad from "./cases/setAuthPreLoad";
import setCurrentProfile from "./cases/setCurrentProfile";

const cases = {
  setAuth,
  resetResetPassword,
  resetUpdateProfile,
  setAuthPreLoad,
  setCurrentProfile,
  resetAutoIngestion
};

export default cases;
