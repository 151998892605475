import { Box, Stack, Typography, Backdrop } from "@mui/material";
import SpinLoader from "../SpinLoader";

export default function LogoLoader({ text, open, variant='filled',needBackDrop=true }) {
  return (<>
  {needBackDrop ? <Backdrop
      sx={{
        color: "#fff",
        backdropFilter: "blur(4px)",
        // backgroundColor: 'rgb(0 0 0 / 88%)',
        zIndex: (theme) => theme.zIndex.drawer + 10000,
      }}
      open={open}
    >
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        sx={{
          // background: '#313131',
          padding: "5px",
          borderRadius: "10px",
        }}
      >
        <Box>
          <SpinLoader/>
        </Box>
       {text && <Typography mr={1} fontWeight={300} variant="caption">
          {text}
        </Typography>}
      </Stack>
    </Backdrop> : <>
    {open && <Stack
        flexDirection={"row"}
        alignItems={"center"}
        sx={{
          position: 'absolute',
    top: "50%",
    left: '50%',
    zIndex: '99999',
    background: variant ==='filled' ? '#282828' :'none',
    color: 'white',
          padding: "5px",
          borderRadius: "10px",
        }}
      >
        <Box>
          <SpinLoader/>
        </Box>
        {text && <Typography mr={1} sx={{
           color: 'white !important'}}fontWeight={300} variant="caption">
          {text}
        </Typography>}
      </Stack>}
    </>}
  </>
    
  );
}
