import ModalContent from "./ModalContent";
import OwnerAvatar from "./OwnerAvatar";
import DateFormatted from "./DateFormatted";
import ContentActions from "./ContentActions";
export default function ColumnsTable(toggleDrawer) {
  return [
    {
      field: "blob",
      headerName: "Name",
      flex: 1,
      display: "flex",
      alignItems: "center",
      getRowId: (row) => row.internalId,
      renderCell: (params) => <ModalContent params={params} />,
    },
    {
      field: "owner",
      headerName: "Owner",
      display: "flex",
      alignItems: "center",
      renderCell: (params) => <OwnerAvatar params={params} />,
    },
    {
      field: "id",
      headerName: "Actions",
      disableColumnMenu: true,
      disableColumnSorting: true,
      sortable: false,
      width: 300,
      renderCell: (params) =>
        params.row.isFile ? (
          <ContentActions toggleDrawer={toggleDrawer} params={params} />
        ) : null,
    },
    {
      field: "timestamp",
      headerName: "Created At",
      width: 200,
      display: "flex",
      alignItems: "center",
      renderCell: (params) => <DateFormatted params={params} />,
    },
  ];
}
