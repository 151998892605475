import { useEffect } from "react";
import { Box, Button, Stack } from "@mui/material";
import useCreateOrganizationHandler from "../../../../hooks/useCreateOrganizationHandler.js";
import { useFormik } from "formik";
import * as Yup from "yup";
import useOrganizationListHandler from "../../../../hooks/useOrganizationListHandler.js";
import { InputText } from "../../../../components/index.js";

export default function CreateOrganizationForm({ handleClose }) {
  const { onSubmitForm, isSuccess, onResetPostOrganization } =
    useCreateOrganizationHandler();
  const { onRefreshOrgList } = useOrganizationListHandler();

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(50, "Must be 20 characters or less")
        .required("Required"),
      description: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      onSubmitForm(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      onRefreshOrgList();
      handleClose();
      formik.resetForm();
      onResetPostOrganization();
    }
  }, [
    isSuccess,
    onRefreshOrgList,
    handleClose,
    formik,
    onResetPostOrganization,
  ]);
  return (
    <Box>
      <Stack
        component="form"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        spacing={2}
        noValidate
        autoComplete="off"
      >
        <InputText
          name={"name"}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          placeholder="name"
        />

        <InputText
          placeholder="description"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
        />

        <Button variant="contained" onClick={formik.handleSubmit}>
          create
        </Button>
      </Stack>
    </Box>
  );
}
