import resetBrandBuilderStatus from "./cases/resetBrandBuilderStatus";
import resetDeleteBrand from "./cases/resetDeleteBrand";
import setBrandBuilderSubNext from "./cases/setBrandBuilderSubNext";
import setMode from "./cases/setMode";
import setWizzard from "./cases/setWizzard";

const cases = {
  setWizzard,
  setMode,
  resetBrandBuilderStatus,
  setBrandBuilderSubNext,
  resetDeleteBrand
};

export default cases;
