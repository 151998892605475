import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./extraReducers";
import reducers from "./reducers";

export const contentSlice = createSlice({
  name: "audience",
  initialState: {
    createAudience: {
      data: null,
      status: "idle",
    },
    updateAudience: {
        data: null,
        status: "idle",
    },
    disableAudience: {
        data: null,
        error:null,
        status: "idle",
    },
    listAudience: {
      data: [],
      status: "idle",
    },
    currentAudience: {
      data: null,
    },
  },
  reducers,
  extraReducers,
});

export default contentSlice;
