// DISABLE PERSONA
export const putDisablePersonaPending = (state, action) => {
  state.disablePersona.status = "pending";
};

export const putDisablePersonaFulfilled = (state, action) => {
  state.disablePersona.status = "fulfilled";
  state.disablePersona.data = action.payload;
};

export const putDisablePersonaRejected = (state, action) => {
  state.disablePersona.status = "rejected";
};
