import { useDispatch, useSelector } from "react-redux";
import { organizationAPIs } from "../api_";
import {
  resetOrgUserProfile,
  selectCurrentOrganization,
  selectOrgUserProfileData,
  selectOrgUserProfileStatus,
} from "../redux/organizations";

export default function useGetOrgUserProfile() {
  const dispatch = useDispatch();
  const data = useSelector((state) => selectOrgUserProfileData(state));
  const status = useSelector((state) => selectOrgUserProfileStatus(state));
  const currentOrganization = useSelector((state) =>
    selectCurrentOrganization(state)
  );
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onGetOrgUserProfile = () => {
    if (currentOrganization) {
      dispatch(organizationAPIs.getOrgUserProfile(currentOrganization.id));
    }
  };

  const onResetOrgUserProfile = () => {
    dispatch(resetOrgUserProfile());
  };

  const canManageUsers =
    data !== null && (data.is_accounts_owner || data.is_admin || data.is_owner);

  const canManageOrganizations =
    data !== null && (data.is_accounts_owner || data.is_admin || data.is_owner);

  const canManageContent =
    data !== null &&
    (data.is_accounts_owner ||
      data.is_admin ||
      data.is_owner ||
      data.is_editor);

  const canManageFolders =
    data !== null &&
    (data.is_accounts_owner ||
      data.is_admin ||
      data.is_owner ||
      data.is_editor);

  const canManageBrand =
    data !== null &&
    (data.is_accounts_owner ||
      data.is_admin ||
      data.is_owner ||
      data.is_editor);

  const canManagePersona =
    data !== null &&
    (data.is_accounts_owner ||
      data.is_admin ||
      data.is_owner ||
      data.is_editor);

  const canManageAudience =
    data !== null &&
    (data.is_accounts_owner ||
      data.is_admin ||
      data.is_owner ||
      data.is_editor);

  const canExecuteAnalyze =
    data !== null &&
    (data.is_accounts_owner ||
      data.is_admin ||
      data.is_owner ||
      data.is_editor);

  const canExecuteAdaptToNewChannel =
    data !== null &&
    (data.is_accounts_owner ||
      data.is_admin ||
      data.is_owner ||
      data.is_editor);
  return {
    profile: data,
    onGetOrgUserProfile,
    onResetOrgUserProfile,
    canManageUsers,
    canManageOrganizations,
    canManageContent,
    canManageBrand,
    canManagePersona,
    canManageAudience,
    canExecuteAnalyze,
    canExecuteAdaptToNewChannel,
    canManageFolders,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
