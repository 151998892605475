export const putUpdateProfilePending = (state, action) => {
  state.updateProfile.status = "pending";
  state.updateProfile.error = null;
};

export const putUpdateProfileFulfilled = (state, action) => {
  state.updateProfile.status = "fulfilled";
  state.updateProfile.data = action.payload;
};

export const putUpdateProfileRejected = (state, action) => {
  state.updateProfile.status = "rejected";
  state.updateProfile.error = action.payload.response.data;
};
