import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";
import InputText from "../../../../../components/InputText";
import useContentUpdateMetadataHanlder from "../../../../../hooks/useContentUpdateMetadataHanlder";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  AudienceSelect,
  ChannelSelect,
  FoldersSelect,
  OrgUsersSelect,
} from "../../../../../components";
import useAudienceByOrganizationHandler from "../../../../../hooks/useAudienceByOrganizationHandler";
import useUsersByOrganizationHandler from "../../../../../hooks/useUsersByOrganizationHandler";
import useAllFolderHandler from "../../../../../hooks/useAllFolderHandler";

const generateSchema = (name) => {
  if (name === "name") {
    return Yup.object().shape({
      name: Yup.string()
        .max(100, "Must be 50 characters or less")
        .required("Required"),
    });
  }
  if (name === "filename") {
    return Yup.object().shape({
      filename: Yup.string()
        .max(100, "Must be 50 characters or less")
        .required("Required"),
    });
  }
  if (name === "owner") {
    return Yup.object().shape({
      owner: Yup.object().required("Required"),
    });
  }
  if (name === "channel") {
    return Yup.object().shape({
      channel: Yup.object().required("Required"),
    });
  }
  if (name === "audience") {
    return Yup.object().shape({
      audience: Yup.object().required("Required"),
    });
  }
  if (name === "folder") {
    return Yup.object().shape({
      folder: Yup.object().required("Required"),
    });
  }
};

export default function MetadataFiled({
  readOnly,
  value,
  displayValue,
  displayValueVariant,
  displayValueLabel,
  type,
  label,
  name,
  disabled
}) {
  const {
    onUpdateContentMetadata,
    isSuccess,
    isLoading,
    onResetUpdateContentMetadata,
  } = useContentUpdateMetadataHanlder();
  const [mode, setMode] = React.useState("view");
  const formik = useFormik({
    initialValues: {
      [name]: value,
    },
    validationSchema: generateSchema(name),
    onSubmit: async (values) => {
      onUpdateContentMetadata(values);
    },
  });
  const { onResetAudienceList } = useAudienceByOrganizationHandler();
  const { onResetAllFoldersList } = useAllFolderHandler();

  const { onResetUsersList } = useUsersByOrganizationHandler();
  React.useEffect(() => {
    if (isLoading && mode === "edit") {
      setMode("view");
    }
    if ((isSuccess === mode) === "view") {
      onResetUpdateContentMetadata();
    }
  }, [isSuccess, isLoading, mode, onResetUpdateContentMetadata]);

  const onCancel = () => {
    if (name === "audience") {
      onResetAudienceList();
    }
    if (name === "owner") {
      onResetUsersList()
    }
    if (name === "folder") {
      onResetAllFoldersList();
    }
    setMode("view");
  };

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {mode === "edit" && type === "text" && (
          <>
            <InputText
              name={name}
              disabled={disabled}
              value={formik.values[name]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[name] && Boolean(formik.errors[name])}
            />
          </>
        )}
        {mode === "edit" &&
          name === "channel" &&
          formik?.values?.channel.id && (
            <>
              <ChannelSelect
                disabled={disabled}
                formik={formik}
                name={"channel"}
                value={formik?.values?.channel}
                error={formik.touched.channel && Boolean(formik.errors.channel)}
              />
            </>
          )}
        {mode === "edit" && name === "audience" && (
          <AudienceSelect
            formik={formik}
            name={`audience`}
            value={formik?.values?.audience}
            error={formik.touched?.audience && Boolean(formik.errors?.audience)}
          />
        )}
        {mode === "edit" && name === "owner" && formik?.values?.owner && (
          <>
            <OrgUsersSelect
              formik={formik}
              disabled={disabled}
              name={"owner"}
              value={formik?.values?.owner}
              error={formik.touched.owner && Boolean(formik.errors.owner)}
            />
          </>
        )}

        {mode === "edit" && name === "folder" && formik?.values?.folder && (
          <FoldersSelect
            formik={formik}
            disabled={disabled}
            name={`folder`}
            value={formik?.values?.folder}
            error={formik.touched.folder && Boolean(formik.errors.folder)}
          />
        )}
        {mode === "edit" && !isLoading && (
          <>
            <Box
              onClick={formik.handleSubmit}
              sx={{ color: "#66BF76", fontSize: "11px", cursor: "pointer" }}
            >
              Save
            </Box>
            {" - "}
            <Box
              onClick={onCancel}
              sx={{ color: "#66BF76", fontSize: "11px", cursor: "pointer" }}
            >
              Cancel
            </Box>
          </>
        )}
        {mode === "view" && (
          <>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack>
                <Typography variant="caption">{label}</Typography>
                <Typography variant={displayValueVariant}>
                  {displayValue}
                </Typography>
              </Stack>
            </Stack>
            {!readOnly && !disabled && (
              <Box
                onClick={() => setMode("edit")}
                sx={{ color: "#66BF76", fontSize: "11px", cursor: "pointer" }}
              >
                {displayValueLabel}
              </Box>
            )}
          </>
        )}
      </Stack>
    </>
  );
}
