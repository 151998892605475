//Packages Imports
import { useSelector } from "react-redux";
//App Import
import { useAppDispatch } from "../redux/store";
import { selectSignUpSubscriptionData, selectSignUpSubscriptionError, selectSignUpSubscriptionStatus } from "../redux/auth";
import { authAPIs } from "../api_";
import {useEffect} from "react"
export function useSignUpSubscriptionHandler() {
  const dispatch = useAppDispatch();

  const status = useSelector((state) => selectSignUpSubscriptionStatus(state));
  const data = useSelector((state) => selectSignUpSubscriptionData(state));
  const error = useSelector((state) => selectSignUpSubscriptionError(state));

  const isUninitialized = status === undefined;
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onSubmitForm = (values) => {
    dispatch(authAPIs.postSignupSubscription({ ...values, auth:JSON.parse(localStorage.getItem('auth')) }));
  }; 
  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem("auth", JSON.stringify(data));
      return;
    }
  }, [isSuccess, data]);

  return {
    data,
    error,
    onSubmitForm,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
