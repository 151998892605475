import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { LogoLoader, Modal } from "../../../../components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Button, Typography, useTheme } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useCallback, useEffect, useState } from "react";
import usePaymentMethodUpdateHandler from "../../../../hooks/usePaymentMethodUpdateHandler";
import ErrorMessageDisplayer from "../../../../components/ErrorMessageDisplayer";
import { useLoginHandler } from "../../../../hooks/useLoginHandler";

export default function ModalUpdatePaymentMethod() {
  const stripe = useStripe();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { onRefreshProfile } = useLoginHandler();
  const elements = useElements();
  const { isSuccess, isLoading, isError, onSubmitForm, onResetPaymentMethod } =
    usePaymentMethodUpdateHandler();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      paymentId: null,
    },
    validationSchema: Yup.object({
      paymentId: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      onSubmitForm(values);
    },
  });

  const onClose = useCallback(() => {
    if (isLoading) return;
    setOpen(false);
    onResetPaymentMethod();
  }, [isLoading, setOpen, onResetPaymentMethod]);

  useEffect(() => {
    if (isSuccess) {
      onClose();
      onRefreshProfile();
    }
  }, [isSuccess, onClose, onRefreshProfile]);

  return (
    <>
      <Button
        variant={"outlined"}
        endIcon={<ModeEditIcon />}
        onClick={() => setOpen(true)}
        size="small"
      >
        Edit Payment Method
      </Button>
      <Modal
        maxWidth="sm"
        actions={
          <>
            {isError ? (
              <Button onClick={onClose} variant="contained" color="error">
                Close
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (!formik.errors.paymentId) {
                      formik.handleSubmit();
                    }
                  }}
                >
                  update
                </Button>
                <Button onClick={onClose} variant="contained" color="error">
                  cancel
                </Button>
              </>
            )}
          </>
        }
        handleClose={onClose}
        open={open}
        title={"Payment Method"}
        body={
          <>
            <LogoLoader open={isLoading} />
            {isError ? (
              <ErrorMessageDisplayer content={"error"}></ErrorMessageDisplayer>
            ) : (
              <Box
                sx={{
                  padding: "10px",
                  border: "1px solid #5e5e5e",
                  borderRadius: "10px",
                }}
              >
                <CardElement
                  id="card"
                  options={{
                    style: {
                      complete: {
                        color: theme.palette.green.main,
                      },
                      base: {
                        fontSize: "16px",
                        color: "white",
                        "::placeholder": {
                          color: "white",
                        },
                      },
                      invalid: {
                        color: theme.palette.red.main,
                      },
                    },
                  }}
                  onChange={async () => {
                    if (!stripe || !elements) {
                      return;
                    }
                    const cardElement = elements.getElement(CardElement);
                    const { error, paymentMethod } =
                      await stripe.createPaymentMethod({
                        type: "card",
                        card: cardElement,
                      });
                    if (error) {
                      formik.setFieldError("paymentId", error.message);
                      return;
                    }
                    formik.setFieldValue(
                      "paymentId",
                      paymentMethod ? paymentMethod.id : ""
                    );
                  }}
                />
              </Box>
            )}
            <Box
              sx={{
                height: 2,
              }}
            >
              <Typography color="error" variant="body1">
                {" "}
                {formik.errors.paymentId}
              </Typography>
            </Box>
          </>
        }
      />
    </>
  );
}
