import { useDispatch, useSelector } from "react-redux";
import { utilityAPIs} from "../api_";
import { selectAvatarsData, selectAvatarsStatus } from "../redux/utilities";


export default function useAvatarHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectAvatarsStatus(state));
  const data = useSelector((state) => selectAvatarsData(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onGetAvatars = ()=>{
    dispatch(utilityAPIs.getAvatars())
  }

  const filterAvatars = (gender)=>{
    if(gender){
      const regex = new RegExp(`_${gender.toLowerCase()}`); // Regex to match exact gender
      return data.filter(file => regex.test(file.avatar_blob));
    }
    return data

    }

  return {
    avatars: data,
    onGetAvatars,
    filterAvatars,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
