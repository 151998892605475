import { configureStore } from "@reduxjs/toolkit";
//reducers
import utilityReducer from "./utilities";
import authReducer from "./auth";
import organizationsReducer from "./organizations";
import personaReducer from "./persona";
import contentReducer from "./content";
import audienceReducer from "./audience";
import brandReducer from "./brand";
import adminReducer from "./admin";

export const store = configureStore({
  reducer: {
    admin: adminReducer,
    auth: authReducer,
    content: contentReducer,
    utility: utilityReducer,
    organizations: organizationsReducer,
    persona: personaReducer,
    audience: audienceReducer,
    brand: brandReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const useAppDispatch = () => store.dispatch;
