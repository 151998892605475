import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Logo } from "../../icons";
import ErrorMessageDisplayer from "../ErrorMessageDisplayer";
import Modal from "../Modal";
import MobileOffIcon from "@mui/icons-material/MobileOff";

export default function ModalBlockApp() {
  const theme = useTheme();
  const open = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Modal
      maxWidth="sm"
      fullScreen={true}
      title={""}
      open={
        open &&
        !window.location.pathname.includes("/signup") &&
        !window.location.pathname.includes("/login") &&
        !window.location.pathname.includes("/autoingestion")
      }
      disableClose={true}
      body={
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Stack justifyContent={"center"} alignItems={"center"}>
            {" "}
            <Logo width={"60%"} />
            <Typography variant="overline">
              Mobile experience not yet available
            </Typography>
          </Stack>
          <ErrorMessageDisplayer
            content={{
              icon: (
                <MobileOffIcon
                  color="red"
                  sx={{
                    fontSize: "40px",
                  }}
                />
              ),
              header: "",
              title: "",
              message: "Please use desktop or laptop only",
            }}
          />
        </Box>
      }
    />
  );
}
