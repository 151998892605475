import React, { useEffect } from "react";
import { useLoginHandler } from "../../../hooks/useLoginHandler";
import { useNavigate } from "react-router-dom";
import useOrganizationListHandler from "../../../hooks/useOrganizationListHandler";
import useGetOrgUserProfile from "../../../hooks/useGetOrgUserProfileHanlder";
import useCurrentOrganizationHandler from "../../../hooks/useCurrentOrganizationHandler";

export default function Home() {
  const navigate = useNavigate();
  const { accountsSuccess , currentProfile } = useLoginHandler();
  const { isSuccess: orgsByUserSuccess, organizations } =
    useOrganizationListHandler();

  const { isSuccess: orgUserProfileSuccess, profile: orgProfile } =
    useGetOrgUserProfile();

  const { previousOrganization } = useCurrentOrganizationHandler();
  useEffect(() => {
    if (accountsSuccess && currentProfile !== null && orgsByUserSuccess && orgUserProfileSuccess) {
      navigate("/organizations");
      if (organizations && organizations.length > 0) {
        if (previousOrganization === null) {
          if (!orgProfile.org_has_brand) {
            navigate(`/organization/${organizations[0].id}/brand`);
          } else {
            navigate(`/organization/${organizations[0].id}/content_library`);
          }
        } else {
          //selectOrganization(organizations[0])
        }
      }
    }
  }, [
    accountsSuccess,
    orgsByUserSuccess,
    orgUserProfileSuccess,
    currentProfile,
    navigate,
    orgProfile.org_has_brand,
    organizations,
    previousOrganization,
  ]);

  return <></>;
}
