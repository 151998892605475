import handleSnackbar from "./cases/handleSnackbar";
import pullhAnalyzeContentQueue from "./cases/pullAnalyzeContentQueue";
import pushAnalyzeContentQueue from "./cases/pushAnalyzeContentQueue";
import setPusherNotificationChannel from "./cases/setPusherNotificationChannel";
import setPusherNotificationCreditsUpdate from "./cases/setPusherNotificationCreditsUpdate";
import updateAnalyzeContentQueue from "./cases/updateAnalyzeContentQueue";

const cases = {
  handleSnackbar: handleSnackbar,
  pushAnalyzeContentQueue,
  pullhAnalyzeContentQueue,
  updateAnalyzeContentQueue,
  setPusherNotificationChannel,
  setPusherNotificationCreditsUpdate
};

export default cases;
