export const getStrapiProductsPending = (state, action) => {
  state.strapiProducts.status = "pending";
};

export const getStrapiProductsFulfilled = (state, action) => {
  state.strapiProducts.status = "fulfilled";
  state.strapiProducts.data = action.payload.result;
};

export const getStrapiProductsRejected = (state, action) => {
  state.strapiProducts.status = "rejected";
};
