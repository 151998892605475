import Modal from "../Modal";
import { useEffect, useState } from "react";
import { Typography, Button, IconButton } from "@mui/material";
import useAnalyzeContentHandler from "../../hooks/useAnalyzeContentHandler";
import { AnalyzeIcon } from "../../icons";
import BootstrapTooltip from "../BootstrapTooltipComponent";
import InsightsIcon from "@mui/icons-material/Insights";
import useGetAnalyzedContent from "../../hooks/useGetAnalyzedContent";
import useGetOrgUserProfile from "../../hooks/useGetOrgUserProfileHanlder";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ErrorMessageDisplayer from "../ErrorMessageDisplayer";

export default function AnalyzeConfirmationModal({
  content,
  fullWidth,
  btnType,
  has_analytics,
  disabled,
}) {
  const { canExecuteAnalyze } = useGetOrgUserProfile();
  const [open, setOpen] = useState(false);
  const {
    onAnalyzeContent,
    onResetAnalyzeContent,
    analyzeQueue,
    analyzeData,
    isSuccess,
    isError,
  } = useAnalyzeContentHandler();

  const { onGotToAnalyze, pullContentFromQueue } = useGetAnalyzedContent();

  useEffect(() => {
    if (isSuccess) {
      onResetAnalyzeContent();
      setOpen(false);
    }
  }, [isSuccess, onResetAnalyzeContent, setOpen]);

  const onConfirmationAnalyze = () => {
    onAnalyzeContent(content);
  };

  const onConfirmationNoAnalyze = () => {
    setOpen(false);
    onResetAnalyzeContent();
  };

  const hasAnalytics =
    has_analytics ||
    analyzeQueue.find((record) => record.content_id === content.id)?.status ===
      "success";

  const onClickAnalyze = () => {
    if (hasAnalytics) {
      pullContentFromQueue({ ...content, content_id: content.id });
      //onGetAnalyzedContent(content.id);
      onGotToAnalyze(content.id);
      return;
    }
    if (canExecuteAnalyze) {
      //setOpen(true);
      onConfirmationAnalyze()
    }
  };

  return (
    <>
      {btnType === "small" && (
        <BootstrapTooltip
          title={
            hasAnalytics
              ? "View Analyze Results"
              : "Analyze Results (not yet run) "
          }
        >
          <span>
            <IconButton
              variant={"contained"}
              color={
                has_analytics ||
                analyzeQueue.find((record) => record.content_id === content.id)
                  ?.status === "success"
                  ? "green"
                  : "gray"
              }
              onClick={onClickAnalyze}
              size="small"
            >
              <InsightsIcon fontSize="small"></InsightsIcon>
            </IconButton>
          </span>
        </BootstrapTooltip>
      )}
      {btnType === "textBtn" && (
        <Button
          fullWidth={fullWidth}
          disabled={!canExecuteAnalyze || disabled}
          color="warning"
          variant="contained"
          onClick={onClickAnalyze}
          startIcon={<AnalyzeIcon />}
        >
          {" "}
          Analyze
        </Button>
      )}
      {btnType === "eyeIcon" && (
        <IconButton
          disabled={!canExecuteAnalyze}
          onClick={onClickAnalyze}
          aria-label="delete"
          size="small"
          color="success"
        >
          <VisibilityOutlinedIcon fontSize="inherit" />
        </IconButton>
      )}
      
      <Modal
        maxWidth="sm"
        title={isError ? "Analyze" : "Analyze Confirmation"}
        open={open}
        actions={
          <>
            {isError ? (
              <Button
                onClick={onConfirmationNoAnalyze}
                variant="contained"
                color="error"
              >
                Close
              </Button>
            ) : (
              <>
                <Button
                  startIcon={<AnalyzeIcon></AnalyzeIcon>}
                  variant="contained"
                  onClick={onConfirmationAnalyze}
                >
                  Yes
                </Button>
                <Button
                  onClick={onConfirmationNoAnalyze}
                  variant="contained"
                  color="dark"
                >
                  No
                </Button>
              </>
            )}
          </>
        }
        body={
          <>
            {isError ? (
              <ErrorMessageDisplayer
                content={{
                  header: "The Analyze could not be executed",
                  title: analyzeData.error,
                  message: analyzeData.message,
                }}
              />
            ) : (
              <>
                <Typography variant="overline">
                  do you want to analyze it?
                </Typography>
                <br></br>
                <Typography variant="subtitle1" color={"error"}>
                  **Analyzing content will consume credits.
                </Typography>
              </>
            )}
          </>
        }
        handleClose={onConfirmationNoAnalyze}
      />
    </>
  );
}
