import { useDispatch, useSelector } from "react-redux";
import { brandBuilderQuestionsSchema } from "../constants";
import {
  selectBrandBuilderMode,
  selectBrandBuilderWizzard,
  setWizzard,
} from "../redux/brand";

export function useBrandBuilderQuestionSelectorHandler() {
  const wizzardState = useSelector((state) => selectBrandBuilderWizzard(state));
  const mode = useSelector((state) => selectBrandBuilderMode(state));
  const dispatch = useDispatch();
  /*const steps = useMemo(() => {
   
    const schemaQuestionsToUse =
      mode === "fastlane"
        ? brandBuilderQuestionsSchema.filter(
            (question) => question.isFastLane
          )
        : brandBuilderQuestionsSchema;
    return schemaQuestionsToUse.map((question, idx)=>({...question,continousIndex: idx}));
  });*/

  const schemaQuestionsToUse =
    mode === "fastlane"
      ? brandBuilderQuestionsSchema.filter((question) => question.isFastLane)
      : brandBuilderQuestionsSchema;

  const steps = schemaQuestionsToUse.map((question, idx) => ({
    ...question,
    continousIndex: idx,
  }));

  const onStepChange = (val) => {
    if (val + 1 > steps.length || val - 1 < -1) return;
    dispatch(setWizzard({ activeStep: val }));
  };

  return {
    stepsList:
      mode === "fastlane"
        ? brandBuilderQuestionsSchema.filter((question) => question.isFastLane)
        : brandBuilderQuestionsSchema,
    steps,
    activeStep: wizzardState.activeStep,
    onStepChange,
  };
}
