import { useDispatch, useSelector } from "react-redux";
import { organizationAPIs } from "../api_";
import {
  resetDisableOrganization,
  selectDisableOrganizationData,
  selectDisableOrganizationStatus,
} from "../redux/organizations";

export default function useDisableOrganizationHandler() {
  const dispatch = useDispatch();
  const data = useSelector((state) => selectDisableOrganizationData(state));
  const status = useSelector((state) => selectDisableOrganizationStatus(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onDisableOrganization = (id) => {
    dispatch(organizationAPIs.disableOrganization(id));
  };
  const onResetDisableOrganization = () => {
    dispatch(resetDisableOrganization());
  };

  return {
    disable: data,
    onDisableOrganization,
    onResetDisableOrganization,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
