import adminSlice from "./slice";

// SELECTORS
export const selectAllUsersData = (state) => state?.admin?.allUsers?.data;
export const selectAllUsersStatus = (state) => state?.admin?.allUsers?.status

export const selectPaymentMethodData = (state) => state?.admin?.paymentMethod?.data;
export const selectPaymentMethodError = (state) => state?.admin?.paymentMethod?.error;
export const selectPaymentMethodStatus = (state) => state?.admin?.paymentMethod?.status;

export const selectUpdateSubscriptionData = (state) => state?.admin?.updateSubscription?.data;
export const selectUpdateSubscriptionError = (state) => state?.admin?.updateSubscription?.error;
export const selectUpdateSubscriptionStatus = (state) => state?.admin?.updateSubscription?.status;

export const selectCancelSubscriptionData = (state) => state?.admin?.cancelSubscription?.data;
export const selectCancelSubscriptionError = (state) => state?.admin?.cancelSubscription?.error;
export const selectCancelSubscriptionStatus = (state) => state?.admin?.cancelSubscription?.status;

export const selectGenereteAPIKeyData = (state) => state?.admin?.generateAPIKey?.data;
export const selectGenereteAPIKeyError = (state) => state?.admin?.generateAPIKey?.error;
export const selectGenereteAPIKeyStatus = (state) => state?.admin?.generateAPIKey?.status;

export const selectGetAPIKeyData = (state) => state?.admin?.getAPIKey?.data;
export const selectGetAPIKeyError = (state) => state?.admin?.getAPIKey?.error;
export const selectGetAPIKeyStatus = (state) => state?.admin?.getAPIKey?.status;


// ACTIONS
export const {
    resetGetAllUsers,
    resetPaymentMethod,
    resetUpdateSubscription,
    resetCancelSubscription,
    resetGenerateAPIKey,
    resetGeAPIToken

} = adminSlice.actions

// REDUCER
export default adminSlice.reducer