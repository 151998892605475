export const postLoginPending = (state, action) => {
  state.login.status = "pending";
};

export const postLoginFulfilled = (state, action) => {
  state.login.status = "fulfilled";
  state.data = action.payload;
};

export const postLoginRejected = (state, action) => {
  state.login.status = "rejected";
  state.login.error = action.payload.response.data;
};
