import useGetAnalyzedContent from "../../../../../../hooks/useGetAnalyzedContent";
import useSuggestionsContentHandler from "../../../../../../hooks/useSuggestionsContentHandler";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import * as React from "react";
import {
  AnalyzeConfirmationModal,
  ModalBrandAligmentConfirmation,
  ModalGenerateSuggestionsConfirmation,
  ModalSaveEditsConfirmation,
} from "../../../../../../components";
import useGetOrgUserProfile from "../../../../../../hooks/useGetOrgUserProfileHanlder";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";

export default function ButtonsWrapper({
  formik,
  setMode,
  isEditting,
  setTab,
}) {
  const { analyzedContent } = useGetAnalyzedContent();
  const { canExecuteAnalyze } = useGetOrgUserProfile();
  const theme = useTheme();
  const {
    suggestionsData,
    onResetSuggestionsContent,
    isLoading: isGeneratingSuggestions,
    isSuccess: isSuggestionsGenerated,
  } = useSuggestionsContentHandler();

  const hasPendingSuggestEdits = !!analyzedContent?.audience_personas_suggestions?.copy_suggestions;


  React.useEffect(() => {
    if (isSuggestionsGenerated) {
      const rows =
        suggestionsData?.audience_personas_suggestions?.copy_suggestions.map(
          (suggestion, idx) => ({
            ...suggestion,
          })
        );
      formik.setFieldValue("status_list", rows);
      onResetSuggestionsContent();
      setMode(false, true);
      setTab(2);
    }
  }, [
    isSuggestionsGenerated,
    suggestionsData,
    setTab,
    setMode,
    onResetSuggestionsContent,
    formik,
  ]);
  return (
    <Box
      sx={{
        borderTop: "1px solid #b6b6b6",
        padding: "10px 0px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      {Boolean(analyzedContent.audience_personas_reactions) ? (
        <Grid container>
          {isEditting && (
            <Grid item xs={4}>
              <ModalSaveEditsConfirmation
                disabled={
                  !canExecuteAnalyze ||
                  (analyzedContent.content.is_current &&
                    !Boolean(analyzedContent.audience_personas_reactions)) ||
                  (!analyzedContent.content.is_current &&
                    Boolean(analyzedContent.audience_personas_reactions)) ||
                  isGeneratingSuggestions ||
                  (isEditting && Boolean(formik.errors.content_text))
                }
                onSubmit={(executeAnalyze) => {
                  formik.setFieldValue("executeAnalyze", executeAnalyze);
                  formik.handleSubmit();
                }}
                onCancel={() => {
                  setMode(false, false);
                  onResetSuggestionsContent();
                }}
              />
            </Grid>
          )}
          <Grid item xs={4} px={1}>
            <Stack>
              <Button
                fullWidth
                variant="contained"
                disabled={
                  !isEditting &&
                  (isGeneratingSuggestions || hasPendingSuggestEdits)
                }
                startIcon={isEditting ? <ClearIcon /> : <EditIcon />}
                color={isEditting ? "error" : "success"}
                onClick={() => {
                  if (isEditting) {
                    setMode(false, false);
                  } else {
                    setMode(true, false);
                  }
                  setTab(2);
                }}
              >
                {isEditting ? "Cancel" : "Edit Content"}
              </Button>
              <Typography
                variant="caption"
                fontWeight={200}
                color={theme.palette.warning.main}
              >
                {" "}
                {hasPendingSuggestEdits && !isEditting
                  ? "There are pending suggestions, please review them first"
                  : ""}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={4} px={1}>
            {hasPendingSuggestEdits &&
            !isEditting &&
            analyzedContent.content.is_current ? (
              <Button
                fullWidth
                variant="contained"
                startIcon={<VisibilityIcon />}
                color="success"
                onClick={() => {
                  const rows =
                    analyzedContent?.audience_personas_suggestions.copy_suggestions.map(
                      (suggestion, idx) => ({
                        id: idx,
                        ...suggestion,
                      })
                    );
                  formik.setFieldValue("status_list", rows);
                  setMode(false, true);
                  setTab(2);
                }}
              >
                View Suggest Edits
              </Button>
            ) : (
              !isEditting && <ModalGenerateSuggestionsConfirmation />
            )}
          </Grid>

          <Grid item xs={4}>
            {!isEditting && <ModalBrandAligmentConfirmation fullWidth={true} />}
          </Grid>
        </Grid>
      ) : (
        <AnalyzeConfirmationModal
          fullWidth={true}
          btnType={"textBtn"}
          content={{
            id: analyzedContent.content.content_id,
            title: analyzedContent.content.title,
            isAnalyze: true,
          }}
        />
      )}
    </Box>
  );
}
