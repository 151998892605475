import {
  resetOrgList,
  selectOrganizationListData,
  selectOrganizationListStatus,
} from "../redux/organizations";
import { useDispatch, useSelector } from "react-redux";
import { organizationAPIs } from "../api_";
import { selectProfileData } from "../redux/auth";

export default function useOrganizationListHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectOrganizationListStatus(state));
  const data = useSelector((state) => selectOrganizationListData(state));
  const currentProfile = useSelector((state) => selectProfileData(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onRefreshOrgList = () => {
    dispatch(organizationAPIs.getList(currentProfile.owner_id));
  };

  const onResetOrgList = () => {
    dispatch(resetOrgList());
  };

  return {
    organizations: data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onRefreshOrgList,
    onResetOrgList,
  };
}
