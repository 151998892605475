import {
  Button,
  Stack,
  Box,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect, useCallback } from "react";
import { LogoLoader, Modal } from "../../../../../components";
import { NewFolderIcon } from "../../../../../icons";
import InputText from "../../../../../components/InputText";
import { useFormik } from "formik";
import * as Yup from "yup";
import useCreateFolderHandler from "../../../../../hooks/useCreateFolderHandler";
import useGetOrgUserProfile from "../../../../../hooks/useGetOrgUserProfileHanlder";

export default function ModalNewFolder() {
  const { canManageFolders } = useGetOrgUserProfile();
  const [open, setOpen] = useState(false);
  const { isSuccess, isLoading, onCreateFolder } = useCreateFolderHandler();

  const formik = useFormik({
    initialValues: {
      folderName: "",
    },
    validationSchema: Yup.object().shape({
      folderName: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      onCreateFolder(values);
    },
  });
  const handleClose = useCallback(() => {
    if (!isLoading) {
      setOpen(false);
      formik.resetForm();
      //onResetStatusCreateFolder()
    }
  }, [isLoading, setOpen, formik]);
  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [handleClose, isSuccess]);

  const actions = (
    <>
      <Button
        fullWidth
        variant="contained"
        disabled={!canManageFolders}
        onClick={formik.handleSubmit}
        startIcon={<AddIcon />}
      >
        Make folder
      </Button>
    </>
  );
  const body = (
    <Box>
      <InputText
        name={`folderName`}
        value={formik.values.folderName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.folderName && Boolean(formik.errors.folderName)}
        placeholder={"Folder name"}
      />
    </Box>
  );
  return (
    <>
      <LogoLoader needBackDrop={true} text={"Creating Folder"} open={isLoading} />
      <Button
        variant="contained"
        disabled={!canManageFolders}
        onClick={() => !isLoading && setOpen(true)}
        color="dark"
        startIcon={<NewFolderIcon />}
      >
        Make new folder
      </Button>
      <Modal
        maxWidth="xs"
        handleClose={handleClose}
        actions={actions}
        title={
          <Stack direction={"row"} alignItems={"center"}>
            <NewFolderIcon />
            <Typography variant="h6" ml={1}>
              Make new folder
            </Typography>
          </Stack>
        }
        body={body}
        open={open}
      />
    </>
  );
}
