export const putPaymentMethodPending = (state, action) => {
  state.paymentMethod.status = "pending";
  state.paymentMethod.error = null;
};

export const putPaymentMethodFulfilled = (state, action) => {
  state.paymentMethod.status = "fulfilled";
  state.paymentMethod.data = action.payload;
};

export const putPaymentMethodRejected = (state, action) => {
  state.paymentMethod.status = "rejected";
  state.paymentMethod.error = action.payload.response.data;
};
