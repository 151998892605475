
import { Box, Stack } from "@mui/material";
export default function LogoLanding ({opacity}){
    return <Stack
          sx={{
            position: "absolute",
            //top: "-1000px",
            opacity:opacity?opacity:0,
            animationName: "logo",
            animationDuration: "4s",
          }}
          flexDirection={"row"}
        >
          <Box
            width={"60px"}
            sx={{
              position: "relative",
              animationName: "example3",
              animationDuration: "2s",
              //animationIterationCount: 'infinite'
            }}
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 22 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.66621 9.40465C8.44731 6.3866 12.8962 6.3866 14.6773 9.40465C16.4584 12.4227 14.2339 16.2029 10.6717 16.2029C7.10954 16.2029 4.88511 12.4227 6.66621 9.40465Z"
                fill="white"
              />
              <path
                d="M2.65998 18.8094C4.12905 18.8094 5.31996 17.6425 5.31996 16.2029C5.31996 14.7634 4.12905 13.5964 2.65998 13.5964C1.19091 13.5964 0 14.7634 0 16.2029C0 17.6425 1.19091 18.8094 2.65998 18.8094Z"
                fill="#66BF76"
              />
              <path
                d="M18.6815 18.8094C20.1505 18.8094 21.3414 17.6425 21.3414 16.2029C21.3414 14.7634 20.1505 13.5964 18.6815 13.5964C17.2124 13.5964 16.0215 14.7634 16.0215 16.2029C16.0215 17.6425 17.2124 18.8094 18.6815 18.8094Z"
                fill="#E3B549"
              />
              <path
                d="M10.6717 5.213C12.1408 5.213 13.3317 4.04603 13.3317 2.6065C13.3317 1.16697 12.1408 0 10.6717 0C9.20263 0 8.01172 1.16697 8.01172 2.6065C8.01172 4.04603 9.20263 5.213 10.6717 5.213Z"
                fill="#EF3937"
              />
            </svg>
          </Box>
          <Box
            sx={{
              position: "relative",
              left: "0%",
              animationName: "example2",
              animationDuration: "2s",
              //animationIterationCount: 'infinite'
            }}
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 121 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.08664 18.9848C6.3011 19.0381 5.51554 18.9848 4.71444 18.84C3.92111 18.6876 3.17445 18.4589 2.47446 18.1541C1.77446 17.8492 1.18336 17.4834 0.693359 17.0642L3.14334 14.2443C3.57889 14.6178 4.09222 14.8998 4.67555 15.0979C5.25888 15.2961 5.86554 15.3723 6.49554 15.3342C6.78331 15.3189 7.00887 15.2656 7.16442 15.1741C7.31997 15.0903 7.39775 14.976 7.3822 14.8464C7.36664 14.6101 7.21109 14.4501 6.92331 14.351C6.63553 14.2519 6.26998 14.1681 5.82665 14.0919C5.38332 14.0233 4.91666 13.9242 4.41889 13.7947C3.92111 13.6651 3.44667 13.4822 2.98779 13.2383C2.5289 12.9944 2.14002 12.6286 1.83668 12.1561C1.53335 11.6835 1.35446 11.0586 1.30002 10.2965C1.24558 9.53433 1.42447 8.80268 1.83668 8.16249C2.2489 7.5223 2.85557 6.99642 3.65667 6.58487C4.45778 6.17332 5.40666 5.92944 6.49554 5.86084C7.58442 5.79225 8.68885 5.90657 9.74662 6.2038C10.8122 6.50104 11.6755 7.00405 12.3366 7.69759L9.86329 10.5175C9.4044 10.0754 8.91441 9.79346 8.3933 9.67151C7.87219 9.54957 7.44442 9.49622 7.1022 9.51909C6.75998 9.54195 6.55776 9.60292 6.43332 9.702C6.30887 9.80108 6.25443 9.9154 6.26221 10.0526C6.27776 10.2355 6.41776 10.3803 6.69776 10.4794C6.97776 10.5785 7.33553 10.6623 7.77886 10.7385C8.22219 10.8071 8.68108 10.9138 9.17107 11.051C9.66107 11.1882 10.1277 11.3939 10.5866 11.6607C11.0377 11.9351 11.4188 12.3085 11.7299 12.781C12.0333 13.2535 12.2122 13.8861 12.2666 14.6711C12.3522 15.8905 11.9166 16.8889 10.9833 17.6663C10.0422 18.4437 8.7433 18.8933 7.07109 19L7.08664 18.9848Z"
                fill="white"
              />
              <path
                d="M27.293 18.5655V1.16602H32.3096V9.25988L31.4929 12.2398L32.1852 15.2503V18.5579H27.3007L27.293 18.5655Z"
                fill="white"
              />
              <path
                d="M51.1172 18.5655V15.2578L51.5916 12.2474L51.1172 9.26742V6.05884H55.7916V18.5655H51.1172Z"
                fill="white"
              />
              <path
                d="M56.832 10.0677V6.05892H67.2153V10.0677H56.832ZM59.5153 18.5655V1.16602H64.532V18.5655H59.5153Z"
                fill="white"
              />
              <path
                d="M81.7832 18.5656V6.23428H86.7998V18.5656H81.7832ZM89.5376 18.5656V11.493C89.5376 11.0738 89.4132 10.7461 89.1565 10.5022C88.8998 10.2583 88.5732 10.1364 88.1843 10.1364C87.9121 10.1364 87.6709 10.1898 87.4532 10.2965C87.2432 10.4031 87.0798 10.5632 86.9709 10.7614C86.8621 10.9595 86.8076 11.2034 86.8076 11.4854L84.8632 10.7842C84.8632 9.7782 85.0887 8.92461 85.5398 8.20058C85.991 7.48418 86.6054 6.92782 87.3676 6.54675C88.1376 6.16568 89.0009 5.96753 89.9731 5.96753C90.8287 5.96753 91.5987 6.16568 92.2909 6.56962C92.9831 6.97355 93.5353 7.5299 93.9398 8.23869C94.3442 8.94747 94.5542 9.78582 94.5542 10.7614V18.558H89.5376V18.5656ZM97.292 18.5656V11.493C97.292 11.0738 97.1675 10.7461 96.9109 10.5022C96.6542 10.2583 96.3275 10.1364 95.9387 10.1364C95.6664 10.1364 95.4253 10.1898 95.2076 10.2965C94.9976 10.4031 94.8342 10.5632 94.7253 10.7614C94.6164 10.9595 94.562 11.2034 94.562 11.4854L91.622 11.5082C91.622 10.3574 91.8554 9.36665 92.322 8.53592C92.7887 7.70519 93.442 7.07262 94.282 6.63059C95.1142 6.18855 96.0709 5.96753 97.1442 5.96753C98.1475 5.96753 99.042 6.17331 99.8197 6.59248C100.598 7.01165 101.204 7.61374 101.648 8.39874C102.091 9.18373 102.309 10.1288 102.309 11.2339V18.5504H97.292V18.5656Z"
                fill="white"
              />
              <path
                d="M105.895 5.11387C105.164 5.11387 104.549 4.86998 104.067 4.3746C103.585 3.87921 103.336 3.27712 103.336 2.56072C103.336 1.84431 103.577 1.2346 104.067 0.746838C104.549 0.251451 105.164 0.00756836 105.895 0.00756836C106.626 0.00756836 107.256 0.251451 107.738 0.746838C108.213 1.24223 108.454 1.84431 108.454 2.56072C108.454 3.27712 108.213 3.88683 107.738 4.3746C107.264 4.86998 106.649 5.11387 105.895 5.11387Z"
                fill="white"
              />
              <path
                d="M108.407 6.26465H103.391V18.596H108.407V6.26465Z"
                fill="white"
              />
              <path
                d="M74.2166 18.7332C70.5766 18.7332 67.6211 15.8371 67.6211 12.2703C67.6211 8.70348 70.5766 5.80737 74.2166 5.80737C77.8566 5.80737 80.8121 8.70348 80.8121 12.2703C80.8121 15.8371 77.8566 18.7332 74.2166 18.7332ZM76.3166 12.2703C76.3166 11.1347 75.3755 10.2125 74.2166 10.2125C73.0577 10.2125 72.1166 11.1347 72.1166 12.2703C72.1166 13.4058 73.0577 14.328 74.2166 14.328C75.3755 14.328 76.3166 13.4058 76.3166 12.2703Z"
                fill="white"
              />
              <path
                d="M26.3219 12.2703C26.3219 15.8371 23.3663 18.7332 19.7264 18.7332C16.0864 18.7332 13.1309 15.8371 13.1309 12.2703V6.22656H17.7742V12.4151C17.7742 13.4668 18.6453 14.3204 19.7186 14.3204C20.7919 14.3204 21.663 13.4668 21.663 12.4151V6.22656H26.3063V12.2703H26.3219Z"
                fill="white"
              />
              <path
                d="M48.41 18.7332C44.77 18.7332 41.8145 15.8371 41.8145 12.2703C41.8145 8.70348 44.77 5.80737 48.41 5.80737C52.0499 5.80737 51.8866 8.70348 51.8866 12.2703C51.8866 15.8371 52.0499 18.7332 48.41 18.7332ZM50.5099 12.2703C50.5099 11.1347 49.5688 10.2125 48.41 10.2125C47.2511 10.2125 46.31 11.1347 46.31 12.2703C46.31 13.4058 47.2511 14.328 48.41 14.328C49.5688 14.328 50.5099 13.4058 50.5099 12.2703Z"
                fill="white"
              />
              <path
                d="M34.6672 18.7332C31.0272 18.7332 31.1906 15.8371 31.1906 12.2703C31.1906 8.70348 31.0272 5.80737 34.6672 5.80737C38.3072 5.80737 41.2627 8.70348 41.2627 12.2703C41.2627 15.8371 38.3072 18.7332 34.6672 18.7332ZM36.7672 12.2703C36.7672 11.1347 35.8261 10.2125 34.6672 10.2125C33.5083 10.2125 32.5672 11.1347 32.5672 12.2703C32.5672 13.4058 33.5083 14.328 34.6672 14.328C35.8261 14.328 36.7672 13.4058 36.7672 12.2703Z"
                fill="white"
              />
              <path
                d="M115.888 14.3661C114.73 14.3661 113.788 13.4439 113.788 12.3084C113.788 11.1728 114.73 10.2506 115.888 10.2506C116.34 10.2506 116.752 10.3954 117.094 10.624L120.602 7.7889C119.404 6.59235 117.74 5.84546 115.888 5.84546C112.248 5.84546 109.293 8.74157 109.293 12.3084C109.293 15.8751 112.248 18.7713 115.888 18.7713C117.95 18.7713 119.785 17.8414 120.998 16.3934L117.032 14.0308C116.705 14.2366 116.316 14.3661 115.896 14.3661H115.888Z"
                fill="white"
              />
            </svg>
          </Box>
                </Stack>
}