import { selectRolesData, selectRolesStatus } from "../redux/utilities";
  import { useDispatch, useSelector } from "react-redux";
  import { useEffect } from "react";
  import { utilityAPIs } from "../api_";

  export default function useRoleHandler() {
    const dispatch = useDispatch();
    const status = useSelector((state) => selectRolesStatus(state));
    const data = useSelector((state) => selectRolesData(state));
    const isUninitialized = status === "idle";
    const isLoading = status === "pending" || status === "idle";
    const isError = status === "rejected";
    const isSuccess = status === "fulfilled";
  
    useEffect(() => {
      if (isUninitialized) {
        dispatch(
            utilityAPIs.getRoles()
        );
      }
    }, [isUninitialized, dispatch]);
  
    return {
      roles: data,
      isUninitialized,
      isLoading,
      isError,
      isSuccess,
    };
  }
  