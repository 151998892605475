export const postSetNewPasswordPending = (state, action) => {
  state.setNewPassword.status = "pending";
};

export const postSetNewPasswordFulfilled = (state, action) => {
  state.setNewPassword.status = "fulfilled";
  state.setNewPassword.data = action.payload;
};

export const postSetNewPasswordRejected = (state, action) => {
  state.setNewPassword.status = "rejected";
  state.setNewPassword.error = action.payload.response.data;
};
