
import { useState } from "react";
import { Button, Box, Typography } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { useFormik } from "formik";
import * as Yup from "yup";
import useGetOrgUserProfile from "../../../../../../hooks/useGetOrgUserProfileHanlder";
import { usePersonaBuilderModeHandler } from "../../../../../../hooks/usePersonaBuilderModeHandler";
import { InputText, Modal } from "../../../../../../components";

const ModalImportPersona = () => {
  const { canManagePersona } = useGetOrgUserProfile();
  const [open, setOpen] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const { onSubmitForm, isLoading, persona } = usePersonaBuilderModeHandler();

  const formik = useFormik({
    initialValues: {
      description: "",
    },
    validationSchema: Yup.object({
      description: Yup.string()
        .min(100, "Must be 100 characters or less")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      onSubmitForm(values, persona);
    },
  });

  return (
    <>
      <Button
        color="success"
        disabled={!canManagePersona}
        onClick={() => !isLoading && setOpen(true)}
        variant="contained"
      >
        Import an existing persona
      </Button>
      <Modal
        actions={
          <>
            {
            !confirmation? <>
              <Button
              variant="contained"
              onClick={() => setConfirmation(true)}
            >
             Agree
            </Button>
            </>: <Button
              variant="contained"
              onClick={() => !isLoading && formik.handleSubmit()}
              startIcon={<AddIcon />}
            >
              Upload persona
            </Button>}
            <Button
              variant="contained"
              color="red"
              onClick={() => setOpen(false)}
            >
              CANCEL
            </Button>
          </>
        }
        body={
          <>
            {confirmation ? (
              <Box sx={{ mt: 2, minWidth: 120 }}>
                <InputText
                  multiline={true}
                  rows={15}
                  name={"description"}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  placeholder={"Write or paste your existing persona here"}
                />
              </Box>
            ) : (
              <>
                <Typography variant="overline">
                  do you want to continue?
                </Typography>
                <br></br>
                <Typography variant="subtitle1" color={"error"}>
                  ** this action will consume credits
                </Typography>
              </>
            )}
          </>
        }
        title="Import your persona"
        open={open}
        handleClose={() => {
          if (!isLoading) {
            formik.resetForm();
            setOpen(false);
          }
        }}
      />
    </>
  );
};

export default ModalImportPersona;
