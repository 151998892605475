import { useDispatch, useSelector } from "react-redux";
import {
  resetGenerateAPIKey,
  selectGenereteAPIKeyData,
  selectGenereteAPIKeyError,
  selectGenereteAPIKeyStatus,
} from "../redux/admin";
import { adminAPIs } from "../api_";

export default function useAdminGenerateAPIKeyHandler() {
  const dispatch = useDispatch();
  const data = useSelector((state) => selectGenereteAPIKeyData(state));
  const status = useSelector((state) => selectGenereteAPIKeyStatus(state));
  const error = useSelector((state) => selectGenereteAPIKeyError(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onGenerateAPIKey = () => {
    dispatch(adminAPIs.postGenerateAPIKey());
  };

  const onResetGenerateAPIKey = () => {
    dispatch(resetGenerateAPIKey());
  };

  return {
    apiKey: data,
    error,
    onGenerateAPIKey,
    onResetGenerateAPIKey,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
