import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./extraReducers";
import reducers from "./reducers";

export const brandSlice = createSlice({
  name: "brand",
  initialState: {
    mode:"viewer",
    brands:{
      status:'idle',
      data:null
    },
    delete:{
      status:'idle',
      data:null
    },
    builder: {
      status: "idle",
      data: {},
      error:null,
      submitNext:false,
      wizzard:{
        activeStep: 0,
      }
    },
  },
  reducers,
  extraReducers,
});

export default brandSlice;
