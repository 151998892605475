const constants = {
  options: {
    LOGIN: "LOGIN",
    SIGNUP: "SIGNUP",
    SIGNUP_SUBSCRIPTION: "SIGNUP_SUBSCRIPTION",
    RESET_PASS: "RESET_PASS",
    SET_NEW_PASS: "SET_NEW_PASS",
  },
};
export default constants;
