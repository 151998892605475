import { useDispatch, useSelector } from "react-redux";
import { organizationAPIs } from "../api_";
import { resetDisableOrganizationUser, selectDisableOrganizationUserData, selectDisableOrganizationUserStatus } from "../redux/organizations";

export default function useDisableOrganizationUserHandler() {
  const dispatch = useDispatch();
  const data = useSelector((state) => selectDisableOrganizationUserData(state));
  const status = useSelector((state) => selectDisableOrganizationUserStatus(state));
  
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onDisableOrganizationUser = (user) => {
    dispatch(organizationAPIs.disableOrganizationUser({
      id:user.organization_id,
      orgUserId:user.org_user_id
    }));
  };
const onResetDisableOrganizatioUser = ()=>{
  dispatch(resetDisableOrganizationUser());
}

  return {
    disable: data,
    onDisableOrganizationUser,
    onResetDisableOrganizatioUser,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
