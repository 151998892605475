import { darken, useTheme } from "@mui/material";

const Leaf = (props) => {
    const theme = useTheme();
    let { attributes, children, leaf } = props;
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }
  
    if (leaf.code) {
      children = <code>{children}</code>;
    }
  
    if (leaf.italic) {
      children = <em>{children}</em>;
    }
  
    if (leaf.underline) {
      children = <u>{children}</u>;
    }
    if (leaf.status === "to_replace") {
      return (
        <>
          <span
            {...attributes}
            
            contentEditable={false}
            style={{
              color:
                leaf.status === "to_replace" &&
                darken(theme.palette.red.main, 0.12),
              border:
                leaf.status === "to_replace" &&
                `1px dotted ${theme.palette.red.main}`,
              borderLeft: leaf.status === "to_replace" && "0px",
              borderRight: leaf.status === "to_replace" && "0px",
              textDecoration: leaf.status === "to_replace" && "line-through",
              cursor: "pointer",
              lineHeight: 1.7,
            }}
          >
            <span
              contentEditable={false}
              style={{
                position: "relative",
                width: "100%",
                marginBottom: "7px",
                height: "0px",
              }}
            ></span>
            {children}
          </span>
        </>
      );
    }
    if (
      leaf?.status === "suggested" ||
      !leaf.status ||
      leaf.status === "accepted"
    ) {
      return (
        <span
          {...attributes}
          className={leaf?.status === "suggested" ? "to_replace" :""}
          id={props?.text?.id}
          style={{
            color: leaf.status === "suggested" && theme.palette.green.main,
            border:
              leaf.status === "suggested" &&
              `1px dotted ${theme.palette.green.main}`,
            borderLeft: leaf.status === "suggested" && "0px",
            borderRight: leaf.status === "suggested" && "0px",
            cursor: "pointer",
            lineHeight: 1.7,
          }}
        >
          {children}
        </span>
      );
    }
    if (leaf?.status === "rejected" || leaf?.status === "rejected") {
      return <span></span>;
    }
  
    return <span {...attributes}>{children}</span>;
  };

  export default Leaf