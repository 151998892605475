import * as React from "react";
import { Box, Select, CircularProgress, Chip, MenuItem } from "@mui/material";
import InputText from "../InputText";
import useUsersByOrganizationHandler from "../../hooks/useUsersByOrganizationHandler";

export default function OrgUsersSelect({ formik, name, value, error }) {
  const { onGetUsers, isLoading, users, isSuccess, isUninitialized } =
    useUsersByOrganizationHandler();
  React.useEffect(() => {
    if (isUninitialized) {
      onGetUsers();
    }
  }, [isUninitialized, onGetUsers]);

  return (
    <Select
      displayEmpty
      MenuProps={{
        PaperProps: {
          style: {
            //maxHeight: 48 * 4.5 + 8,
            // width: 250,
          },
        },
      }}
      name={name} //
      value={
        isSuccess && value !== ""
          ? users.find((i) => i.org_user_id === value.id)
          : ""
      }
      error={error}
      startAdornment={isLoading && <CircularProgress size={"20px"} />}
      input={<InputText placeholder="Choose user" />}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      renderValue={(selected) => {
        if (!selected) return <em>{"Choose user"}</em>;
        return selected ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            <Chip
              sx={{ height: "20px !important" }}
              key={value.id}
              label={`${value.name} ${value.last_name}`}
            />
          </Box>
        ) : (
          <em>{"Choose user"}</em>
        );
      }}
      inputProps={{ "aria-label": "Without label" }}
    >
      <MenuItem disabled value="">
        <em>Choose user</em>
      </MenuItem>
      {isSuccess &&
        users.map((user, idx) => (
          <MenuItem
            sx={{
              textAlign: "center",
              pl: 5,
            }}
            key={idx}
            value={user}
          >
            {`${user.name} ${user.last_name}`}
          </MenuItem>
        ))}
    </Select>
  );
}
