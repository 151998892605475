import { useDispatch, useSelector } from "react-redux";
import { brandAPIs } from "../api_";
import {
  resetDeleteBrand,
  selectBrandsData,
  selectDeleteBrandData,
  selectDeleteBrandStatus,
} from "../redux/brand";
import { selectCurrentOrganization } from "../redux/organizations";

export default function useRemoveBrand() {
  const dispatch = useDispatch();

  const status = useSelector((state) => selectDeleteBrandStatus(state));
  const data = useSelector((state) => selectDeleteBrandData(state));

  const currentBrand = useSelector((state) => selectBrandsData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onDeleteBrand = () => {
    dispatch(
      brandAPIs.deleteBrand({
        orgId: currentOrg.id,
        id: currentBrand.id,
      })
    );
  };

  const onReseteDeleteBrand = ()=>{
    dispatch(resetDeleteBrand())
  }

  return {
    roles: data,
    onDeleteBrand,
    onReseteDeleteBrand,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
