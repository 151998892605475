import { Grid, Box } from "@mui/material";
import { useLoginHandler } from "../../../hooks/useLoginHandler";
import { HomeMenuComponent } from "../../../components";
import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CodeIcon from "@mui/icons-material/Code";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import PersonalInfoTab from "./components/PersonalInfoTab";
import SubscriptionInfoTab from "./components/SubscriptionInfoTab";
import DeveloperInfoTab from "./components/DeveloperInfoTab";
import useGetOrgUserProfile from "../../../hooks/useGetOrgUserProfileHanlder";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            height: "81.7vh",
            border: "1px solid #e8e8e8",
            borderLeft: "none",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Profile() {
  const { currentProfile } = useLoginHandler();
  const { profile } = useGetOrgUserProfile();

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <HomeMenuComponent>
        {currentProfile && (
          <Grid
            container
            sx={{
              ml: 1,
            }}
          >
            <Grid item xs={2}>
              <Tabs
                value={value}
                sx={{
                  height: "100%",
                  //xs padding: 1,
                  color: "red",
                  borderRight: "1px solid #e8e8e8",
                }}
                onChange={handleChange}
                aria-label="basic tabs example"
                indicatorColor="secondary"
                textColor="inherit"
                orientation="vertical"
                variant="scrollable"
              >
                <Tab
                  sx={{ fontSize: "11px" }}
                  iconPosition="top"
                  icon={<FolderSharedIcon></FolderSharedIcon>}
                  label="Personal Details"
                  {...a11yProps(0)}
                />
                {(currentProfile.is_accounts_owner ||
                  profile.is_admin ||
                  profile.is_owner) && (
                  <Tab
                    sx={{ fontSize: "11px" }}
                    iconPosition="top"
                    icon={<LocalPoliceIcon></LocalPoliceIcon>}
                    label="Account Subscription"
                    {...a11yProps(1)}
                  />
                )}
                {(currentProfile.is_accounts_owner ||
                  profile.is_admin ||
                  profile.is_owner) && (
                  <Tab
                    sx={{ fontSize: "11px" }}
                    iconPosition="top"
                    icon={<CodeIcon></CodeIcon>}
                    label="Developers"
                    {...a11yProps(2)}
                  />
                )}
              </Tabs>
            </Grid>
            <Grid item xs={10}>
              <CustomTabPanel value={value} index={0}>
                <PersonalInfoTab />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <SubscriptionInfoTab />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <DeveloperInfoTab />
              </CustomTabPanel>
            </Grid>
          </Grid>
        )}
      </HomeMenuComponent>
    </>
  );
}
