import {
  resetCurrentOrganization,
  selectCurrentOrganization,
  selectOrganizationUsersData,
  selectOrganizationUsersStatus,
  selectPreviousOrganization,
  setCurrentOrganization,
  setPreviousOrganization,
} from "../redux/organizations";
import { useDispatch, useSelector } from "react-redux";

export default function useCurrentOrganizationHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectOrganizationUsersStatus(state));
  const data = useSelector((state) => selectOrganizationUsersData(state));
  const currentOrganization = useSelector((state) =>
    selectCurrentOrganization(state)
  );
  const previousOrganization = useSelector((state) =>
    selectPreviousOrganization(state)
  );

  const isUninitialized = status === "idle";
  const isLoading = status === "pending" || status === "idle";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const selectOrganization = (organization) => {
    dispatch(setCurrentOrganization(organization));
  };

  const onResetCurrentOrganization = ()=>{
    dispatch(resetCurrentOrganization())
  }

  const onSetPreviousOrganization = ()=>{
    dispatch(setPreviousOrganization())
  }

  return {
    users: data,
    selectOrganization,
    onResetCurrentOrganization,
    onSetPreviousOrganization,
    currentOrganization,
    previousOrganization,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
