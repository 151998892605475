import Modal from "../Modal";
import { useEffect, useState } from "react";
import {
  Typography,
  Button,
} from "@mui/material";
import { AddContentIcon, AnalyzeIcon } from "../../icons";
import useGetAnalyzedContent from "../../hooks/useGetAnalyzedContent";
import useGetOrgUserProfile from "../../hooks/useGetOrgUserProfileHanlder";
import ErrorMessageDisplayer from "../ErrorMessageDisplayer";
import useSuggestionsContentHandler from "../../hooks/useSuggestionsContentHandler";
import LogoLoader from "../LogoLoader";

export default function ModalGenerateSuggestionsConfirmation({disabled}) {
    const [open, setOpen] = useState(false)
    const { analyzedContent } = useGetAnalyzedContent();
    const {canExecuteAnalyze } = useGetOrgUserProfile()
    const {
      suggestionsData,
      onResetSuggestionsContent,
      onGenerateSuggestionsContent,
      isLoading,
      isSuccess,
      isError
    } = useSuggestionsContentHandler();
  useEffect(() => {
    if (isSuccess || isError) {
      setOpen(false);
      onResetSuggestionsContent()
    }
  }, [isSuccess, isError, onResetSuggestionsContent]);
  const onConfirmationSuggestEdits = () => {
    if (
        !analyzedContent?.audience_personas_suggestions
      ) {
        onGenerateSuggestionsContent();
      } else if (
        analyzedContent?.audience_personas_suggestions &&
        analyzedContent?.audience_personas_suggestions.copy_suggestions.filter(
          (val) => val.status === "recommended"
        ).length === 0
      ) {
        onGenerateSuggestionsContent();
        return;
      }
  };

  const onConfirmationNoSuggestEdits = () => {
    setOpen(false);
    onResetSuggestionsContent()
  };

  const onClickGetSuggestEdits = () => {
    if (canExecuteAnalyze) {
      //setOpen(true);
      onConfirmationSuggestEdits()
    }
  };

  return (
    <>
    <LogoLoader needBackDrop={true} open={isLoading} text={'Generating Suggestions'}/>
       <Button
          disabled={!canExecuteAnalyze || disabled || !analyzedContent.content.is_current}
          color="success"
          variant="contained"
          onClick={onClickGetSuggestEdits}
          startIcon={<AddContentIcon />}
        >
          {" "}
          Get Suggest Edits
        </Button>
      <Modal
        maxWidth="sm"
        title={isError ? "Suggest Edits " : "Get Suggest Edits Confirmation"}
        open={open}
        actions={
          <>
            {isError ? (
              <Button
                onClick={onConfirmationNoSuggestEdits}
                variant="contained"
                color="error"
              >
                Close
              </Button>
            ) : (
              <>
                <Button
                  startIcon={<AnalyzeIcon></AnalyzeIcon>}
                  variant="contained"
                  onClick={onConfirmationSuggestEdits}
                >
                  Yes
                </Button>
                <Button
                  onClick={onConfirmationNoSuggestEdits}
                  variant="contained"
                  color="error"
                >
                  No
                </Button>
              </>
            )}
          </>
        }
        body={
          <>
            {isError ? (
              <ErrorMessageDisplayer
                content={{
                  header: "The get suggest edits could not be executed",
                  title: suggestionsData.error,
                  message: suggestionsData.message,
                }}
              />
            ) : (
              <>
                <Typography variant="overline">
                  do you want to get suggest edits?
                </Typography>
                <br></br>
                <Typography variant="subtitle1" color={"error"}>
                  **Get suggest edits will consume credits.
                </Typography>
              </>
            )}
          </>
        }
        handleClose={onConfirmationNoSuggestEdits}
      />
    </>
  );
}
