export const getOrganizationChannelsPending = (state, action) => {
  state.channels.status = "pending";
};

export const getOrganizationChannelsFulfilled = (state, action) => {
  state.channels.status = "fulfilled";
  state.channels.data = action.payload.result;
};

export const getOrganizationChannelsRejected = (state, action) => {
  state.channels.status = "rejected";
};
