export const postAnalyzeContentPending = (state, action) => {
  state.analyzeContent.status = "pending";
};

export const postAnalyzeContentFulfilled = (state, action) => {
  state.analyzeContent.status = "fulfilled";
  state.analyzeContent.data = action.payload;
};

export const postAnalyzeContentRejected = (state, action) => {
  state.analyzeContent.status = "rejected";
  state.analyzeContent.data = action.payload.response.data;
};
