import { Modal } from "../../../../components";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import ErrorMessageDisplayer from "../../../../components/ErrorMessageDisplayer";
import useCancelSubscriptionHandler from "../../../../hooks/useCancelSubscriptionHandler";

export default function ModalCancelSubscription() {
  const [open, setOpen] = useState(false);

  const {
    error,
    isSuccess,
    isLoading,
    isError,
    onSubmitForm,
    onResetCancelSubscription,
  } = useCancelSubscriptionHandler();

  useEffect(() => {
    if (isSuccess) {
      window.location.reload();
    }
  }, [isSuccess]);

  const onClose = () => {
    if (isLoading) return;
    setOpen(false);
    onResetCancelSubscription();
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        color="red"
        sx={{ fontSize: "10px", p: 1.2 }}
      >
        cancel subscription
      </Button>
      <Modal
        maxWidth="sm"
        actions={
          <>
            {isError ? (
              <Button onClick={onClose} variant="contained" color="error">
                Close
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => onSubmitForm()}
                  color="error"
                  variant="text"
                >
                  Agree to cancel subscription
                </Button>
                <Button onClick={onClose} variant="contained" color="success">
                  I want to keep my subscription
                </Button>
              </>
            )}
          </>
        }
        handleClose={onClose}
        open={open}
        title={"Cancel Subscription"}
        body={
          <>
            {isError ? (
              <ErrorMessageDisplayer
                content={{
                  header: "Subatomic Subscription",
                  message: error.error,
                }}
              ></ErrorMessageDisplayer>
            ) : (
              <ErrorMessageDisplayer
                content={{
                  header: "Subatomic Subscription Cancelation",
                  message: "Are you sure you want to cancel your subscription?",
                }}
              ></ErrorMessageDisplayer>
            )}
          </>
        }
      />
    </>
  );
}
