export default function useDateFormat() {
  const getFormattedDate = (timestamp) => {
    const date = new Date(timestamp);
    const dateString = date.toLocaleDateString();
    const timeString = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${dateString} ${timeString}`;
  };
  return {
    getFormattedDate,
  };
}
