import routes from "../routes";
import { buildUrl } from "../helpers/url";
import { getHeaders } from "../helpers/headers";
import { handleResponse } from "../helpers/handleResponse";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../axios";

const getAllFolders = createAsyncThunk(
  "content/getAllFolders",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(
          routes.organization.content.listBlobs(data.id, data.folder, "folders")
        ),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const getContentNavigator = createAsyncThunk(
  "content/getContentNavigator",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(
          routes.organization.content.libraryNavigator(
            data.id,
            data.folder,
            "folders"
          )
        ),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const getContentMetadata = createAsyncThunk(
  "content/getContentMetadata",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.organization.content.metadata(data.id, data.blobId)),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const getContentMetadataFolder = createAsyncThunk(
  "content/getContentMetadataFolder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(
          routes.organization.content.metadataFolder(data.id, data.blobId)
        ),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const putUpdateContentMetadata = createAsyncThunk(
  "content/putUpdateContentMetadata",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        buildUrl(
          routes.organization.content.updateContentMetadata(
            data.id,
            data.blobId
          )
        ),
        { ...data.values },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const deleteContent = createAsyncThunk(
  "content/deleteContent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        buildUrl(
          routes.organization.content.deleteContent(
            data.orgId,
            data.id
          )
        ),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);


const postAnalyzeContent = createAsyncThunk(
  "content/postAnalyzeContent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.organization.content.analyzeContent(data.orgId, data.id)),
        { ...data.values },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);
const getAnalyzeContent = createAsyncThunk(
  "content/getAnalyzeContent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.organization.content.analyzeContent(data.orgId, data.id)),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const postSuggestionsContent = createAsyncThunk(
  "content/postSuggestionsContent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.organization.content.sugestionsContent(data.orgId, data.id)),
        { },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const putHandleContentEdits = createAsyncThunk(
  "content/putHandleContentEdits",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        buildUrl(routes.organization.content.contentEdits(data.orgId, data.id)),
        {...data.values},
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const postBrandReaction = createAsyncThunk(
  "content/postBrandReaction",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.organization.content.brandReactionContent(data.orgId, data.id)),
        { },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const postGenerateDraftContent = createAsyncThunk(
  "content/postGenerateDraftContent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.organization.content.generateDraft(data.orgId)),
        { ...data.values },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const getWatchVersion = createAsyncThunk(
  "content/getWatchVersion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.organization.content.setVersion(data.orgId, data.id, data.versionId)),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);


const getAnalyzeExportPDF = createAsyncThunk(
  "content/getAnalyzeExportPDF",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.organization.content.exportPDF(data.orgId, data.contentId, data.versionId)),
        {...getHeaders(),
          responseType: "blob"
        },

      );
      const contentDisposition = response.headers['content-disposition'];
      let filename = `${(new Date()).toDateString()}.pdf`;
      
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=([^;\n]+)/);
        if (fileNameMatch && fileNameMatch[1]) {
          filename = fileNameMatch[1];
        }
      }
      
      return {
        file: handleResponse(response),
        filename,
        data
      };
      
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const postAdaptContentChannel = createAsyncThunk(
  "content/postAdaptContentChannel",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.organization.content.adaptChannel(data.orgId, data.contentId, data.channelId)),
        {...data.values},
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const content = {
  getAllFolders,
  getContentNavigator,
  getContentMetadata,
  getContentMetadataFolder,
  putUpdateContentMetadata,
  postAnalyzeContent,
  getAnalyzeContent,
  postSuggestionsContent,
  putHandleContentEdits,
  postBrandReaction,
  getWatchVersion,
  postAdaptContentChannel,
  deleteContent,
  getAnalyzeExportPDF,
  postGenerateDraftContent
};

export default content;
