import {
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useContentMetadataHandler from "../../hooks/useContentMetadataHandler";
import useUploadContentTextHandler from "../../hooks/useUploadContentTextHandler";
import useContentAdaptChannelHandler from "../../hooks/useContentAdaptChannelHandler";
import { AdaptIcon } from "../../icons";
import Modal from "../Modal";
import LogoLoader from "../LogoLoader";
import BootstrapTooltip from "../BootstrapTooltipComponent";
import useGetOrgUserProfile from "../../hooks/useGetOrgUserProfileHanlder";
import ErrorMessageDisplayer from "../ErrorMessageDisplayer";
import AdaptContentForm from "./AdaptContentForm";
import AdaptedContentForm from "./AdaptedContentForm";

export default function ModalAdaptContent({ content, btnType, disabled }) {
  const { canExecuteAdaptToNewChannel } = useGetOrgUserProfile();
  const [open, setOpen] = useState(false);
  const [temp, setTemp] = useState(false);
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const {
    contentAdapted,
    isLoading: isAdapting,
    isSuccess: isAdapted,
    isError,
    onAdaptContentChannel,
    onResetAdaptContentChannel,
  } = useContentAdaptChannelHandler();
  const { isSuccess, onUploadContentText, onResetUploadContentText } =
    useUploadContentTextHandler();
  const {
    data: contentData,
    isSuccess: contentDataLoaded,
    onGetContentMetadata,
    isUninitialized,
    onResetCurrentContent,
  } = useContentMetadataHandler();

  const formikSaveAdaptedContent = useFormik({
    initialValues: {
      contentText: [],
      adaptionType: "",
      audience: "",
      channel: "",
      contentId: "",
      folder: "",
      title: "",
    },
    validationSchema: Yup.object().shape({
      contentText: Yup.array().required("Required"),
    }),
    onSubmit: async (values) => {
      onUploadContentText(values);
    },
  });

  const formikGenerateAdaptContent = useFormik({
    initialValues: {
      channel: "",
      adaption_type: "supporting",
    },
    validationSchema: Yup.object().shape({
      channel: Yup.object().required("Required"),
    }),
    onSubmit: async (values) => {
      onAdaptContentChannel(values);
    },
  });

  useEffect(() => {
    if (isAdapted && !loaded) {
      formikSaveAdaptedContent.setValues({
        contentText: [
          {
            type: "paragraph",
            children: [{ text: contentAdapted.content_text }],
          },
        ],
        adaptionType: contentAdapted.adaption_type,
        audience: contentAdapted.audience,
        channel: contentAdapted.channel,
        contentId: contentAdapted.content_id,
        folder: contentAdapted.folder,
        title: contentAdapted.title,
      });
      setLoaded(true);
    }
  }, [isAdapted,loaded, setLoaded, contentAdapted, formikSaveAdaptedContent]);

  const handleClose = useCallback(() => {
    if (!isAdapting) {
      setOpen(false);
      onResetUploadContentText();
      formikGenerateAdaptContent.resetForm();
      formikSaveAdaptedContent.resetForm();
      onResetAdaptContentChannel();
      onResetCurrentContent();
    }
  }, [
    isAdapting,
    setOpen,
    onResetUploadContentText,
    formikGenerateAdaptContent,
    formikSaveAdaptedContent,
    onResetAdaptContentChannel,
    onResetCurrentContent,
  ]);

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess, handleClose]);

  useEffect(() => {
    if (open && isUninitialized) {
      if (content && content?.metadata) {
        setData(content);
      } else {
        if (content && content?.content_id) {
          onResetCurrentContent();
          onGetContentMetadata(content.content_id);
        }
      }
      return;
    }
  }, [
    open,
    isUninitialized,
    setData,
    onResetCurrentContent,
    onGetContentMetadata,
    content,
  ]);

  useEffect(() => {
    if (contentDataLoaded && data === null) {
      setData(contentData);
    }
  }, [contentData,data, contentDataLoaded, setData]);

  return (
    <>
      {btnType === "small" ? (
        <BootstrapTooltip title="Adapt to new channel">
          <IconButton
            disabled={!canExecuteAdaptToNewChannel || disabled}
            onClick={() => setOpen(true)}
          >
            <AdaptIcon color="#000" />
          </IconButton>
        </BootstrapTooltip>
      ) : (
        <Button
          color={"primary"}
          variant="contained"
          disabled={!canExecuteAdaptToNewChannel || disabled}
          onClick={() => setOpen(true)}
          sx={{
            backgroundColor: "#0048D9 !important",
          }}
          startIcon={<AdaptIcon />}
        >
          Adapt to New Channel
        </Button>
      )}
      <Modal
        maxWidth={isError ? "sm" : temp ? "lg" : "md"}
        handleClose={handleClose}
        actions={
          <>
            {isAdapted ? (
              <Button
                onClick={formikSaveAdaptedContent.handleSubmit}
                color="primary"
                variant="contained"
              >
                + Save Channel Adaptation
              </Button>
            ) : (
              <Button
                disabled={isAdapting}
                onClick={() => {
                  formikGenerateAdaptContent.submitForm();
                  setTemp(true);
                }}
                color="primary"
                variant="contained"
              >
                + Begin Adaptation
              </Button>
            )}
            <Button onClick={handleClose} variant="contained" color="error">
              Close
            </Button>
          </>
        }
        title={
          <Stack direction={"row"} alignItems={"center"}>
            <AdaptIcon />
            <Typography variant="h6" ml={1}>
              {isError ? "Adapt" : "Adapt to New Channel"}
            </Typography>
          </Stack>
        }
        body={
          <>
            <LogoLoader
              text={"adapting content"}
              open={isAdapting}
              needBackDrop={true}
            />
            {isError && (
              <ErrorMessageDisplayer
                content={{
                  header: "The Adapt Channel could not be executed",
                  title: contentAdapted.error,
                  message: contentAdapted.message,
                }}
              />
            )}
            {!isError && !isAdapted && (
              <AdaptContentForm
                formikGenerateAdaptContent={formikGenerateAdaptContent}
                data={data}
              />
            )}
            {!isError && isAdapted && (
              <AdaptedContentForm
                formikSaveAdaptedContent={formikSaveAdaptedContent}
                data={data}
                isAdapted={isAdapted}
                loaded={loaded}
              />
            )}
          </>
        }
        open={open}
      />
    </>
  );
}
