import { useDispatch, useSelector } from "react-redux";
import { selectCurrentOrganization } from "../redux/organizations";
import { contentAPIs } from "../api_";
import {
  resetDeleteContent,
  selectDeleteContentData,
  selectDeleteContentStatus,
} from "../redux/content";

export default function useDeleteContentHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectDeleteContentStatus(state));
  const data = useSelector((state) => selectDeleteContentData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onResetDeleteContent = () => {
    dispatch(resetDeleteContent());
  };

  const onDeleteContent = (contentId) => {
    dispatch(
      contentAPIs.deleteContent({
        id: contentId,
        orgId: currentOrg.id,
      })
    );
  };

  return {
    onDeleteContent,
    onResetDeleteContent,
    deleteContent: data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
