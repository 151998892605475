export const postGenerateDraftContentPending = (state, action) => {
  state.generateDraftContent.status = "pending";
};

export const postGenerateDraftContentFulfilled = (state, action) => {
  state.generateDraftContent.status = "fulfilled";
  state.generateDraftContent.data = action.payload;
};

export const postGenerateDraftContentRejected = (state, action) => {
  state.generateDraftContent.status = "rejected";
  state.generateDraftContent.error = action.payload;
};
