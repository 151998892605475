import { useDispatch, useSelector } from "react-redux";
import { contentAPIs } from "../api_";
import {
  resetSuggestionsContent,
  selectAnalyzedContentData,
  selectSuggestionsContentData,
  selectSuggestionsContentStatus,
} from "../redux/content";
import { selectCurrentOrganization } from "../redux/organizations";

export default function useSuggestionsContentHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectSuggestionsContentStatus(state));
  const data = useSelector((state) => selectSuggestionsContentData(state));
  const analyzedContentData = useSelector((state) => selectAnalyzedContentData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onGenerateSuggestionsContent = () => {
    dispatch(resetSuggestionsContent());
    dispatch(
      contentAPIs.postSuggestionsContent({
        orgId: currentOrg.id,
        id: analyzedContentData.content.content_id
      })
    );
  };

  const onResetSuggestionsContent = ()=>{
    dispatch(resetSuggestionsContent())
  }

  return {
    suggestionsData: data,
    onGenerateSuggestionsContent,
    onResetSuggestionsContent,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
