import { useDispatch, useSelector } from "react-redux";
import { contentAPIs } from "../api_";
import {
  resetContentMetadataFolder,
  selectGetContentMetadataFolderData,
  selectGetContentMetadataFolderStatus,
} from "../redux/content";
import { selectCurrentOrganization } from "../redux/organizations";

export default function useContentMetadataFolderHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectGetContentMetadataFolderStatus(state));
  const data = useSelector((state) => selectGetContentMetadataFolderData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onResetCurrentContentFolder = (id)=>{
    dispatch(
      resetContentMetadataFolder(id)
    );
  }
  const onGetContentMetadataFolder = (id)=>{
    dispatch(
      contentAPIs.getContentMetadataFolder({
        id: currentOrg.id,
        blobId: id,
      })
    );
  }
  
  return {
    data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onGetContentMetadataFolder,
    onResetCurrentContentFolder
  };
}
