export const deleteBrandPending = (state, action) => {
  state.delete.status = "pending";
};

export const deleteBrandFulfilled = (state, action) => {
  state.delete.status = "fulfilled";
  state.delete.data = action.payload;
};

export const deleteBrandRejected = (state, action) => {
  state.delete.status = "rejected";
};
