import { organizationAPIs } from "../../../api_";
import {
    postCreateAudienceFulfilled,
    postCreateAudiencePending,
    postCreateAudienceRejected,
} from "./cases/postCreateAudience";

import {
    putUpdateAudienceFulfilled,
    putUpdateAudiencePending,
    putUpdateAudienceRejected,
} from "./cases/putUpdateAudience";

import {
    putDisableAudienceFulfilled,
    putDisableAudiencePending,
    putDisableAudienceRejected,
} from "./cases/putDisableAudience";

import {
    getAudienceFulfilled,
    getAudiencePending,
    getAudienceRejected,
} from "./cases/getAudience";
  
export const extraReducers = (builder) => {
    builder.addCase(organizationAPIs.postCreateAudience.pending, postCreateAudiencePending);
    builder.addCase(organizationAPIs.postCreateAudience.fulfilled, postCreateAudienceFulfilled);
    builder.addCase(organizationAPIs.postCreateAudience.rejected, postCreateAudienceRejected);

    builder.addCase(organizationAPIs.putUpdateAudience.pending, putUpdateAudiencePending);
    builder.addCase(organizationAPIs.putUpdateAudience.fulfilled, putUpdateAudienceFulfilled);
    builder.addCase(organizationAPIs.putUpdateAudience.rejected, putUpdateAudienceRejected);

    builder.addCase(organizationAPIs.putDisableAudience.pending, putDisableAudiencePending);
    builder.addCase(organizationAPIs.putDisableAudience.fulfilled, putDisableAudienceFulfilled);
    builder.addCase(organizationAPIs.putDisableAudience.rejected, putDisableAudienceRejected);

    builder.addCase(organizationAPIs.getAudience.pending, getAudiencePending);
    builder.addCase(organizationAPIs.getAudience.fulfilled, getAudienceFulfilled);
    builder.addCase(organizationAPIs.getAudience.rejected, getAudienceRejected);
}