export const putHandleContentEditsPending = (state, action) => {
  state.contentEdits.status = "pending";
};

export const putHandleContentEditsFulfilled = (state, action) => {
  state.contentEdits.status = "fulfilled";
  state.contentEdits.data = action.payload;
};

export const putHandleContentEditsRejected = (state, action) => {
  state.contentEdits.status = "rejected";
  state.contentEdits.data = action.payload.response.data;
};
