import { Box, Typography } from "@mui/material";

export default function TermsAndConditions(){
    return <Box sx={{ height: "300px", overflow: "auto" }}>
    <Typography textAlign={"left"} variant="body1" fontWeight={200}>
      By signing up for Subatomic ("the Service"), you agree to comply
      with and be bound by the following terms and conditions. Please
      review them carefully before using the Service.
    </Typography>
      <ol style={{
        textAlign:"left", fontWeight:200
      }}>
        <li>
          <b>Acceptance of Terms:</b> Your use of the Service is subject
          to these Terms and Conditions. If you do not agree to these
          Terms and Conditions, you may not use the Service.
        </li>
        <li>
          <b>Subscription and Payment:</b> The Service is provided on a
          subscription basis. By subscribing, you agree to pay the
          applicable subscription fees. All fees are non-refundable. No
          refunds or credits will be issued for partial use, downgrades,
          or unused periods of the Service.
        </li>
        <li>
          <b>Account Responsibility:</b> You are responsible for
          maintaining the confidentiality of your account credentials
          and for all activities that occur under your account. You
          agree to notify us immediately of any unauthorized use of your
          account.
        </li>
        <li>
          <b>License and Use:</b> Upon payment, you are granted a
          non-exclusive, non-transferable license to use the Service
          according to these Terms and Conditions. You agree not to
          misuse the Service, including but not limited to copying,
          modifying, distributing, or creating derivative works of the
          Service.
        </li>
        <li>
          <b>Modifications to the Service:</b> We reserve the right to
          modify, suspend, or discontinue the Service at any time, with
          or without notice. We will not be liable to you or any third
          party for any such modification, suspension, or
          discontinuation.
        </li>
        <li>
          <b>Limitation of Liability:</b> To the maximum extent
          permitted by law, we shall not be liable for any direct,
          indirect, incidental, special, consequential, or punitive
          damages, or any loss of profits or revenues, whether incurred
          directly or indirectly, or any loss of data, use, goodwill, or
          other intangible losses, resulting from your use of the
          Service.
        </li>
        <li>
          <b>Termination:</b> We reserve the right to terminate or
          suspend your account and access to the Service at our sole
          discretion, without notice, if you violate these Terms and
          Conditions or for any other reason.
        </li>
        <li>
          <b>Governing Law:</b> These Terms and Conditions are governed
          by and construed in accordance with the laws of the State of
          Wisconsin, without regard to its conflict of law principles.
          Any disputes arising out of or relating to these Terms and
          Conditions or the Service shall be resolved exclusively in the
          state or federal courts located in Wisconsin.
        </li>
      </ol>
    <Typography textAlign={"left"} variant="body1" fontWeight={200}>
      By creating an account and using the Service, you acknowledge that
      you have read, understood, and agree to be bound by these Terms
      and Conditions.
    </Typography>
    <Typography textAlign={"left"} variant="body1" fontWeight={200}>
      If you have any questions or concerns about these Terms and
      Conditions, please contact us at hello@getsubatomic.ai
    </Typography>
  </Box>
}