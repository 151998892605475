export const getAnalyzeContentPending = (state, action) => {
  state.analyzedContent.status = "pending";
};

export const getAnalyzeContentFulfilled = (state, action) => {
  state.analyzedContent.status = "fulfilled";
  state.analyzedContent.data = action.payload;
};

export const getAnalyzeContentRejected = (state, action) => {
  state.analyzedContent.status = "rejected";
  state.analyzedContent.data = action.payload.response.data;
};
