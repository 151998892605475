import * as React from "react";
import { Step, StepLabel, Stepper, Typography, styled, useTheme } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const QontoStepIconRoot = styled("div")(({ ownerState,theme }) => {
  return {
    color: ownerState.error ? theme.palette.red.main : ownerState.completed ? theme.palette.green.main : "#fff",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: ownerState.error ? theme.palette.red.main :theme.palette.dark.main,
    }),
    "& .QontoStepIcon-completedIcon": {
      color: theme.palette.green.main,
      zIndex: 1,
    },
    "& .QontoStepIcon-circle": {
      width: 12,
      height: 12,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  };
});

const IconContainer = styled("div")((_) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "12px",
  };
});
function QontoStepIcon(props) {
  const { active, completed, className, error } = props;
  return (
    <IconContainer>
      <QontoStepIconRoot
        ownerState={{ active, error, completed }}
        className={className}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {completed ? (
          <CheckCircleIcon fontSize="6px" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    </IconContainer>
  );
}

export default function CustomStepper({
  formik,
  questions,
  activeStep,
  onStepChange,
}) {
  const refs = React.useRef([]);
  const theme = useTheme()
  const containerRef = React.useRef([]);

  const checkRows = (subStep) => {
    const subFormKeys = Object.keys(subStep.fieldsSchema);
    const completed = formik?.values[subStep.inputName].filter((val, i) => {
      const values = subFormKeys.filter(
        (field) => !(val[field] && val[field].length)
      );
      return values.length === 0;
    });
    return completed.length === formik?.values[subStep.inputName].length;
  };

  const colorCheck = (subStep, i) => {
    if (activeStep === i) {
      return theme.palette.dark.main;
    }
    if (subStep.inputType === "row_input") {
      if (checkRows(subStep, i)) {
        return theme.palette.green.main;
      }
      if (Boolean(formik.errors[subStep.inputName])) {
        return "red";
      }
      return "gray";
    }
    if (
      formik?.touched[subStep.inputName] &&
      Boolean(formik.errors[subStep.inputName])
    ) {
      return "red";
    }
    if (
      formik?.values[subStep.inputName] &&
      formik?.values[subStep.inputName].length
    ) {
      return theme.palette.dark.main;
    }
    return "gray";
  };

  const commpletedCheck = (subStep, i) => {
    if (subStep.inputType === "row_input") {
      if (
        !formik?.touched[subStep.inputName] &&
        formik?.values[subStep.inputName].length === 0
      ) {
        return false;
      }
      return checkRows(subStep, i);
    }
    return (
      formik?.values[subStep.inputName] &&
      formik?.values[subStep.inputName].length
    );
  };

  const errorCheck = (subStep, i) => {
    if (subStep.inputType === "row_input") {
      if (Boolean(formik?.errors[subStep.inputName])) {
        return !checkRows(subStep, i);
      }
    }

    return (
      formik?.touched[subStep.inputName] &&
      Boolean(formik?.errors[subStep.inputName])
    );
  };
  React.useEffect(() => {
    // Wait for all refs to be set
    if (refs.current.length === questions.length) {
      if (activeStep === questions.length - 1) {
        if (containerRef.current) {
          containerRef.current.scrollTo({
            top: 350 * activeStep,
            behavior: 'smooth',
          })}
      }else 
      if (refs.current[activeStep]) {
        refs.current[activeStep].scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [activeStep, questions.length, refs]);
  return (
    <Stepper
      sx={{
        maxHeight: "350px",
        overflow: "auto",
      }}
      connector={
        <div
          classes={{
            line: { border: "red" },
          }}
        />
      }
      activeStep={activeStep}
      orientation="vertical"
      ref={containerRef}
    >
      {questions?.map((subStep, i) => (
        <Step
          key={i}
          completed={Boolean(commpletedCheck(subStep, i))}
          onClick={(e) => {
            e.stopPropagation();
            onStepChange(i);
          }}
          sx={{ cursor: "pointer", color: "red" }}
          ref={(el) => (refs.current[i] = el)}
        >
          <StepLabel
            StepIconComponent={QontoStepIcon}
            error={errorCheck(subStep, i)}
          >
            <Typography
              sx={{
                cursor: "pointer",
                fontWeight: "bold",
                ml: 1,
                color: colorCheck(subStep, i),
              }}
              variant="caption"
            >
              {subStep?.continousIndex + 1} - {subStep?.label}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
