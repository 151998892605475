const FolderIcon = () => (
  <svg
    width="23"
    height="18"
    viewBox="0 0 23 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_437_6735)">
      <path
        d="M21.3569 17.5908C22.0387 17.5908 22.5891 17.0426 22.5891 16.3635V4.909C22.5891 4.459 22.2194 4.09082 21.7676 4.09082"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.410645 1.22736C0.410645 0.777362 0.780287 0.40918 1.23207 0.40918H6.98207L8.21422 2.45463H19.3035C19.7553 2.45463 20.1249 2.82282 20.1249 3.27282V16.3637C20.1249 17.0428 20.6753 17.591 21.3571 17.591H2.46422C1.33064 17.591 0.410645 16.6746 0.410645 15.5455V1.22736Z"
        fill="#D4D4D4"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_437_6735">
        <rect width="23" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default FolderIcon;
