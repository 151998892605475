import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { PersonaCard } from "../../../../../components";
import useGetBrandsHandler from "../../../../../hooks/useGetBrandsHandler";
import { useNavigate } from "react-router-dom";
import useCurrentOrganizationHandler from "../../../../../hooks/useCurrentOrganizationHandler";
import { useBrandBuilderModeHandler } from "../../../../../hooks/useBrandBuilderModeHandler";
import { useBrandBuilderQuestionSelectorHandler } from "../../../../../hooks/useBrandBuilderQuestionSelectorHandler";

export default function RigthPane({ formikGenerateDraft, children }) {
  const { brand } = useGetBrandsHandler();
  const { onSetMode } = useBrandBuilderModeHandler();
  const navigate = useNavigate();
  const { onStepChange } = useBrandBuilderQuestionSelectorHandler();
  const { currentOrganization } = useCurrentOrganizationHandler();
  return (
    <Grid item xs={5}>
      <Box
        sx={{
          height: "100%",
          background: "#fafafa",
          borderLeft: "1px solid #eeeeee",
        }}
      >
        <Stack
          mx={1.5}
          spacing={2}
          sx={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              textAlign: "left",
              pt: "70px",
            }}
          >
            <Box
              sx={{
                height: 400,
                pr: 2,
                overflowX: "auto",
              }}
            >
              <Typography variant="h5" fontWeight={"bold"} textAlign={"left"}>
                Audience
              </Typography>
              <>
                {formikGenerateDraft?.values?.audience ? (
                  <>
                    <Box
                      sx={{
                        background: "#f0f0f0",
                        margin: "0px",
                        padding: "1px",
                        display: "flex",
                        overflowX: "auto",
                      }}
                    >
                      {formikGenerateDraft?.values?.audience?.personas?.map(
                        (persona, idx) => (
                          <PersonaCard
                            key={idx}
                            persona={{
                              description_metadata: {
                                ...persona.description_metadata,
                              },
                              avatar_signed_url: persona.avatar_signed_url,
                            }}
                          />
                        )
                      )}
                    </Box>
                    <Typography>Audience Description</Typography>
                    <Box
                      sx={{
                        height: 100,
                        pr: 2,
                        overflowX: "auto",
                      }}
                    >
                      {formikGenerateDraft?.values?.audience.description ? (
                        <Typography fontWeight={200} variant="body1">
                          {formikGenerateDraft?.values?.audience && (
                            <>
                              {
                                formikGenerateDraft?.values?.audience
                                  .description
                              }
                            </>
                          )}
                        </Typography>
                      ) : (
                        <>
                          <Alert severity="warning" variant="outlined">
                            <AlertTitle>
                              Audience :{" "}
                              {formikGenerateDraft?.values?.audience.name}
                            </AlertTitle>
                            This audience has no description.{" "}
                            <strong
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(
                                  `/organization/${currentOrganization.id}/audience/${formikGenerateDraft?.values?.audience.id}`
                                )
                              }
                            >
                              Click here
                            </strong>{" "}
                            to add one.
                          </Alert>
                        </>
                      )}
                    </Box>
                  </>
                ) : (
                  <Alert severity="warning" variant="outlined">
                    <AlertTitle>No Audience Selected</AlertTitle>
                    Please select an audience to generate the draft content.
                  </Alert>
                )}
              </>
            </Box>

            <Typography variant="h5" fontWeight={"bold"} textAlign={"left"}>
              Brand
            </Typography>
            <Divider></Divider>
            <Typography variant="h4">{brand?.name}</Typography>
            <Box mb={10} sx={{ height: 260, pr: 2, overflowX: "auto" }}>
              <Typography textAlign={"left"} fontWeight={500} variant="body1">
                Brand Voice
              </Typography>
              {brand?.voice ? (
                <Typography textAlign={"left"} fontWeight={200} variant="body1">
                  {brand?.voice}
                </Typography>
              ) : (
                <Alert severity="warning" variant="outlined">
                  <AlertTitle>No Brand Voice Defined Yet!</AlertTitle>
                  Please go to{" "}
                  <strong
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onStepChange(4);
                      onSetMode("edit", brand);
                      navigate(`/organization/${currentOrganization.id}/brand`);
                    }}
                  >
                    Brand Builder
                  </strong>{" "}
                  and define a brand voice for your brand.
                </Alert>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: 30,
              left: 1,
            }}
          >
            {children}
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
}
