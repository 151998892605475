import { Box, Button, Grid, Typography } from "@mui/material";
import { LogoLoader, Modal } from "../../../../components";
import { useFormik } from "formik";
import useGetBrandsHandler from "../../../../hooks/useGetBrandsHandler";
import { useEffect } from "react";
import useGenerateDraftContentHandler from "../../../../hooks/useGenerateDraftContentHandler";
import * as Yup from "yup";
import LeftPaneForm from "./components/LeftPaneForm";
import RigthPane from "./components/RigthPane";
import DraftContentPreview from "./components/DraftContentPreview";
import useUploadContentTextHandler from "../../../../hooks/useUploadContentTextHandler";
import ModalChooseFolder from "./components/ModalChooseFolder";
import useGetOrgUserProfile from "../../../../hooks/useGetOrgUserProfileHanlder";
import { useNavigate } from "react-router-dom";
import { useBrandBuilderModeHandler } from "../../../../hooks/useBrandBuilderModeHandler";
import useCurrentOrganizationHandler from "../../../../hooks/useCurrentOrganizationHandler";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

export default function DraftContent() {
  const {
    brand,
    onGetBrands,
    isUninitialized,
    isSuccess: isSuccesBrand,
  } = useGetBrandsHandler();

  const {
    onGenerateDraftContent,
    onResetGenerateDraftContent,
    isLoading,
    isSuccess,
    data,
  } = useGenerateDraftContentHandler();

  const { canManageBrand } = useGetOrgUserProfile();
  const navigate = useNavigate();
  const { onSetMode } = useBrandBuilderModeHandler();
  const { currentOrganization } = useCurrentOrganizationHandler();

  useEffect(() => {
    if (isUninitialized) {
      onGetBrands();
    }
  }, [brand, isUninitialized, onGetBrands]);

  const formikGenerateDraft = useFormik({
    initialValues: {
      audience: "",
      brand: "",
      channel: "",
      keyMessage: "",
      callToAction: "",
      otherInstructions: "",
      maxLength: "",
      minLength: "",
    },
    validationSchema: Yup.object({
      audience: Yup.object().required("Required"),
      channel: Yup.object().required("Required"),
      keyMessage: Yup.string().required("Required"),
      callToAction: Yup.string().required("Required"),
      otherInstructions: Yup.string().required("Required"),
      minLength: Yup.number()
        .min(50, "Min length must be at least 50")
        .required("Required"),
      maxLength: Yup.number()
        .min(50, "Max length must be at least 50")
        .when("minLength", (minLength, schema) =>
          minLength !== undefined
            ? schema.moreThan(
                minLength,
                `Max length must be greater than ${minLength}`
              )
            : schema
        )
        .required("Required"),
    }),
    onSubmit: (values) => {
      const {
        audience: { id: audienceId } = {},
        channel: { id: channelId } = {},
        keyMessage,
        callToAction,
        otherInstructions,
        maxLength,
        minLength,
      } = values;

      const { id: brandId } = brand || {};
      onResetGenerateDraftContent()
      formikSaveDraft.resetForm();
      onGenerateDraftContent({
        audienceId,
        channelId,
        brandId,
        keyMessage,
        callToAction,
        otherInstructions,
        maxLength,
        minLength,
      });
    },
  });

  const { isLoading: isLoadingUploadContentText, onUploadContentText } =
    useUploadContentTextHandler();

  const formikSaveDraft = useFormik({
    initialValues: {
      title: null,
      contentText: null,
      audience: null,
      channel: null,
      folder: "",
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Required"),
      contentText: Yup.array()
        .of(
          Yup.object({
            type: Yup.string()
              .oneOf([
                "paragraph",
                "bulleted-list",
                "block-quote",
                "numbered-list",
                "list-item",
                "heading-one",
                "heading-two",
                "heading-three",
              ])
              .required("Type is required"),
            children: Yup.array()
              .of(
                Yup.object({
                  type: Yup.string().oneOf(["list-item"]).optional(),
                  children: Yup.array()
                    .of(
                      Yup.object({
                        text: Yup.string().nullable(),
                      })
                    )
                    .min(1, "Each block must have at least one child")
                    .optional(),
                  text: Yup.string().nullable(),
                })
              )
              .min(1, "Each block must have at least one child"),
          })
        )
        .test(
          "at-least-one-non-empty",
          "At least one block must have content",
          function (blocks) {
            return (
              blocks &&
              blocks.some((block) =>
                block.children.some((child) =>
                  child.children
                    ? child.children.some(
                        (grandChild) =>
                          grandChild.text && grandChild.text.trim().length > 0
                      )
                    : child.text && child.text.trim().length > 0
                )
              )
            );
          }
        )
        .required("Content is required"),
      audience: Yup.object().required("Required"),
      channel: Yup.object().required("Required"),
      folder: Yup.object().required("Required"),
    }),
    onSubmit: (values) => {
      onUploadContentText(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      formikSaveDraft.setFieldValue("title", data.content_title);
      formikSaveDraft.setFieldValue("contentText", [
        {
          type: "paragraph",
          children: [{ text: data.content_draft }],
        },
      ]);

      formikSaveDraft.setFieldValue(
        "channel",
        formikGenerateDraft.values.channel
      );
      formikSaveDraft.setFieldValue(
        "audience",
        formikGenerateDraft.values.audience
      );
      setTimeout(() => {
        formikSaveDraft.validateForm();
      }, 0);
      onResetGenerateDraftContent({ status: "idle" });
    }
  }, [
    isSuccess,
    data,
    formikGenerateDraft,
    onResetGenerateDraftContent,
    formikSaveDraft,
  ]);

  return (
    <>
      <Grid container>
        <LogoLoader
          open={isLoading || isLoadingUploadContentText}
          needBackDrop={true}
          text={
            (isLoading && "Crafting something special") ||
            (isLoadingUploadContentText && "Uploading Draft Content")
          }
        />
        <LeftPaneForm formikGenerateDraft={formikGenerateDraft} />
        <Grid item xs={5}>
          <Box
            mx={2}
            sx={{
              pt: "70px",
            }}
          >
            <DraftContentPreview formik={formikSaveDraft}></DraftContentPreview>
          </Box>
        </Grid>
        <RigthPane
          formikGenerateDraft={formikGenerateDraft}
          formikSaveDraft={formikSaveDraft}
        >
          <ModalChooseFolder formikSaveDraft={formikSaveDraft} />
        </RigthPane>
        <Modal
          open={!brand?.id && isSuccesBrand}
          disableClose={true}
          body={
            <>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    padding: "20px",
                    color: "#a5a5a5",
                    borderRadius: "10px",
                  }}
                >
                  <BlurOnIcon fontSize="large"></BlurOnIcon>
                  <Typography>You must first define your Brand!</Typography>
                  <Button
                    startIcon={<ArrowOutwardIcon></ArrowOutwardIcon>}
                    size="small"
                    color="warning"
                    disabled={!canManageBrand}
                    onClick={() => {
                      onSetMode("create", null);
                      navigate(`/organization/${currentOrganization.id}/brand`);
                    }}
                  >
                    Create a new Brand Profile
                  </Button>
                </Box>
              </Box>
            </>
          }
        />
      </Grid>
    </>
  );
}
