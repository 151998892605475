import { Box, Grid, Stack } from "@mui/material";

export default function LeftPaneComponent({ children, space=true }) {
  return (
    <Grid item xs={2}>
        <Box sx={{ height: "100%",
           background: '#fafafa',
           borderRight: '1px solid #eeeeee'
           }}>
          <Stack
            mx={space?1.5:0}
            spacing={2}
            sx={{
              position: "relative",
              height: "100%",
            }}
          >
            {children}
          </Stack>
        </Box>
      </Grid>
  );
}
