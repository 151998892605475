export const getOrganizationUsersPending = (state, action) => {
  state.users.status = "pending";
};

export const getOrganizationUsersFulfilled = (state, action) => {
  state.users.status = "fulfilled";
  state.users.data = action.payload.result;
};

export const getOrganizationUsersRejected = (state, action) => {
  state.users.status = "rejected";
};
