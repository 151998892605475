import { useDispatch, useSelector } from "react-redux";
import {
  selectPersonaBuilderWizzard,
  selectPersonaMode,
  setWizzard,
} from "../redux/persona";
import { personaBuilderQuestionsSchema } from "../constants";

export function usePersonaBuilderQuestionSelectorHandler() {
  const wizzardState = useSelector((state) =>
    selectPersonaBuilderWizzard(state)
  );
  const mode = useSelector((state) => selectPersonaMode(state));
  const dispatch = useDispatch();
  /*const stepsGrouped = useMemo(() => {
   
    const schemaQuestionsToUse =
      mode === "fastlane"
        ? personaBuilderQuestionsSchema.filter(
            (question) => question.isFastLane
          )
        : personaBuilderQuestionsSchema;
    return schemaQuestionsToUse.reduce((acc, obj) => {
      const existingCategory = acc.find((item) => item.label === obj.category);
      if (existingCategory) {
        existingCategory.questions.push({
          ...obj,
          continousIndex: schemaQuestionsToUse.indexOf(obj),
          //inputName: generateInputName(obj.label),
        });
      } else {
        acc.push({
          label: obj.category,
          questions: [
            {
              ...obj,
              //inputName: generateInputName(obj.label),
              continousIndex: schemaQuestionsToUse.indexOf(obj),
            },
          ],
        });
      }
      return acc;
    }, []);
  });*/
  const schemaQuestionsToUse =
    mode === "fastlane"
      ? personaBuilderQuestionsSchema.filter((question) => question.isFastLane)
      : personaBuilderQuestionsSchema;

  const stepsGrouped = schemaQuestionsToUse.reduce((acc, obj) => {
    const existingCategory = acc.find((item) => item.label === obj.category);
    if (existingCategory) {
      existingCategory.questions.push({
        ...obj,
        continousIndex: schemaQuestionsToUse.indexOf(obj),
      });
    } else {
      acc.push({
        label: obj.category,
        questions: [
          {
            ...obj,
            continousIndex: schemaQuestionsToUse.indexOf(obj),
          },
        ],
      });
    }
    return acc;
  }, []);

  const onStepChange = (val) => {
    dispatch(setWizzard({ activeStep: val, activeSubStep: 0 }));
  };

  const onSubStepChange = (val) => {
    if (val + 1 > stepsGrouped[wizzardState.activeStep].questions.length) {
      if (stepsGrouped[wizzardState.activeStep + 1]) {
        moveNextTab(val);
      }
      return;
    }
    if (val < 0) {
      moveBackQuestion(val);
      return;
    }
    moveNextQuestion(val);
  };

  const moveNextTab = () => {
    dispatch(
      setWizzard({ activeSubStep: 0, activeStep: wizzardState.activeStep + 1 })
    );
  };

  const moveBackQuestion = (val) => {
    if (wizzardState.activeStep - 1 > -1) {
      dispatch(
        setWizzard({
          activeSubStep:
            stepsGrouped[wizzardState.activeStep - 1].questions.length - 1,
          activeStep: wizzardState.activeStep - 1,
        })
      );
    }
  };

  const moveNextQuestion = (val) => {
    dispatch(setWizzard({ ...wizzardState, activeSubStep: val }));
  };

  return {
    stepsList:
      mode === "fastlane"
        ? personaBuilderQuestionsSchema.filter(
            (question) => question.isFastLane
          )
        : personaBuilderQuestionsSchema,
    stepsGrouped,
    activeStep: wizzardState.activeStep,
    activeSubStep: wizzardState.activeSubStep,
    onStepChange,
    onSubStepChange,
  };
}
