import * as React from "react";
import { Box, Select, CircularProgress, Chip, MenuItem } from "@mui/material";
import InputText from "../InputText";
import useAllFolderHandler from "../../hooks/useAllFolderHandler";

export default function FoldersSelect({ formik, name, value, error }) {
  const {
    folders,
    isSuccess,
    isUninitialized,
    isLoading: isLoadingFolders,
    onGetAllFolders,
  } = useAllFolderHandler();

  React.useEffect(() => {
    if (isUninitialized) {
      onGetAllFolders();
    }
  }, [isUninitialized, onGetAllFolders]);

  return (
    <Select
      displayEmpty
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 48 * 4.5 + 8,
            // width: 250,
          },
        },
      }}
      startAdornment={isLoadingFolders && <CircularProgress size={"20px"} />}
      input={<InputText placeholder="Choose folder" />}
      name={name}
      value={
        isSuccess && value !== ""
          ? folders.find((i) => i.folder_id === value.folder_id)
          : ""
      }
      error={error}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      renderValue={(selected) => {
        if (!selected) return <em>{"Choose folder"}</em>;
        return selected || selected?.length > 0 ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.1 }}>
            <Chip
              sx={{ height: "20px !important" }}
              key={value.id}
              label={`${value.blob}`}
            />
          </Box>
        ) : (
          <em>{"Choose folder.."}</em>
        );
      }}
      inputProps={{
        sx:{
          padding:0
        }
       }}
    >
      <MenuItem disabled value="">
        <em>Choose folder</em>
      </MenuItem>
      {isSuccess &&
        folders?.map((folder, idx) => (
          <MenuItem key={idx} value={folder}>
            {folder.blob}
          </MenuItem>
        ))}
    </Select>
  );
}
