const setMode = (state, action) => {
  state.mode = action.payload.mode;
  state.builder.data = action.payload.persona;
  state.builder.payload = action.payload.persona;
  if(action.payload.mode === 'view'){
    state.list.status = "idle"
  }
};

export default setMode;
