export const postInviteUserPending = (state, action) => {
  state.inviteUser.status = "pending";
};

export const postInviteUserFulfilled = (state, action) => {
  state.inviteUser.status = "fulfilled";
  state.inviteUser.data = action.payload.result;
};

export const postInviteUserRejected = (state, action) => {
  state.inviteUser.status = "rejected";
  state.inviteUser.data = action.payload.response.data;
};
