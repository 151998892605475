import { useDispatch, useSelector } from "react-redux";
import { utilityAPIs} from "../api_";
import { selectStrapiProductsData, selectStrapiProductsStatus } from "../redux/utilities";


export default function useGetStrapiProductHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectStrapiProductsStatus(state));
  const data = useSelector((state) => selectStrapiProductsData(state))

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onGetStrapiProducts = ()=>{
    dispatch(utilityAPIs.getStrapiProducts())
  }

  return {
    products: data,
    onGetStrapiProducts,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
