import {
  Button,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { useState, useEffect, useCallback } from "react";
import {
  BootstrapTooltip,
  Modal,
} from "../../../../../components";
import useGetOrgUserProfile from "../../../../../hooks/useGetOrgUserProfileHanlder";
import useDeleteContentHandler from "../../../../../hooks/useDeleteContentHandler";

export default function ModalDeleteContent({ contentId }) {
  const { canManageContent } = useGetOrgUserProfile();
  const [open, setOpen] = useState(false);
  const { isLoading, isSuccess, onDeleteContent } = useDeleteContentHandler();

  const handleClose = useCallback(() => {
    if (!isLoading) {
      setOpen(false);
    }
  },[isLoading]);

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
    }
  }, [isSuccess]);

  const body = (
    <>
      <Typography variant="overline">
        Are sure you want to remove this content?.
      </Typography>
      <br></br>
      <Typography variant="subtitle1" color={"error"}>
        **All analyze results related to this content will be lost.
      </Typography>
    </>
  );
  return (
    <>
      <BootstrapTooltip title="Delete Content">
        <IconButton
          variant={"contained"}
          color={"red"}
          size="small"
          disabled={!canManageContent}
          onClick={() => setOpen(true)}
        >
          <HighlightOffOutlinedIcon fontSize="small" />
        </IconButton>
      </BootstrapTooltip>
      <Modal
        maxWidth="sm"
        handleClose={handleClose}
        actions={
          <>
            <Button
              color="red"
              variant="contained"
              onClick={() => onDeleteContent(contentId)}
            >
              Yes
            </Button>
            <Button onClick={handleClose} color={"dark"} variant="contained">
              Cancel
            </Button>
          </>
        }
        title={
          <Stack direction={"row"} alignItems={"center"}>
            <DeleteIcon />
            <Typography variant="h6" ml={1}>
              Delete Content
            </Typography>
          </Stack>
        }
        body={body}
        open={open}
      />
    </>
  );
}
