const setCurrentFolder = (state, action) => {
    if(action.payload ===''){
        ///going back 
        let newFullCurrent = state.navigator.prevFolder.replace(state.navigator.currentFolder,'')
        state.navigator.prevFolder = newFullCurrent
        const prevFolder = newFullCurrent.split('/').filter(f=>f!=='').pop()
        state.navigator.currentFolder = prevFolder ? `${prevFolder}/`:'/'
    }else{
        let currentPath = `${state.navigator.prevFolder}${action.payload}`
        state.navigator.prevFolder = currentPath
        state.navigator.currentFolder = action.payload
    }
  };
  
  export default setCurrentFolder;
