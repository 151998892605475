import { useDispatch, useSelector } from "react-redux";
import { authAPIs } from "../api_";
import {
  resetAutoIngestion,
  selectAutoIngestionData,
  selectAutoIngestionError,
  selectAutoIngestionStatus,
} from "../redux/auth";

export default function useAutoIngestionHandler() {
  const dispatch = useDispatch();
  const data = useSelector((state) => selectAutoIngestionData(state));
  const status = useSelector((state) => selectAutoIngestionStatus(state));
  const error = useSelector((state) => selectAutoIngestionError(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onPostAutoIngestion = (data) => {
    dispatch(authAPIs.postAutoIngestion(data));
  };

  const onResetAutoIngestion = () => {
    dispatch(resetAutoIngestion());
  };

  return {
    data,
    error,
    onPostAutoIngestion,
    onResetAutoIngestion,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
