import { useDispatch, useSelector } from "react-redux";
import { organizationAPIs } from "../api_";
import { selectCurrentOrganization } from "../redux/organizations";
import {
  selectUploadFileData,
  selectUploadFileStatus,
  resetStatusUploadFile,
  selectUploadFileError,
} from "../redux/content";

export default function useUploadContentFile() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectUploadFileStatus(state));
  const data = useSelector((state) => selectUploadFileData(state));
  const error = useSelector((state) => selectUploadFileError(state));

  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onUploadFile = (values) => {
    dispatch(
      organizationAPIs.postUploadFile({
        forms: values,
        token: JSON.parse(localStorage.getItem("auth")).token,
        id: currentOrg.id,
      })
    );
  };
  const onResetUploadContentFile = () => {
    dispatch(resetStatusUploadFile());
  };

  return {
    personas: data,
    error,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onUploadFile,
    onResetUploadContentFile,
  };
}
