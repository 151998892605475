import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  BuilderAvatarComponent,
  BuilderIAInputComponent,
  BuilderOtherComponent,
  BuilderSelectInputComponent,
} from "../../components";

export default function QuestionComponent({
  formik,
  onSubmitToNextQuestion,
  onNextQuestion,
  currentQuestion,
  rowInputs,
}) {
  const questionNumber = currentQuestion.continousIndex + 1;
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSubmitToNextQuestion();
    }
  };

  const DirectionButton = ({ direction }) => (
    <IconButton size="small" onClick={() => onNextQuestion(direction)}>
      {direction === "next" ? (
        <ArrowDropDownIcon sx={{ fontSize: "70px" }} />
      ) : (
        <ArrowDropUpIcon sx={{ fontSize: "70px" }} />
      )}
    </IconButton>
  );

  const arrowControls = (
    <Grid
      item
      xs={0}
      sm={3}
      md={3}
      lg={2}
      xl={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "36vh",
      }}
    >
      <Stack justifyContent={"center"} alignItems={"center"}>
        <Box>
          <DirectionButton direction="back" />
        </Box>
        <Typography textAlign={"center"} variant="h1">
          {questionNumber}
        </Typography>
        <Box>
          <DirectionButton direction="next" />
        </Box>
      </Stack>
    </Grid>
  );

  return (
    <Grid container mt={15}>
      {arrowControls}
      <Grid
        item
        xs={9}
        sm={9}
        md={9}
        lg={9}
        xl={9}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "36vh",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Typography textAlign={"left"} variant="overline" display="block">
            {currentQuestion?.label}
          </Typography>
          <Typography textAlign={"left"} variant="h5">
            {currentQuestion.question}
          </Typography>
          {currentQuestion.category === "Other details" &&
            currentQuestion.inputName === "avatarSource" && (
              <BuilderAvatarComponent
                currentQuestion={currentQuestion}
                formik={formik}
                onChange={onSubmitToNextQuestion}
              />
            )}
          {currentQuestion.category === "Other details" &&
            (currentQuestion.inputName === "other" ||
              currentQuestion.inputName === "other_details") && (
              <BuilderOtherComponent
                currentQuestion={currentQuestion}
                formik={formik}
                handleKeyDown={handleKeyDown}
              />
            )}
          {currentQuestion.inputType === "text" && (
            <BuilderIAInputComponent
              currentQuestion={currentQuestion}
              formik={formik}
              handleKeyDown={handleKeyDown}
            />
          )}
          {(currentQuestion.inputType === "dropdown" ||
            currentQuestion.inputType === "multiple") && (
            <BuilderSelectInputComponent
              currentQuestion={currentQuestion}
              isMultiple={currentQuestion.inputType === "multiple"}
              formik={formik}
              handleKeyDown={handleKeyDown}
            />
          )}
          {currentQuestion.inputType === "row_input" && rowInputs}
          {currentQuestion.inputName !== "avatarSource" && currentQuestion.inputName !== "other_details" && (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{ mt: 1 }}
            >
              <Stack spacing={0}>
                <Button
                  disabled={!formik.isValid}
                  onClick={(e) => {
                    onSubmitToNextQuestion(e);
                  }}
                  variant="contained"
                  color="dark"
                >
                  ok
                </Button>
                {currentQuestion.aiQuestion && (
                  <Typography variant="caption" fontWeight={200}>
                    Or press Enter
                  </Typography>
                )}
              </Stack>
            </Grid>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
