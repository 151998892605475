export const putUpdateAudiencePending = (state, action) => {
  state.updateAudience.status = "pending";
};

export const putUpdateAudienceFulfilled = (state, action) => {
  state.updateAudience.status = "fulfilled";
  state.updateAudience.data = action.payload;
};

export const putUpdateAudienceRejected = (state, action) => {
  state.updateAudience.status = "rejected";
};
