import { useDispatch, useSelector } from "react-redux";
import { selectCurrentOrganization } from "../redux/organizations";
import { contentAPIs } from "../api_";
import { resetGenerateDraftContent, selectGenerateDraftContentData, selectGenerateDraftContentStatus } from "../redux/content";

export default function useGenerateDraftContentHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectGenerateDraftContentStatus(state));
  const data = useSelector((state) => selectGenerateDraftContentData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onResetGenerateDraftContent = (values) => {
    dispatch(resetGenerateDraftContent(values));
  };

  const onGenerateDraftContent = (values) => {
    dispatch(
      contentAPIs.postGenerateDraftContent({
        orgId: currentOrg.id,
        values
      })
    );
  };

  return {
    onGenerateDraftContent,
    onResetGenerateDraftContent,
    data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
