//Packages Imports
import { useSelector } from "react-redux";
//App Import
import { useAppDispatch } from "../redux/store";
import {
  selectSetNewPasswordData,
  selectSetNewPasswordError,
  selectSetNewPasswordStatus,
} from "../redux/auth";
import { authAPIs } from "../api_";

export function useSetNewPasswordHandler() {
  const dispatch = useAppDispatch();
  const status = useSelector((state) => selectSetNewPasswordStatus(state));
  const data = useSelector((state) => selectSetNewPasswordData(state));
  const error = useSelector((state) => selectSetNewPasswordError(state));

  const isUninitialized = status === 'idle';
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onSetNewPassword = (values) => {
    dispatch(authAPIs.postSetNewPassword(values));
  };

  const onResetSetNewPassword = () => {
    //dispatch(resetResetPassword());
  };


  return {
    onSetNewPassword,
    onResetSetNewPassword,
    error,
    data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
