export const getAPIKeyPending = (state, action) => {
  state.getAPIKey.status = "pending";
};

export const getAPIKeyFulfilled = (state, action) => {
  state.getAPIKey.status = "fulfilled";
  state.getAPIKey.data = action.payload;
};

export const getAPIKeyRejected = (state, action) => {
  state.getAPIKey.status = "rejected";
};
