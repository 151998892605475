import {
  resetChannelsList,
  selectCurrentOrganization,
  selectOrganizationChannelsData,
  selectOrganizationChannelsStatus,
} from "../redux/organizations";
import { useDispatch, useSelector } from "react-redux";
import { organizationAPIs } from "../api_";

export default function useOrganizationChannelsHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectOrganizationChannelsStatus(state));
  const data = useSelector((state) => selectOrganizationChannelsData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));


  const isUninitialized = status === "idle";
  const isLoading = status === "pending" || status === "idle";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onGetChannels = ()=> dispatch(organizationAPIs.getChannels({id:currentOrg.id}))
  const onResetChannels = ()=> dispatch(resetChannelsList())

  return {
    channles: data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onGetChannels,
    onResetChannels,
    groupedByChannels:()=>Object.values(data.reduce((grouped, item) => {
      // Si el grupo para este canal aún no existe, lo creamos
      if (!grouped[item.channel]) {
          grouped[item.channel] = { channel: item.channel, items: [] };
      }
      // Agregamos el objeto al grupo correspondiente
      grouped[item.channel].items.push(item);
      return grouped;
  }, {}))
  };
}
