import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import InputText from "../InputText";

export default function ContentTitlePreview({formik, isDark}){
    const [edit, setEdit] = useState(false);
    return <Stack flexDirection={"row"} alignItems={"center"}>
    <Box width={"100%"}>
      {edit ? (
        <InputText
          fullWidth={true}
          isDark={isDark}
          placeholder={"Keep tone light"}
          name={"title"}
          formik={formik}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.title}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title ? formik.errors.title : ""}
        />
      ) : (
        <Typography py={2} variant="h5">
          {formik.values.title}
        </Typography>
      )}
    </Box>
    <Box
      sx={edit&&{
        paddingBottom: "13px",
        margin: "5px",
      }}
    >
      {edit ? (
        <IconButton
            color={isDark?"white":'dark'}
          onClick={() => {
            setEdit(false);
          }}
        >
          <CheckIcon />
        </IconButton>
      ) : (
        <IconButton
            color={isDark?"white":'dark'}
          onClick={() => {
            setEdit(true);
          }}
        >
          <ModeEditIcon />
        </IconButton>
      )}
    </Box>
  </Stack>
}