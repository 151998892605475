import resetCreateStatus from "./cases/resetCreateStatus";
import resetCurrentAudience from "./cases/resetCurrentAudience";
import resetDisableAudience from "./cases/resetDisableAudience";
import resetListAudience from "./cases/resetListAudience";
import resetUpdateStatus from "./cases/resetUpdateStatus";
import setCurrentAudience from "./cases/setCurrentAudience";

const cases = {
  setCurrentAudience,
  resetCreateStatus,
  resetUpdateStatus,
  resetCurrentAudience,
  resetListAudience,
  resetDisableAudience
};

export default cases;
