import { contentAPIs, organizationAPIs } from "../../../api_";
import {
  postUploadFileFulfilled,
  postUploadFilePending,
  postUploadFileRejected,
} from "./cases/postUploadFile";

import {
  postUploadContentTextPending,
  postUploadContentTextFulfilled,
  postUploadContentTextRejected,
} from "./cases/postUploadContentText";

import {
  postCreateFolderPending,
  postCreateFolderFulfilled,
  postCreateFolderRejected,
} from "./cases/postCreateFolder";

import {
  getAllFoldersFulfilled,
  getAllFoldersPending,
  getAllFoldersRejected,
} from "./cases/getAllFolders";

import {
  getContentNavigatorFulfilled,
  getContentNavigatorPending,
  getContentNavigatorRejected,
} from "./cases/getContentNavigator";

import {
  getContentMetadataFulfilled,
  getContentMetadataPending,
  getContentMetadataRejected,
} from "./cases/getContentMetadata";


import {
  getContentMetadataFolderFulfilled,
  getContentMetadataFolderPending,
  getContentMetadataFolderRejected,
} from "./cases/getContentMetadataFolder";

import {
  putContentMetadataFulfilled,
  putContentMetadataPending,
  putContentMetadataRejected,
} from "./cases/putContentMetadata";

import {
  postAnalyzeContentFulfilled,
  postAnalyzeContentPending,
  postAnalyzeContentRejected,
} from "./cases/postAnalyzeContent";

import {
  getAnalyzeContentFulfilled,
  getAnalyzeContentPending,
  getAnalyzeContentRejected,
} from "./cases/getAnalyzeContent";


import {
  postSuggestionsContentFulfilled,
  postSuggestionsContentPending,
  postSuggestionsContentRejected,
} from "./cases/postSuggestionsContent";


import {
  putHandleContentEditsFulfilled,
  putHandleContentEditsPending,
  putHandleContentEditsRejected,
} from "./cases/putHandleContentEdits";

import {
  postBrandReactionFulfilled,
  postBrandReactionPending,
  postBrandReactionRejected,
} from "./cases/postBrandReaction";

import {
  getWatchVersionPending,
  getWatchVersionFulfilled,
  getWatchVersionRejected,
} from "./cases/getWatchVersion";

import {
  postAdaptContentChannelPending,
  postAdaptContentChannelFulfilled,
  postAdaptContentChannelRejected,
} from "./cases/postAdaptContentChannel";

import {
  deleteContentPending,
  deleteContentFulfilled,
  deleteContentRejected,
} from "./cases/deleteContent";

import {
  getAnalyzeExportPDFPending,
  getAnalyzeExportPDFFulfilled,
  getAnalyzeExportPDFRejected,
} from "./cases/getAnalyzeExportPDF";

import {
  postGenerateDraftContentPending,
  postGenerateDraftContentFulfilled,
  postGenerateDraftContentRejected,
}
from "./cases/postGenerateDraftContent";

export const extraReducers = (builder) => {
  builder.addCase(organizationAPIs.postUploadFile.pending, postUploadFilePending);
  builder.addCase(organizationAPIs.postUploadFile.fulfilled, postUploadFileFulfilled);
  builder.addCase(organizationAPIs.postUploadFile.rejected, postUploadFileRejected);

  builder.addCase(organizationAPIs.postContentText.pending, postUploadContentTextPending);
  builder.addCase(organizationAPIs.postContentText.fulfilled, postUploadContentTextFulfilled);
  builder.addCase(organizationAPIs.postContentText.rejected, postUploadContentTextRejected);

  builder.addCase(organizationAPIs.postCreateFolder.pending, postCreateFolderPending);
  builder.addCase(organizationAPIs.postCreateFolder.fulfilled, postCreateFolderFulfilled);
  builder.addCase(organizationAPIs.postCreateFolder.rejected, postCreateFolderRejected);

  builder.addCase(contentAPIs.getAllFolders.pending, getAllFoldersPending);
  builder.addCase(contentAPIs.getAllFolders.fulfilled, getAllFoldersFulfilled);
  builder.addCase(contentAPIs.getAllFolders.rejected, getAllFoldersRejected);

  builder.addCase(contentAPIs.getContentNavigator.pending, getContentNavigatorPending);
  builder.addCase(contentAPIs.getContentNavigator.fulfilled, getContentNavigatorFulfilled);
  builder.addCase(contentAPIs.getContentNavigator.rejected, getContentNavigatorRejected);

  builder.addCase(contentAPIs.getContentMetadata.pending, getContentMetadataPending);
  builder.addCase(contentAPIs.getContentMetadata.fulfilled, getContentMetadataFulfilled);
  builder.addCase(contentAPIs.getContentMetadata.rejected, getContentMetadataRejected);

  builder.addCase(contentAPIs.getContentMetadataFolder.pending, getContentMetadataFolderPending);
  builder.addCase(contentAPIs.getContentMetadataFolder.fulfilled, getContentMetadataFolderFulfilled);
  builder.addCase(contentAPIs.getContentMetadataFolder.rejected, getContentMetadataFolderRejected);

  builder.addCase(contentAPIs.putUpdateContentMetadata.pending, putContentMetadataPending);
  builder.addCase(contentAPIs.putUpdateContentMetadata.fulfilled, putContentMetadataFulfilled);
  builder.addCase(contentAPIs.putUpdateContentMetadata.rejected, putContentMetadataRejected);

  builder.addCase(contentAPIs.postAnalyzeContent.pending, postAnalyzeContentPending);
  builder.addCase(contentAPIs.postAnalyzeContent.fulfilled, postAnalyzeContentFulfilled);
  builder.addCase(contentAPIs.postAnalyzeContent.rejected, postAnalyzeContentRejected);

  builder.addCase(contentAPIs.getAnalyzeContent.pending, getAnalyzeContentPending);
  builder.addCase(contentAPIs.getAnalyzeContent.fulfilled, getAnalyzeContentFulfilled);
  builder.addCase(contentAPIs.getAnalyzeContent.rejected, getAnalyzeContentRejected);

  builder.addCase(contentAPIs.postSuggestionsContent.pending, postSuggestionsContentPending);
  builder.addCase(contentAPIs.postSuggestionsContent.fulfilled, postSuggestionsContentFulfilled);
  builder.addCase(contentAPIs.postSuggestionsContent.rejected, postSuggestionsContentRejected);

  builder.addCase(contentAPIs.putHandleContentEdits.pending, putHandleContentEditsPending);
  builder.addCase(contentAPIs.putHandleContentEdits.fulfilled, putHandleContentEditsFulfilled);
  builder.addCase(contentAPIs.putHandleContentEdits.rejected, putHandleContentEditsRejected);

  builder.addCase(contentAPIs.postBrandReaction.pending, postBrandReactionPending);
  builder.addCase(contentAPIs.postBrandReaction.fulfilled, postBrandReactionFulfilled);
  builder.addCase(contentAPIs.postBrandReaction.rejected, postBrandReactionRejected);

  builder.addCase(contentAPIs.getWatchVersion.pending, getWatchVersionPending);
  builder.addCase(contentAPIs.getWatchVersion.fulfilled, getWatchVersionFulfilled);
  builder.addCase(contentAPIs.getWatchVersion.rejected, getWatchVersionRejected);

  builder.addCase(contentAPIs.postAdaptContentChannel.pending, postAdaptContentChannelPending);
  builder.addCase(contentAPIs.postAdaptContentChannel.fulfilled, postAdaptContentChannelFulfilled);
  builder.addCase(contentAPIs.postAdaptContentChannel.rejected, postAdaptContentChannelRejected);

  builder.addCase(contentAPIs.deleteContent.pending, deleteContentPending);
  builder.addCase(contentAPIs.deleteContent.fulfilled, deleteContentFulfilled);
  builder.addCase(contentAPIs.deleteContent.rejected, deleteContentRejected);

  builder.addCase(contentAPIs.getAnalyzeExportPDF.pending, getAnalyzeExportPDFPending);
  builder.addCase(contentAPIs.getAnalyzeExportPDF.fulfilled, getAnalyzeExportPDFFulfilled);
  builder.addCase(contentAPIs.getAnalyzeExportPDF.rejected, getAnalyzeExportPDFRejected);

  builder.addCase(contentAPIs.postGenerateDraftContent.pending, postGenerateDraftContentPending);
  builder.addCase(contentAPIs.postGenerateDraftContent.fulfilled, postGenerateDraftContentFulfilled);
  builder.addCase(contentAPIs.postGenerateDraftContent.rejected, postGenerateDraftContentRejected);
};
