import * as React from "react";
import {
  useTheme,
  Typography,
  Box,
  Avatar,
  Divider,
  Alert,
} from "@mui/material";
import { Button, Grid, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  AccordionComponent,
  LeftPaneComponent,
  LogoLoader,
  ModalConfirmationPersonaBuilder,
} from "../../../../../components";
import { useCurrentPersonaViewer } from "../../../../../hooks/useCurrentPersonaViewer";
import useTextTransform from "../../../../../hooks/useTextTransform";
import { AIIconWhite } from "../../../../../icons";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function PersonaViewer() {
  const { camelCaseToTitle } = useTextTransform();
  const navigate = useNavigate();
  const { persona, isUninitialized, isLoading, onResetPersona, onGetPersona } =
    useCurrentPersonaViewer();
  const { id, persona_id } = useParams();
  const theme = useTheme();

  React.useEffect(() => {
    if (isUninitialized) {
      onGetPersona(id, persona_id);
    }
  }, [persona, isUninitialized, id, persona_id, onGetPersona]);

  return (
    <Grid container sx={{ height: "100vh" }}>
      <LogoLoader open={isLoading} text={"Loading persona"}></LogoLoader>
      <LeftPaneComponent>
        <Stack pt={"70px"}>
          <Stack
            sx={{
              borderRadius: "10px",
              background: theme.palette.dark.main,
              padding: "10px 6px",
            }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {persona?.avatar_signed_url ? (
              <Box sx={{ maxWidth: 160 }}>
                <Avatar
                  src={persona.avatar_signed_url}
                  sx={{ width: "100%", height: "100%" }}
                ></Avatar>
              </Box>
            ) : (
              <Box sx={{ width: 160, height: 160 }}>
                <Avatar
                  src={""}
                  sx={{ width: "100%", height: "100%" }}
                ></Avatar>
              </Box>
            )}

            <Typography
              sx={{ color: theme.palette.yellow.main }}
              variant="overline"
            >
              {" "}
              {persona?.name}
            </Typography>
            <Typography
              sx={{ color: "#fff" }}
              textAlign={"center"}
              variant="subtitle1"
            >
              {persona?.title}
            </Typography>
          </Stack>
        </Stack>
        <ModalConfirmationPersonaBuilder
          type={"editFullBtn"}
          persona={persona}
        />
        <Button
          sx={{ fontSize: "12px" }}
          color="dark"
          variant="contained"
          startIcon={<AIIconWhite />}
        >
          Auto-generate available unfilled attributes
        </Button>
        <Button
          color="success"
          size="small"
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            onResetPersona();
            navigate(`..`);
          }}
        >
          BACK TO PERSONAS LIST
        </Button>
      </LeftPaneComponent>
      <Grid item xs={6}>
        <Box
          sx={{
            px: 10,
            pt: "70px",
          }}
        >
          <Typography textAlign={"left"} variant="h5">
            Persona Attributes
          </Typography>
          <Divider></Divider>
          <br></br>
          <Box sx={{ maxHeight: 600, overflowX: "auto" }}>
            {persona?.id &&
              Object.entries(persona?.description_metadata).map(
                ([name, value], idx) => (
                  <Box key={idx}>
                    {value !== "" &&
                      value.length > 0 &&
                      !["avatarSource"].includes(name) && (
                        <AccordionComponent
                          expanded={true}
                          title={camelCaseToTitle(name)}
                          content={
                            <Typography
                              ml={4.5}
                              textAlign={"left"}
                              fontWeight={200}
                              variant="body1"
                            >
                              {value}
                            </Typography>
                          }
                        />
                      )}
                  </Box>
                )
              )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ pr: 10, pt: "70px" }}>
          <Typography textAlign={"left"} variant="h5">
            About {persona?.title}
          </Typography>
          <Divider></Divider>
          <br></br>
          <Alert variant="outlined" severity="warning">
            Changes to Persona Attributes will update the paragraph below
          </Alert>

          <Box sx={{ maxHeight: 600, overflowX: "auto" }}>
            <Typography textAlign={"left"} fontWeight={200} variant="body1">
              {persona?.bio_summary}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
