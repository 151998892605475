import axiosInstance from "..";
import { authAPIs } from "../..";

let isRefreshing = false;
let refreshSubscribers = [];

/**
 * Function to renew the authentication token.
 * Replace the logic inside this function with your actual token renewal logic.
 * @returns {Promise<string>} A promise resolving to the new authentication token.
 */
async function renewToken() {
  // Replace this with your actual token renewal logic
  const response = await authAPIs.getRefreshToken(
    JSON.parse(localStorage.getItem("auth"))
  );
  console.log("RENEW TOKEN :", response);
  if (response.token) {
    localStorage.setItem("auth", JSON.stringify(response));
    return response.token;
  }
  throw new Error("Refresh token failed");
}

function onRefreshed(token) {
  refreshSubscribers.map(callback => callback(token));
  refreshSubscribers = [];
}

function addRefreshSubscriber(callback) {
  refreshSubscribers.push(callback);
}

/**
 * Axios error handler for handling unauthorized (401) responses.
 * Attempts to renew the authentication token and retry the original request.
 * @param {Error} error - The Axios error object.
 * @returns {Promise} A promise resolving to the retried request or rejecting with the original error.
 */
export const unauthorizedHanlder = async (error) => {
  const originalRequest = error.config;

  // Check if the error is due to a 401 status code and if the original request was not a token renewal attempt
  console.log(error);
  if (
    !originalRequest.url.includes("/api/auth/login") &&
    error.response.status === 401 &&
    !originalRequest._retry
  ) {
    if (isRefreshing) {
      return new Promise((resolve) => {
        addRefreshSubscriber((newToken) => {
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
          resolve(axiosInstance(originalRequest));
        });
      });
    }

    originalRequest._retry = true;
    isRefreshing = true;

    try {
      // Attempt to renew the token (call your token renewal function here)
      const newToken = await renewToken();
      isRefreshing = false;
      onRefreshed(newToken);

      // Update the token in the original request headers
      originalRequest.headers["Authorization"] = `Bearer ${newToken}`;

      // Retry the original request with the new token
      return axiosInstance(originalRequest);
    } catch (renewError) {
      // Handle token renewal error
      console.error("Error renewing token:", renewError);
      isRefreshing = false;
      window.location.href = "/";
      localStorage.clear();
      return Promise.reject(renewError);
    }
  }
  // Reject with the original error if not a 401 or if the original request was already retried
  return Promise.reject(error);
};
