import { Box, Button, IconButton, Stack } from "@mui/material";
import { BuilderIAInputComponent } from "../../../../../components";
import { useBrandBuilderQuestionSelectorHandler } from "../../../../../hooks/useBrandBuilderQuestionSelectorHandler";
import { TrashIcon } from "../../../../../icons";
import { useRef, useEffect } from "react";

export default function ProductServicesLayout({ formik }) {
  const { activeStep, steps } = useBrandBuilderQuestionSelectorHandler();

  const addNewProductForm = () => {
    formik.setFieldValue("products_services", [
      ...formik.values.products_services,
      {
        name: "",
        description: "",
      },
    ]);
  };

  const removeProductForm = (index) => {
    let newForms = formik.values.products_services.filter(
      (_, idx) => idx !== index
    );
    formik.setFieldValue("products_services", newForms);
  };
  const scrollToBottom = () => {
    productsContainer.current?.scrollIntoView({
      behavior: "smooth",
    });
  };
  const productsContainer = useRef(null);
  useEffect(() => {
    if (scrollToBottom) {
      scrollToBottom();
    }
  }, [formik.values.products_services]); // eslint-disable-line react-hooks/exhaustive-deps
  return steps[activeStep].inputName === "products_services" ? (
    <Stack justifyContent="start" width={"100%"} alignItems={"flex-start"}>
      <Box width={"100%"} maxHeight={"150px"} sx={{ overflow: "auto" }}>
        {formik.values.products_services.map((subForm, index) => (
          <Stack
            key={index}
            width={"90%"}
            justifyContent={"center"}
            alignItems={"baseline"}
            direction={"row"}
          >
            <Stack
              width={"100%"}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"end"}
              spacing={2}
            >
              <BuilderIAInputComponent
                currentQuestion={{
                  ...steps[activeStep],
                  inputName: `products_services.${index}.name`,
                  placeholder: "product name",
                }}
                width={"50%"}
                multiline={false}
                formik={formik}
                value={subForm.name}
                error={
                  formik?.errors["products_services"] &&
                  formik?.errors["products_services"].find(
                    (errorField) => errorField?.name
                  ) &&
                  subForm.name === ""
                }
                //handleKeyDown={handleKeyDown} formik?.errors?['products_services'][index]?.name
              />

              <BuilderIAInputComponent
                multiline={false}
                currentQuestion={{
                  ...steps[activeStep],
                  inputName: `products_services.${index}.description`,
                  placeholder: "product description",
                }}
                formik={formik}
                value={subForm.description}
                error={
                  formik?.errors["products_services"] &&
                  formik?.errors["products_services"].find(
                    (errorField) => errorField?.description
                  ) &&
                  subForm.description === ""
                }
                //handleKeyDown={handleKeyDown}
              />
              <div ref={productsContainer} ></div>
            </Stack>
            <IconButton
              onClick={() => removeProductForm(index)}
              size={"small"}
              color="error"
            >
              <TrashIcon></TrashIcon>
            </IconButton>
            <Box sx={{ w: "200px" }}></Box>
          </Stack>
        ))}
      </Box>
      <Button color="success" onClick={addNewProductForm} size="small">
        {" "}
        + add more
      </Button>
    </Stack>
  ) : null;
}
