import { useDispatch, useSelector } from "react-redux";
import {
  resetPersona,
  selectPersonaData,
  selectPersonaStatus
} from "../redux/persona";
import { organizationAPIs } from "../api_";

export function useCurrentPersonaViewer() {
  const dispatch = useDispatch();
  const persona = useSelector((state) => selectPersonaData(state));

  const status = useSelector((state) => selectPersonaStatus(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onResetPersona = () => dispatch(resetPersona());

  const onGetPersona = (id,personaId) => {
    dispatch(
      organizationAPIs.getPersona({
        orgId: id,
        personaId,
      })
    );
  };

  return {
    persona,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onResetPersona,
    onGetPersona,
  };
}
