const brandBuilderQuestionsSchema = [
  {
    isFastLane: true,
    category: "Brand Name",
    label: "Brand Name",
    inputType:"text",
    inputName: "name",
    defaultValue: "",
    placeholder: "Type your answer",
    question: "What is the name of your brand?",
    hasAIOption:false,
    aiQuestion: ""
  },
  {
    isFastLane: true,
    category: "Mission",
    label: "Mission",
    inputType:"text",
    inputName: "mission",
    defaultValue: "",
    placeholder: "Type your answer",
    question: "What is your brand's mission?",
    hasAIOption:false,
    aiQuestion: ""
  },
  {
    isFastLane: true,
    category: "Vision",
    label: "Vision",
    inputType:"text",
    inputName: "vision",
    defaultValue: "",
    placeholder: "Type your answer",
    question: "What is your brand's vision for the future?",
    hasAIOption:true,
    promptIdentifier: "brandVision",
    aiQuestion: "Task Description: \
You are a visionary strategist tasked with defining the future vision for a brand. \
Consider the next 5-10 years and articulate a vision that includes key goals, aspirations, \
and strategic objectives. Your vision should encapsulate the brand's commitment to innovation, \
market leadership, and unique value propositions. \
Guidelines: \
- Use clear and concise language. \
- Emphasize the brand's commitment to its core values and future growth. \
- Highlight specific areas of innovation or strategic focus if applicable. \
- Refer to the provided examples for guidance on the expected level of specificity and relevance. \
Response Requirements: \
1. Content: Craft a comprehensive vision statement that clearly outlines the brand's future aspirations, focusing on innovation, leadership, and strategic direction. \
2. Length: Limit your response to one or two sentences. \
3. Format: The response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
JSON Format Example: \
```json \
{ \
'output': 'your-response-here' \
}\
```"
  },
  {
    isFastLane: false,
    category: "Brand Values",
    label: "Values",
    inputType:"text",
    inputName: "values",
    defaultValue: "",
    placeholder: "Type your answer",
    question: "What are your brand's values?",
    subText:'List out, separated by commas',
    hasAIOption:true,
    promptIdentifier: "brandValues",
    aiQuestion: "Task Description: \
You are a visionary strategist tasked with defining the core values of a brand. \
These values should reflect the principles and standards that guide the brand's actions, \
decisions, and culture. Consider the brand's mission, identity, and what it stands for when crafting these values. \
Guidelines: \
- Use clear and concise language. \
- Reflect the brand's unique characteristics and market positioning. \
- Highlight how these values shape the brand's interactions and decisions. \
- Refer to the provided examples for guidance on the expected level of specificity and relevance. \
Response Requirements: \
1. Content: List the key values that the brand embodies. Each value should be briefly described, explaining its importance to the brand's identity and operations. \
2. Length: Provide a list of 3 to 5 core values, each with a brief description. \
3. Format: The response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
JSON Format Example: \
{ \
'output': 'your-response-here' \
}\
"},
  {
    isFastLane: false,
    category: "Brand Voice",
    label: "Voice",
    inputType:"text",
    inputName: "voice",
    defaultValue: "",
    placeholder:"Type your answer",
    question: "What is your brand voice?",
    hasAIOption:true,
    promptIdentifier: "brandVoice",
    aiQuestion: "Task Description: \
You are a visionary strategist tasked with defining the brand voice for a company. \
This voice should reflect the brand's personality and style of communication, \
ensuring consistency across all interactions and messaging. Consider the brand's target audience, \
values, and overall identity when describing the brand voice. \
Guidelines: \
- Use clear and concise language. \
- Reflect the brand's personality and how it communicates with its audience. \
- Highlight any specific elements that define the brand's voice, such as tone, language style, and formality level. \
- Refer to the provided examples for guidance on the expected level of specificity and relevance. \
Response Requirements: \
1. Content: Describe the brand voice, including its tone, style, and key characteristics. \
2. Length: Provide a brief description, focusing on the main aspects of the brand's communication style. \
3. Format: The response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
JSON Format Example: \
{ \
'output': 'your-response-here' \
}\
" },
  {
    isFastLane: true,
    category: "Positioning statement",
    label: "Positioning statement",
    inputType:"text",
    inputName: "positioning_statement",
    defaultValue: "",
    placeholder: "Type your answer",
    question: "What is your brand positioning statement?",
    hasAIOption:true,
    promptIdentifier: "brandPositioningStatement",
    aiQuestion: "Task Description: \
You are a visionary strategist tasked with crafting a positioning statement for a brand. \
This statement should clearly convey the unique value and competitive advantage that the \
brand offers to its target audience. Consider the brand's key strengths, market \
differentiation, and customer needs when creating this statement. \
Example for Reference: \
The Melon company positions itself as a leader in sustainable fashion, \
offering innovative designs that blend style with environmental responsibility. \
By prioritizing eco-friendly materials and ethical production, we cater to conscious \
consumers who value both quality and sustainability. \
Guidelines: \
- Use clear and concise language. \
- Emphasize the brand's unique characteristics and value to its target audience. \
- Highlight the brand's strengths and how it stands out in the market. \
- Refer to the provided examples for guidance on the expected level of specificity and relevance. \
Response Requirements: \
1. Content: Craft a positioning statement that highlights the brand's unique selling propositions and key market differentiators. \
2. Length: Limit the statement to one or two sentences. \
3. Format: The response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
JSON Format Example: \
{ \
'output': 'your-response-here' \
}\
"
  },
  {
    isFastLane: true,
    category: "Products & services",
    label: "Products & services",
    inputType:"row_input",
    inputName: "products_services",
    defaultValue: [],
    fieldsSchema:{
      name:'',
      description:''
    },
    placeholder: "Type your answer",
    question:"Name or describe your product or service?",
    subText:"If you have more than one, tap + ADD ANOTHER",
    hasAIOption:false,
    aiQuestion: "",
    type: "multiple",
  },
  {
    isFastLane: true,
    category: "Other details",
    label: "Other details",
    inputType:"textarea",
    inputName: "other_details",
    defaultValue: "",
    placeholder: "Type your answer",
    question: "You can use the freeform field to Add more detail to your brand not covered in builder questions",
    hasAIOption:false,
    aiQuestion: "",
  },
];

export default brandBuilderQuestionsSchema;
