import contentSlice from "./slice"

// SELECTORS
export const selectCreateAudienceData = (state) => state?.audience?.createAudience?.data;
export const selectCreateAudienceStatus = (state) => state?.audience?.createAudience?.status

export const selectUpdateAudienceData = (state) => state?.audience?.updateAudience?.data;
export const selectUpdateAudienceStatus = (state) => state?.audience?.updateAudience?.status

export const selectDisableAudienceData = (state) => state?.audience?.disableAudience?.data;
export const selectDisableAudienceStatus = (state) => state?.audience?.disableAudience?.status

export const selectAudienceData = (state) => state?.audience?.listAudience?.data
export const selectAudienceStatus = (state) => state?.audience?.listAudience?.status

export const selectCurrentAudienceData = (state) => state?.audience?.currentAudience?.data


// ACTIONS
export const { 
    setCurrentAudience,
    resetCreateStatus,
    resetUpdateStatus,
    resetCurrentAudience,
    resetListAudience,
    resetDisableAudience,
} = contentSlice.actions;

// REDUCER
export default contentSlice.reducer

