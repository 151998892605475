const AIIcon = ({color='#231F20', width=30,height=43}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 30 34"
    fill="none"
  >
    <g clipPath="url(#clip0_4_445)">
      <path
        d="M14.9 33.24C18.2026 33.24 20.88 25.9288 20.88 16.91C20.88 7.89118 18.2026 0.579987 14.9 0.579987C11.5973 0.579987 8.91998 7.89118 8.91998 16.91C8.91998 25.9288 11.5973 33.24 14.9 33.24Z"
        stroke={color}
        strokeWidth="1.16"
        strokeMiterlimit="10"
      />
      <path
        d="M28.8748 25.3527C30.5832 22.5263 25.7111 16.453 17.9927 11.7878C10.2742 7.12252 2.63224 5.63189 0.923842 8.45836C-0.784561 11.2848 4.08754 17.3581 11.806 22.0233C19.5244 26.6886 27.1664 28.1792 28.8748 25.3527Z"
        stroke={color}
        strokeWidth="1.16"
        strokeMiterlimit="10"
      />
      <path
        d="M17.9922 22.0221C25.7107 17.3569 30.5828 11.2836 28.8744 8.45717C27.166 5.6307 19.524 7.12132 11.8056 11.7866C4.08711 16.4518 -0.784988 22.5251 0.923414 25.3515C2.63182 28.178 10.2738 26.6874 17.9922 22.0221Z"
        stroke={color}
        strokeWidth="1.16"
        strokeMiterlimit="10"
      />
      <path
        d="M14.9 20.53C16.8993 20.53 18.52 18.9093 18.52 16.91C18.52 14.9107 16.8993 13.29 14.9 13.29C12.9008 13.29 11.28 14.9107 11.28 16.91C11.28 18.9093 12.9008 20.53 14.9 20.53Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_4_445">
        <rect width="29.79" height="33.82" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default AIIcon;
