import { useDispatch, useSelector } from "react-redux";
import { contentAPIs } from "../api_";
import { selectCurrentOrganization } from "../redux/organizations";
import {
  resetUpdateContentMetadata,
  selectGetContentMetadataData,
  selectGetContentUpdateMetadataStatus,
} from "../redux/content";

export default function useContentUpdateMetadataHanlder() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectGetContentUpdateMetadataStatus(state));
  const data = useSelector((state) => selectGetContentMetadataData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  const currentBlob = useSelector((state) =>
    selectGetContentMetadataData(state)
  );
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onUpdateContentMetadata = (values) => {
    dispatch(
      contentAPIs.putUpdateContentMetadata({
        values,
        id: currentOrg.id,
        blobId: currentBlob.metadata.id,
      })
    );
  };

  const onResetUpdateContentMetadata = () => {
    dispatch(resetUpdateContentMetadata());
  };

  return {
    data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onUpdateContentMetadata,
    onResetUpdateContentMetadata,
  };
}
