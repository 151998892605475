import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./extraReducers";
import reducers from "./reducers";

const initialState = {
  mode: "view",
  persona: {
    status: "idle",
    data: "",
    error: null,
  },
  list: {
    status: "idle",
    data: [],
  },
  builder: {
    status: "idle",
    data: {},
    payload: {},
    error: null,
    submitNext: false,
    wizzard: {
      activeStep: 0,
      activeSubStep: 0,
    },
  },
  disablePersona: {
    status: "idle",
    data: {},
  },
  aiAnswer: {
    status: "",
    data: "",
    error: null,
  },
  exportPDF: {
    status: "",
    data: "",
    error: null,
  },
  exportPDFQueue:{
    queue:[],
  },
};

const personaSlice = createSlice({
  name: "persona",
  initialState,
  reducers,
  extraReducers,
});

export default personaSlice;
