import { Box, Button, LinearProgress, Stack, Typography } from "@mui/material";
import * as React from "react";
import useGetAnalyzedContent from "../../../../../../hooks/useGetAnalyzedContent";
import useBrandReactionHandler from "../../../../../../hooks/useBrandReactionHandler";
import { Modal } from "../../../../../../components";
import useCurrentOrganizationHandler from "../../../../../../hooks/useCurrentOrganizationHandler";
import { useNavigate } from "react-router-dom";
import { useBrandBuilderModeHandler } from "../../../../../../hooks/useBrandBuilderModeHandler";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CommitIcon from "@mui/icons-material/Commit";
import EmptyTab from "./EmptyTab";
import useGetOrgUserProfile from "../../../../../../hooks/useGetOrgUserProfileHanlder";

export default function BrandAlignmentTab() {
  const { canManageBrand } = useGetOrgUserProfile();
  const navigate = useNavigate();
  const { analyzedContent } = useGetAnalyzedContent();
  const { onSetMode } = useBrandBuilderModeHandler();
  const { currentOrganization } = useCurrentOrganizationHandler();

  const { isLoading: isGeneratingBrandReaction } = useBrandReactionHandler();
  const [showBrand, setShowBrand] = React.useState(false);
  const emptyTabText = () => {
    if (
      analyzedContent.content.is_current &&
      !Boolean(analyzedContent.audience_personas_reactions)
    ) {
      return "This content is not analyzed yet";
    }
    if (analyzedContent.content.is_current) {
      return "No Brand Reaction generated yet, please click in CHECK BRAND ALIGNMENT";
    }
    if (!Boolean(analyzedContent.audience_personas_reactions)) {
      return "This version content was not Analyzed";
    }
    return "This version didn't get Brand Alignment";
  };
  const noBrandMetadata = (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          background: "#fafafa",
          padding: "20px",
          color: "#a5a5a5",
          borderRadius: "10px",
        }}
      >
        <BlurOnIcon fontSize="large"></BlurOnIcon>
        <Typography>You must first define your Brand!</Typography>
        <Button
          startIcon={<ArrowOutwardIcon></ArrowOutwardIcon>}
          size="small"
          disabled={!canManageBrand}
          onClick={() => {
            onSetMode("create", null);
            navigate(`/organization/${currentOrganization.id}/brand`);
          }}
        >
          Create a new Brand Profile
        </Button>
      </Box>
    </Box>
  );
  const noBrandReaction = (
    <>
      {!isGeneratingBrandReaction && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <EmptyTab icon={<CommitIcon />}>{emptyTabText()}</EmptyTab>
        </Box>
      )}
      {isGeneratingBrandReaction && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box width={200}>
            <Typography variant="caption">
              {" "}
              Generating Brand Reaction
            </Typography>
            <LinearProgress></LinearProgress>
          </Box>
        </Box>
      )}
    </>
  );
  const brandReaction = () => {
    const data = analyzedContent.brand;
    if (isGeneratingBrandReaction) {
      return noBrandReaction;
    }
    return data?.brand_reaction ? (
      <Box sx={{ py: 1 }}>
        <Stack justifyContent={"space-between"} direction={"row"}>
          <Typography variant="h6" fontWeight={"bold"} textAlign={"left"}>
            Brand Alignment Feedback{" "}
          </Typography>
          <Button
            size="small"
            color="success"
            onClick={() => setShowBrand(true)}
          >
            VIEW BRAND PROFILE
          </Button>
        </Stack>
        <Box>
          <Typography
            textAlign={"left"}
            fontWeight={"bold"}
            variant="subtitle1"
          >
            Overall Suggestion
          </Typography>
          <Typography textAlign={"left"}  style={{whiteSpace: 'pre-line'}}>{data?.brand_reaction}</Typography>
        </Box>
        <Modal
          open={showBrand}
          handleClose={() => setShowBrand(false)}
          title={"Brand Profile"}
          body={
            <Box>
              {data?.brand_metadata &&
                Object.entries(data?.brand_metadata.description_metadata).map(
                  ([name, value]) => (
                    <div key={name}>
                      <Typography
                        sx={{ py: 2, textAlign: "left", fontSize: "11px" }}
                        textAlign={"left"}
                        textTransform={"uppercase"}
                        variant="body1"
                      >
                        {name}
                      </Typography>

                      {name === "products_services" &&
                        data.brand_metadata.description_metadata.products_services.map(
                          (item, key) => {
                            return (
                              <Typography
                                key={key}
                                sx={{ pb: 2, fontSize: "18px" }}
                                textAlign={"left"}
                                variant="body1"
                              >
                                - {item.name}
                              </Typography>
                            );
                          }
                        )}
                      <Typography
                        sx={{
                          pb: 2,
                          fontSize: "18px",
                          color: value ? "inherit" : "red",
                        }}
                        textAlign={"left"}
                        variant="body1"
                      >
                        {name !== "products_services"
                          ? value === ""
                            ? "need to be completed"
                            : value
                          : ""}
                      </Typography>
                    </div>
                  )
                )}
            </Box>
          }
        ></Modal>
      </Box>
    ) : (
      noBrandReaction
    );
  };
  return (
    <>
      {!analyzedContent?.brand?.brand_metadata
        ? noBrandMetadata
        : brandReaction()}
    </>
  );
}
