import { useEffect, useState } from "react";
import { Modal } from "../../../../../components";
import { AIIcon } from "../../../../../icons";
import { Button, Typography } from "@mui/material";
import useGenerateDraftContentHandler from "../../../../../hooks/useGenerateDraftContentHandler";

export default function ModalConfirmGenerateDraft({ formikGenerateDraft }) {
  const [open, setOpen] = useState(false);
  const { isSuccess } = useGenerateDraftContentHandler();
  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
    }
  }, [isSuccess]);
  return (
    <>
      <Button
        fullWidth
        onClick={() => formikGenerateDraft.errors && setOpen(true)}
        startIcon={<AIIcon height={20} width={20} color="white"></AIIcon>}
        sx={{
          position: "absolute",
          bottom: 30,
          left: 0,
        }}
        color="primary"
        size="small"
        variant="contained"
      >
        Generate Draft
      </Button>
      <Modal
        maxWidth="sm"
        open={open}
        handleClose={() => setOpen(false)}
        actions={
          <>
            <Button
              //startIcon={<AnalyzeIcon></AnalyzeIcon>}
              variant="contained"
              onClick={formikGenerateDraft.handleSubmit}
            >
              Yes
            </Button>
            <Button
              onClick={() => setOpen(false)}
              variant="contained"
              color="dark"
            >
              No
            </Button>
          </>
        }
        body={
          <>
            <Typography variant="overline">
              do you want to generate a new draft?
            </Typography>
            <br></br>
            <Typography variant="subtitle1" color={"error"}>
              **Generating a new draft content will consume credits.
            </Typography>
          </>
        }
      />
    </>
  );
}
