import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  IconButton,
  Box,
  Button,
  Stack,
} from "@mui/material";
import { useRef, useEffect } from "react";
import { useGenerateAnswerHandler } from "../../hooks/useGenerateAnswerHandler";
import { AIIcon } from "../../icons";
import SpinLoader from "../SpinLoader";
import Modal from "../Modal";
import ErrorMessageDisplayer from "../ErrorMessageDisplayer";

const BuilderIAInputComponent = (props) => {
  const { currentQuestion, formik, handleKeyDown } = props;
  const inputRef = useRef();
  const {
    onGenerateAnswer,
    onResetGenerateAnswerStatus,
    isLoading,
    isError,
    error,
    isSuccess,
    data,
  } = useGenerateAnswerHandler();

  useEffect(() => {
    if (isSuccess) {
      onResetGenerateAnswerStatus();
      inputRef?.current?.focus();
      formik.setFieldValue(currentQuestion.inputName, data);
    }
  }, [
    isSuccess,
    onResetGenerateAnswerStatus,
    currentQuestion.inputName,
    data,
    formik,
  ]);
  const AIBtn = () => (
    <IconButton
      onMouseDown={(e) => e.preventDefault()}
      onClick={() =>
        onGenerateAnswer({
          question: currentQuestion.aiQuestion,
          promptIdentifier: currentQuestion.promptIdentifier,
          otherDetails: formik.values,
        })
      }
      sx={{ marginBottom: 3 }}
    >
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          fontSize: "10px",
          padding: "0px",
          lineHeight: "1",
          paddingBottom: "10px",
          textTransform: "uppercase",
          color: "#66bf76",
        }}
      >
        <AIIcon />
        {"AI Assist"}
      </Stack>
    </IconButton>
  );
  return (
    <>
      <Modal
        maxWidth="sm"
        open={isError}
        actions={
          <Button
            onClick={onResetGenerateAnswerStatus}
            variant="contained"
            color="dark"
          >
            Close
          </Button>
        }
        handleClose={onResetGenerateAnswerStatus}
        body={
          <ErrorMessageDisplayer
            content={{
              header: "Ask AI could not be executed",
              title: error?.error,
              message: error?.message,
            }}
          />
        }
      />
      <FormControl fullWidth variant="standard">
        <Input
          color="dark"
          id="standard-adornment-password"
          sx={{
            fontSize: "14px",
            fontWeight: 300,
          }}
          minRows={1}
          maxRows={5}
          fullWidth
          multiline={props.multiline ? props.multiline : true}
          placeholder={currentQuestion.placeholder}
          name={currentQuestion.inputName}
          value={
            props?.value
              ? props?.value?.replace("\n", "")
              : formik?.values[currentQuestion.inputName]?.replace("\n", "")
          }
          onChange={formik.handleChange}
          onKeyDown={handleKeyDown}
          inputRef={inputRef}
          onBlur={formik.handleBlur}
          error={
            props.error
              ? props.error
              : formik.touched[currentQuestion.inputName] &&
                Boolean(formik.errors[currentQuestion.inputName])
          }
          endAdornment={
            currentQuestion.hasAIOption && (
              <InputAdornment position="end">
                {isLoading ? (
                  <Box height="65px">
                    <SpinLoader />
                  </Box>
                ) : (
                  <AIBtn></AIBtn>
                )}
              </InputAdornment>
            )
          }
        />
        <FormHelperText id="standard-weight-helper-text">
          {formik.touched[currentQuestion.inputName]
            ? formik.errors[currentQuestion.inputName]
            : " "}
        </FormHelperText>
      </FormControl>
    </>
  );
};
export default BuilderIAInputComponent;
