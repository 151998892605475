import {
  Box,
  Button,
  MenuItem,
  Select,
  Grid,
  Chip,
  CircularProgress,
  Typography,
} from "@mui/material";

import { useEffect } from "react";
import useRoleHandler from "../../../../hooks/useRoleHandler";
import useInviteUserHandler from "../../../../hooks/useInviteUserHandler";
import { useFormik } from "formik";
import * as Yup from "yup";
import useOrganizationListHandler from "../../../../hooks/useOrganizationListHandler";
import { InputText } from "../../../../components";
import useUsersByOrganizationHandler from "../../../../hooks/useUsersByOrganizationHandler";
import useCurrentOrganizationHandler from "../../../../hooks/useCurrentOrganizationHandler";

export default function useAddNewUser() {
  const { roles, isLoading } = useRoleHandler();
  const { organizations } = useOrganizationListHandler();
  const { onSubmitForm, isSuccess, onResetInviteUser } = useInviteUserHandler();
  const { onGetUsers } = useUsersByOrganizationHandler();
  const { currentOrganization } = useCurrentOrganizationHandler();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      passwordConfirmation:"",
      name: "",
      last_name: "",
      organization_id: currentOrganization?.id,
      role_id: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Required")
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address"),
      repeatEmail: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("email"), null], "Email must match"),
      password: Yup.string().required("Required"),
      passwordConfirmation: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      role_id: Yup.string().required("Required"),
      organization_id: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      onSubmitForm(values);
    },
  });
  useEffect(() => {
    if (isSuccess) {
      formik.resetForm();
      onResetInviteUser();
      onGetUsers();
    }
  }, [isSuccess, formik, onResetInviteUser, onGetUsers]);

  return {
    reset: () => {
      formik.resetForm();
    },
    actions: (
      <>
        <Button variant="contained" onClick={formik.handleSubmit}>
          Submit
        </Button>
      </>
    ),
    body: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4">Invite new user</Typography>
        </Grid>
        <Grid xs={6} item>
          <InputText
            name={"name"}
            placeholder={"Name"}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name ? formik.errors.name : ""}
            label="name"
            variant="outlined"
          />
        </Grid>
        <Grid xs={6} item>
          <InputText
            name={"last_name"}
            placeholder={"Last Name"}
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.touched.last_name ? formik.errors.last_name : ""}
            variant="outlined"
          />
        </Grid>
        <Grid item spacing={1.5} container>
          <Grid xs={6} item>
            <InputText
              name={"email"}
              placeholder={"Email"}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email ? formik.errors.email : ""}
              label="email"
              variant="outlined"
            />
          </Grid>
          <Grid xs={6} item>
            <InputText
              name={"repeatEmail"}
              placeholder={"Repeat Email"}
              value={formik.values.repeatEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.repeatEmail && Boolean(formik.errors.repeatEmail)
              }
              helperText={
                formik.touched.repeatEmail ? formik.errors.repeatEmail : ""
              }
              label="repeatEmail"
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid xs={6} item>
          <InputText
            label="Password"
            variant="outlined"
            name={"password"}
            type={"password"}
            placeholder={"Password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
          />
        </Grid>
        <Grid xs={6} item>
          <InputText
            variant="outlined"
            name={"passwordConfirmation"}
            placeholder={"Repeat Password"}
            type={"password"}
            value={formik.values.passwordConfirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.passwordConfirmation &&
              Boolean(formik.errors.passwordConfirmation)
            }
            helperText={
              formik.touched.passwordConfirmation
                ? formik.errors.passwordConfirmation
                : ""
            }
          />
        </Grid>

        <Grid xs={6} item>
          <Select
            displayEmpty
            SelectDisplayProps={{
              style: {
                padding: 0,
              },
            }}
            name={"role_id"} //
            value={
              formik.values.role_id
                ? roles.find((i) => i.id === formik.values.role_id).id
                : ""
            } //formik?.values?.channel
            error={formik?.touched?.role_id && Boolean(formik?.errors?.role_id)}
            startAdornment={isLoading && <CircularProgress size={"20px"} />}
            input={
              <InputText
                placeholder="Choose Role"
                helperText={formik.touched.role_id ? formik.errors.role_id : ""}
              />
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            renderValue={(selected) => {
              if (!selected) return <em>{"Choose Role"}</em>;
              return selected ? (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  <Chip
                    sx={{ height: "20px !important" }}
                    key={formik.values.role_id}
                    label={
                      roles.find((i) => i.id === formik.values.role_id).role
                    }
                  />
                </Box>
              ) : (
                <em>{"Choose Role"}</em>
              );
            }}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem disabled value="">
              <em>Choose Role</em>
            </MenuItem>
            {roles.map((role, idx) => (
              <MenuItem
                sx={{
                  textAlign: "center",
                  pl: 5,
                }}
                key={idx}
                value={role.id}
              >
                {role.role}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid xs={6} item>
          <Select
            displayEmpty
            SelectDisplayProps={{
              style: {
                padding: 0,
              },
            }}
            name={"organization_id"} //
            value={
              formik.values.organization_id
                ? organizations.find(
                    (i) => i.id === formik.values.organization_id
                  ).id
                : ""
            } //formik?.values?.channel
            error={
              formik?.touched?.organization_id &&
              Boolean(formik?.errors?.organization_id)
            }
            startAdornment={isLoading && <CircularProgress size={"20px"} />}
            input={
              <InputText
                placeholder="Choose Organization"
                helperText={
                  formik.touched.organization_id
                    ? formik.errors.organization_id
                    : ""
                }
              />
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            renderValue={(selected) => {
              if (!selected) return <em>{"Choose Organization"}</em>;
              return selected ? (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  <Chip
                    sx={{ height: "20px !important" }}
                    key={formik.values.organization_id}
                    label={
                      organizations.find(
                        (i) => i.id === formik.values.organization_id
                      ).name
                    }
                  />
                </Box>
              ) : (
                <em>{"Choose Organization"}</em>
              );
            }}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem disabled value="">
              <em>Choose Organization</em>
            </MenuItem>
            {organizations.map((organization, idx) => (
              <MenuItem
                sx={{
                  textAlign: "center",
                  pl: 5,
                }}
                key={idx}
                value={organization.id}
              >
                {organization.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid xs={6} item></Grid>
      </Grid>
    ),
  };
}
