import { Box, Typography } from "@mui/material";
import * as React from "react";
import useGetAnalyzedContent from "../../../../../../hooks/useGetAnalyzedContent";
import EmptyTab from "./EmptyTab";
import PsychologyIcon from "@mui/icons-material/Psychology";

export default function ReactionsTab() {
  const { analyzedContent } = useGetAnalyzedContent();
  const emptyTabText = () => {
    if (
      analyzedContent.content.is_current &&
      !Boolean(analyzedContent.audience_personas_reactions)
    ) {
      return "This content is not analyzed yet";
    }
    if (analyzedContent.content.is_current) {
      return "No Suggestions generated yet, please click in SUGGEST EDITS";
    }
    return "This content was not analyzed";
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {Boolean(analyzedContent?.audience_personas_reactions) ? (
        <Box sx={{ py: 1 }}>
          <Typography variant="h6" fontWeight={"bold"} textAlign={"left"}>
            Audience Reactions
          </Typography>
          <Box>
            <Typography
              textAlign={"left"}
              fontWeight={"bold"}
              variant="subtitle1"
            >
              Overall Reaction
            </Typography>
            <Typography textAlign={"left"}>
              {analyzedContent?.audience_personas_reactions?.audience_reaction}
            </Typography>
          </Box>
          <br />
          <Box>
            <Typography
              textAlign={"left"}
              fontWeight={"bold"}
              variant="subtitle1"
            >
              Individual Audience Member Reactions
            </Typography>
            {analyzedContent?.audience_personas_reactions?.personas_reactions &&
              analyzedContent?.audience_personas_reactions?.personas_reactions.map(
                (persona, idx) => (
                  <Box key={idx} py={0.5}>
                    <Typography
                      textAlign={"left"}
                      fontWeight={"bold"}
                      variant="subtitle2"
                    >
                      {persona.job_title}
                    </Typography>
                    <Typography textAlign={"left"}>
                      {persona.reaction}
                    </Typography>
                  </Box>
                )
              )}
          </Box>
        </Box>
      ) : (
        <EmptyTab icon={<PsychologyIcon />}>{emptyTabText()}</EmptyTab>
      )}
    </Box>
  );
}
