export const getBrandsPending = (state, action) => {
  state.brands.status = "pending";
};

export const getBrandsFulfilled = (state, action) => {
  state.brands.status = "fulfilled";
  state.brands.data = action.payload.result;
};

export const getBrandsRejected = (state, action) => {
  state.brands.status = "rejected";
};
