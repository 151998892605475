import * as React from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import { BackIcon } from "../../../../../icons";
import useCurrentFolderHandler from "../../../../../hooks/useCurrentFolderHandler";
import useContentNavigatorHandler from "../../../../../hooks/useContentNavigatorHandler";
import { useTheme } from "@emotion/react";

export default function CurrentFolderBtn () {
  const theme = useTheme();
  const { onSetCurrentFolder, currentFolder, prevFolder } =
    useCurrentFolderHandler();
    const {
      isSuccess,
    } = useContentNavigatorHandler({ readOnly: true });
  return (
    <>
      {currentFolder !== "/" && isSuccess && (
        <Stack
          sx={{
            textTransform: "uppercase",
            background: "#2828281f",
            borderRadius: "0px 10px 10px 0px",
            fontSize: "11px",
            fontWeight: 200,
          }}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <Button
            color="dark"
            variant="contained"
            startIcon={<BackIcon />}
            onClick={() => onSetCurrentFolder("")}
          >
            Back
          </Button>
          <Box width={"100%"}>
            {prevFolder !== "/" && (
              <Typography
                sx={{
                  textAlign: "left !important",
                  fontSize: "12px",
                  marginLeft: 1,
                }}
              >
                <b
                  style={{
                    color: theme.palette.green.main,
                  }}
                >
                  Current Folder:
                </b>{" "}
                {prevFolder}{" "}
              </Typography>
            )}
          </Box>
        </Stack>
      )}
    </>
  );
}
