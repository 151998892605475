import authSlice from "./slice"

// SELECTORS
export const selectLoginStatus = (state) => state?.auth?.login?.status;
export const selectLoginError = (state) => state?.auth?.login?.error;

export const selectSignUpStatus = (state) => state?.auth?.signup?.status;
export const selectSignUpData = (state) => state?.auth?.signup?.data;
export const selectSignUpError = (state) => state?.auth?.signup?.error;

export const selectSignUpAccountOwnerStatus = (state) => state?.auth?.signupAccountOwner?.status;
export const selectSignUpAccountOwnerData = (state) => state?.auth?.signupAccountOwner?.data;
export const selectSignUpAccountOwnerError = (state) => state?.auth?.signupAccountOwner?.error;

export const selectResetPasswordStatus = (state) => state?.auth?.resetPassword?.status;
export const selectResetPasswordData = (state) => state?.auth?.resetPassword?.data;
export const selectResetPasswordError = (state) => state?.auth?.resetPassword?.error;

export const selectSignUpSubscriptionStatus = (state) => state?.auth?.signupSubscription?.status;
export const selectSignUpSubscriptionData = (state) => state?.auth?.signupSubscription?.data;
export const selectSignUpSubscriptionError = (state) => state?.auth?.signupSubscription?.error;

export const selectUpdateProfileStatus = (state) => state?.auth?.updateProfile?.status;
export const selectUpdateProfileData = (state) => state?.auth?.updateProfile?.data;
export const selectUpdateProfileError = (state) => state?.auth?.updateProfile?.error;

export const selectAuthData = (state) => state?.auth?.data;
export const selectAuthPreLoad = (state) => state?.auth?.authPreLoad;

export const selectUserAccountsData = (state) => state?.auth?.userAccounts?.data;
export const selectUserAccountsStatus = (state) => state?.auth?.userAccounts?.status;
export const selectUserAccountsError = (state) => state?.auth?.userAccounts?.error;

export const selectProfileData = (state) => state?.auth?.profile?.data;
export const selectSetNewPasswordStatus = (state) => state?.auth?.setNewPassword?.status;
export const selectSetNewPasswordData = (state) => state?.auth?.setNewPassword?.data;
export const selectSetNewPasswordError = (state) => state?.auth?.setNewPassword?.error;

export const selectAutoIngestionStatus = (state) => state?.auth?.autoIngestion?.status;
export const selectAutoIngestionData = (state) => state?.auth?.autoIngestion?.data;
export const selectAutoIngestionError = (state) => state?.auth?.autoIngestion?.error;

// ACTIONS
export const { 
    setAuth,
    resetResetPassword,
    resetUpdateProfile,
    setAuthPreLoad,
    setCurrentProfile,
    resetAutoIngestion
} = authSlice.actions;

// REDUCER
export default authSlice.reducer

