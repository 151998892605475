import { Box, Stack, Typography, useTheme } from "@mui/material";
import {
  AudienceSelect,
  ChannelSelect,
  InputText,
  LeftPaneComponent,
} from "../../../../../components";
import ModalConfirmGenerateDraft from "./ModalConfirmGenerateDraft";

export default function LeftPaneForm({ formikGenerateDraft }) {
  const theme = useTheme();
  const errorLength =
    formikGenerateDraft.touched.maxLength &&
    formikGenerateDraft.errors.maxLength
      ? formikGenerateDraft.errors.maxLength
      : formikGenerateDraft.touched.minLength &&
        formikGenerateDraft.errors.minLength
      ? formikGenerateDraft.errors.minLength
      : "";

  return (
    <LeftPaneComponent>
      <Box pt={"70px"}>
        <Stack spacing={1} justifyContent={"center"} alignItems={"normal"}>
          <Box display={"flex"}> </Box>
          <Typography textAlign={"left"} variant="h4">
            Draft Content Generator
          </Typography>
        </Stack>
        
        <Typography variant="subtitle2" sx={{ ml: 0.2, textAlign: "left" }}>
          Audience
        </Typography>
        <AudienceSelect
          name={"audience"}
          formik={formikGenerateDraft}
          onChange={formikGenerateDraft.handleChange}
          onBlur={formikGenerateDraft.handleBlur}
          value={formikGenerateDraft.values.audience}
          error={
            formikGenerateDraft.touched.audience &&
            Boolean(formikGenerateDraft.errors.audience)
          }
        ></AudienceSelect>
        <Typography variant="subtitle2" sx={{ ml: 0.2, textAlign: "left" }}>
          Channel
        </Typography>
        <ChannelSelect
          size={"small"}
          name={"channel"}
          formik={formikGenerateDraft}
          onChange={formikGenerateDraft.handleChange}
          onBlur={formikGenerateDraft.handleBlur}
          value={formikGenerateDraft.values.channel}
          error={
            formikGenerateDraft.touched.channel &&
            Boolean(formikGenerateDraft.errors.channel)
          }
        ></ChannelSelect>
        <Typography variant="subtitle2" sx={{ ml: 0.2, textAlign: "left" }}>
          Key Message
        </Typography>
        <InputText
          placeholder={"e.g. Announcing the rel"}
          name={"keyMessage"}
          formik={formikGenerateDraft}
          onChange={formikGenerateDraft.handleChange}
          onBlur={formikGenerateDraft.handleBlur}
          value={formikGenerateDraft.values.keyMessage}
          error={
            formikGenerateDraft.touched.keyMessage &&
            Boolean(formikGenerateDraft.errors.keyMessage)
          }
          multiline={true}
          rows={3}
          helperText={
            formikGenerateDraft.touched.keyMessage
              ? formikGenerateDraft.errors.keyMessage
              : ""
          }
        />
        <Typography variant="subtitle2" sx={{ ml: 0.2, textAlign: "left" }}>
          Call To Action
        </Typography>
        <InputText
          placeholder={"e.g. Use promo code XYZ-123"}
          name={"callToAction"}
          formik={formikGenerateDraft}
          onChange={formikGenerateDraft.handleChange}
          onBlur={formikGenerateDraft.handleBlur}
          value={formikGenerateDraft.values.callToAction}
          error={
            formikGenerateDraft.touched.callToAction &&
            Boolean(formikGenerateDraft.errors.callToAction)
          }
          helperText={
            formikGenerateDraft.touched.callToAction
              ? formikGenerateDraft.errors.callToAction
              : ""
          }
        />
        <Typography variant="subtitle2" sx={{ ml: 0.2, textAlign: "left" }}>
          Other Instructions
        </Typography>
        <InputText
          placeholder={"Keep tone light"}
          name={"otherInstructions"}
          formik={formikGenerateDraft}
          onChange={formikGenerateDraft.handleChange}
          onBlur={formikGenerateDraft.handleBlur}
          value={formikGenerateDraft.values.otherInstructions}
          error={
            formikGenerateDraft.touched.otherInstructions &&
            Boolean(formikGenerateDraft.errors.otherInstructions)
          }
          multiline={true}
          rows={3}
          helperText={
            formikGenerateDraft.touched.otherInstructions
              ? formikGenerateDraft.errors.otherInstructions
              : ""
          }
        />
        <Box color="dark">
          <Typography variant="subtitle2" sx={{ ml: 0.2, textAlign: "left" }}>
            Length (Words)
          </Typography>

          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box>
              <InputText
                placeholder={"Min"}
                type={"number"}
                name={"minLength"}
                formik={formikGenerateDraft}
                onChange={formikGenerateDraft.handleChange}
                onBlur={formikGenerateDraft.handleBlur}
                value={formikGenerateDraft.values.minLength}
                error={
                  formikGenerateDraft.touched.minLength &&
                  Boolean(formikGenerateDraft.errors.minLength)
                }
              />
            </Box>
            <Box
              sx={{
                width: "83px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                height: "30px",
              }}
            >
              To
            </Box>
            <Box>
              <InputText
                placeholder={"Max"}
                type={"number"}
                name={"maxLength"}
                formik={formikGenerateDraft}
                onChange={formikGenerateDraft.handleChange}
                onBlur={formikGenerateDraft.handleBlur}
                value={formikGenerateDraft.values.maxLength}
                error={
                  formikGenerateDraft.touched.maxLength &&
                  Boolean(formikGenerateDraft.errors.maxLength)
                }
              />
            </Box>
          </Stack>
          <Typography
            color={theme.palette.error.main}
            variant="caption"
            fontWeight={200}
          >
            <small>{errorLength}</small>
          </Typography>
        </Box>
      </Box>
      <ModalConfirmGenerateDraft
        formikGenerateDraft={formikGenerateDraft}
      ></ModalConfirmGenerateDraft>
    </LeftPaneComponent>
  );
}
