import { Box, Typography } from "@mui/material";
import useGetAnalyzedContent from "../../../../../../hooks/useGetAnalyzedContent";
import { useState } from "react";
import VersionActionButtons from "./VersionActionButtons";

export default function History() {
  const [openControlsVersion, setOpenControlsVersion] = useState(null);
  const { analyzedContent } = useGetAnalyzedContent();

  const getFormattedDate = (timestamp) => {
    const date = new Date(timestamp);
    const dateString = date.toLocaleDateString();
    const timeString = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${dateString} ${timeString}`;
  };

  return (
    <Box pt={1}>
      <Typography variant="subtitle1" textAlign={"left"} fontWeight={"bold"}>
        Accepted edits:
      </Typography>
      <Box
        sx={{
          height: "160px",
          overflowY: "auto",
          borderTop: "1px solid gray",
        }}
      >
        {analyzedContent.content_history.map((params, idx) => (
          <Box
            key={idx}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              background: "#00000000",
              borderRadius: "0px",
              transition: "0.3s",
              cursor: "pointer",
              padding: "0px 5px 0px 5px",
              margin: "1px",
              "&:hover": {
                background: "#0000008a",
                borderRadius: "10px",
                transition: "0.2s",
                color: "white",
              },
            }}
          >
            <Typography variant="caption" textAlign={"left"}>
              {getFormattedDate(params.created_at)}
            </Typography>
            <VersionActionButtons
              params={params}
              setOpenControlsVersion={(val) => setOpenControlsVersion(val)}
              openControlsVersion={openControlsVersion}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
