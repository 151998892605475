export const getAvatarsPending = (state, action) => {
  state.avatars.status = "pending";
};

export const getAvatarsFulfilled = (state, action) => {
  state.avatars.status = "fulfilled";
  state.avatars.data = action.payload.result;
};

export const getAvatarsRejected = (state, action) => {
  state.avatars.status = "rejected";
};
