import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useLoginHandler } from "../../../../hooks/useLoginHandler.js";
import { InputText, LogoLoader, Modal } from "../../../../components/index.js";

import LoginIcon from "@mui/icons-material/Login";

export default function Login({ children }) {
  const [open, setOpen] = useState(false);

  const { onLogin, isError, error, isLoading, isSuccess } = useLoginHandler();
  useEffect(() => {
    if (isError) {
      setOpen(isError);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      window.location.reload()
    }
  }, [isSuccess]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email: "", password: "" },
    validationSchema: Yup.object({
      email: Yup.string().required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      onLogin(values);
    },
  });

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      formik.submitForm();
    }
  };

  return (
    <Box>
      <Box>
        <LogoLoader
          open={isLoading}
          needBackDrop={true}
          text={"Loading"}
        ></LogoLoader>
        <Modal
          maxWidth="xs"
          open={open}
          title={"Login"}
          handleClose={() => setOpen(false)}
          body={
            <>
              <Typography variant="subtitle1">Authentication Failed</Typography>
              <Typography variant="subtitle2">Reason: </Typography>
              <Typography variant="caption" sx={{ color: "red !important" }}>
                {error?.error}{" "}
              </Typography>
            </>
          }
        ></Modal>
        <form onKeyDown={handleKeyDown}>
          <Stack spacing={2}>
            <InputText
              placeholder="email"
              name={"email"}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
            <InputText
              placeholder="password"
              name={"password"}
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
            />
          </Stack>
        </form>
      </Box>
      {children}
      <Button
        fullWidth
        startIcon={<LoginIcon />}
        variant="contained"
        color="success"
        onClick={() => formik.submitForm()}
      >
        Login
      </Button>
    </Box>
  );
}
