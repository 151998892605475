const AnalyzeIcon=({color="white"})=><svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="12" width="4" height="5" fill={color}/>
<rect x="5" y="13" width="4" height="4" fill={color}/>
<rect x="10" y="9" width="4" height="8" fill={color}/>
<rect x="15" y="3" width="4" height="14" fill={color}/>
<path d="M8.87793 0V0.849077" stroke={color} strokeLinejoin="round"/>
<path d="M8.87793 1.69824V2.54732" stroke={color} strokeLinejoin="round"/>
<path d="M8.46403 1.27344H7.63477" stroke={color} strokeLinejoin="round"/>
<path d="M10.1222 1.27344H9.29297" stroke={color} strokeLinejoin="round"/>
<path d="M3.07324 2.54688V3.82049" stroke={color} strokeLinejoin="round"/>
<path d="M3.07324 5.51953V6.79315" stroke={color} strokeLinejoin="round"/>
<path d="M2.2439 4.66992H1" stroke={color} strokeLinejoin="round"/>
<path d="M5.14625 4.66992H3.90234" stroke={color} strokeLinejoin="round"/>
</svg>

export default AnalyzeIcon