import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { AudienceIcon } from "../../../../../icons";
import usePersonaListHandler from "../../../../../hooks/usePersonaListHandler";
import { useCallback, useEffect, useState } from "react";
import { usePersonaBuilderModeHandler } from "../../../../../hooks/usePersonaBuilderModeHandler";
import { useCurrentPersonaViewer } from "../../../../../hooks/useCurrentPersonaViewer";
import useCurrentOrganizationHandler from "../../../../../hooks/useCurrentOrganizationHandler";
import { useNavigate, useParams } from "react-router-dom";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import {
  BootstrapTooltip,
  DragAndDrop,
  LogoLoader,
  Modal,
  PersonaCard,
} from "../../../../../components";
import { DndContext } from "@dnd-kit/core";
import InputText from "../../../../../components/InputText";
import useUpsertAudienceHandler from "../../../../../hooks/useUpsertAudienceHandler";
import { useFormik } from "formik";
import * as Yup from "yup";
import useCurrentAudienceHandler from "../../../../../hooks/useCurrentAudienceHandler";
import useUpdateAudienceHandler from "../../../../../hooks/useUpdateAudienceHandler";
import useDisableAudienceHandler from "../../../../../hooks/useDisableAudienceHandler";
import useGetOrgUserProfile from "../../../../../hooks/useGetOrgUserProfileHanlder";
import TuneIcon from "@mui/icons-material/Tune";
import NoPersonasBtn from "./NoPersonasBtn";

export default function ModalUpsertAudience({ smallBtn, params }) {
  const navigate = useNavigate();
  const { audience_id } = useParams();
  const [openAudienceCreator, setOpenAudienceCreator] = useState(false);
  const { canManageAudience } = useGetOrgUserProfile();
  const { onSetMode } = usePersonaBuilderModeHandler();
  const { onResetPersona } = useCurrentPersonaViewer();
  const { personas, isSuccess, isLoading } = usePersonaListHandler();
  const { currentOrganization } = useCurrentOrganizationHandler();
  const { isSuccess: isDisabled } = useDisableAudienceHandler();
  const { currentAudience, selectAudience, onResetCurrentAudience } =
    useCurrentAudienceHandler();
  const { onCreateAudience, isSuccess: audienceCreated, isLoading: isCreating } =
    useUpsertAudienceHandler();
  const {
    onUpdateAudience,
    onResetUpdateAudience,
    isSuccess: isUpdated,
    isLoading: isUpdating,
  } = useUpdateAudienceHandler();

  useEffect(() => {
    if (
      audience_id &&
      audience_id === params?.row?.id.toString() &&
      currentAudience === null
    ) {
      selectAudience(params?.row);
      setOpenAudienceCreator(true);
    }
  }, [audience_id, params, selectAudience, currentAudience]);

  const formik = useFormik({
    initialValues: {
      audienceName: "",
      audienceDescription: "",
      personas: [],
    },
    validationSchema: Yup.object().shape({
      audienceName: Yup.string()
        .max(200, "Must be 200 characters or less")
        .required("Required"),
      personas: Yup.array().required("Required").max(8),
    }),
    onSubmit: async (values) => {
      const payload = {
        ...values,
        personas: values.personas.map((persona) => persona.id),
      };
      currentAudience?.id
        ? onUpdateAudience(payload)
        : onCreateAudience(payload);
    },
  });

  useEffect(() => {
    if (audienceCreated || isUpdated || isDisabled) {
      formik.resetForm();
      setOpenAudienceCreator(false);
      if (audience_id) {
        navigate("..");
      }
    }
  }, [audienceCreated, isUpdated, audience_id, isDisabled, navigate, formik]);

  const addItemsToBox = (e) => {
    const newItem = e.active.data.current?.title;
    const id = e.active.data.current?.id;
    const temp = [...formik.values.personas];
    if (e.over?.id !== "cart-droppable" || !newItem) {
      formik.setFieldValue(
        "personas",
        temp.filter((item) => item.id !== id)
      );
      return;
    }
    if (temp.length < 8 && !temp.find((item) => item.id === id)) {
      formik.setFieldValue("personas", [...temp, e.active.data.current]);
    }
  };

  const generateCard = useCallback(
    (persona, idx) =>
      canManageAudience ? (
        <DragAndDrop.Draggable key={idx} persona={persona}>
          <PersonaCard persona={persona}></PersonaCard>
        </DragAndDrop.Draggable>
      ) : (
        <PersonaCard persona={persona}></PersonaCard>
      ),
    [canManageAudience]
  );

  const personaCards = (
    <Stack direction={"row"} flexWrap={"wrap"} alignItems={"flex-start"}>
      {personas.map(
        (persona, idx) =>
          !formik.values.personas.find((item) => item.id === persona.id) &&
          generateCard(persona, idx)
      )}
    </Stack>
  );

  const personaWarning = isSuccess && <NoPersonasBtn />;

  useEffect(() => {
    if (
      openAudienceCreator &&
      currentAudience &&
      formik.values.audienceName === ""
    ) {
      formik.setValues({
        audienceName: currentAudience.name,
        audienceDescription: currentAudience.description,
        personas: currentAudience.personas.map((persona, idx) => ({
          id: persona.id,
          title: generateCard(persona, idx),
        })),
      });
    }
  }, [currentAudience, openAudienceCreator, formik, generateCard]);

  return (
    <>
    <LogoLoader open={isCreating ||
isUpdating} text={isCreating ?"Creating Audience" : "Updating Audience" }></LogoLoader>
      {smallBtn ? (
        <BootstrapTooltip title={"View Audience"}>
          <IconButton
            disabled={!canManageAudience || params?.row?.disable}
            onClick={() => {
              selectAudience(params?.row);
              setOpenAudienceCreator(true);
            }}
            size="small"
            color="dark"
          >
            <TuneIcon fontSize="small" />
          </IconButton>
        </BootstrapTooltip>
      ) : (
        <Button
          variant="contained"
          disabled={!canManageAudience}
          onClick={() => {
            onResetUpdateAudience();
            onResetCurrentAudience();
            formik.resetForm();
            setOpenAudienceCreator(true);
          }}
          startIcon={<AudienceIcon small={true} />}
        >
          Create new audience
        </Button>
      )}

      <Modal
        maxWidth="lg"
        actions={
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (
                  !canManageAudience ||
                  personas.length === 0 ||
                  formik.values.personas.length === 0
                ) {
                  return;
                }
                formik.handleSubmit();
              }}
            >
              {currentAudience?.id ? "Update" : "Save"} Audience
            </Button>
            <Button
              color="red"
              variant="contained"
              onClick={() => {
                onResetUpdateAudience();
                onResetCurrentAudience();
                formik.resetForm();
                setOpenAudienceCreator(false);
                if (audience_id) {
                  navigate("..");
                }
              }}
            >
              Close
            </Button>
          </>
        }
        handleClose={() => {
          onResetUpdateAudience();
          onResetCurrentAudience();
          formik.resetForm();
          setOpenAudienceCreator(false);
          if (audience_id) {
            navigate("..");
          }
        }}
        open={openAudienceCreator}
        body={
          <DndContext onDragEnd={addItemsToBox}>
            <Box height={500}>
              <Stack justifyContent={"left"} flexDirection={"column"}>
                <Typography textAlign={"left"} variant="h5">
                  Available Personas
                </Typography>
                <Button
                  variant="text"
                  size="large"
                  color="success"
                  startIcon={<SensorOccupiedIcon />}
                  onClick={() => {
                    onSetMode("view");
                    onResetPersona();
                    navigate(`/organization/${currentOrganization.id}/persona`);
                  }}
                >
                  View & Create Personas
                </Button>
              </Stack>
              <Grid xs={12} item container>
                {isLoading && <LinearProgress />}
                <Grid item xs={6}>
                  {isSuccess && personas.length ? personaCards : personaWarning}
                </Grid>
                <Grid item xs={6}>
                  <Box py={1}>
                    <InputText
                      disabled={personas.length === 0}
                      placeholder="Audience Name"
                      name="audienceName"
                      value={formik.values.audienceName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.audienceName &&
                        Boolean(formik.errors.audienceName)
                      }
                    />
                  </Box>
                  <Box>
                    <InputText
                      disabled={personas.length === 0}
                      placeholder="Audience Description (Optional)"
                      name="audienceDescription"
                      value={formik.values.audienceDescription}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.audienceDescription &&
                        Boolean(formik.errors.audienceDescription)
                      }
                      multiline
                      rows={5}
                    />
                  </Box>
                  <Box>
                    <DragAndDrop.Droppable
                      items={formik.values.personas}
                      error={
                        formik.touched.personas &&
                        Boolean(formik.errors.personas)
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DndContext>
        }
      />
    </>
  );
}
