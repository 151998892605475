export const postAdaptContentChannelPending = (state, action) => {
  state.adaptContentChannel.status = "pending";
};

export const postAdaptContentChannelFulfilled = (state, action) => {
  state.adaptContentChannel.status = "fulfilled";
  state.adaptContentChannel.data = action.payload;
};

export const postAdaptContentChannelRejected = (state, action) => {
  state.adaptContentChannel.status = "rejected";
  state.adaptContentChannel.data = action.payload.response.data;
};
