export const postSignupPending = (state, action) => {
  state.signup.status = "pending";
  state.signup.error = null;
};

export const postSignupFulfilled = (state, action) => {
  state.signup.status = "fulfilled";
  state.data = action.payload;
};

export const postSignupRejected = (state, action) => {
  state.signup.status = "rejected";
  state.signup.error = action.payload.response.data;
};
