import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, Slate, ReactEditor } from "slate-react";
import { Transforms, createEditor, Range } from "slate";
import { withHistory } from "slate-history";
import {
  FormatBold as BoldIcon,
  FormatItalic as ItalicIcon,
  FormatUnderlined as UnderlineIcon,
  Code as CodeIcon,
  LooksOne as HeadingOneIcon,
  LooksTwo as HeadingTwoIcon,
  Looks3 as HeadingThreeIcon,
  FormatQuote as BlockQuoteIcon,
  FormatListNumbered as NumberedListIcon,
  FormatListBulleted as BulletedListIcon,
  FormatAlignLeft as AlignLeftIcon,
  FormatAlignCenter as AlignCenterIcon,
  FormatAlignRight as AlignRightIcon,
  FormatAlignJustify as AlignJustifyIcon,
} from "@mui/icons-material";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import DrawIcon from "@mui/icons-material/Draw";
import { Toolbar } from "./editor_components";
import {
  Box,
  Chip,
  darken,
  FormHelperText,
  Grid,
  Paper,
  useTheme,
} from "@mui/material";
import Leaf from "./Leaf";
import { toggleMark, Element, BlockButton, MarkButton } from "./addons";
import acceptRejectButtons from "./acceptRejectButtons";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+": "code",
};

const EditorComponent = ({
  initialValue,
  onHandleChange,
  isSuggestionMode,
  height,
  showToolbar,
  error,
  readOnly = false,
  isDark = false,
}) => {
  const theme = useTheme();
  const editorContainer = useRef(null);
  const buttonsContainer = useRef(null);
  const [value, setValue] = useState(initialValue);
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const acceptChange = useCallback(
    (acceptedPath, replacedPath) => {
      Transforms.setNodes(editor, { status: "rejected" }, { at: replacedPath });
      Transforms.setNodes(
        editor,
        { isChange: false, status: "accepted" },
        { at: acceptedPath }
      );
    },
    [editor]
  );

  const rejectChange = useCallback(
    (acceptPath, rejectPath) => {
      Transforms.setNodes(editor, { status: "rejected" }, { at: rejectPath });
      Transforms.setNodes(
        editor,
        { isChange: false, status: "accepted" },
        { at: acceptPath }
      );
    },
    [editor]
  );

  const renderLeaf = useCallback(
    (props) => (
      <Leaf
        {...props}
        acceptChange={acceptChange}
        rejectChange={rejectChange}
        value={value}
      />
    ),
    [acceptChange, rejectChange, value]
  );
  useEffect(() => {
    if (editor.selection && !Range.isCollapsed(editor.selection)) {
      ReactEditor.focus(editor);
    }
  }, [value, editor]);

  const createRejectAcceptDiv = useCallback(
    (e) => {
      if (!buttonsContainer.current) return;
      acceptRejectButtons(
        e,
        theme,
        showToolbar,
        darken,
        buttonsContainer,
        value,
        rejectChange,
        acceptChange
      );
    },
    [acceptChange, rejectChange, showToolbar, theme, value]
  );

  const addBtnsRecursively = useCallback(
    (element) => {
      if (!element) return;

      if (element?.className === "to_replace") {
        createRejectAcceptDiv(element);
        return;
      }

      Array.from(element.children).forEach((child) =>
        addBtnsRecursively(child)
      );
    },
    [createRejectAcceptDiv]
  );

  useEffect(() => {
    if (editorContainer.current && buttonsContainer.current) {
      addBtnsRecursively(editorContainer.current);
    }
  }, [editorContainer, addBtnsRecursively]);

  useEffect(() => {
    buttonsContainer.current.innerHTML = "";
    addBtnsRecursively(editorContainer.current);
  }, [value, addBtnsRecursively]);

  return (
    <>
      <Paper
        elevation={4}
        sx={{
          background: isDark ? theme.palette.dark.main : "white",
          border: error ? `1px solid ${theme.palette.error.main}` : "none",
          borderRadius: "10px",
          p: 1,
        }}
      >
        <Slate
          editor={editor}
          initialValue={value}
          onChange={(newValue) => {
            if (newValue !== value) {
              setValue(newValue);
              onHandleChange(newValue);
            }
          }}
        >
          {showToolbar && (
            <Toolbar
              sx={{
                position: "relative",
                display: "flex",
                borderRadius: "10px 10px 10px 0px !important",
              }}
            >
              <MarkButton format="bold" icon={<BoldIcon />} />
              <MarkButton format="italic" icon={<ItalicIcon />} />
              <MarkButton format="underline" icon={<UnderlineIcon />} />
              <MarkButton format="code" icon={<CodeIcon />} />
              <BlockButton format="heading-one" icon={<HeadingOneIcon />} />
              <BlockButton format="heading-two" icon={<HeadingTwoIcon />} />
              <BlockButton format="heading-three" icon={<HeadingThreeIcon />} />
              <BlockButton format="block-quote" icon={<BlockQuoteIcon />} />
              <BlockButton format="numbered-list" icon={<NumberedListIcon />} />
              <BlockButton format="bulleted-list" icon={<BulletedListIcon />} />
              <BlockButton format="left" icon={<AlignLeftIcon />} />
              <BlockButton format="center" icon={<AlignCenterIcon />} />
              <BlockButton format="right" icon={<AlignRightIcon />} />
              <BlockButton format="justify" icon={<AlignJustifyIcon />} />
              {isSuggestionMode ? (
                <Chip
                  sx={{
                    position: "absolute",
                    top: "42px",
                    left: "0px",
                    zIndex: 1,
                    boxShadow: "-3px 3px 3px -3px rgba(0,0,0,0.2)",
                    background: "white",
                    border: "1px solid #d2d2d2",
                    borderTop: 0,
                    borderLeft: 0,
                    borderRadius: "0px 0px 10px 10px",
                  }}
                  size="small"
                  variant="outlined"
                  color="primary"
                  label="AI Suggestions editor mode"
                  onDelete={() => {}}
                  deleteIcon={<MapsUgcIcon />}
                />
              ) : (
                <Chip
                  sx={{
                    position: "absolute",
                    top: "42px",
                    left: "0px",
                    zIndex: 1,
                    boxShadow: "-3px 3px 3px -3px rgba(0,0,0,0.2)",
                    background: "white",
                    border: "1px solid #d2d2d2",
                    borderTop: 0,
                    borderLeft: 0,
                    borderRadius: "0px 0px 10px 10px",
                  }}
                  size="small"
                  variant="outlined"
                  color="success"
                  label="Free style editor mode"
                  onDelete={() => {}}
                  deleteIcon={<DrawIcon />}
                />
              )}
            </Toolbar>
          )}
          <Box
            sx={{
              position: "relative",
              height: height ? height : "300px",
              overflow: "auto",
              mt: "1px",
            }}
          >
            <Grid container>
              <Grid item xs={10}>
                <Box>
                  <Box>
                    <Paper
                      elevation={0}
                      sx={{
                        padding: 2,
                        position: "relative",
                        background: isDark ? theme.palette.dark.main : "white",
                        color: isDark ? "white !important" : "black",
                      }}
                    >
                      {isSuggestionMode && (
                        <Paper
                          elevation={0}
                          sx={{
                            position: "absolute",
                            height: height ? height : "300px",
                            overflow: "auto",
                            padding: 2,
                            width: "90%",
                            background: "#fff0",
                            zIndex: "1",
                          }}
                        ></Paper>
                      )}
                      <Editable
                        ref={editorContainer}
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        placeholder="Enter some rich text…"
                        spellCheck
                        readOnly={readOnly}
                        style={{
                          fontSize: "14px",
                          fontWeight: "300",
                        }}
                        onKeyDown={(event) => {
                          for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event)) {
                              event.preventDefault();
                              const mark = HOTKEYS[hotkey];
                              toggleMark(editor, mark);
                            }
                          }
                        }}
                      />
                    </Paper>
                  </Box>
                </Box>
              </Grid>
              <Grid item ref={buttonsContainer} xs={2}></Grid>
            </Grid>
          </Box>
        </Slate>
      </Paper>
      {error && <FormHelperText error={error}>Required</FormHelperText>}
    </>
  );
};

export default EditorComponent;
