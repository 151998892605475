import {
  resetPersonasList,
  selectGetPersonasStatus,
  selectPersonasListData,
} from "../redux/persona";
import { useDispatch, useSelector } from "react-redux";
import { organizationAPIs } from "../api_";
import { selectCurrentOrganization } from "../redux/organizations";

export default function usePersonaListHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectGetPersonasStatus(state));
  const data = useSelector((state) => selectPersonasListData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const getPersonasActive = ()=>{
    dispatch(
      organizationAPIs.getPersonas({ id: currentOrg?.id, filter:'active' })
    );
  }
  const getPersonasAll = ()=>{
    dispatch(
      organizationAPIs.getPersonas({ id: currentOrg?.id, filter:'all' })
    );
  }

  const onResetPersonasList = ()=>dispatch(resetPersonasList())

  return {
    personas: data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    getPersonasAll,
    getPersonasActive,
    onResetPersonasList
  };
}
