export const postResetPasswordPending = (state, action) => {
  state.resetPassword.status = "pending";
};

export const postResetPasswordFulfilled = (state, action) => {
  state.resetPassword.status = "fulfilled";
  state.resetPassword.data = action.payload;
};

export const postResetPasswordRejected = (state, action) => {
  state.resetPassword.status = "rejected";
  state.resetPassword.error = action.payload.response.data;
};
