import { Box, Button, Typography, Tabs, useTheme, Tab } from "@mui/material";
import PropTypes from "prop-types";

import { useState, useEffect } from "react";
import useInviteExistentUser from "./useInviteExistentUser";
import useInviteUserHandler from "../../../../hooks/useInviteUserHandler";
import { Modal } from "../../../../components";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useUsersByOrganizationHandler from "../../../../hooks/useUsersByOrganizationHandler";
import useAdminAllUsersHandler from "../../../../hooks/useAdminAllUsersHandler";
import useAddNewUser from "./useAddNewUser";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function InviteUser() {
  const [show, setShow] = useState(0);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { isSuccess, onResetInviteUser, isError, invite } =
    useInviteUserHandler();
  const { onGetUsers } = useUsersByOrganizationHandler();
  const { onResetGetAdminAllUsers } = useAdminAllUsersHandler();
  const addUser = useAddNewUser();
  const existingUser = useInviteExistentUser();

  useEffect(() => {
    if (isSuccess) {
      onResetInviteUser();
      onGetUsers();
    }
  }, [isSuccess, onResetInviteUser, onGetUsers]);

  const handleChange = (event, newValue) => {
    setShow(newValue);
    onResetInviteUser();
    addUser.reset();
    existingUser.reset();
    onResetGetAdminAllUsers();
  };

  return (
    <>
      <Button
        startIcon={<AddCircleOutlineIcon></AddCircleOutlineIcon>}
        size="small"
        variant="text"
        color={"green"}
        onClick={() => setOpen(true)}
      >
        Add member
      </Button>
      <Modal
        open={open}
        actions={
          <>
            {show === 0 && addUser.actions}
            {show === 1 && existingUser.actions}
            <Button
              color="dark"
              variant="contained"
              onClick={() => {
                setOpen(false);
                setShow(0);
                addUser.reset();
                existingUser.reset();
              }}
            >
              cancel
            </Button>
          </>
        }
        body={
          <>
            <Box>
              <Tabs
                textColor="inherit"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: theme.palette.red.main,
                  },
                }}
                value={show}
                onChange={handleChange}
              >
                <Tab label=" Add New User" {...a11yProps(0)} />
                <Tab label="Invite Existing User" {...a11yProps(1)} />
              </Tabs>
              <CustomTabPanel value={show} index={0}>
                {addUser.body}
                <Typography color={"error"}>
                  {isError && invite.error}
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={show} index={1}>
                {existingUser.body}
                <Typography color={"error"}>
                  {isError && invite.error}
                </Typography>
              </CustomTabPanel>
            </Box>
          </>
        }
        handleClose={() => { 
          existingUser.reset();
          setOpen(false)}}
      />
    </>
  );
}
