import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { organizationAPIs } from "../api_";
import { selectCurrentOrganization } from "../redux/organizations";
import {
  resetListAudience,
  selectAudienceData,
  selectAudienceStatus,
} from "../redux/audience";

export default function useAudienceByOrganizationHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectAudienceStatus(state));
  const data = useSelector((state) => selectAudienceData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  const isUninitialized = status === "idle";
  const isLoading = status === "pending" || status === "idle";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  useEffect(() => {
    if (!currentOrg) {
      window.location.href = "/home";
    }
  }, [currentOrg]);
  const onGetAudience = () => {
    if (currentOrg) {
      dispatch(
        organizationAPIs.getAudience({ orgId: currentOrg.id, filter: "all" })
      );
    }
  };
  const onGetActiveAudience = () => {
    if (currentOrg) {
      dispatch(
        organizationAPIs.getAudience({ orgId: currentOrg.id, filter: "active" })
      );
    }
  };

  const onResetAudienceList = () => {
    dispatch(resetListAudience());
  };

  return {
    data,
    dataMapped: data ? data.map((i) => ({ id: i.id, name: i.name })) : [],
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onGetAudience,
    onGetActiveAudience,
    onResetAudienceList,
  };
}
