import { useState } from "react";
import { Grid, Typography, Stack } from "@mui/material";
import ListOrganizations from "./components/listOrganizations";
import InviteUser from "./components/InviteUser";
import { HomeMenuComponent, Modal } from "../../../components";
import OrganizationUsers from "./components/OrganizationUsers";

export function Organizations() {
  const [openInviteUsers, setOpenInviteUsers] = useState(false);

  return (
    <>
      <HomeMenuComponent>
        <Grid container>
          <Grid item xs={6}>
            <Stack ml={5} width={"100%"} alignItems={"baseline"}>
              <Typography variant="h3" fontWeight={"bold"}>
                Organizations
              </Typography>
              <ListOrganizations />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <OrganizationUsers></OrganizationUsers>
          </Grid>
        </Grid>
        <Modal
          handleClose={() => setOpenInviteUsers(false)}
          open={openInviteUsers}
          body={<InviteUser />}
        />
      </HomeMenuComponent>
    </>
  );
}
