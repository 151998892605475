export const postAutoIngestionPending = (state, action) => {
  state.autoIngestion.status = "pending";
};

export const postAutoIngestionFulfilled = (state, action) => {
  state.autoIngestion.status = "fulfilled";
  state.data = action.payload;
};

export const postAutoIngestionRejected = (state, action) => {
  state.autoIngestion.status = "rejected";
  state.autoIngestion.error = action.payload.response.data;
};
