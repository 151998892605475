import * as React from "react";
import { Box, Select, CircularProgress, Chip, MenuItem } from "@mui/material";
import InputText from "../InputText";
import useAudienceByOrganizationHandler from "../../hooks/useAudienceByOrganizationHandler";

export default function AudienceSelect({ formik, name, value, error }) {
  const { onGetActiveAudience, data, isLoading, isUninitialized, isSuccess } =
    useAudienceByOrganizationHandler();
  React.useEffect(() => {
    if (isUninitialized) {
      onGetActiveAudience();
    }
  }, [isUninitialized, onGetActiveAudience]);
  return (
    <Select
      //multiple
      displayEmpty
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 48 * 4.5 + 8,
            //width: 250,
          },
        },
      }}
      startAdornment={isLoading && <CircularProgress size={"20px"} />}
      input={<InputText placeholder="Choose audience" />}
      name={name}
      value={
        isSuccess && value !== "" ? data.find((i) => i.id === value?.id) : ""
      }
      error={formik.touched?.audience && Boolean(formik.errors?.audience)}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      renderValue={(selected) => {
        if (!selected) return <em>{"Choose audience"}</em>;
        return selected ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            <Chip
              sx={{ height: "20px !important" }}
              key={value?.id}
              label={`${value?.name}`}
            />
          </Box>
        ) : (
          <em>{"Choose audience"}</em>
        );
      }}
      inputProps={{
        sx:{
          padding:0
        }
       }}
    >
      <MenuItem disabled value="">
        <em>Choose audience</em>
      </MenuItem>
      {isSuccess &&
        data.map((persona) => (
          <MenuItem key={persona.id} value={persona}>
            {persona.name}
          </MenuItem>
        ))}
    </Select>
  );
}
