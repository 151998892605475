import { IconButton, Switch, Typography, Button, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { usePersonaBuilderModeHandler } from "../../../../../hooks/usePersonaBuilderModeHandler";
import usePersonaListHandler from "../../../../../hooks/usePersonaListHandler";
import useDisablePersonaHandler from "../../../../../hooks/useDisablePersonaHandler";
import {
  BootstrapTooltip,
  LogoLoader,
  ModalConfirmationPersonaBuilder,
  PersonaExportPDFButton,
  Table,
} from "../../../../../components";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TuneIcon from "@mui/icons-material/Tune";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import useCurrentOrganizationHandler from "../../../../../hooks/useCurrentOrganizationHandler";
import { useNavigate } from "react-router-dom";
import useGetPersonaExportHandler from "../../../../../hooks/useGetPersonaExportHandler";

export function PersonaList() {
  const {
    personas,
    isSuccess: isSuccessPersonaList,
    isLoading,
    isUninitialized,
    getPersonasAll
  } = usePersonaListHandler();
  usePersonaBuilderModeHandler();
  const { onSubmitForm, onResetDisablePersona, isSuccess } =
    useDisablePersonaHandler();
  const { currentOrganization } = useCurrentOrganizationHandler();
  const navigate = useNavigate();

  const { onSetMode, onResetBuilder } = usePersonaBuilderModeHandler();
  const onDisable = (persona) => {
    onSubmitForm(!persona.disabled, persona.id);
  };
  useEffect(()=>{
    if(isUninitialized){
      getPersonasAll()
    }
  },[isUninitialized,
    getPersonasAll])
  useEffect(() => {
    if (isSuccess) {
      onResetDisablePersona();
      onResetBuilder();
    }
  }, [isSuccess, onResetDisablePersona, onResetBuilder]);

  useEffect(() => {
    if (isSuccessPersonaList && personas.length === 0) {
      onSetMode("builder_option", null);
    }
  }, [isSuccessPersonaList, personas.length, onSetMode]);

  const { queue, removeFromQueue } = useGetPersonaExportHandler();
  const [processedQueue, setProcessedQueue] = useState([]);

  useEffect(() => {
    let fulfilled = 0;
    queue.forEach((q) => {
      if (q.status === "fulfilled" && !processedQueue.includes(q.personaId)) {
        fulfilled += 1;
        const blob = new Blob([q.data.file.file], { type: "application/pdf" });
        const link = document.createElement("a");
        link.download = q.data.file.filename;
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setProcessedQueue((prev) => [...prev, q.personaId]);
      }
    });
    if (fulfilled > 0) {
      removeFromQueue(0);
      setProcessedQueue([]);
      //if (queue.length > 0 && processedQueue.length === queue.length) {
      // }
    }
  }, [queue, processedQueue, removeFromQueue]);

  return (
    <>
      <LogoLoader
        needBackDrop={false}
        open={isLoading}
        text={"Loading Personas"}
      ></LogoLoader>
      {isSuccessPersonaList && (
        <>
          <Typography textAlign={"left"} variant="h5">
            Personas List
          </Typography>
          <Alert
            severity="success"
            variant="standard"
            action={
              <Button
                color="success"
                size="small"
                variant="contained"
                onClick={() =>
                  navigate(`/organization/${currentOrganization.id}/audience`)
                }
              >
                Go To Audiences
              </Button>
            }
          >
            Great job creating Personas. If you’re done, now mix and match them
            into target audiences.
          </Alert>
          <Table
            rows={personas}
            columns={[
              {
                field: "name",
                headerName: "Name",
                renderCell: (params) => {
                  return (
                    <>
                      <Switch
                        onClick={() => onDisable(params.row)}
                        checked={!params.row.disabled}
                      />
                      {params.value}
                    </>
                  );
                },
                width: 200,
              },
              {
                field: "jobTitle",
                headerName: "Title",
                width: 300,
                renderCell: (params) => {
                  return (
                    params.row.description_metadata?.jobTitle || "No Title"
                  );
                },
              },
              {
                field: "created_at",
                headerName: "Date",
                width: 200,
                renderCell: (params) => {
                  const date = new Date(params.value);
                  const dateString = date.toLocaleDateString();
                  const timeString = date.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  });

                  const formattedDate = `${dateString} ${timeString}`;
                  return <>{formattedDate}</>;
                },
              },
              {
                field: "last_modified_at",
                headerName: "Last Modified At",
                width: 200,
                renderCell: (params) => {
                  if (params.value) {
                    const date = new Date(params.value);
                    const dateString = date.toLocaleDateString();
                    const timeString = date.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    });

                    const formattedDate = `${dateString} ${timeString}`;
                    return <>{formattedDate}</>;
                  }
                  return "";
                },
              },

              {
                field: "id",
                headerName: "Actions",
                disableColumnMenu: true,
                disableColumnSorting: true,
                sortable: false,
                width: 200,
                renderCell: (params) => {
                  const persona = personas.find(
                    (persona) => persona.id === params.id
                  );
                  return (
                    <>
                      <BootstrapTooltip title="View Persona">
                        <IconButton
                          color="dark"
                          size="small"
                          onClick={() => {
                            navigate(`${persona.id}`);
                          }}
                        >
                          <TuneIcon fontSize="small" />
                        </IconButton>
                      </BootstrapTooltip>
                      <ModalConfirmationPersonaBuilder
                        type={"editSmllBtn"}
                        persona={persona}
                      />
                      <BootstrapTooltip
                        title={
                          persona.disabled
                            ? "Enable Persona"
                            : "Disable Persona"
                        }
                      >
                        <IconButton
                          color={"error"}
                          onClick={() => onDisable(persona)}
                          size="small"
                        >
                          {persona.disabled ? (
                            <RestartAltIcon fontSize="small" />
                          ) : (
                            <HighlightOffOutlinedIcon fontSize="small" />
                          )}
                        </IconButton>
                      </BootstrapTooltip>
                      <PersonaExportPDFButton persona={persona} />
                    </>
                  );
                },
              },
            ]}
            initialState={{}}
            hideFooterPagination
          />
        </>
      )}
    </>
  );
}
