export const getHeaders = (requireToken = true, headers, extraHeaders) => {
  let defaultHeaders = !headers
    ? {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    : {
        ...headers,
      };
  if (requireToken) {
    let authDta = localStorage.getItem("auth");
    if (!authDta) throw new Error("No Auth");
    authDta = JSON.parse(authDta);
    if (!authDta.token) throw new Error("No Auth");
    const token = authDta.token;
    defaultHeaders = { ...defaultHeaders, Authorization: "Bearer " + token };
  }
  if (extraHeaders) {
    defaultHeaders = { ...defaultHeaders, ...extraHeaders };
  }
  return {
    headers: defaultHeaders,
  };
};
