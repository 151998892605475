import { useDispatch, useSelector } from "react-redux";
import { organizationAPIs } from "../api_";
import { selectCurrentOrganization } from "../redux/organizations";
import { resetStatusCreateFolder, selectCreateFolderData, selectCreateFolderStatus } from "../redux/content";
import useCurrentFolderHandler from "./useCurrentFolderHandler";

export default function useCreateFolderHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectCreateFolderStatus(state));
  const data = useSelector((state) => selectCreateFolderData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  const {currentFolder} = useCurrentFolderHandler()
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onResetStatusCreateFolder = ()=>{
    dispatch(resetStatusCreateFolder())
  }

  const onCreateFolder = (values) => {
    dispatch(
      organizationAPIs.postCreateFolder({
        values:{...values,folder:currentFolder},
        token: JSON.parse(localStorage.getItem("auth")).token,
        id: currentOrg.id
      })
    );
  };

  return {
    data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onCreateFolder,
    onResetStatusCreateFolder
  };
}
