import { useDispatch, useSelector } from "react-redux";
import {
  resetPostOrganization,
  selectCreateOrganizationStatus,
} from "../redux/organizations";
import { organizationAPIs } from "../api_";

export default function useCreateOrganizationHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectCreateOrganizationStatus(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending" || status === "idle";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onSubmitForm = (values) => {
    dispatch(organizationAPIs.postOrganization({ values }));
  };

  const onResetPostOrganization = () => {
    dispatch(resetPostOrganization());
  };

  return {
    onSubmitForm,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onResetPostOrganization,
  };
}
