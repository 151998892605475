import { Box, Typography } from "@mui/material";

import * as React from "react";
import { BootstrapTooltip, PersonaCard } from "../../../../../../components";
import useGetAnalyzedContent from "../../../../../../hooks/useGetAnalyzedContent";

export default function AudienceHeader() {
  const { analyzedContent } = useGetAnalyzedContent();
  return (
    <Box sx={{ pt: 10, cursor:'pointer' }}>
      {analyzedContent.audience.description ? (
        <BootstrapTooltip placement="bottom-start" title={analyzedContent.audience.description}>
          <Typography variant="h5" fontWeight={"bold"} textAlign={"left"}>
            Audience: {analyzedContent.audience.name}
          </Typography>
        </BootstrapTooltip>
      ) : (
        <Typography variant="h5" fontWeight={"bold"} textAlign={"left"}>
          Audience: {analyzedContent.audience.name}
        </Typography>
      )}

      <Box
        sx={{
          background: "#f0f0f0",
          margin: "0px",
          padding: "1px",
          display: "flex",
          overflowX: "auto",
        }}
      >
        {analyzedContent.audience.personas.map((persona, idx) => (
          <PersonaCard
            key={idx}
            persona={{
              description_metadata: { ...persona.metadata },
              avatar_signed_url: persona.avatar_signed_url,
            }}
          />
        ))}
      </Box>
    </Box>
  );
}
