import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { Auth } from "./pages";
import Home from "./pages/private/Home";
import { LogoLoader, Modal } from "./components";
import { AppContext } from "./AppContext";
import { useState } from "react";
import {
  ThemeProvider,
  createTheme,
  Snackbar,
  Alert,
  Slide,
  Paper,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Pusher from "pusher-js";
import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import CircularProgress from "@mui/material/CircularProgress";
import { ProductFruits } from "react-product-fruits";
// REDUX
import PersonaModule from "./pages/private/Organization/Persona";
import Audience from "./pages/private/Organization/Audience";
import ContentLibraryModule from "./pages/private/Organization/ContentLibrary";
import { NavigationMenu } from "./pages/private/Organization/NavigationMenu";
import Brand from "./pages/private/Organization/Brand";
import Analyze from "./pages/private/Organization/Analyze";
import { useSnackbarHandler } from "./hooks/useSnackbarHandler";
import useAnalyzeContentHandler from "./hooks/useAnalyzeContentHandler";
import useGetAnalyzedContent from "./hooks/useGetAnalyzedContent";
import { useEffect } from "react";
import { useLoginHandler } from "./hooks/useLoginHandler";
import Profile from "./pages/private/Profile";
import { Organizations } from "./pages/private/Organizations";
import useOrganizationListHandler from "./hooks/useOrganizationListHandler";
import ModalBlockApp from "./components/ModalBlockApp";
import Landing from "./pages/private/Landing";
import PersonaViewer from "./pages/private/Organization/Persona/viewer";
import NoInvitedToOrg from "./pages/private/NoInvitedToOrg";
import DraftContent from "./pages/private/Organization/DraftContent";
import AutoIngestion from "./pages/public/AutoIngestion";
export const pusher = new Pusher(process.env.REACT_APP_PUSHER_JS_API_KEY, {
  cluster: process.env.REACT_APP_PUSHER_JS_CLUSTER,
  useTLS: true,
});

function App() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);

  const { profile } = useLoginHandler();
  const handleClick = () => {
    setOpen(!open);
  };

  const [global, setGlobal] = useState({
    personaWizzard: {
      activeStep: 0,
      activeSubStep: 0,
      mode: "view",
      persona: null,
    },
  });
  const theme = createTheme({
    palette: {
      dark: {
        main: "#282828",
        contrastText: "#fff",
      },
      primary: {
        main: "#1751c5",
      },
      info: {
        main: "#00dcff",
      },
      success: {
        main: "#66BF76",
        contrastText: "#fff",
      },
      gray: {
        main: "#7F7D75",
        contrastText: "#fff",
      },
      grayLight: {
        main: "#c4c4c4",
      },
      warning: {
        main: "#E3B549",
        contrastText: "#fff",
      },
      green: {
        main: "#66BF76",
      },
      red: {
        main: "#EF3937",
      },
      yellow: {
        main: "#E3B549",
      },
      blue: {
        main: "#1751c5",
        contrastText: "#fff",
      },
      white: {
        main: "#fff",
        contrastText: "#fff",
      },
      leftPane: {
        main: "#E9E8E3",
      },
      rightPane: {
        main: "#E9E8E3",
      },
      error: {
        main: "#EF3937",
      },
    },
    typography: {
      fontFamily: ["Outfit"],
    },
  });

  const { snackbarConfig, closeSnackbar } = useSnackbarHandler();
  const { analyzeQueue } = useAnalyzeContentHandler(true);
  const { onGetAnalyzedContent, pullContentFromQueue } =
    useGetAnalyzedContent();
  const [accessToken, setAccessToken] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const { authPreLoad: authPreLoadDone } = useLoginHandler();
  const { isSuccess: orgsByUserDone, organizations } =
    useOrganizationListHandler();

  useEffect(() => {
    let authDta = localStorage.getItem("auth");
    const path = window.location.pathname;
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    if (hashParams) {
      if (hashParams.get("access_token") && hashParams.get("refresh_token")) {
        setAccessToken("access_token");
        setRefreshToken("refresh_token");
        return;
      }
    }

    if (
      //path === "/signup/subscription" ||
      path.includes("/signup/subscription") &&
      authDta === null
    ) {
      return (window.location.href = "/login");
    }
    if (
      ((path !== "/login" && !path.includes("/signup/") && path !== "/autoingestion" )|| path === "/") &&
      authDta === null
    ) {
      return (window.location.href = "/login");
    }
    const auth = JSON.parse(authDta);
    if (
      auth?.token &&
      auth.in_account &&
      (path.includes("/login") || path.includes("/signup") || path === "/")
    ) {
      return navigate("/home");
    }
    if (
      auth?.token &&
      !auth.in_account &&
      auth.is_account_owner &&
      (!path.includes("/signup/subscription") || path === "/")
    ) {
      return navigate(
        `/signup/subscription/${process.env.REACT_APP_STRIPE_PRODUCT_FREE}`
      );
    }
    //return navigate("/home");
  },[setRefreshToken, navigate]);

  return (
    <div className="App">
      <AppContext.Provider value={{ global, setGlobal }}>
        {profile && orgsByUserDone && (
          <>
            <ProductFruits
              workspaceCode={process.env.REACT_APP_PRODUCT_FRUITS_KEY}
              language="en"
              user={{
                username: profile?.email,
                email: profile?.name,
                firstname: profile?.name,
                lastname: profile?.last_name,
                signUpAt: profile?.created_at,
                role: profile?.is_accounts_owner
                  ? "account_owner"
                  : "organization_user",
                props: {
                  organizations: JSON.stringify(
                    organizations.map(({ id, name, role }) => ({
                      id,
                      name,
                      role,
                    }))
                  ),
                },
              }}
            />
          </>
        )}
          <ThemeProvider theme={theme}>
            <ModalBlockApp />
            {!accessToken && !refreshToken && (
              <Modal
                fullScreen
                open={
                  !authPreLoadDone &&
                  !window.location.pathname.includes("/login") &&
                  !window.location.pathname.includes("/signup") &&
                  !window.location.pathname.includes("/autoingestion") &&
                  !window.location.pathname.includes(
                    "/not_invited_to_organization"
                  )
                }
                disableClose
                body={<LogoLoader open text={"Subatomic"} />}
              />
            )}
            <Routes>
              <Route
                path="/not_invited_to_organization"
                element={<NoInvitedToOrg></NoInvitedToOrg>}
              />
              <Route
                path="/"
                element={
                  accessToken && refreshToken && <Auth type="SET_NEW_PASS" />
                }
              />
              <Route
                path="/login"
                element={
                  <>
                    <Auth type="LOGIN"></Auth>
                  </>
                }
              />
              
              <Route
                path="/signup/:product_id"
                element={
                  <>
                    <Auth type="SIGNUP"></Auth>
                  </>
                }
              />
              <Route
                path="/signup"
                element={
                  <>
                    <Auth type="SIGNUP"></Auth>
                  </>
                }
              />
              <Route
                path="/signup/subscription/:product_id"
                element={
                  <>
                    <Auth type="SIGNUP_SUBSCRIPTION"></Auth>
                  </>
                }
              />
              <Route
                path="/signup/subscription"
                element={
                  <>
                    <Auth type="SIGNUP_SUBSCRIPTION"></Auth>
                  </>
                }
              />
              <Route
                path="/signup/"
                element={
                  <>
                    <Auth type="SIGNUP"></Auth>
                  </>
                }
              />

              <Route
                path="/reset_password/"
                element={
                  <>
                    <Auth type="SET_NEW_PASSWORD"></Auth>
                  </>
                }
              />
              <Route
                path="/autoingestion"
                element={
                  <>
                   <AutoIngestion></AutoIngestion>
                  </>
                }
              />

              <Route
                path="/home"
                element={
                  <>
                    <Landing>
                      <NavigationMenu isPublicPage={false} />
                      <Home />
                    </Landing>
                  </>
                }
              ></Route>
              <Route
                path="organizations"
                element={
                  <Landing>
                    <NavigationMenu isPublicPage={false} />
                    <Organizations />
                  </Landing>
                }
              />
              <Route
                path="profile"
                element={
                  <>
                    <Landing>
                      <NavigationMenu isPublicPage={false} />
                      <Profile />
                    </Landing>
                  </>
                }
              />

              <Route path="/organization">
                <Route path=":id">
                  <Route path="content">
                    <Route
                      path="draft"
                      element={
                        <>
                          <Landing>
                            <NavigationMenu isPublicPage={false} />
                            <DraftContent />
                          </Landing>
                        </>
                      }
                    />
                  </Route>

                  <Route path="persona">
                    <Route
                      path=""
                      element={
                        <>
                          <Landing>
                            <NavigationMenu isPublicPage={false} />
                            <PersonaModule />
                          </Landing>
                        </>
                      }
                    />
                    <Route
                      path=":persona_id"
                      element={
                        <>
                          <Landing>
                            <NavigationMenu isPublicPage={false} />
                            <PersonaViewer />
                          </Landing>
                        </>
                      }
                    />
                  </Route>

                  <Route path="audience">
                    <Route
                      path=""
                      element={
                        <>
                          <Landing>
                            <NavigationMenu isPublicPage={false} />
                            <Audience />
                          </Landing>
                        </>
                      }
                    />
                    <Route
                      path=":audience_id"
                      element={
                        <>
                          <Landing>
                            <NavigationMenu isPublicPage={false} />
                            <Audience />
                          </Landing>
                        </>
                      }
                    />
                  </Route>
                  <Route
                    path="brand"
                    element={
                      <>
                        <Landing>
                          <NavigationMenu isPublicPage={false} />
                          <Brand />
                        </Landing>
                      </>
                    }
                  />
                  <Route path="content">
                    <Route path=":content_id">
                      <Route path="version">
                        <Route path=":version_id">
                          <Route
                            path="analyze"
                            element={
                              <>
                                <Landing>
                                  <NavigationMenu isPublicPage={false} />
                                  <Analyze />
                                </Landing>
                              </>
                            }
                          />
                        </Route>
                      </Route>
                    </Route>
                  </Route>

                  <Route
                    path="content_library"
                    element={
                      <>
                        <Landing>
                          <NavigationMenu isPublicPage={false} />
                          <ContentLibraryModule />
                        </Landing>
                      </>
                    }
                  />
                </Route>
              </Route>
            </Routes>
            {analyzeQueue.length > 0 && (
              <Paper
                sx={{
                  position: "absolute",
                  top: "7%",
                  right: "1%",
                }}
              >
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <ListItemButton onClick={handleClick}>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Analyzing Content Status" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {analyzeQueue.map((item, key) => (
                        <ListItemButton
                          key={key}
                          disabled={item.status === "in queue"}
                          onClick={() => {
                            //onGetAnalyzedContent(item.content_id);
                            onGetAnalyzedContent(item.content_id);
                            pullContentFromQueue(item);
                          }}
                          sx={{ pl: 4 }}
                        >
                          <ListItemIcon>
                            {item.status === "in queue" ? (
                              <CircularProgress size={15}></CircularProgress>
                            ) : (
                              <StarBorder />
                            )}
                          </ListItemIcon>
                          {item.title}
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </List>
              </Paper>
            )}
          </ThemeProvider>
      </AppContext.Provider>

      <Snackbar
        anchorOrigin={{
          vertical: snackbarConfig.vertical,
          horizontal: snackbarConfig.horizontal,
        }}
        TransitionComponent={Slide}
        open={snackbarConfig.open}
        autoHideDuration={snackbarConfig.duration}
        onClose={closeSnackbar}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbarConfig.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarConfig.text}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
