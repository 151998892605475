import { Box, Typography, LinearProgress } from "@mui/material";
import * as React from "react";
import useGetAnalyzedContent from "../../../../../../hooks/useGetAnalyzedContent";
import useSuggestionsContentHandler from "../../../../../../hooks/useSuggestionsContentHandler";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import EmptyTab from "./EmptyTab";

export default function SuggestionsTab() {
  const { analyzedContent } = useGetAnalyzedContent();
  const { isLoading: isGeneratingSuggestions } = useSuggestionsContentHandler();

  const emptyTabText = () => {
    if (
      analyzedContent.content.is_current &&
      !Boolean(analyzedContent.audience_personas_reactions)
    ) {
      return "This content is not analyzed yet";
    }
    if (analyzedContent.content.is_current) {
      return "No Suggestions generated yet, please click in SUGGEST EDITS";
    }
    return "This content was not analyzed";
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isGeneratingSuggestions ? (
          <Box
            width={200}
            sx={{
              position: "relative",
              width: "370px",
              top: "150px",
            }}
          >
            <Typography variant="caption"> Generating Suggestions</Typography>
            <LinearProgress></LinearProgress>
          </Box>
        ) : analyzedContent?.audience_personas_suggestions ? (
          <Box sx={{ py: 1 }}>
            <Typography variant="h6" fontWeight={"bold"} textAlign={"left"}>
              Audience Suggestion
            </Typography>
            <Box>
              <Typography
                textAlign={"left"}
                fontWeight={"bold"}
                variant="subtitle1"
              >
                Overall Reaction
              </Typography>
              <Typography textAlign={"left"}>
                {
                  analyzedContent?.audience_personas_suggestions
                    .audience_suggestion
                }
              </Typography>
            </Box>
            <br />
            <Box>
              <Typography
                textAlign={"left"}
                fontWeight={"bold"}
                variant="subtitle1"
              >
                Individual Audience Member Suggestions
              </Typography>
              {analyzedContent?.audience_personas_suggestions
                .personas_suggestions &&
                analyzedContent?.audience_personas_suggestions.personas_suggestions.map(
                  (persona, idx) => (
                    <Box key={idx} py={0.5}>
                      <Typography
                        textAlign={"left"}
                        fontWeight={"bold"}
                        variant="subtitle2"
                      >
                        {persona.job_title}
                      </Typography>
                      <Typography textAlign={"left"}>
                        {persona.suggestion}
                      </Typography>
                    </Box>
                  )
                )}
            </Box>
          </Box>
        ) : (
          <EmptyTab icon={<RecordVoiceOverIcon />}>{emptyTabText()}</EmptyTab>
        )}
      </Box>
    </>
  );
}
