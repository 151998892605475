export const disableOrganizationPending = (state, action) => {
  state.disableOrganization.status = "pending";
};

export const disableOrganizationFulfilled = (state, action) => {
  state.disableOrganization.status = "fulfilled";
  state.disableOrganization.data = action.payload.result;
};

export const disableOrganizationRejected = (state, action) => {
  state.disableOrganization.status = "rejected";
};
