export const postUploadFilePending = (state, action) => {
  state.uploadFileContent.status = "pending";
};

export const postUploadFileFulfilled = (state, action) => {
  state.uploadFileContent.status = "fulfilled";
  state.data = action.payload;
};

export const postUploadFileRejected = (state, action) => {
  state.uploadFileContent.status = "rejected";
  state.uploadFileContent.error = action.payload.response.data;
};
