import { useSelector } from "react-redux";
import { adminAPIs } from "../api_";
import {
  resetPaymentMethod,
  selectPaymentMethodData,
  selectPaymentMethodError,
  selectPaymentMethodStatus,
} from "../redux/admin";
import { useAppDispatch } from "../redux/store";

export default function usePaymentMethodUpdateHandler() {
  const dispatch = useAppDispatch();

  const status = useSelector((state) => selectPaymentMethodStatus(state));
  const data = useSelector((state) => selectPaymentMethodData(state));
  const error = useSelector((state) => selectPaymentMethodError(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onSubmitForm = (values) => {
    dispatch(adminAPIs.putPaymentMethod(values));
  };
  const onResetPaymentMethod = () => {
    dispatch(resetPaymentMethod());
  };
  return {
    data,
    error,
    onSubmitForm,
    onResetPaymentMethod,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
