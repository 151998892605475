import { useDispatch, useSelector } from "react-redux";
import { resetGetAllUsers, selectAllUsersData, selectAllUsersStatus } from "../redux/admin";
import { adminAPIs } from "../api_";

export default function useAdminAllUsersHandler() {
  const dispatch = useDispatch();
  const data = useSelector((state) => selectAllUsersData(state));
  const status = useSelector((state) => selectAllUsersStatus(state));
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onGetAdminAllUsers = () => {
    dispatch(adminAPIs.getAllUser());
  };

  const onResetGetAdminAllUsers = () => {
    dispatch(resetGetAllUsers());
  };

  return {
    users: data,
    onGetAdminAllUsers,
    onResetGetAdminAllUsers,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
