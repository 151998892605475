//Packages Imports
import { useSelector } from "react-redux";
//App Import
import { useAppDispatch } from "../redux/store";
import { selectSignUpAccountOwnerData, selectSignUpAccountOwnerError, selectSignUpAccountOwnerStatus } from "../redux/auth";
import { authAPIs } from "../api_";
import {useEffect} from "react"
export function useSignUpAccountOwnerHandler() {
  const dispatch = useAppDispatch();

  const status = useSelector((state) => selectSignUpAccountOwnerStatus(state));
  const data = useSelector((state) => selectSignUpAccountOwnerData(state));
  const error = useSelector((state) => selectSignUpAccountOwnerError(state));

  const isUninitialized = status === undefined;
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onSubmitForm = (values) => {
    dispatch(authAPIs.postSignupAccountOwner({ ...values }));
  };
  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem("auth", JSON.stringify(data));
      return;
    }
  }, [isSuccess, data]);

  return {
    data,
    error,
    onSubmitForm,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
