export const putBrandPending = (state, action) => {
    state.builder.status = "pending";
  };
  
  export const putBrandFulfilled = (state, action) => {
    state.builder.status = "fulfilled";
    //state.builder.data = action.payload;
  };
  
  export const putBrandRejected = (state, action) => {
    state.builder.status = "rejected";
    state.builder.error = action.payload.response.data;
  };
  