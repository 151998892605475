export const postCreateAudiencePending = (state, action) => {
  state.createAudience.status = "pending";
};

export const postCreateAudienceFulfilled = (state, action) => {
  state.createAudience.status = "fulfilled";
  state.createAudience.data = action.payload;
};

export const postCreateAudienceRejected = (state, action) => {
  state.createAudience.status = "rejected";
};
