import sessionsSlice from "./slice"

// SELECTORS
export const selectPersonasListData = (state) =>  state?.persona?.list?.data
export const selectGetPersonasStatus = (state) => state?.persona?.list?.status

export const selectPersonaMode = (state) => state?.persona?.mode

export const selectPersonaBuilderStatus = (state) => state?.persona?.builder?.status

export const selectPersonaBuilderSubNext = (state) => state?.persona?.builder?.submitNext
export const selectPersonaBuilderData = (state) => state?.persona?.builder?.data
export const selectPersonaBuilderPayload = (state) => state?.persona?.builder?.payload
export const selectPersonaBuilderError = (state) => state?.persona?.builder?.error

export const selectPersonaBuilderWizzard = (state) => state?.persona?.builder?.wizzard

export const selectDisablePersonaStatus = (state) => state?.persona?.disablePersona?.status
export const selectDisablePersonaData = (state) => state?.persona?.disablePersona?.data

export const selectPersonaData = (state) =>  state?.persona?.persona?.data
export const selectPersonaStatus = (state) => state?.persona?.persona?.status

export const selectPersonaExportData = (state) =>  state?.persona?.exportPDF?.data
export const selectPersonaExportStatus = (state) => state?.persona?.exportPDF?.status
export const selectPersonaExportError = (state) => state?.persona?.exportPDF?.error
export const selectPersonaExportQueue = (state) => state?.persona?.exportPDFQueue.queue

export const selectGenerateAnswerStatus = (state) => state?.persona?.aiAnswer?.status
export const selectGenerateAnswerData = (state) => state?.persona?.aiAnswer?.data
export const selectGenerateAnswerError = (state) => state?.persona?.aiAnswer?.error
// ACTIONS
export const {
    setMode,
    resetPersonaBuilderStatus,
    setPersonaBuilderSubNext,
    resetGetPersonaExportPDF,
    setWizzard,
    resetGenerateAnswerStatus,
    resetDisablePersona,
    resetPersona,
    removeFromPersonaExportQueue,
    resetPersonasList
} = sessionsSlice.actions;

// REDUCER
export default sessionsSlice.reducer