const AddContentIcon = () => (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_445_7758)">
      <path
        d="M3.01023 16.5018C2.07316 14.9395 2.42145 12.0357 2.75731 10.9998L3.69023 10.7663C3.69023 10.7663 3.45389 9.32287 4.5195 7.92614C5.80072 6.24497 9.46194 2.90385 16.5024 2.50903C13.9276 5.44684 12.0907 10.4521 11.6346 11.1908C10.6768 11.8574 9.74389 12.3074 9.74389 12.3074L10.5939 13.1565C10.5939 13.1565 5.8795 17.3042 3.01023 16.5061V16.5018Z"
        stroke="white"
        strokeLinejoin="round"
      />
      <path
        d="M11.0251 6.66089C5.65562 10.8299 2.87343 16.1111 1.76221 19.5753"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_445_7758">
        <rect width="17" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default AddContentIcon;
