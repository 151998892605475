export const postOrganizationPending = (state, action) => {
  state.createOrganization.status = "pending";
};

export const postOrganizationFulfilled = (state, action) => {
  state.createOrganization.status = "fulfilled";
  state.createOrganization.data = action.payload.result;
};

export const postOrganizationRejected = (state, action) => {
  state.createOrganization.status = "rejected";
};
