import { useDispatch, useSelector } from "react-redux";
import { contentAPIs } from "../api_";
import {
  resetBrandReaction,
  selectAnalyzedContentData,
  selectBrandReactionData,
  selectBrandReactionStatus,
} from "../redux/content";
import { selectCurrentOrganization } from "../redux/organizations";

export default function useBrandReactionHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectBrandReactionStatus(state));
  const data = useSelector((state) => selectBrandReactionData(state));
  const analyzedContentData = useSelector((state) => selectAnalyzedContentData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onGenerateBrandReaction = () => {
    dispatch(resetBrandReaction());
    dispatch(
      contentAPIs.postBrandReaction({
        orgId: currentOrg.id,
        id: analyzedContentData.content.content_id
      })
    );
  };

  const onResetBrandReaction = ()=>{
    dispatch(resetBrandReaction())
  }

  return {
    brandReactionData: data,
    onGenerateBrandReaction,
    onResetBrandReaction,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
