import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
export default function AccordionComponent({ content, title, expanded }) {
  return (
    <Accordion disableGutters defaultExpanded={expanded} elevation={0}>
      <AccordionSummary
        sx={{
          flexDirection: "row-reverse",
          padding: 0,

          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },
        }}
        expandIcon={<ArrowForwardIosSharpIcon fontSize="small" />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography style={{ marginLeft: "2rem" }}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
}
