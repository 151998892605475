import { Box, Tabs, Tab } from "@mui/material";
import * as React from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import PsychologyIcon from "@mui/icons-material/Psychology";
import CommitIcon from "@mui/icons-material/Commit";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import ScoresTab from "./ScoresTab";
import ReactionsTab from "./ReactionsTab";
import SuggestionsTab from "./SuggestionsTab";
import BrandAlignmentTab from "./BrandAlignmentTab";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            height: "25rem",
            my: 1,
            overflowY: "auto",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function TabsComponent({ value, handleChange }) {
  return (
    <>
      <Tabs
        value={value}
        textColor="primary"
        indicatorColor="primary"
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab
          sx={{ fontSize: "10px", minWidth: "134px", minHeight: "48px" }}
          label="Content Score"
          icon={<BarChartIcon sx={{ padding: 0, margin: 0 }} />}
          iconPosition="start"
          {...a11yProps(0)}
        />
        <Tab
          sx={{ fontSize: "10px", minWidth: "134px", minHeight: "48px" }}
          label="Reactions"
          icon={<PsychologyIcon sx={{ padding: 0, margin: 0 }} />}
          iconPosition="start"
          {...a11yProps(1)}
        />
        <Tab
          sx={{ fontSize: "10px", minWidth: "134px", minHeight: "48px" }}
          label="Suggestions"
          icon={<RecordVoiceOverIcon sx={{ padding: 0, margin: 0 }} />}
          iconPosition="start"
          {...a11yProps(2)}
        />
        <Tab
          sx={{ fontSize: "10px", minWidth: "134px", minHeight: "48px" }}
          icon={<CommitIcon sx={{ padding: 0, margin: 0 }} />}
          iconPosition="start"
          label=" Brand Alignment"
          {...a11yProps(2)}
        />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <ScoresTab/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ReactionsTab/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <SuggestionsTab/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <BrandAlignmentTab/>
      </CustomTabPanel>
    </>
  );
}
