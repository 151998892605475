import { useDispatch, useSelector } from "react-redux";
import { contentAPIs } from "../api_";
import {
  resetAnalyzedContent,
  selectAnalyzedContentData,
  selectAnalyzedContentStatus,
  selectGetContentMetadataData,
} from "../redux/content";
import { selectCurrentOrganization } from "../redux/organizations";
import { useNavigate } from "react-router-dom";
import { pullhAnalyzeContentQueue } from "../redux/utilities";

export default function useGetAnalyzedContent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = useSelector((state) => selectAnalyzedContentStatus(state));
  const data = useSelector((state) => selectAnalyzedContentData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  const currentBlob = useSelector((state) =>
    selectGetContentMetadataData(state)
  );
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onGetAnalyzedContent = (id) => {
    if (currentBlob?.metadata?.id || id) {
      dispatch(
        contentAPIs.getAnalyzeContent({
          orgId: currentOrg.id,
          id: id ? id : currentBlob?.metadata?.id,
        })
      );
    }
  };
  const onGotToAnalyze = (id) => {
    if (currentBlob?.metadata?.id || id) {
      navigate(
        `/organization/${currentOrg.id}/content/${
          currentBlob?.metadata?.id || id
        }/version/latest/analyze`
      );
    }
  };
  const pullContentFromQueue = (id) => {
    dispatch(pullhAnalyzeContentQueue(id));
  };

  const onResetGetAnalyzedContent = () => {
    dispatch(resetAnalyzedContent());
  };

  /*useEffect(()=>{
    if(isSuccess){
      dispatch(setAnalyzedContentStatus('idle'))
      navigate(
        `/organization/${currentOrg.id}/content/${}/version/${}/analyze`
      );
    }
  },[isSuccess])*/

  return {
    analyzedContent: data,
    onGetAnalyzedContent,
    onResetGetAnalyzedContent,
    onGotToAnalyze,
    pullContentFromQueue,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
