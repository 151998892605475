const BrandIcon = ({small, color='black'}) => small ? <svg width="18" height="26" viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1545_8850)">
<path d="M18.0004 10.5362C18.0004 6.54622 14.7127 3.30005 10.6716 3.30005C8.71283 3.30005 6.87207 4.05254 5.48848 5.41863C4.1049 6.78472 3.34277 8.60221 3.34277 10.5362V15.4637C3.34277 17.6339 5.13125 19.3998 7.32925 19.3998C8.3954 19.3998 9.39676 18.9904 10.1487 18.2475C10.9011 17.5046 11.3157 16.5159 11.3157 15.4637V10.5042C11.3157 10.1529 11.0274 9.86824 10.6716 9.86824C10.3158 9.86824 10.0275 10.1529 10.0275 10.5042V15.4637C10.0275 16.176 9.74705 16.8453 9.23758 17.3484C8.72903 17.8509 8.05112 18.1278 7.32925 18.1278C5.84155 18.1278 4.63103 16.9326 4.63103 15.4637V10.5362C4.63103 8.94167 5.25897 7.44355 6.39915 6.31778C7.53933 5.19201 9.05665 4.57202 10.6716 4.57202C14.0024 4.57202 16.7122 7.24754 16.7122 10.5362V15.4633C16.7122 20.5722 12.5031 24.7284 7.32925 24.7284C6.97341 24.7284 6.68512 25.0131 6.68512 25.3644C6.68512 25.7158 6.97341 26.0004 7.32925 26.0004C10.182 26.0004 12.8622 24.9053 14.8765 22.916C16.8908 20.9272 18.0004 18.2804 18.0004 15.4637V10.5367V10.5362Z" fill={color}/>
<path d="M12.5124 20.5813C13.896 19.2152 14.6577 17.3978 14.6581 15.4638V10.5362C14.6581 8.36604 12.8696 6.60017 10.6716 6.60017C9.60549 6.60017 8.60413 7.00954 7.85218 7.75244C7.09977 8.49533 6.68516 9.48404 6.68516 10.5362V15.4957C6.68516 15.8471 6.97344 16.1317 7.32929 16.1317C7.68513 16.1317 7.97342 15.8471 7.97342 15.4957V10.5362C7.97342 9.82396 8.25384 9.15462 8.76331 8.65159C9.27186 8.14902 9.94977 7.87214 10.6716 7.87214C12.1593 7.87214 13.3699 9.06736 13.3699 10.5362V15.4638C13.3699 17.0583 12.7419 18.5564 11.6017 19.6822C10.4616 20.808 8.94424 21.4279 7.32929 21.4279C4.00638 21.4279 1.30168 18.7657 1.28826 15.4884C1.28826 15.4802 1.28872 15.472 1.28872 15.4638V10.475C1.30446 8.02154 2.27944 5.71656 4.03507 3.98404C5.80642 2.23509 8.16314 1.27197 10.6716 1.27197C11.0275 1.27197 11.3158 0.98733 11.3158 0.635985C11.3158 0.28464 11.0275 0 10.6716 0C7.81886 0 5.13869 1.09561 3.12394 3.08444C1.12862 5.05407 0.0194349 7.67385 0.000462737 10.4627C0 10.4874 0 10.5116 0 10.5362V15.4638C0 19.4537 3.28775 22.6999 7.32929 22.6999C9.28805 22.6999 11.1288 21.9474 12.5124 20.5813Z" fill={color}/>
</g>
<defs>
<clipPath id="clip0_1545_8850">
<rect width="18" height="26" fill={color}/>
</clipPath>
</defs>
</svg>
:
  <svg
    width="45"
    height="66"
    viewBox="0 0 45 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_458_9410)">
      <path
        d="M44.9996 26.7459C44.9996 16.6175 36.7802 8.3772 26.6775 8.3772C21.7806 8.3772 17.1787 10.2874 13.7197 13.7551C10.2608 17.2229 8.35547 21.8365 8.35547 26.7459V39.2542C8.35547 44.7632 12.8267 49.2458 18.3217 49.2458C20.987 49.2458 23.4904 48.2066 25.3703 46.3208C27.2513 44.435 28.2879 41.9252 28.2879 39.2542V26.6647C28.2879 25.7728 27.5671 25.0503 26.6775 25.0503C25.7879 25.0503 25.0672 25.7728 25.0672 26.6647V39.2542C25.0672 41.0623 24.3662 42.7614 23.0925 44.0383C21.8211 45.3141 20.1263 46.0169 18.3217 46.0169C14.6024 46.0169 11.5761 42.9829 11.5761 39.2542V26.7459C11.5761 22.6982 13.146 18.8953 15.9964 16.0376C18.8469 13.1799 22.6402 11.606 26.6775 11.606C35.0045 11.606 41.7789 18.3978 41.7789 26.7459V39.253C41.7789 52.2218 31.2563 62.7724 18.3217 62.7724C17.4321 62.7724 16.7113 63.4949 16.7113 64.3868C16.7113 65.2787 17.4321 66.0012 18.3217 66.0012C25.4536 66.0012 32.154 63.2212 37.1898 58.1715C42.2255 53.1229 44.9996 46.4043 44.9996 39.2542V26.7471V26.7459Z"
        fill={color}
      />
      <path
        d="M31.281 52.2449C34.74 48.7772 36.6441 44.1635 36.6453 39.2542V26.7458C36.6453 21.2369 32.1741 16.7543 26.6791 16.7543C24.0137 16.7543 21.5103 17.7935 19.6305 19.6793C17.7494 21.5651 16.7129 24.0749 16.7129 26.7458V39.3353C16.7129 40.2272 17.4336 40.9498 18.3232 40.9498C19.2128 40.9498 19.9335 40.2272 19.9335 39.3353V26.7458C19.9335 24.9377 20.6346 23.2387 21.9083 21.9617C23.1796 20.686 24.8744 19.9831 26.6791 19.9831C30.3983 19.9831 33.4246 23.0171 33.4246 26.7458V39.2542C33.4246 43.3018 31.8548 47.1048 29.0043 49.9625C26.1539 52.8202 22.3606 54.394 18.3232 54.394C10.0159 54.394 3.2542 47.6359 3.22065 39.3168C3.22065 39.2959 3.22181 39.275 3.22181 39.2542V26.5904C3.26114 20.3624 5.6986 14.5113 10.0877 10.1133C14.5161 5.67368 20.4079 3.22885 26.6791 3.22885C27.5687 3.22885 28.2894 2.5063 28.2894 1.61442C28.2894 0.722547 27.5687 0 26.6791 0C19.5472 0 12.8467 2.78117 7.80984 7.82972C2.82154 12.8296 0.0485874 19.4798 0.00115684 26.5591C0 26.6218 0 26.6832 0 26.7458V39.2542C0 49.3826 8.21936 57.6229 18.3232 57.6229C23.2201 57.6229 27.8221 55.7127 31.281 52.2449Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_458_9410">
        <rect width="45" height="66" fill={color} />
      </clipPath>
    </defs>
  </svg>

export default BrandIcon;
