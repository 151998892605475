import { Box, Typography } from "@mui/material";
import List from "@mui/material/List";
import {
  AnalyzeConfirmationModal,
  BootstrapTooltip,
} from "../../../../../../components";
import useGetAnalyzedContent from "../../../../../../hooks/useGetAnalyzedContent";

export default function ContentLists() {
  const { analyzedContent } = useGetAnalyzedContent();
  return (
    <Box pt={1}>
      <Typography variant="subtitle1" textAlign={"left"} fontWeight={"bold"}>
        Supporting Content:
      </Typography>
      <Box
        sx={{
          height: "200px",
          overflowY: "auto",
          borderTop: "1px solid gray",
        }}
      >
        <List>
          {analyzedContent?.related_content.map((content, idx) => (
            <BootstrapTooltip
              key={idx}
              title={`Go to: ${content.title}`}
              placement="right"
            >
              <Box
                key={idx}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  background: "#00000000",
                  borderRadius: "0px",
                  transition: "0.3s",
                  cursor: "pointer",
                  padding: "0px 5px 0px 5px",
                  margin: "1px",
                  "&:hover": {
                    background: "#0000008a",
                    borderRadius: "10px",
                    transition: "0.2s",
                    color: "white",
                  },
                }}
              >
                <Typography variant="caption" textAlign={"left"}>
                  {content.title.slice(0, 25)}
                  {content.title.length > 21 ? "..." : ""}
                </Typography>
                <AnalyzeConfirmationModal
                  btnType="eyeIcon"
                  has_analytics={content.has_analytics}
                  fullWidth={true}
                  content={{ id: content.id, title: content.title }}
                />
              </Box>
            </BootstrapTooltip>
          ))}
        </List>
      </Box>
    </Box>
  );
}
