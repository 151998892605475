import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { contentAPIs } from "../api_";
import {
  resetNavigator,
  selectGetContentNavigatorData,
  selectGetContentNavigatorStatus,
} from "../redux/content";
import {
  selectCurrentOrganization,
  selectOrgUserProfileStatus,
} from "../redux/organizations";
import useCurrentFolderHandler from "./useCurrentFolderHandler";
import { useNavigate } from "react-router-dom";

export default function useContentNavigatorHandler({ readOnly }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentFolder } = useCurrentFolderHandler();
  const status = useSelector((state) => selectGetContentNavigatorStatus(state));
  const data = useSelector((state) => selectGetContentNavigatorData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  const userOrgProfileStatus = useSelector((state) =>
    selectOrgUserProfileStatus(state)
  );

  const prevState = useRef(currentFolder);

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  useEffect(() => {
    if (!readOnly && currentOrg && userOrgProfileStatus === "fulfilled") {
      return;
    } else if (currentOrg === null) {
      navigate(`/organizations`);
    }
  }, [
    isUninitialized,
    currentOrg,
    readOnly,
    navigate,
    currentFolder,
    userOrgProfileStatus,
  ]);

  useEffect(() => {
    if (readOnly) return;
    if (prevState.current !== currentFolder && !readOnly) {
      prevState.current = currentFolder;
      dispatch(
        contentAPIs.getContentNavigator({
          id: currentOrg.id,
          folder: currentFolder,
        })
      );
    }
  }, [currentFolder, currentOrg, dispatch, readOnly]);

  const onGetContentNavigator = () => {
    dispatch(
      contentAPIs.getContentNavigator({
        id: currentOrg.id,
        folder: currentFolder,
      })
    );
  };

  const onResetNavigator = () => dispatch(resetNavigator());

  return {
    folders: () => {
      if (isSuccess || data) {
        const files = data.files.map((file) => {
          return { ...file, isFile: true };
        });
        return files.concat(data.folders).map((r, i) => ({ ...r, id: i }));
      }
    },
    onResetNavigator,
    allBlobs: data ? [...data?.files, ...data.folders] : [],
    filesList: data ? data.files : [],
    foldersList: data ? data.folders : [],
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onGetContentNavigator,
  };
}
