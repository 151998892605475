import {
  Button,
  Stack,
  Box,
  Paper,
  Grid,
  Typography,
  ToggleButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";

import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect, useCallback } from "react";
import {
  AudienceSelect,
  ChannelSelect,
  EditorComponent,
  FoldersSelect,
  InputFileUpload,
  LogoLoader,
  Modal,
} from "../../../../../components";
import { AddContentIcon } from "../../../../../icons";
import InputText from "../../../../../components/InputText";
import { useFormik } from "formik";
import * as Yup from "yup";

import useUploadContentFile from "../../../../../hooks/useUploadFile";
import useAllFolderHandler from "../../../../../hooks/useAllFolderHandler";
import useOrganizationChannelsHandler from "../../../../../hooks/useOrganizationChannelsHandler";
import useUploadContentTextHandler from "../../../../../hooks/useUploadContentTextHandler";
import useAudienceByOrganizationHandler from "../../../../../hooks/useAudienceByOrganizationHandler";
import useGetOrgUserProfile from "../../../../../hooks/useGetOrgUserProfileHanlder";
import ErrorMessageDisplayer from "../../../../../components/ErrorMessageDisplayer";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: "1px solid transparent",
    },
}));

export default function ModalUploadFile() {
  const { canManageContent } = useGetOrgUserProfile();
  const [importType, setImportType] = useState("text");
  const [open, setOpen] = useState(false);
  const {
    isSuccess,
    isLoading,
    onUploadFile,
    isError,
    error,
    onResetUploadContentFile,
  } = useUploadContentFile();
  const { onResetAudienceList } = useAudienceByOrganizationHandler();
  const { onResetChannels } = useOrganizationChannelsHandler();
  const { onResetAllFoldersList } = useAllFolderHandler();
  const {
    isSuccess: isSuccessUploadContentText,
    isLoading: isLoadingUploadContentText,
    isError: isErrorUploadContentText,
    error: errorUploadContentText,
    onUploadContentText,
    onResetUploadContentText,
  } = useUploadContentTextHandler();

  const handleChange = (_, val) => {
    setImportType(val);
    formik.resetForm();
  };

  const addNewBulkForm = () => {
    formik.setFieldValue("forms", [
      ...formik.values.forms,
      {
        title: "",
        channel: "",
        audience: "",
        folder: "",
        file: "",
      },
    ]);
  };

  const removeBulkForm = (index) => {
    const newBulkForms = [...formik.values.forms];
    newBulkForms.splice(index, 1);
    formik.setFieldValue("forms", newBulkForms);
  };

  const formik = useFormik({
    initialValues: {
      forms: [
        {
          title: "",
          contentText: [
            {
              type: "paragraph",
              align: "left",
              children: [
                {
                  text: "",
                },
              ],
            },
          ],
          channel: "",
          audience: "",
          folder: "",
          file: "",
        },
      ],
    },
    validationSchema: Yup.object().shape({
      forms: Yup.array().of(
        Yup.object().shape({
          title: Yup.string()
            .max(200, "Must be 200 characters or less")
            .required("Required"),
          contentText:
            importType === "text"
              ? Yup.array()
              .of(
                Yup.object({
                  type: Yup.string()
                    .oneOf([
                      "paragraph",
                      "bulleted-list",
                      "block-quote",
                      "numbered-list",
                      "list-item",
                      "heading-one",
                      "heading-two",
                      "heading-three",
                    ])
                    .required("Type is required"),
                  children: Yup.array()
                    .of(
                      Yup.object({
                        type: Yup.string()
                          .oneOf([
                            "list-item",
                          ])
                          .optional(),
                        children: Yup.array()
                          .of(
                            Yup.object({
                              text: Yup.string().nullable(),
                            })
                          )
                          .min(1, "Each block must have at least one child")
                          .optional(),
                        text: Yup.string().nullable(),
                      })
                    )
                    .min(1, "Each block must have at least one child"),
                })
              )
              .test(
                "at-least-one-non-empty",
                "At least one block must have content",
                function (blocks) {
                  return (
                    blocks &&
                    blocks.some((block) =>
                      block.children.some((child) =>
                        child.children
                          ? child.children.some(
                              (grandChild) =>
                                grandChild.text && grandChild.text.trim().length > 0
                            )
                          : child.text && child.text.trim().length > 0
                      )
                    )
                  );
                }
              )
              .required("Content is required")
              : Yup.array(),
          channel: Yup.object().required("Required"),
          folder: Yup.object().required("Required"),
          file:
            importType !== "text"
              ? Yup.string().required("Required")
              : Yup.string(),
        })
      ),
    }),
    onSubmit: async (values) => {
      if (importType === "text") {
        onUploadContentText(values.forms[0]);
        return;
      }
      if (importType === "file") {
        onUploadFile(values.forms);
        return;
      }
      if (importType === "bulk") {
        onUploadFile(values.forms);
        return;
      }
    },
  });

  const handleClose = useCallback(() => {
    if (!isLoading || !isLoadingUploadContentText) {
      formik.resetForm();
      setOpen(false);
      onResetAudienceList();
      onResetChannels();
      onResetAllFoldersList();
    }
  }, [
    isLoading,
    isLoadingUploadContentText,
    formik,
    setOpen,
    onResetAudienceList,
    onResetChannels,
    onResetAllFoldersList,
  ]);
  useEffect(() => {
    if (isError || isErrorUploadContentText) {
      formik.resetForm();
      setOpen(false);
    }
  }, [isError, isErrorUploadContentText, formik]);
  useEffect(() => {
    if (isSuccess || isSuccessUploadContentText) {
      handleClose();
    }
  }, [handleClose, isSuccess, isSuccessUploadContentText]);

  const actions = (
    <>
      <Button
        fullWidth
        variant="contained"
        onClick={formik.handleSubmit}
        disabled={!canManageContent}
        startIcon={<AddIcon />}
      >
        Add content
      </Button>
    </>
  );
  const body = (
    <>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: "wrap",
        }}
      >
        <StyledToggleButtonGroup
          size="small"
          value={importType}
          exclusive
          color="primary"
          sx={{
            color: "white",
          }}
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value="text">Import Copy Text</ToggleButton>
          <ToggleButton value="file">Import File</ToggleButton>
          <ToggleButton value="bulk">Import Bulk</ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
      {formik.values.forms.map((bulkForm, index) => (
        <div key={index}>
          <Stack paddingY={2} spacing={2}>
            {(importType === "bulk" && formik.values.forms.length) > 1 && (
              <Button
                onClick={() => removeBulkForm(index)}
                sx={{ alignSelf: "flex-start", color: "error.main" }}
              >
                Remove
              </Button>
            )}
            <Grid
              container
              direction="row"
              spacing={0.2}
              justifyContent="center"
            >
              <Grid item xs={6} p={1}>
                <InputText
                  name={`forms.${index}.title`}
                  value={bulkForm.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.forms?.[index]?.title &&
                    Boolean(formik.errors.forms?.[index]?.title)
                  }
                  placeholder={"Name your content"}
                />
              </Grid>
              <Grid item xs={6} p={1}>
                <ChannelSelect
                  formik={formik}
                  name={`forms.${index}.channel`}
                  value={bulkForm.channel}
                  error={
                    formik.touched.forms?.[index]?.channel &&
                    Boolean(formik.errors.forms?.[index]?.channel)
                  }
                />
              </Grid>
              <Grid item xs={6} p={1}>
                <FoldersSelect
                  formik={formik}
                  name={`forms.${index}.folder`}
                  value={bulkForm.folder}
                  error={
                    formik.touched.forms?.[index]?.folder &&
                    Boolean(formik.errors.forms?.[index]?.folder)
                  }
                />
              </Grid>
              <Grid item xs={6} p={1}>
                <AudienceSelect
                  formik={formik}
                  name={`forms.${index}.audience`}
                  value={bulkForm.audience}
                  error={
                    formik.touched.forms?.[index]?.audience &&
                    Boolean(formik.errors.forms?.[index]?.audience)
                  }
                />
              </Grid>
              <Grid item xs={6} p={1}>
                {(importType === "file" || importType === "bulk") && (
                  <Paper
                    elevation={0}
                    sx={{
                      borderRadius: "50px",
                      padding: "2px",
                      display: "flex",
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                      flexWrap: "wrap",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <InputFileUpload
                        formik={formik}
                        filedName={`forms.${index}.file`}
                      />
                      <Box sx={{ width: "75%" }}>
                        <Typography>
                          {bulkForm.file ? bulkForm.file.name : ""}
                          {formik.touched.forms?.[index]?.file &&
                            Boolean(formik.errors.forms?.[index]?.file) &&
                            "required"}
                        </Typography>
                      </Box>
                    </Stack>
                  </Paper>
                )}
              </Grid>
            </Grid>
            {importType === "text" && (
              <EditorComponent
                showToolbar={true}
                isSuggestionsMode={false}
                initialValue={bulkForm.contentText}
                formik={formik}
                error={Boolean(formik.errors.forms?.[index]?.contentText)}
                onHandleChange={(value) =>
                  formik.setFieldValue(`forms.${index}.contentText`, value)
                }
                onHandleBlur={() => formik.handleBlur()}
              />
            )}
            {/**
             * 
             * <InputText
                name={`forms.${index}.contentText`}
                value={bulkForm.contentText}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.forms?.[index]?.contentText &&
                  Boolean(formik.errors.forms?.[index]?.contentText)
                }
                multiline={true}
                rows={15}
                placeholder={"Write or paste your content here"}
              />
             */}
          </Stack>
        </div>
      ))}
      {importType === "bulk" && (
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#0048D9 !important",
          }}
          onClick={addNewBulkForm}
        >
          +
        </Button>
      )}
    </>
  );
  return (
    <>
      <LogoLoader
        needBackDrop={true}
        text={"uploading content"}
        open={isLoading || isLoadingUploadContentText}
      />

      <Button
        variant="contained"
        color="primary"
        disabled={!canManageContent}
        onClick={() => !isLoading && setOpen(true)}
        sx={{
          backgroundColor: "#0048D9 !important",
        }}
        startIcon={<AddContentIcon />}
      >
        Add new content
      </Button>
      <Modal
        maxWidth="xl"
        handleClose={handleClose}
        actions={actions}
        title={
          <Stack direction={"row"} alignItems={"center"}>
            <AddContentIcon />
            <Typography variant="h6" ml={1}>
              Add new content
            </Typography>
          </Stack>
        }
        body={open ? body : ""}
        open={open}
      />
      <Modal
        maxWidth="sm"
        open={isError || isErrorUploadContentText}
        actions={
          <Button
            onClick={() => {
              onResetUploadContentFile();
              onResetUploadContentText();
            }}
            variant="contained"
            color="dark"
          >
            Close
          </Button>
        }
        handleClose={() => {
          onResetUploadContentFile();
          onResetUploadContentText();
        }}
        body={
          <ErrorMessageDisplayer
            content={{
              header: "Upload Content could not be executed",
              title: error ? error.error : errorUploadContentText?.error,
              message: error ? error.message : errorUploadContentText?.message,
            }}
          />
        }
      />
    </>
  );
}
