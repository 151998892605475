import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";

const BuilderSelectInputComponent = ({ handleKeyDown, formik, currentQuestion, isMultiple }) => (
  <FormControl fullWidth>
    <Select
    color="dark"
    sx={{
      fontSize:'14px',
      fontWeight:300
    }}
      variant="standard"
      displayEmpty
      labelId="demo-multiple-chip-label"
      id="demo-multiple-chip"
      multiple={isMultiple}
      placeholder={currentQuestion.placeholder}
      name={currentQuestion.inputName}
      value={formik.values[currentQuestion.inputName]}
      onChange={formik.handleChange}
      onKeyDown={handleKeyDown}
      onBlur={formik.handleBlur}
      error={
        formik.touched[currentQuestion.inputName] &&
        Boolean(formik.errors[currentQuestion.inputName])
      }
      renderValue={(selected) => {
        if (isMultiple && selected.length === 0)
          return <em>{currentQuestion.placeholder}</em>;
        return selected || selected?.length > 0 ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {isMultiple ? (
              formik.values[currentQuestion.inputName].map((value) => (
                <Chip key={value} label={value} />
              ))
            ) : (
              <Chip
                key={formik.values[currentQuestion.inputName]}
                label={formik.values[currentQuestion.inputName]}
              />
            )}
          </Box>
        ) : (
          <em>{currentQuestion.placeholder}</em>
        );
      }}
    >
      <MenuItem disabled value="">
        <em>{currentQuestion?.placeholder}</em>
      </MenuItem>
      {currentQuestion &&
        currentQuestion.options &&
        currentQuestion?.options.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
    </Select>
  </FormControl>
);

export default BuilderSelectInputComponent;
