import { useDispatch, useSelector } from "react-redux";
import { contentAPIs } from "../api_";
import {
  resetAdaptContentChannel,
  selectAdaptContentChannelData,
  selectAdaptContentChannelStatus,
  selectAnalyzedContentData,
  selectGetContentMetadataData,
} from "../redux/content";
import { selectCurrentOrganization } from "../redux/organizations";

export default function useContentAdaptChannelHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectAdaptContentChannelStatus(state));
  const data = useSelector((state) => selectAdaptContentChannelData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  const analyzedContentData = useSelector((state) =>
    selectAnalyzedContentData(state)
  );
  const currentBlob = useSelector((state) =>
    selectGetContentMetadataData(state)
  );

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onAdaptContentChannel = (values) => {
    const contentId =
      currentBlob !== null
        ? currentBlob.metadata.id
        : analyzedContentData.content.id;
    dispatch(
      contentAPIs.postAdaptContentChannel({
        values: {
          adaption_type: values.adaption_type,
          id: values.channel.id,
          channelId: values.channel.id,
          contentId: contentId,
        },
        orgId: currentOrg.id,
        contentId: contentId,
        channelId: values.channel.id,
      })
    );
  };

  const onResetAdaptContentChannel = () => {
    dispatch(resetAdaptContentChannel());
  };

  return {
    contentAdapted: data,
    onAdaptContentChannel,
    onResetAdaptContentChannel,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
