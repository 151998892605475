import { useCallback, useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useBrandBuilderQuestionSelectorHandler } from "../../../../../hooks/useBrandBuilderQuestionSelectorHandler";
import { useBrandBuilderModeHandler } from "../../../../../hooks/useBrandBuilderModeHandler";
import useGetBrandsHandler from "../../../../../hooks/useGetBrandsHandler";
import ProductServicesLayout from "./ProductServicesLayout";
import {
  BuilderHeaderComponent,
  CustomStepper,
  LogoLoader,
  QuestionComponent,
} from "../../../../../components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModalSaveGenerateBioConfirmation from "./ModalSaveGenerateBio";
import { BrandIcon } from "../../../../../icons";

export default function BrandBuilder() {
  const [generateBio, setGenerateBio] = useState(false);
  const { activeStep, onStepChange, steps } =
    useBrandBuilderQuestionSelectorHandler();
  const { onSetMode, subNext, onSetBrandBuilderSubNext } =
    useBrandBuilderModeHandler();
  const { onGetBrands } = useGetBrandsHandler();
  const {
    onSubmitForm,
    onResetBuilder,
    onResetBrandBuilderStatus,
    isLoading,
    isError,
    isSuccess,
    builderMode,
    brand,
  } = useBrandBuilderModeHandler();

  const formik = useFormik({
    initialValues: steps
      .map((question) => question)
      .reduce((acc, field) => {
        // initialize form values
        if (brand?.id) {
          acc[field.inputName] = brand.description[field.inputName];
          return acc;
        }
        acc[field.inputName] = field.defaultValue;
        return acc;
      }, {}),
    validationSchema: Yup.object({
      name: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
      products_services: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("Required"),
          description: Yup.string().required("Required"),
        })
      ),
    }),
    onSubmit: async (values) => {
      onSubmitForm(values, brand, generateBio);
    },
  });

  const resetBrandBuilder = useCallback(() => {
    onResetBuilder(null);
    onGetBrands();
    onSetMode("viewer", null);
  }, [onResetBuilder, onGetBrands, onSetMode]);

  useEffect(() => {
    if (isError) {
      setGenerateBio(false);
      onSetBrandBuilderSubNext(false);
      return;
    }
    if (isSuccess && subNext) {
      onSetBrandBuilderSubNext(false);
      onStepChange(activeStep + 1);
      onResetBrandBuilderStatus();
      return;
    }
    if (isSuccess) {
      resetBrandBuilder();
    }
  }, [
    isSuccess,
    subNext,
    isError,
    activeStep,
    resetBrandBuilder,
    onResetBrandBuilderStatus,
    onStepChange,
    onSetBrandBuilderSubNext,
  ]);
  const formKeys = Object.keys(formik.values);
  const stepFormKeys = formKeys.filter((k) => k !== "other");
  const formCompleted =
    (stepFormKeys &&
      formik &&
      stepFormKeys.filter((k) => {
        if (k === "products_services") {
          const checkResult = formik.values[k].filter(
            (subForm) => subForm.name !== "" && subForm.description !== ""
          );
          return checkResult.length === formik.values[k].length;
        }
        return formik.values[k] !== "" && formik.values[k]?.length;
      })?.length / stepFormKeys?.length) * 100;

  return (
    <Box px={4}>
      <LogoLoader
        needBackDrop={true}
        open={isLoading}
        text={"Processing Brand"}
      />
      <Grid container spacing={2} mt={10}>
        {true && (
          <>
            <Grid item md={0} lg={1} xl={1}></Grid>
            <Grid item sm={5} md={4} lg={3} xl={3}></Grid>
            <Grid item xs={2} sm={7} md={8} lg={8} xl={8}>
              <Alert severity="info">
                <AlertTitle>Brand Builder </AlertTitle>
                Note: ATOM AI is available to accelerate the Build process in
                most steps; please keep in mind that ATOM AI usage does consume
                existing credit balance
              </Alert>
            </Grid>
          </>
        )}
        <Grid item xs={1}></Grid>
        <Grid item sm={5} md={4} lg={3} xl={3}>
          <Box sx={{ textAlign: "left" }}>
            <BuilderHeaderComponent
              title={"Brand Builder"}
              icon={<BrandIcon></BrandIcon>}
              progress={formCompleted}
              displayProgress={true}
            ></BuilderHeaderComponent>
            <Box>
              <CustomStepper
                questions={steps}
                activeStep={activeStep}
                formik={formik}
                onStepChange={(i) => onStepChange(i)}
              />
            </Box>
            <Box mt={1.2}>
              <Stack spacing={2} pt={2} mx={5} mb={2}>
                <ModalSaveGenerateBioConfirmation
                  disabled={!formik.isValid}
                  formik={formik}
                  setGenerateBio={setGenerateBio}
                />
                <Button
                  variant="text"
                  size="medium"
                  color="success"
                  onClick={onResetBuilder}
                  startIcon={<ArrowBackIcon></ArrowBackIcon>}
                >
                  Return to Brand
                </Button>
              </Stack>
            </Box>
          </Box>
        </Grid>
        {(builderMode === "create" ||
          builderMode === "edit" ||
          builderMode === "fastlane") && (
          <>
            <Grid item xs={2} sm={4} md={5} lg={6} xl={7}>
              <QuestionComponent
                formik={formik}
                onSubmitToNextQuestion={(e) => {
                  onSetBrandBuilderSubNext(true);
                  formik.handleSubmit();
                }}
                onNextQuestion={(direction) =>
                  onStepChange(
                    direction === "next" ? activeStep + 1 : activeStep - 1
                  )
                }
                rowInputs={<ProductServicesLayout formik={formik} />}
                activeStep={activeStep}
                questions={steps}
                currentQuestion={steps[activeStep]}
              />
            </Grid>
          </>
        )}

        {builderMode === "builder_option" && (
          <Grid item xs={9} spacing={2}>
            <Stack mt={15} alignItems={"center"} spacing={2}>
              <Box width={"67%"}>
                <Stack justifyContent={"center"} spacing={2}>
                  <Typography variant="h6">
                    The intro screen is a quick primer on how to use the persona
                    builder tool. Including a note on which is me suggesting a
                    feature where our AI can suggest answer to them (which could
                    be too much for now but I wanted to drop the suggestion)
                  </Typography>
                  <Button
                    onClick={
                      () => {} //onSetMode("create", null)
                    }
                    variant="contained"
                  >
                    Build a persona from scratch
                  </Button>

                  <Button
                    variant="contained"
                    color="dark"
                    onClick={
                      () => {} //onSetMode("fastlane", null)
                    }
                  >
                    {" "}
                    Start a new persona with fastlane method
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
