export const postSignupSubscriptionPending = (state, action) => {
  state.signupSubscription.status = "pending";
  state.signupSubscription.error = null;
};

export const postSignupSubscriptionFulfilled = (state, action) => {
  state.signupSubscription.status = "fulfilled";
  state.signupSubscription.data = action.payload;
};

export const postSignupSubscriptionRejected = (state, action) => {
  state.signupSubscription.status = "rejected";
  state.signupSubscription.error = action.payload.response.data;
};
