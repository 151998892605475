// GET PERSONAS
export const getPersonaPending = (state, action) => {
  state.persona.status = "pending";
  state.persona.data= null;
};
export const getPersonaFulfilled = (state, action) => {
  state.persona.status = "fulfilled";
  state.persona.data = action.payload.persona
};
export const getPersonaRejected = (state, action) => {
  state.persona.status = "rejected";
  state.persona.error = action.payload
};
