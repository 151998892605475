export const getContentNavigatorPending = (state, action) => {
  state.navigator.status = "pending";
};

export const getContentNavigatorFulfilled = (state, action) => {
  state.navigator.status = "fulfilled";
  state.navigator.data = action.payload;
};

export const getContentNavigatorRejected = (state, action) => {
  state.navigator.status = "rejected";
};
