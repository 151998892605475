import resetCancelSubscription from "./cases/resetCancelSubscription";
import resetGenerateAPIKey from "./cases/resetGenerateAPIKey";
import resetGetAllUsers from "./cases/resetGetAllUsers";
import resetGeAPIToken from "./cases/resetGetAPIKey";
import resetPaymentMethod from "./cases/resetPaymentMethod";
import resetUpdateSubscription from "./cases/resetUpdateSubscription";

const cases = {
  resetGetAllUsers,
  resetPaymentMethod,
  resetUpdateSubscription,
  resetCancelSubscription,
  resetGenerateAPIKey,
  resetGeAPIToken
};

export default cases;
