export const postBrandPending = (state, action) => {
  state.builder.status = "pending";
};

export const postBrandFulfilled = (state, action) => {
  state.builder.status = "fulfilled";
  state.builder.data = {id:action.payload.new_brand.id, description_metadata:action.payload.new_brand.description_metadata};
};

export const postBrandRejected = (state, action) => {
  state.builder.status = "rejected";
  state.builder.error = action.payload.response.data;
};
