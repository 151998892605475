import { Button, Typography } from "@mui/material";
import { useState } from "react"; 
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import { useBrandBuilderModeHandler } from "../../../../../hooks/useBrandBuilderModeHandler";
import { Modal } from "../../../../../components";
import ErrorMessageDisplayer from "../../../../../components/ErrorMessageDisplayer";
import useGetOrgUserProfile from "../../../../../hooks/useGetOrgUserProfileHanlder";

export default function ModalSaveGenerateBioConfirmation({ setGenerateBio,formik, disabled }) {
  const { canManageBrand  } = useGetOrgUserProfile();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { isError, error, onResetBrandBuilderStatus } =
    useBrandBuilderModeHandler();

  const onClose = () => {
    if (isError) {
        onResetBrandBuilderStatus();
      setGenerateBio(false);
    }
    setOpenConfirmation(false);
  };
  return (
    <>
      <Button
        pt={2}
        fullWidth
        disabled={!canManageBrand || disabled}
        //onClick={() => setOpenConfirmation(true)}
        onClick={() => {
          setGenerateBio(true);
          setOpenConfirmation(false);
          formik.handleSubmit()
        }}
        variant="contained"
        color="dark"
        size="medium"
        startIcon={<ModelTrainingIcon></ModelTrainingIcon>}
      >
        save and generate bio
      </Button>
      <Modal
        actions={
          <>
            {isError ? (
              <Button
                pt={2}
                variant="contained"
                color="dark"
                size="medium"
                onClick={onClose}
              >
                Close
              </Button>
            ) : (
              <>
                <Button
                  pt={2}
                  onClick={() => {
                    setGenerateBio(true);
                    setOpenConfirmation(false);
                    formik.handleSubmit()
                  }}
                  variant="contained"
                  color="red"
                  size="medium"
                >
                  Agree
                </Button>
                <Button
                  pt={2}
                  variant="contained"
                  color="dark"
                  size="medium"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </>
            )}
          </>
        }
        maxWidth="sm"
        title={"Brand Builder"}
        open={isError || openConfirmation}
        handleClose={onClose}
        body={
          <>
            {isError ? (
              <ErrorMessageDisplayer
                content={{
                  header: "The brand builder could not be executed",
                  title: error?.error,
                  message: error?.message,
                }}
              />
            ) : (
              <>
                <Typography variant="overline">
                  do you want to processed?
                </Typography>
                <br></br>
                <Typography variant="subtitle1" color={"error"}>
                  **Save and Generate Bio will consume credits.
                </Typography>
              </>
            )}
          </>
        }
      ></Modal>
    </>
  );
}
