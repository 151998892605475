export const getAnalyzeExportPDFPending = (state, action) => {
  state.exportPDFQueue.queue.push({...action.meta.arg, file:null, error:null, status : 'pending'});
};

export const getAnalyzeExportPDFFulfilled = (state, action) => {
  const index = state.exportPDFQueue.queue.findIndex(
    (item) => item.personaId === action.meta.arg.personaId
  );
  if (index !== -1) {
    state.exportPDFQueue.queue[index].status = 'fulfilled';
    state.exportPDFQueue.queue[index].data = state.exportPDFQueue.queue[index].data || {};
    state.exportPDFQueue.queue[index].data.file = action.payload;
  }
};

export const getAnalyzeExportPDFRejected = (state, action) => {
  const index = state.exportPDFQueue.queue.findIndex(
    (item) =>  item.personaId === action.meta.arg.personaId
  );

  if (index !== -1) {
    state.exportPDFQueue.queue[index].status = 'rejected';
    state.exportPDFQueue.queue[index].data = state.exportPDFQueue.queue[index].data || {};
    state.exportPDFQueue.queue[index].data.error = action.payload;
  }
};
