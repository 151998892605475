import { useDispatch, useSelector } from "react-redux";
import {
  resetPersonaBuilderStatus,
  selectPersonaBuilderData,
  selectPersonaBuilderError,
  selectPersonaBuilderPayload,
  selectPersonaBuilderStatus,
  selectPersonaBuilderSubNext,
  selectPersonaMode,
  setMode,
  setPersonaBuilderSubNext,
  setWizzard,
} from "../redux/persona";
import { organizationAPIs } from "../api_";
import useCurrentOrganizationHandler from "./useCurrentOrganizationHandler";

export function usePersonaBuilderModeHandler() {
  const dispatch = useDispatch();
  const { currentOrganization } = useCurrentOrganizationHandler();
  const mode = useSelector((state) => selectPersonaMode(state));
  const persona = useSelector((state) => selectPersonaBuilderData(state));
  const personaPayload = useSelector((state) =>
    selectPersonaBuilderPayload(state)
  );

  const error = useSelector((state) => selectPersonaBuilderError(state));

  const status = useSelector((state) => selectPersonaBuilderStatus(state));
  const subNext = useSelector((state) => selectPersonaBuilderSubNext(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onResetBuilder = (persona) => {
    dispatch(setMode({ mode: "view", persona }));
    dispatch(resetPersonaBuilderStatus());
  };

  const onResetPersonaBuilderStatus = () =>
    dispatch(resetPersonaBuilderStatus());
  const onSetPersonaBuilderSubNext = (val) =>
    dispatch(setPersonaBuilderSubNext(val));

  const onSetMode = (mode, persona) => {
    dispatch(setWizzard({ activeStep: 0, activeSubStep: 0 }));
    dispatch(setMode({ mode, persona }));
  };

  const onSubmitForm = (values, persona, generateBio) => {
    if (!persona.id) {
      dispatch(
        organizationAPIs.postPersona({
          orgId: currentOrganization.id,
          values: {
            savePersonaBioFlag: generateBio,
            builderType:
              mode === "create" || mode === "fastlane" ? "builder" : "import",
            description:
              mode === "create" || mode === "fastlane"
                ? { ...values }
                : values.description,
          },
        })
      );
    }
    if (persona.id) {
      dispatch(
        organizationAPIs.putPersona({
          orgId: currentOrganization.id,
          personaId: persona.id,
          builderType: "builder",
          savePersonaBioFlag: generateBio,
          values,
        })
      );
    }
  };

  return {
    persona: {
      id: persona?.id,
      description: persona?.description_metadata,
    },
    personaPayload,
    onResetBuilder,
    onResetPersonaBuilderStatus,
    onSetPersonaBuilderSubNext,
    onSubmitForm,
    onSetMode,
    subNext,
    builderMode: mode,
    builderOn: mode === "create" || mode === "edit" || mode === "fastlane",
    isUninitialized,
    isLoading,
    isError,
    error,
    isSuccess,
  };
}
