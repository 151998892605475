const DragAndDropIcon = ()=><svg width="116" height="88" viewBox="0 0 116 88" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1354_7755)">
<path d="M28.6095 63.3699C26.6095 55.8299 31.0995 48.0899 38.6395 46.0899C46.1795 44.0899 53.9195 48.5799 55.9195 56.1199" stroke="#BDBDBD" strokeWidth="3.15" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M35.2797 41.71C39.8692 41.71 43.5897 37.9895 43.5897 33.4C43.5897 28.8105 39.8692 25.09 35.2797 25.09C30.6902 25.09 26.9697 28.8105 26.9697 33.4C26.9697 37.9895 30.6902 41.71 35.2797 41.71Z" stroke="#BDBDBD" strokeWidth="3.15" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M50.5006 1.79856L6.53199 13.4648C2.97679 14.4081 0.859429 18.0549 1.80274 21.6101L17.5826 81.0823C18.5259 84.6375 22.1727 86.7548 25.7279 85.8115L69.6965 74.1452C73.2517 73.2019 75.369 69.5552 74.4257 66L58.6459 6.52781C57.7025 2.97261 54.0558 0.855255 50.5006 1.79856Z" stroke="#BDBDBD" strokeWidth="3.15" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M79.4395 33.4H113.419" stroke="#BDBDBD" strokeWidth="3.15" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M103.27 23L113.67 33.4L103.27 43.79" stroke="#BDBDBD" strokeWidth="3.15" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1354_7755">
<rect width="115.24" height="87.61" fill="white"/>
</clipPath>
</defs>
</svg>


export default DragAndDropIcon