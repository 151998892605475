import { useDispatch, useSelector } from "react-redux";
import { resetInviteUser, selectInviteUserData, selectInviteUserStatus } from "../redux/organizations";
import { organizationAPIs } from "../api_";

export default function useInviteUserHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectInviteUserStatus(state));
  const data = useSelector((state) => selectInviteUserData(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending" || status === "idle";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onSubmitForm = (values) => {
    dispatch(organizationAPIs.postInviteUser({ values, orgId:values.organization_id }));
  };

  const onResetInviteUser = ()=>{
    dispatch(resetInviteUser())
  }

  return {
    onSubmitForm,
    onResetInviteUser,
    invite:data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
