import { useDispatch, useSelector } from "react-redux";
import { organizationAPIs } from "../api_";
import { selectCurrentOrganization } from "../redux/organizations";
import {
    removeFromPersonaExportQueue,
  resetGetPersonaExportPDF,
  selectPersonaExportData,
  selectPersonaExportError,
  selectPersonaExportQueue,
  selectPersonaExportStatus,
} from "../redux/persona";

export default function useGetPersonaExportHandler() {
  const dispatch = useDispatch();
  const data = useSelector((state) => selectPersonaExportData(state));
  const error = useSelector((state) => selectPersonaExportError(state));
  const status = useSelector((state) => selectPersonaExportStatus(state));

  const queue = useSelector((state)=>selectPersonaExportQueue(state))
  const currentOrganization = useSelector((state) =>
    selectCurrentOrganization(state)
  );

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onGetPersonaExportPDF = (personaId) => {
    dispatch(organizationAPIs.getPersonaExportPDF({
        orgId: currentOrganization.id,
        personaId,
      }))
    
  };
  const onResetGetPersonaExportPDF = () => dispatch(resetGetPersonaExportPDF());
  const removeFromQueue = (idx) => dispatch(removeFromPersonaExportQueue(idx));
 
  return {
    data,
    queue,
    error,
    onGetPersonaExportPDF,
    removeFromQueue,
    onResetGetPersonaExportPDF,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
