import {
  selectOrganizationUsersData,
  selectOrganizationUsersStatus,
} from "../redux/organizations";
import { useDispatch, useSelector } from "react-redux";
import { organizationAPIs } from "../api_";

export default function useOrganizationUsersHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectOrganizationUsersStatus(state));
  const data = useSelector((state) => selectOrganizationUsersData(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onGetUsers = ()=>{
    dispatch(
      organizationAPIs.getUsers()
    );
  }

  return {
    users: data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onGetUsers
  };
}
