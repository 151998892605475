const personaBuilderQuestionsSchema = [
  {
    isFastLane: true,
    category: "Job details",
    label: "Job title",
    inputType:"text",
    inputName: "jobTitle",
    defaultValue: "",
    placeholder: "e.g., Sales representative, Digital Marketing Specialist",
    question: "What is their job title?",
    hasAIOption:true,
    aiQuestion: "Produce one job title suitable for a professional setting, considering the persona's profile \
Aim for creativity and relevance to a wide range of industries. \
Refer to the provided examples for guidance on the expected level of specificity and relevance. \
Your response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
Follow this JSON format for your response: \
{ \
'output' : 'your-response-here', \
}",
    promptIdentifier: "personaJobTitle",
  },
  {
    isFastLane: true,
    category: "Job details",
    label: "Employment status",
    inputType:"dropdown",
    inputName: "employmentStatus",
    defaultValue: "",
    placeholder: "Choose an employment status",
    question: "What is the persona's employment status?",
    hasAIOption:false,
    aiQuestion: "",
    options: [
      "Full-time",
      "Part-time",
      "Contractor",
      "Freelancer",
      "Intern",
      "Volunteer",
      "Seasonal",
    ],
  },
  {
    isFastLane: false,
    category: "Job details",
    label: "Work environment",
    inputType:"dropdown",
    inputName: "workEnvironment",
    defaultValue: "",
    placeholder: "Choose a work environment",
    question: "What is the persona's work environment?",
    hasAIOption:false,
    aiQuestion: "",
    options: ["On-site", "Remote", "Hybrid"],
  },
  {
    isFastLane: false,
    category: "Job details",
    label: "Organization size",
    inputType:"dropdown",
    inputName: "organizationSize",
    defaultValue: "",
    placeholder: "Choose a range",
    question: "What is the size of their organization?",
    hasAIOption:false,
    aiQuestion: "",
    options: [
      "1-10",
      "11-50",
      "51-200",
      "201-500",
      "501-1000",
      "1001-5000",
      "5001-10000",
      "10001+",
    ],
  },
  {
    isFastLane: false,
    category: "Job details",
    label: "Job measurement",
    inputType:"text",
    inputName: "jobMeasurement",
    defaultValue: "",
    placeholder:
      "e.g., Number of products sold, Number of clients signed, Projects completed",
    question: "What metrics are used to measure their job performance?",
    hasAIOption:true,
    aiQuestion:
      "Provide two key performance indicators (KPI) or job evaluation methods relevant for this persona, considering the persona's profile. \
Refer to the provided examples for guidance on the expected level of specificity and relevance. \
Your response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
Your response must align with this JSON format: \
{ \
'output': { \
'KPI1' : 'your-response-here', \
'KPI2' : 'your-response-here', \
} \
}",
  promptIdentifier: "personaJobMeasurement",
  },
  /*{
    isFastLane: false,
    category: "Job details",
    label: "Reporting to ***",
    inputType:"dropdown",
    inputName: "reportingTo***",
    placeholder: "Select the immediate superior or reporting manager",
    question: "Who is the direct superior or manager to whom they report?",
    hasAIOption:true,
    aiQuestion: "",
    options: [
      "Team Leader",
      "Department Head",
      "Project Manager",
      "Project Owner",
      "CEO",
      "Other - Please Specify",
    ],
  },*/
  {
    isFastLane: false,
    category: "Job details",
    label: "Decision maker",
    inputType:"dropdown",
    inputName: "decisionMaker",
    defaultValue: "",
    placeholder: "Choose one",
    question: "Are they a decision maker?",
    hasAIOption:false,
    aiQuestion: "",
    options: ["Yes", "No"],
  },
  {
    isFastLane: false,
    category: "Job details",
    label: "Goals & objectives",
    inputType:"text",
    inputName: "goals&Objectives",
    defaultValue: "",
    placeholder:
      "e.g., Increase sales by 20%, Improve customer satisfaction by 15%, Launch 3 new products",
    question: "What are the primary goals or objectives for this role?",
    hasAIOption:true,
    aiQuestion:
      "Provide two specific goals and objectives that are typically expected for this persona, considering the persona's profile. \
Refer to the provided examples for guidance on the expected level of specificity and relevance. \
Your response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
Follow this JSON format for your response: \
{ \
'output': { \
'goal1': 'your-response-here', \
'goal2': 'your-response-here', \
} \
}",
    promptIdentifier: "personaGoals",
  },
  {
    isFastLane: false,
    category: "Job details",
    label: "Biggest challenges",
    inputType:"text",
    inputName: "biggestChallenges",
    defaultValue: "",
    placeholder:
      "e.g., Navigating complex client relationships, Effective problem-solving under pressure, Adapting to rapid technological changes",
    question: "What are the biggest challenges associated with this role?",
    hasAIOption:true,
    aiQuestion:
      "Provide three major challenges faced by this persona in their job, considering the persona's profile. \
Refer to the provided examples for guidance on the expected level of specificity and relevance. \
Your response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
Follow this JSON format for your response: \
{ \
'output': { \
'challenge1': 'your-response-here', \
'challenge2': 'your-response-here', \
'challenge3': 'your-response-here', \
} \
}",
    promptIdentifier: "personaBiggestChallenges",
  },
  {
    isFastLane: false,
    category: "Job details",
    label: "Responsibilities",
    inputType:"text",
    inputName: "responsibilities",
    defaultValue: "",
    placeholder:
      "e.g., Managing a team of 10, Creating weekly content for social media, Conducting quarterly performance reviews",
    question: "What are the primary responsibilities of this role?",
    hasAIOption:true,
    aiQuestion:
      "Provide the three main responsibilities of this persona in their job, considering the persona's profile. \
Refer to the provided examples for guidance on the expected level of specificity and relevance. \
Your response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
Follow this JSON format for your response: \
{ \
'output': { \
'responsibility1': 'your-response-here', \
'responsibility2': 'your-response-here', \
'responsibility3': 'your-response-here', \
} \
}",
    promptIdentifier: "personaResponsibilities",
  },
  {
    isFastLane: false,
    category: "Job details",
    label: "Job tools",
    inputType:"text",
    inputName: "JobTools",
    defaultValue: "",
    placeholder:
      "e.g., Microsoft Office Suite for administrative tasks, Adobe Creative Suite for design",
    question: "What tools are essential for performing this job?",
    hasAIOption:true,
    aiQuestion:
      "Provide two main tools that would be essential for this persona's daily activities, considering the persona's profile. \
Please be specific in your response, focusing on the tools' primary purpose or the tasks they facilitate.\
Refer to the provided examples for guidance on the expected level of specificity and relevance. \
These examples aim to demonstrate the level of specificity and relevance expected in your answer, reflecting \
the tools' applicability to the persona's daily professional tasks. \
Your response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
Follow this JSON format for your response: \
{ \
'output': { \
'job-tool1': 'your-response-here', \
'job-tool2': 'your-response-here', \
} \
}",
    promptIdentifier: "personaJobTools",
  },
  {
    isFastLane: false,
    category: "Job details",
    label: "Communication preference",
    inputType:"multiple",
    inputName: "communicationPreference",
    defaultValue: [],
    placeholder: "Select preferred methods",
    question: "What are the preferred communication methods for this role?",
    hasAIOption:false,
    aiQuestion: "",
    options: [
      "Email",
      "Phone Call",
      "Text Message",
      "Instant Messaging",
      "Video Conference",
      "Social Media",
      "In-person Meetings",
      "Written Reports",
    ],
  },
  {
    isFastLane: false,
    category: "Job details",
    label: "Information collection",
    inputType:"text",
    inputName: "informationCollection",
    defaultValue: "",
    placeholder:
      "e.g., Industry databases, Networking events, Professional journals",
    question:
      "What methods do they use to collect information relevant to their job?",
    hasAIOption:true,
      aiQuestion:
      "Provide two best methods and sources this persona should rely on for gathering \
information and conducting research relevant to their job, considering the persona's profile. \
Include the method, a dash and then a brief 1 sentence description on how they would use it like \
Refer to the provided examples for guidance on the expected level of specificity and relevance. \
Your response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
Follow this JSON format for your response: \
{ \
'output': { \
'method1': 'your-response-here', \
'method2': 'your-response-here', \
} \
}",
    promptIdentifier: "personaInformationCollection",
  },
  {
    isFastLane: false,
    category: "Job details",
    label: "Quote",
    inputType:"text",
    inputName: "quote",
    defaultValue: "",
    placeholder:
      "Imagine a phrase or statement reflective of their work ethic, attitude, or professional mantra",
    question:
      "What is a quote that encapsulates their approach or attitude towards their work?",
    hasAIOption:true,
      aiQuestion:
      "Generate a quote that captures the essence of this persona's professional demeanor or philosophy, considering the persona's profile. \
Refer to the provided examples for guidance on the expected level of specificity and relevance. \
These examples are meant to inspire a response that reflects the unique professional ethos or attitude of the persona, \
framed in a memorable and impactful way. \
Your response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
Follow this JSON format for your response: \
{ \
'output': 'your-response-here', \
}",
    promptIdentifier: "personaQuote",
  },
  {
    isFastLane: false,
    category: "Job details",
    label: "Pain points",
    inputType:"text",
    inputName: "painPoints",
    defaultValue: "",
    placeholder:
      "Managing tight deadlines with limited resources, Navigating interdepartmental communication barriers",
    question:
      "What are the most significant pain points they encounter in their role?",
    hasAIOption:true,
      aiQuestion:
      "Provide two possible pain points based on the persona's profile, focusing on common challenges, \
frustrations, or areas needing improvement. \
Refer to the provided examples for guidance on the expected level of specificity and relevance. \
Your response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
Follow this JSON format for your response: \
{ \
'output': { \
'pain_point1': 'your-response-here', \
'pain_point2': 'your-response-here', \
} \
}",
    promptIdentifier: "personaPainPoints",
  },
  {
    isFastLane: false,
    category: "Job details",
    label: "Buying factors",
    inputType:"text",
    inputName: "buyingFactors",
    defaultValue: "",
    placeholder: "Cost-effectiveness, Product reliability, Vendor reputation",
    question: "What factors influence their purchasing decisions?",
    hasAIOption:true,
    aiQuestion:
      "Provide three possible buying factors based on the job details, focusing on priorities, \
must-have features, or criteria that guide their selection process, considering the persona's profile. \
Refer to the provided examples for guidance on the expected level of specificity and relevance. \
Your response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
Follow this JSON format for your response: \
{ \
'output': { \
'factor1': 'your-response-here', \
'factor2': 'your-response-here', \
'factor3': 'your-response-here', \
} \
}",
    promptIdentifier: "personaBuyingFactors",
  },
  {
    isFastLane: true,
    category: "Personal details",
    label: "Gender",
    inputType:"dropdown",
    inputName: "gender",
    defaultValue: "",
    placeholder: "Select the persona gender",
    question:"Gender",
    hasAIOption:false,
    aiQuestion: "",
    type: "dropdown",
    options: [
      "Female",
      "Male",
    ],
  },
  {
    isFastLane: true,
    category: "Personal details",
    label: "Location",
    inputType:"text",
    inputName: "location",
    defaultValue: "",
    placeholder: "e.g., Springfield, California, USA",
    question: "Where are they located?",
    hasAIOption:true,
    aiQuestion:
      "Generate a suitable location within the United States. \
Format your response as follows: 'City, State, USA'. \
Ensure that you spell out all names fully and do not use any acronyms. \
Refer to the provided examples for guidance on the expected level of specificity and relevance. \
Your response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
Follow this JSON format for your response: \
{ \
'output': { \
'location': 'City, State, USA', \
} \
}",
    promptIdentifier: "personaLocation",
  },
  {
    isFastLane: true,
    category: "Personal details",
    label: "Age range",
    inputType:"dropdown",
    inputName: "ageRange",
    defaultValue: "",
    placeholder: "Choose an age range",
    question: "Age range",
    hasAIOption:false,
    aiQuestion: "",
    options: ["25 or under", "26-35", "36-45", "46-55", "56 or over"],
  },
  {
    isFastLane: false,
    category: "Personal details",
    label: "Education level",
    inputType:"dropdown",
    inputName: "educationLevel",
    defaultValue: "",
    placeholder: "Select the highest completed education level",
    question:
      "What is the highest degree or level of education the persona has attained?",
    hasAIOption:false,
    aiQuestion: "",
    options: [
      "Did not complete high school",
      "High school degree or equivalent",
      "Some college, no degree",
      "Trade/Technical/vocational training",
      "Associate's degree",
      "Bachelor's degree",
      "Master's degree",
      "Professional degree (e.g., MD, JD)",
      "Doctorate (e.g., PhD, EdD)",
    ],
  },
  {
    isFastLane: false,
    category: "Personal details",
    label: "Personality",
    inputType:"text",
    inputName: "personality",
    defaultValue: "",
    placeholder:
      "e.g., Detail-Oriented Analyst, Charismatic Leader, Thoughtful Communicator",
    question: "How would you describe their personality?",
    hasAIOption:true,
    aiQuestion:
      "Create three distinct personalities for this persona, considering the persona's profile. \
Refer to the provided examples for guidance on the expected level of specificity and relevance. \
Your response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
Follow this JSON format for your response: \
{ \
'output': { \
'personality1': 'your-response-here', \
'personality2': 'your-response-here', \
'personality3': 'your-response-here', \
} \
}",
    promptIdentifier: "personaPersonality",
  },
  {
    isFastLane: true,
    category: "Personal details",
    label: "Free time",
    inputType:"text",
    inputName: "freeTime",
    defaultValue: "",
    placeholder: "e.g., Gardening, Photography, Baking, Coding",
    question: "What do they like to do when they're not working?",
    hasAIOption:true,
    aiQuestion:
      "Generate a list of three hobbies for this persona. \
Ensure the hobbies are diverse and could appeal to a wide range of interests, providing a unique set of hobbies. \
Refer to the provided examples for guidance on the expected level of specificity and relevance. \
Your response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
Follow this JSON format for your response: \
{ \
'output': { \
'hobby1': 'your-response-here', \
'hobby2': 'your-response-here', \
'hobby3': 'your-response-here', \
} \
}",
    promptIdentifier: "personaHobbies",
  },
  {
    isFastLane: true,
    category: "Personal details",
    label: "Name",
    inputType:"text",
    inputName: "name",
    defaultValue: "",
    placeholder: "e.g., Amelia Warren, Maxwell Harrison, Sophia Carter",
    question: "Name your persona",
    hasAIOption:true,
    aiQuestion:
      "Generate a business-related persona full name without reusing names from the provided examples. \
Avoid adding any additional commentary or starting the name with 'Persona full name:' or 'Name:' or any prefixes like these ones.\
Refer to the provided examples for guidance on the expected level of specificity and relevance. \
Your response must be formatted as a structured JSON object, with no additional text before or after the JSON. \
Follow this JSON format for your response: \
{ \
'output': 'your-response-here', \
}",
    promptIdentifier: "personaName",
  },
  {
    isFastLane: false,
    category: "Other details",
    label: "Other",
    inputType:"textarea",
    inputName: "other",
    hasAIOption:false,
    question: "Add more detail to your persona not covered in builder questions",
    defaultValue: "",
    subSteps: [],
  },
  
  {
    isFastLane: true,
    category: "Other details",
    label: "Avatar",
    hasAIOption:false,
    inputType:"file",
    inputName:"avatarSource",
    question: "Choose an avatar for your persona",
    defaultValue: "",
  },
];

export default personaBuilderQuestionsSchema;
