import { Divider, Typography } from "@mui/material";

export default function TabSignUpHeader({title}) {
  return (
    <>
      <br />
      <Divider
        textAlign="left"
        sx={{
          fontWeight: 200,
        }}
      >
        <Typography variant="h5" fontWeight={500}>
          {title}
        </Typography>
      </Divider>
      <br />
    </>
  );
}
