import {
  Typography,
  Stack,
  Box,
  Paper,
  Divider,
  useTheme,
  Chip,
} from "@mui/material";
import { useLoginHandler } from "../../../../hooks/useLoginHandler";
import ModalUpdatePaymentMethod from "./ModalUpdatePaymentMethod";
import ModalUpdateSubscription from "./ModalUpdateSubscription";
import SecurityIcon from "@mui/icons-material/Security";
import { BootstrapTooltip } from "../../../../components";
import useDateFormat from "../../../../hooks/useDateFormat";

export default function SubscriptionInfoTab() {
  const { currentProfile } = useLoginHandler();
  const theme = useTheme();
  const { getFormattedDate } = useDateFormat();

  return (
    <Stack spacing={2}>
      {currentProfile.is_accounts_owner && (
        <Paper
          sx={{
            width: "100%",
            textAlign: "initial",
          }}
          variant="elevation"
          elevation={0}
        >
          <Box p={1.25}>
            <Stack
              width={"100%"}
              direction={"column"}
              justifyContent={"space-between"}
              spacing={1}
            >
              <Typography variant="h5" fontWeight={"100"}>
                Subscription Details
              </Typography>

              <Box>
                <Stack
                  width={"100px"}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  spacing={1}
                >
                  <Typography variant="body2">Subscription</Typography>
                  <BootstrapTooltip
                    title={
                      <small>
                        STATUS:{" "}
                        {currentProfile.subscription_status.toUpperCase()}
                      </small>
                    }
                  >
                    <Box
                      className="pulse"
                      sx={{
                        background: `${
                          currentProfile.subscription_status === "active"
                            ? theme.palette.green.main
                            : theme.palette.red.main
                        }`,
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        cursor: "pointer",
                        animation: `${
                          currentProfile.subscription_status === "active"
                            ? "pulseGreen"
                            : "pulseRed"
                        }  2s infinite`,
                      }}
                    ></Box>
                  </BootstrapTooltip>
                </Stack>
                <Box>
                  <Chip
                    size="small"
                    label={`Administrator Account Subscription ${
                      currentProfile.owner_current_account
                        ? "Me"
                        : currentProfile.owner_name +
                          " " +
                          currentProfile.owner_last_name
                    }`}
                    onDelete={() => {}}
                    deleteIcon={<SecurityIcon />}
                    variant="outlined"
                    color="success"
                  />
                </Box>
              </Box>
              <Typography variant="body1" fontWeight={"200"}>
                {`${currentProfile.subscription_data.stripe_product.name}  `}/{" "}
                <small>
                  <b>
                    {
                      currentProfile.subscription_data.stripe_product.metadata
                        .credits
                    }
                  </b>{" "}
                  credits
                </small>
              </Typography>
              <Typography variant="body2" fontWeight={"200"}>
                {`${currentProfile.subscription_data.stripe_product.description}`}
              </Typography>
              <Typography variant="caption">
                <b>Start Date: </b>
                {`${getFormattedDate(
                  currentProfile.subscription_start_date
                )}`}{" "}
                <b>End Date:</b>{" "}
                {`${getFormattedDate(currentProfile.subscription_end_date)}`}
              </Typography>

              <Box>
                {currentProfile.owner_current_account && (
                  <ModalUpdateSubscription />
                )}
              </Box>
            </Stack>
          </Box>
        </Paper>
      )}
      <Divider></Divider>
      {(currentProfile.is_accounts_owner
        &&
        currentProfile.subscription_data.stripe_product.id !==
        process.env.REACT_APP_STRIPE_PRODUCT_FREE) &&
        <Paper
          sx={{
            width: "100%",
            textAlign: "initial",
          }}
          variant="elevation"
          elevation={0}
        >
          <Box p={1}>
            <Typography variant="h5" fontWeight={"100"}>
              Payment Details
            </Typography>
            <br />
            <Typography variant="body2">Card Number:</Typography>
            <Typography variant="body2" fontWeight={"200"}>
              <Typography variant="overline">
                {currentProfile.subscription_data.stripe_payment.display_brand}:
              </Typography>
              <Typography variant="caption">
                {currentProfile.subscription_data.stripe_payment.cardNum}
              </Typography>
            </Typography>
            <br />
            <Typography variant="body2">Expiration Date:</Typography>
            <Typography variant="body2" fontWeight={"200"}>
              <Typography variant="caption">
                {currentProfile.subscription_data.stripe_payment.exp_month} /{" "}
                {currentProfile.subscription_data.stripe_payment.exp_year}
              </Typography>
            </Typography>
            {currentProfile.owner_current_account && (
            <ModalUpdatePaymentMethod />)}
          </Box>
        </Paper>
      }
    </Stack>
  );
}
