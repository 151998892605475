import { useDispatch, useSelector } from "react-redux";
import { organizationAPIs } from "../api_";
import { selectCurrentOrganization } from "../redux/organizations";
import {
  resetStatusUploadContentText,
  selectUploadContentTextData,
  selectUploadContentTextError,
  selectUploadContentTextStatus,
} from "../redux/content";

export default function useUploadContentTextHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectUploadContentTextStatus(state));
  const data = useSelector((state) => selectUploadContentTextData(state));
  const error = useSelector((state) => selectUploadContentTextError(state));

  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onUploadContentText = (values) => {
    dispatch(
      organizationAPIs.postContentText({
        values,
        id: currentOrg.id,
      })
    );
  };

  const onResetUploadContentText = () => {
    dispatch(resetStatusUploadContentText());
  };

  return {
    data,
    error,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onUploadContentText,
    onResetUploadContentText,
  };
}
