import routes from "../routes";
import { buildUrl } from "../helpers/url";
import { getHeaders } from "../helpers/headers";
import { handleResponse } from "../helpers/handleResponse";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../axios";

const getBrands = createAsyncThunk(
  "brand/getBrands",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.organization.brand.getAll(data.id)),
        getHeaders()
      );
      return handleResponse(response, rejectWithValue);
    } catch (error) {
      throw error;
    }
  }
);

const postBrand = createAsyncThunk(
    "brand/postBrand",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(
          buildUrl(routes.organization.brand.rootPath(data.orgId)),
          { ...data.values },
          getHeaders()
        );
        return handleResponse(response);
      } catch (error) {
        console.error(error);
        return rejectWithValue(error);
      }
    }
  );

  const putBrand = createAsyncThunk(
    "brand/putBrand",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.put(
          buildUrl(routes.organization.brand.update(data.orgId, data.id)),
          { ...data.values },
          getHeaders()
        );
        return handleResponse(response);
      } catch (error) {
        console.error(error);
        return rejectWithValue(error);
      }
    }
  );

  const deleteBrand = createAsyncThunk(
    "brand/deleteBrand",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.delete(
          buildUrl(routes.organization.brand.delete(data.orgId, data.id)),
          getHeaders()
        );
        return handleResponse(response);
      } catch (error) {
        console.error(error);
        return rejectWithValue(error);
      }
    }
  );

const brand = {
    getBrands,
    postBrand,
    putBrand,
    deleteBrand
  };
  
  export default brand;