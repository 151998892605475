export const postGenerateAPIKeyPending = (state, action) => {
  state.generateAPIKey.status = "pending";
  state.generateAPIKey.error = null;
};

export const postGenerateAPIKeyFulfilled = (state, action) => {
  state.generateAPIKey.status = "fulfilled";
  state.generateAPIKey.data = action.payload;
};

export const postGenerateAPIKeyRejected = (state, action) => {
  state.generateAPIKey.status = "rejected";
  state.generateAPIKey.error = action.payload.response.data;
};
