import { Box, Button, Stack } from "@mui/material";
import { useResetPasswordHandler } from "../../../../hooks/useResetPasswordHandler";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText, LogoLoader } from "../../../../components";

export default function ResetPassword() {
  const { onResetPassword, isLoading, isSuccess, data, error } =
    useResetPasswordHandler();
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      onResetPassword(values);
    },
  });

  return (
    <Box>
      <LogoLoader open={isLoading}></LogoLoader>
      <Stack
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        spacing={2}
      >
        <InputText
          placeholder="email"
          name={"email"}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={isSuccess ? data.message : error?.error}
        />

        <Button color="dark" variant="contained" onClick={formik.handleSubmit}>
          Reset Password
        </Button>
      </Stack>
    </Box>
  );
}
