import useContetCurrentVersionHanlder from "../../../../../../hooks/useContentCurrentVersionHandler";
import useGetAnalyzedContent from "../../../../../../hooks/useGetAnalyzedContent";
import { BootstrapTooltip } from "../../../../../../components";
import { Box, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RestoreIcon from "@mui/icons-material/Restore";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import useCurrentOrganizationHandler from "../../../../../../hooks/useCurrentOrganizationHandler";
import { useNavigate } from "react-router-dom";

export default function VersionActionButtons({
  params,
  setOpenControlsVersion,
  openControlsVersion,
}) {
  const { onGetWatchVersion } = useContetCurrentVersionHanlder();
  const navigate = useNavigate();
  const { analyzedContent, onGetAnalyzedContent } = useGetAnalyzedContent();
  const { currentOrganization } = useCurrentOrganizationHandler();
  const watchingVersion = (
    <BootstrapTooltip
      title={`Watching ${
        openControlsVersion === 0
          ? `First Version`
          : `Version ${analyzedContent.content.version_number + 1}`
      }`}
    >
      <IconButton aria-label="delete" size="small" color="success">
        <VisibilityIcon fontSize="inherit" />
      </IconButton>
    </BootstrapTooltip>
  );

  const versionTrigger =
    analyzedContent.content.version_number === params.version ? (
      watchingVersion
    ) : (
      <BootstrapTooltip
        title={`Watch ${
          params.version === 0 ? `first version` : `version ${params.version}`
        }`}
      >
        <IconButton
          onClick={() => setOpenControlsVersion(params.version)}
          aria-label="delete"
          size="small"
        >
          <RestoreIcon color="success" fontSize="inherit" />
        </IconButton>
      </BootstrapTooltip>
    );

  const actionButtons = (
    <>
      <IconButton
        onClick={() => {
          navigate(
            `/organization/${currentOrganization.id}/content/${analyzedContent.content.content_id}/version/${params.version}/analyze`
          );
          onGetWatchVersion(
            analyzedContent.content.content_id,
            params.version,
            currentOrganization.id
          );
        }}
        color="success"
        aria-label="delete"
        size="small"
      >
        <CheckCircleIcon fontSize="inherit" />
      </IconButton>
      <IconButton
        onClick={() => {
          setOpenControlsVersion(null);
        }}
        aria-label="delete"
        color="error"
        size="small"
      >
        <CancelIcon fontSize="inherit" />
      </IconButton>
    </>
  );

  const latestVersion = (
    <BootstrapTooltip title={"Latest Version"}>
      <IconButton
        color="success"
        size="small"
        onClick={() => {
          navigate(
            `/organization/${currentOrganization.id}/content/${analyzedContent.content.content_id}/version/latest/analyze`
          );
          onGetAnalyzedContent(analyzedContent.content.content_id)
          setOpenControlsVersion(null);
        }}
      >
        <FiberManualRecordIcon color="success" fontSize="small" />
      </IconButton>
    </BootstrapTooltip>
  );
  return !params.is_current ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {openControlsVersion !== params.version && versionTrigger}
      {openControlsVersion === params.version &&
        (analyzedContent.content.version_number === openControlsVersion
          ? watchingVersion
          : actionButtons)}
    </Box>
  ) : (
    latestVersion
  );
}
