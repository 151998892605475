import {
  Stack,
  Box,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import ChannelSelect from "../ChannelSelect";
export default function AdaptContentForm({data, formikGenerateAdaptContent}) {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Box>
          <Typography variant="caption" ml={1}>
            SELECTED CONTENT TITLE:
          </Typography>
        </Box>
        <Typography variant="h5" ml={1}>
          {data?.metadata?.title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={2} mb={4} alignItems={"center"} direction={"row"}>
          <Box>
            <Typography variant="h3">1</Typography>
          </Box>
          <Box>
            <Box>
              <Typography variant="caption" ml={1}>
                SELECTED CONTENT TITLE:
              </Typography>
            </Box>
            <Box>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="adaption_type"
                onChange={formikGenerateAdaptContent.handleChange}
                onBlur={formikGenerateAdaptContent.handleBlur}
                value={formikGenerateAdaptContent.values.adaption_type}
              >
                <FormControlLabel
                  value="supporting"
                  control={
                    <Radio
                      sx={{
                        color: "white",
                      }}
                      color="success"
                    />
                  }
                  label="Supporting"
                />
                <FormControlLabel
                  value="standalone"
                  control={
                    <Radio
                      sx={{
                        color: "white",
                      }}
                      color="success"
                    />
                  }
                  label="Standalone"
                />
              </RadioGroup>
            </Box>
          </Box>
        </Stack>
        <Stack spacing={2} alignItems={"center"} direction={"row"}>
          <Box>
            <Typography variant="h3">2</Typography>
          </Box>
          <Box width="100%">
            <ChannelSelect
              formik={formikGenerateAdaptContent}
              name={`channel`}
              value={formikGenerateAdaptContent.values.channel}
              error={
                formikGenerateAdaptContent.touched.channel &&
                Boolean(formikGenerateAdaptContent.errors.channel)
              }
            ></ChannelSelect>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}
