export const getAllFoldersPending = (state, action) => {
  state.allFolders.status = "pending";
};

export const getAllFoldersFulfilled = (state, action) => {
  state.allFolders.status = "fulfilled";
  state.allFolders.data = action.payload;
};

export const getAllFoldersRejected = (state, action) => {
  state.allFolders.status = "rejected";
  state.allFolders.error = action.payload;
};
