import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./extraReducers";
import reducers from "./reducers";

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    allUsers: {
      data: [],
      status: "idle",
    },
    paymentMethod: {
      data: null,
      error: null,
      status: "idle",
    },
    updateSubscription: {
      data: null,
      error: null,
      status: "idle",
    },
    cancelSubscription: {
      data: null,
      error: null,
      status: "idle",
    },
    generateAPIKey: {
      data: null,
      error: null,
      status: "idle",
    },
    getAPIKey: {
      data: null,
      error: null,
      status: "idle",
    },
  },
  reducers,
  extraReducers,
});

export default adminSlice;
