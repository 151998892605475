import * as React from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
} from "@mui/material";

import { usePersonaBuilderQuestionSelectorHandler } from "../../../../../../hooks/usePersonaBuilderQuestionSelectorHandler";
import { usePersonaBuilderModeHandler } from "../../../../../../hooks/usePersonaBuilderModeHandler";
import { CustomStepper } from "../../../../../../components";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
function StepIcon(props) {
  const { active } = props;
  return <>{active ? <KeyboardArrowDownIcon/>: <KeyboardArrowRightIcon/>}</>;
}

export default function Steps({ children, formik, onJumpQuestion }) {
  const {
    stepsGrouped,
    activeStep,
    activeSubStep,
    onStepChange,
    onSubStepChange,
  } = usePersonaBuilderQuestionSelectorHandler();
  const { builderMode } = usePersonaBuilderModeHandler();
  return (
    <Box 
    sx={{
      //background: '#f0f0f0',
      //borderRadius: '10px',
      //padding: '3px',
      height: '500px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}
    >
      <Stepper
        activeStep={builderMode === "builder_option" ? -1 : activeStep}
         
        connector={
          <div
            classes={{
              line: { border: "red" },
            }}
          />
        }
        orientation="vertical"
      >
        {stepsGrouped.map((step, index) => (
          <Step key={step.label} completed={false}>
            <StepLabel
              onClick={() => {
                onJumpQuestion()
                onStepChange(index);
              }}
              StepIconComponent={StepIcon}
            >
              
              <Typography variant="h6" fontWeight={500}>{step.label}</Typography>
              
            </StepLabel>
            <StepContent sx={{ border: "red" }}>
              <CustomStepper
                formik={formik}
                activeStep={activeSubStep}
                questions={stepsGrouped[activeStep].questions}
                onStepChange={(i) => {
                  onJumpQuestion()
                  onSubStepChange(i)}}
              />
            </StepContent>
          </Step>
        ))}
      </Stepper>
        {children}
    </Box>
  );
}
