import brandSlice from "./slice"

// SELECTORS

export const selectBrandsData = (state) => state?.brand?.brands?.data
export const selectBrandsStatus = (state) => state?.brand?.brands?.status

export const selectDeleteBrandData = (state) => state?.brand?.delete?.data
export const selectDeleteBrandStatus = (state) => state?.brand?.delete?.status

export const selectBrandBuilderMode = (state) => state?.brand?.mode
export const selectBrandBuilderData = (state) => state?.brand?.builder?.data
export const selectBrandBuilderError = (state) => state?.brand?.builder?.error
export const selectBrandBuilderWizzard = (state) => state?.brand?.builder?.wizzard
export const selectBrandBuilderStatus = (state) => state?.brand?.builder?.status
export const selectBrandBuilderSubNext = (state) => state?.brand?.builder?.submitNext

// ACTIONS
export const { 
    setWizzard,
    setMode,
    resetBrandBuilderStatus,
    setBrandBuilderSubNext,
    resetDeleteBrand
} = brandSlice.actions;

// REDUCER
export default brandSlice.reducer
