// GET PERSONAS
export const getPersonasPending = (state, action) => {
  state.list.status = "pending";
  state.list.data= [];
};
export const getPersonasFulfilled = (state, action) => {
  state.list.status = "fulfilled";
  state.list.data = action.payload.personas;
};
export const getPersonasRejected = (state, action) => {
  state.list.status = "rejected";
};
