import Modal from "../Modal";
import { useState, useEffect, useCallback } from "react";
import { Typography, Button, IconButton, useTheme } from "@mui/material";
import useGetOrgUserProfile from "../../hooks/useGetOrgUserProfileHanlder";
import DeleteIcon from "@mui/icons-material/Delete";
import useDisableOrganizationHandler from "../../hooks/useDisableOrganizationHandler";
import LogoLoader from "../LogoLoader";
import useOrganizationListHandler from "../../hooks/useOrganizationListHandler";

export default function ModalDisableOrganization({ organization }) {
  const { canManageOrganizations } = useGetOrgUserProfile();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const {
    onDisableOrganization,
    onResetDisableOrganization,
    isSuccess,
    isLoading,
  } = useDisableOrganizationHandler();
  const { onRefreshOrgList } = useOrganizationListHandler();

  const onClickDisable = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    if (!isLoading) {
      setOpen(false);
    }
  }, [isLoading, setOpen]);

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      onRefreshOrgList();
      onResetDisableOrganization();
    }
  }, [isSuccess, handleClose, onRefreshOrgList, onResetDisableOrganization]);

  return (
    <>
      {canManageOrganizations && (
        <IconButton
          size="small"
          variant="text"
          sx={{ color: theme.palette.grayLight.main }}
          onClick={onClickDisable}
        >
          <DeleteIcon fontSize="small"></DeleteIcon>
        </IconButton>
      )}
      <LogoLoader
        needBackDrop={true}
        open={isLoading}
        text={"Disabling Organization"}
      ></LogoLoader>
      <Modal
        maxWidth="sm"
        title={"Disable Organization Confirmation"}
        open={open}
        actions={
          <>
            <Button
              startIcon={<DeleteIcon></DeleteIcon>}
              variant="contained"
              onClick={() => onDisableOrganization(organization.id)}
              color="red"
            >
              Yes
            </Button>
            <Button onClick={handleClose} variant="contained" color="dark">
              Cancel
            </Button>
          </>
        }
        body={
          <>
            <Typography variant="overline">do you want to proceed?</Typography>
            <br></br>
            <Typography variant="subtitle1" color={"error"}>
              **The organization{" "}
              <i>
                <b>{organization.name}</b>
              </i>{" "}
              will be disabled.
            </Typography>
          </>
        }
        handleClose={handleClose}
      />
    </>
  );
}
