export const putDisableAudiencePending = (state, action) => {
  state.disableAudience.status = "pending";
};

export const putDisableAudienceFulfilled = (state, action) => {
  state.disableAudience.status = "fulfilled";
  state.disableAudience.data = action.payload;
};

export const putDisableAudienceRejected = (state, action) => {
  state.disableAudience.error = action.payload;
  state.disableAudience.status = "rejected";
};
