export const getWatchVersionPending = (state, action) => {
  state.watchVersion.status = "pending";
};

export const getWatchVersionFulfilled = (state, action) => {
  state.watchVersion.status = "fulfilled";
  state.watchVersion.data = action.payload;
  state.analyzedContent.data = action.payload;
};

export const getWatchVersionRejected = (state, action) => {
  state.watchVersion.status = "rejected";
};
