import React from "react";
import {
  Avatar,
  Box,
  Button,
  useTheme,
  Stack,
  Typography,
  IconButton,
  ButtonGroup,
} from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import LoginIcon from "@mui/icons-material/Login";
import { useNavigate } from "react-router-dom";
import CreateOrganizationForm from "./CreateOrganizationForm";
import useOrganizationListHandler from "../../../../hooks/useOrganizationListHandler";
import useCurrentOrganizationHandler from "../../../../hooks/useCurrentOrganizationHandler";
import useColorHandler from "../../../../hooks/useColorHandler";
import { Modal, ModalDisableOrganization } from "../../../../components";
import AddIcon from "@mui/icons-material/Add";
import ApartmentIcon from "@mui/icons-material/Apartment";
import useUsersByOrganizationHandler from "../../../../hooks/useUsersByOrganizationHandler";
import useGetOrgUserProfile from "../../../../hooks/useGetOrgUserProfileHanlder";
import useDisableOrganizationHandler from "../../../../hooks/useDisableOrganizationHandler";

export default function ListOrganizations() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [newOrg, setNewOrg] = React.useState(false);

  const { organizations } = useOrganizationListHandler();
  const { selectOrganization, currentOrganization } =
    useCurrentOrganizationHandler();
  const { onGetUsers, onResetUsersList } = useUsersByOrganizationHandler();
  const { isSuccess: organizationDeleted, onResetDisableOrganization } =
    useDisableOrganizationHandler();

  const { onResetOrgUserProfile, canManageUsers, canManageOrganizations } =
    useGetOrgUserProfile();

  React.useEffect(() => {
    if (organizationDeleted) {
      onResetUsersList();
      onResetDisableOrganization();
    }
  }, [organizationDeleted, onResetUsersList, onResetDisableOrganization]);

  const { stringAvatar } = useColorHandler();
  return (
    <Box sx={{ height: "80vh" }}>
      <Stack flexWrap={"wrap"} flexDirection={"row"}>
        {currentOrganization &&
          organizations?.map((i, key) => (
            <Box
              key={key}
              color="gray"
              sx={{
                cursor: "pointer",
                background:
                  currentOrganization.id === i.id
                    ? theme.palette.dark.main
                    : theme.palette.grayLight.main,
                "&:hover": {
                  background: theme.palette.dark.main,
                  transition: "0.2s",
                },
                transition: "0.2s",
                width: "150px",
                padding: "25px",
                borderRadius: "20px",
                color:'#fff',
                m: 1,
              }}
              onClick={() => {
                if (i.id !== currentOrganization.id) {
                  onResetUsersList();
                  onResetOrgUserProfile();
                }
                selectOrganization(i);
              }}
            >
              {" "}
              <Stack width={"100%"} justifyContent={""}>
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                  <Avatar
                    {...stringAvatar(i.name, "white", "black", {
                      width: 45,
                      height: 45,
                    })}
                  />
                  {i.id === currentOrganization.id && (
                    <ButtonGroup>
                      {canManageUsers && (
                        <IconButton
                          size="small"
                          variant="text"
                          onClick={() => {
                            onResetUsersList();
                            onGetUsers(i.id);
                            //setActiveOrg(i.id);
                            selectOrganization(i.id);
                          }}
                          sx={{ color: theme.palette.grayLight.main }}
                        >
                          {" "}
                          <GroupsIcon></GroupsIcon>
                        </IconButton>
                      )}
                      <IconButton
                        size="small"
                        variant="text"
                        sx={{ color: theme.palette.grayLight.main }}
                        onClick={() => {
                          navigate(`/organization/${i.id}/content_library`);
                        }}
                      >
                        <LoginIcon></LoginIcon>
                      </IconButton>
                      <ModalDisableOrganization
                        organization={i}
                      ></ModalDisableOrganization>
                    </ButtonGroup>
                  )}
                </Stack>
                <Typography variant="h6" textAlign={"left"} py={1}>
                  {i.name}
                </Typography>
                <Typography variant="subtitle1" textAlign={"left"} py={1}>
                  {i.role}
                </Typography>
              </Stack>
            </Box>
          ))}
        {canManageOrganizations && (
          <Button
            color="gray"
            sx={{
              cursor: "pointer",
              background: "white",
              border: "5px dashed #c4c4c4",
              "&:hover": {
                border: "5px dashed black",
                transition: "0.2s",
                color: "black",
              },
              transition: "0.2s",
              width: "200px",
              height: "187px",
              padding: "25px",
              borderRadius: "20px",
              m: 1,
            }}
            onClick={() => setNewOrg(true)}
          >
            <AddIcon
              sx={{
                fontSize: 50,
              }}
            ></AddIcon>
          </Button>
        )}
      </Stack>
      <Modal
        open={newOrg}
        maxWidth="xs"
        title={
          <Stack direction={"row"} alignItems={"center"}>
            <ApartmentIcon />
            <Typography variant="h6" ml={1}>
              New Organization
            </Typography>
          </Stack>
        }
        handleClose={() => setNewOrg(false)}
        body={<CreateOrganizationForm handleClose={() => setNewOrg(false)} />}
      ></Modal>
    </Box>
  );
}
