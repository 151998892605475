import {  ModalDisableOrganizationUser, Table } from "../../../../components";
import useColorHandler from "../../../../hooks/useColorHandler";
import useOrganizationUsersHandler from "../../../../hooks/useOrganizationUsersHandler";
import { Stack, Avatar, Typography, Box } from "@mui/material";
import InviteUser from "./InviteUser";
import useCurrentOrganizationHandler from "../../../../hooks/useCurrentOrganizationHandler";

export default function OrganizationUsers() {
  const { isSuccess, isLoading, users } = useOrganizationUsersHandler();
  const { currentOrganization } = useCurrentOrganizationHandler();
  const { stringAvatar } = useColorHandler();

  return (
    <>
      {isLoading && "Loading..."}
      {isSuccess && currentOrganization&& (
        <Box px={10}>
          <Stack
            direction={"column"}
            alignItems={"start"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Avatar {...stringAvatar(currentOrganization?.name, null, "#000",{
        width:'50px',
        height:'50px',
        fontSize:'24px'
            })} />
          <Typography variant="h3">
            {currentOrganization?.name}</Typography>
            </Stack>
            <Box>
              <InviteUser></InviteUser>
            </Box>
          </Stack>
          <Table
            containerHeigth="350px"
            getRowId={(row) => {
              return row.org_user_id;
            }}
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: "timestamp",
                    sort: "desc",
                  },
                ],
              },
            }}
            rows={users}
            columns={[
              {
                field: "name",
                headerName: "Name",
                display: "flex",
                "align-items": "center",
                width: 200,

                renderCell: (params) => {
                  return (
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      spacing={2}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <Avatar {...stringAvatar(params.value, null, "#fff")} />
                      <Typography variant="caption">{params.value}</Typography>
                    </Stack>
                  );
                },
              },
              {
                field: "last_name",
                headerName: "Last Name",
                display: "flex",
                width: 140,
                "align-items": "center",
                renderCell: (params) => {
                  return (
                    <Typography variant="caption">{params.value}</Typography>
                  );
                },
              },
              {
                field: "role",
                headerName: "Role",
                width: 100,
                display: "flex",
                "align-items": "center",
                renderCell: (params) => {
                  return (
                    <Typography variant="caption">{params.value}</Typography>
                  );
                },
              },
              {
                field: "Actions",
                headerName: "Actions",
                width: 200,
                display: "flex",
                "align-items": "center",
                renderCell: (params) => {
                  return (
                    <ModalDisableOrganizationUser user={params.row}/>
                  );
                },
              },
            ]}
            hideFooterPagination
          />
        </Box>
      )}
       
    </>
  );
}
