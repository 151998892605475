import {
  resetUsersList,
  selectCurrentOrganization,
  selectOrganizationUsersData,
  selectOrganizationUsersStatus,
} from "../redux/organizations";
import { useDispatch, useSelector } from "react-redux";
import { organizationAPIs } from "../api_";

export default function useUsersByOrganizationHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectOrganizationUsersStatus(state));
  const data = useSelector((state) => selectOrganizationUsersData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onGetUsers = (id) => {
    dispatch(
      organizationAPIs.getOrganizationUsers({ orgId: id ? id : currentOrg.id })
    );
  };

  const onResetUsersList = () => {
    dispatch(resetUsersList());
  };

  return {
    users: data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onGetUsers,
    onResetUsersList,
  };
}
