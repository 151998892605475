const GenerateIcon = ()=><svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_437_5620)">
<path d="M3.01072 16.5016C2.07365 14.9393 2.42194 12.0354 2.7578 10.9996L3.69072 10.7661C3.69072 10.7661 3.45438 9.32263 4.51999 7.9259C5.80121 6.24473 9.46243 2.90361 16.5029 2.50879C13.928 5.44659 12.0912 10.4519 11.6351 11.1906C10.6773 11.8571 9.74438 12.3071 9.74438 12.3071L10.5944 13.1562C10.5944 13.1562 5.87999 17.3039 3.01072 16.5058V16.5016Z" stroke="white" strokeLinejoin="round"/>
<path d="M11.0246 6.66113C5.65513 10.8301 2.87294 16.1114 1.76172 19.5756" stroke="white" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M7.87793 0V0.849077" stroke="white" strokeLinejoin="round"/>
<path d="M7.87793 1.69824V2.54732" stroke="white" strokeLinejoin="round"/>
<path d="M7.46306 1.27344H6.63379" stroke="white" strokeLinejoin="round"/>
<path d="M9.12224 1.27344H8.29297" stroke="white" strokeLinejoin="round"/>
<path d="M15.7559 8.49121V9.34029" stroke="white" strokeLinejoin="round"/>
<path d="M15.7559 10.1885V11.0376" stroke="white" strokeLinejoin="round"/>
<path d="M15.341 9.76465H14.5117" stroke="white" strokeLinejoin="round"/>
<path d="M17.0002 9.76465H16.1709" stroke="white" strokeLinejoin="round"/>
<path d="M15.341 14.8291L14.5117 15.6782" stroke="white" strokeLinejoin="round"/>
<path d="M13.2684 16.9521L12.4102 17.8309" stroke="white" strokeLinejoin="round"/>
<path d="M13.2684 15.6782L12.4102 14.8291" stroke="white" strokeLinejoin="round"/>
<path d="M15.341 17.8012L14.5117 16.9521" stroke="white" strokeLinejoin="round"/>
<path d="M2.07324 2.54688V3.82049" stroke="white" strokeLinejoin="round"/>
<path d="M2.07324 5.51855V6.79217" stroke="white" strokeLinejoin="round"/>
<path d="M1.2439 4.66992H0" stroke="white" strokeLinejoin="round"/>
<path d="M4.14625 4.66992H2.90234" stroke="white" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_437_5620">
<rect width="17" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
export default GenerateIcon;