export const getOrgUserProfilePending = (state, action) => {
  state.orgUserProfile.status = "pending";
};

export const getOrgUserProfileFulfilled = (state, action) => {
  state.orgUserProfile.status = "fulfilled";
  state.orgUserProfile.data = action.payload;
};

export const getOrgUserProfileRejected = (state, action) => {
  state.orgUserProfile.status = "rejected";
};
