import { useDispatch, useSelector } from "react-redux";
import { selectCurrentOrganization } from "../redux/organizations";
import { resetCreateStatus, selectCreateAudienceData, selectCreateAudienceStatus } from "../redux/audience";
import { organizationAPIs } from "../api_";

export default function useUpsertAudienceHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectCreateAudienceStatus(state));
  const data = useSelector((state) => selectCreateAudienceData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onResetCreateAudience = ()=>{
    dispatch(resetCreateStatus())
  }

  const onCreateAudience = (values) => {
    dispatch(
      organizationAPIs.postCreateAudience({
        values,
        id: currentOrg.id
      })
    );
  };

  return {
    audienceList:data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onCreateAudience,
    onResetCreateAudience
  };
}
