import * as React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import BootstrapTooltip from "../BootstrapTooltipComponent";
import { Divider } from "@mui/material";

const BorderLinearProgress = styled(
  ({
    isMain,
    backgroundColor,
    fontColor,
    isDiff,
    displayVal,
    valueColor,
    ...other
  }) => <LinearProgress {...other} />
)(({ theme, isMain, backgroundColor }) => ({
  height: isMain ? 10 : 5,
  borderRadius: 5,
  backgroundColor: backgroundColor, // Aplica backgroundColor correctamente
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", cursor: "pointer", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 60 }}>
        {props.displayVal && (
          <Typography
            variant="body2"
            textAlign={"left"}
            color={props.valueColor}
          >
            {`${Math.round(props.value)}%`}
            {"  "}
            <small style={{ color: props.fontColor }}>
              {!props.isDiff && props.diff
                ? `${props.diff > 0 ? "+" : ""}${Math.round(props.diff)}%`
                : ""}{" "}
            </small>
          </Typography>
        )}
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
export default function GraphicBarComponent({
  val,
  oldVal,
  diff,
  label,
  subChart,
}) {
  const [progress, setProgress] = React.useState(0);
  const [subProgress, setSubProgress] = React.useState(0);
  const theme = useTheme();
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + 1;
        if (nextProgress >= val) {
          clearInterval(timer);
        }
        return nextProgress;
      });
    }, 0);

    return () => {
      clearInterval(timer);
    };
  }, [val]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setSubProgress((prevProgress) => {
        const nextProgress = prevProgress + 1;
        if (nextProgress >= oldVal) {
          clearInterval(timer);
        }
        return nextProgress;
      });
    }, 0);
    return () => {
      clearInterval(timer);
    };
  }, [oldVal]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Typography width={"100%"} variant="overline" textAlign={"left"}>
          {label}
        </Typography>
      </Box>
      <BootstrapTooltip
        followCursor={true}
        title={
          <React.Fragment>
            <Typography variant="h6">
              {label
                .toLowerCase()
                .split(" ")
                .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
                .join(" ")}{" "}
              Individual Scores
            </Typography>
            {subChart.map((persona, idx) => (
              <Box
                key={idx}
                display={"block"}
                sx={{
                  // width: "90%;",
                  padding: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Typography
                    width={"100%"}
                    variant="overline"
                    textAlign={"left"}
                  >
                    {persona.job_title}
                  </Typography>
                </Box>
                <LinearProgressWithLabel
                  variant="determinate"
                  value={persona.score}
                  color={
                    //persona.diff === 0 || persona.score ?
                    "success"
                    //  : "grayLight"
                  }
                  fontColor={
                    persona.diff === 0 || persona.score > persona.score_old
                      ? theme.palette.green.main
                      : theme.palette.red.main
                  }
                  valueColor={"white"}
                  diff={persona.diff}
                  isDiff={false}
                  isMain={true}
                  displayVal={true}
                  backgroundColor={theme.palette.dark.main}
                />

                {persona.score_old && (
                  <Box
                    sx={{
                      position: "relative",
                      top: "-6px",
                    }}
                  >
                    <LinearProgressWithLabel
                      variant="determinate"
                      color={
                        //persona.diff === 0 || persona.score_old > persona.score
                        //  ? "success" :
                        "gray"
                      }
                      fontColor={
                        persona.diff === 0 || persona.score > persona.score_old
                          ? theme.palette.green.main
                          : theme.palette.red.main
                      }
                      value={persona.score_old}
                      diff={persona.diff}
                      isDiff={persona.diff !== 0}
                      isMain={false}
                      displayVal={true}
                      valueColor={"white"}
                      backgroundColor={theme.palette.dark.main}
                    />
                  </Box>
                )}
                <Divider color="gray"></Divider>
              </Box>
            ))}
          </React.Fragment>
        }
      >
        <Box>
          <LinearProgressWithLabel
            isMain={true}
            variant="determinate"
            color={"success"}
            fontColor={
              diff === 0 || progress > subProgress
                ? theme.palette.green.main
                : theme.palette.red.main
            }
            value={progress}
            isDiff={false}
            diff={diff}
            displayVal={true}
            valueColor={"white"}
            backgroundColor={theme.palette.white.main}
          />
          {oldVal && (
            <Box
              sx={{
                position: "relative",
                top: "-2px",
              }}
            >
              <LinearProgressWithLabel
                variant="determinate"
                color={"grayLight"}
                fontColor={
                  // diff === 0 || progress > subProgress
                  //  ? theme.palette.green.main :
                  theme.palette.red.main
                }
                value={subProgress}
                diff={diff}
                isDiff={diff !== 0}
                isMain={false}
                displayVal={false}
                valueColor={"white"}
                backgroundColor={theme.palette.white.main}
              />
            </Box>
          )}
        </Box>
      </BootstrapTooltip>
    </Box>
  );
}
