import {
  Box,
  Typography,
} from "@mui/material";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import useGenerateDraftContentHandler from "../../../../../hooks/useGenerateDraftContentHandler";
import { ContentTitlePreview, EditorComponent } from "../../../../../components";

export default function DraftContentPreview({ formik }) {
  const { data, isUninitialized } = useGenerateDraftContentHandler();
  return (
    <Box
      mx={2}
      sx={{
        pt: "10px",
      }}
    >
      <Box
        sx={{
          height: "90vh",
          borderRadius: 5,
        }}
      >
        {data && (
          <Box textAlign={"left"} p={5}>
            <ContentTitlePreview formik={formik}/>
            <EditorComponent
              showToolbar={true}
              height={"60vh"}
              formik={formik}
              error={Boolean(formik.errors.contentText)}
              onHandleChange={(value) =>
                formik.setFieldValue(`contentText`, value)
              }
              onHandleBlur={() => formik.handleBlur()}
              initialValue={[
                {
                  type: "paragraph",
                  children: [
                    {
                      text: data.content_draft,
                    },
                  ],
                },
              ]}
            ></EditorComponent>
          </Box>
        )}
        {isUninitialized && (
          <Box
            sx={{
              textAlign: "center",
              position: "relative",
              top: "40%",
              color: "#000",
              opacity: 0.2,
            }}
          >
            <BlurOnIcon sx={{ fontSize: 100 }}></BlurOnIcon>
            <Typography variant="h4">
              A blank canvas respresents endless possibilities
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
