import * as React from "react";
import {
  Box,
  Stack,
  Typography,
  Avatar,
  Drawer,
  useTheme,
  Chip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import MetadataFiled from "./MetadataField";
import useContentMetadataHandler from "../../../../../hooks/useContentMetadataHandler";
import useContentMetadataFolderHandler from "../../../../../hooks/useContentMetadataFolderHandler";
import useContentUpdateMetadataHanlder from "../../../../../hooks/useContentUpdateMetadataHanlder";
import {
  AnalyzeConfirmationModal,
  LogoLoader,
  ModalAdaptContent,
} from "../../../../../components";
import useGetOrgUserProfile from "../../../../../hooks/useGetOrgUserProfileHanlder";
import { FolderIcon } from "../../../../../icons";

export default function MetadataDisplayer({
  open,
  toggleDrawer,
  metadataType,
}) {
  const { canManageContent } = useGetOrgUserProfile();
  const theme = useTheme();
  const { data, isLoading: gettingContent } = useContentMetadataHandler();
  const { isLoading } = useContentUpdateMetadataHanlder();
  const {
    data: folderData,
    isLoading: isLoadingFolder,
    isSuccess: isSuccessFolder,
  } = useContentMetadataFolderHandler();

  const date = (val) => {
    if (val) {
      const date = new Date(val);
      const dateString = date.toLocaleDateString();
      const timeString = date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      return `${dateString} ${timeString}`;
    }
    return " - ";
  };

  const DrawerFilesBody = (
    <>
      {data !== null ? (
        <Stack direction={"column"} spacing={2}>
          <Stack spacing={1} direction={"row"}>
            <Avatar sx={{ background: "white" }}>
              <FilePresentIcon color="dark"></FilePresentIcon>
            </Avatar>
            <Typography variant="h6">{data?.metadata?.title}</Typography>
            {data.metadata.status === "deleted" && (
              <Chip label="deleted" color="error" icon={<InfoIcon />} />
            )}
          </Stack>
          <Stack direction={"row"} justifyContent={"space-around"}>
            <AnalyzeConfirmationModal
              has_analytics={data.metadata.has_analytics}
              btnType={"textBtn"}
              content={{
                id: data.metadata.id,
                title: data.metadata.title,
              }}
              disabled={data.metadata.status === "deleted"}
            ></AnalyzeConfirmationModal>
            <ModalAdaptContent
              data={data}
              disabled={data.metadata.status === "deleted"}
            ></ModalAdaptContent>
          </Stack>
          <MetadataFiled
            readOnly={false}
            value={data?.metadata?.source}
            type={"text"}
            label={"File Name"}
            name={"filename"}
            displayValue={data?.metadata?.source}
            displayValueLabel={"EDIT FILE NAME"}
            disabled={!canManageContent || data.metadata.status === "deleted"}
          />
          <MetadataFiled
            readOnly={false}
            displayValueLabel={"CHANGE FOLDER"}
            disabled={!canManageContent || data.metadata.status === "deleted"}
            //displayValueVariant={"caption"}
            displayValue={`${data?.metadata?.folder}`}
            value={{
              folder_id: data?.metadata?.folder_id,
              blob: data?.metadata?.folder,
            }}
            label={"Folder"}
            type={"select"}
            name={"folder"}
          />
          <MetadataFiled
            readOnly={false}
            displayValueLabel={"EDIT OWNERSHIP"}
            disabled={!canManageContent || data.metadata.status === "deleted"}
            //displayValueVariant={"caption"}
            displayValue={`${data?.metadata?.owner}`}
            type={"select"}
            label={"Owner"}
            value={{
              id: data?.metadata?.owner_id,
              name: data?.metadata?.owner_name,
              last_name: data?.metadata?.owner_last_name,
            }}
            name={"owner"}
          />
          <MetadataFiled
            readOnly={true}
            displayValueLabel={"EDIT CHANNEL"}
            disabled={!canManageContent || data.metadata.status === "deleted"}
            //   displayValueVariant={"caption"}
            displayValue={`${data?.metadata?.channel} - ${data?.metadata?.subchannel}`}
            value={{
              id: data?.metadata?.channel_id,
              channel: data?.metadata?.channel,
              subchannel: data?.metadata?.subchannel,
            }}
            //data={groupedByChannels()}
            type={"select"}
            label={"Channel"}
            name={"channel"}
          />
          <MetadataFiled
            displayValueLabel={"EDIT AUDIENCE"}
            disabled={!canManageContent || data.metadata.status === "deleted"}
            /*value={audiences?.filter((i)=>{
            const val = data?.audiences.find(b=>b.id===i.id)
            return val?.id
          })}
          displayValue={data?.audiences?.map((val, id)=> `- ${val.name}`)}*/
            value={{
              id: data?.audiences?.id,
              name: data?.audiences?.name,
            }}
            displayValue={`${data?.audiences?.name}`}
            type={"select"}
            label={"Audience"}
            name={"audience"}
          />
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack>
              <Typography variant="caption">Created</Typography>
              <Typography variant="">
                {date(data?.metadata?.created_at)}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack>
              <Typography variant="caption">Modified</Typography>
              <Typography variant="">
                {" "}
                {date(data?.metadata?.last_modified_date)}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack>
              <Typography variant="caption">Tags</Typography>
              <Typography variant="caption"></Typography>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        ""
      )}
    </>
  );

  const DrawerFolderBody = (
    <>
      {isSuccessFolder && (
        <>
          <FolderIcon></FolderIcon>
          <Stack spacing={2}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h5">
                {folderData?.folderMetadata?.blob_location}
              </Typography>
              <Box
                sx={{ color: "#66BF76", fontSize: "11px", cursor: "pointer" }}
              >
                EDIT NAME
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack>
                <Typography variant="caption">Created At</Typography>
                <Typography variant="caption">
                  {" "}
                  {date(folderData?.folderMetadata?.created_at)}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack>
                <Typography variant="caption">Folder Contents</Typography>
                {folderData?.folderContent?.map((p) => (
                  <Typography variant="caption">- {p.name}</Typography>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
    </>
  );

  return (
    <>
      {/*<Tooltip title="open details">
        <IconButton size="small" onClick={() => toggleDrawer(true)}>
          <FilterIcon></FilterIcon>
        </IconButton>
      </Tooltip>*/}
      <Drawer
        sx={{ width: "50%" }}
        anchor={"right"}
        open={open}
        onClose={(e, r) => {
          if (r === "escapeKeyDown" || r === "backdropClick") {
            toggleDrawer(false, null);
          }
        }}
      >
        {/*<Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10000 }}
          open={isLoading || isRefreshigNavigator || gettingContent}
        >
          <Box>
            <LinearProgress color="inherit" />
            <Typography textTransform={"uppercase"} variant="caption">
              updating data
            </Typography>
          </Box>
        </Backdrop>*/}
        <Box
          sx={{
            width: 350,
            py: 5,
            px: 5,
            background: theme.palette.rightPane.main,
            height: "100%",
          }}
          role="presentation"
        >
          <LogoLoader
            open={isLoading || gettingContent || isLoadingFolder}
            variant={"no-filled"}
            needBackDrop={false}
          ></LogoLoader>
          {gettingContent || isLoading || isLoadingFolder ? " ": metadataType === "files"
            ? DrawerFilesBody
            : DrawerFolderBody}
          
        </Box>
      </Drawer>
    </>
  );
}
