import routes from "../routes";
import { buildUrl } from "../helpers/url";
import { getHeaders } from "../helpers/headers";
import { handleResponse } from "../helpers/handleResponse";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../axios";

const getList = createAsyncThunk(
  "organizations/getList",
  async (owne_id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.organization.byUser(owne_id)),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const postOrganization = createAsyncThunk(
  "organizations/postOrganization",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.organization.rootPath()),
        { ...data.values },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const getOrganizationUsers = createAsyncThunk(
  "organizations/getOrganizationUsers",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.organization.users(data.orgId)),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const getPersonas = createAsyncThunk(
  "persona/getPersonas",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.persona.rootPath(data.id,data.filter)),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const postPersona = createAsyncThunk(
  "persona/postPersona",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.persona.createPath(data.orgId)),
        { ...data.values },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const postGenerateAnswer = createAsyncThunk(
  "persona/postGenerateAnswer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.persona.answerPath(data.orgId)),
        { ...data.values },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const getPersonaExportPDF = createAsyncThunk(
  "persona/getPersonaExportPDF",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.persona.exportPDF(data.orgId, data.personaId)),
        {...getHeaders(),
          responseType: "blob"
        },

      );
      const contentDisposition = response.headers['content-disposition'];
      let filename = `${(new Date()).toDateString()}.pdf`;
      
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=([^;\n]+)/);
        if (fileNameMatch && fileNameMatch[1]) {
          filename = fileNameMatch[1];
        }
      }
      
      return {
        file: handleResponse(response),
        filename,
        data
      };
      
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const getPersona = createAsyncThunk(
  "persona/getPersona",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.persona.getPath(data.orgId, data.personaId)),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const putPersona = createAsyncThunk(
  "persona/putPersona",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        buildUrl(routes.persona.editPath(data.orgId, data.personaId)),
        { description: data.values,
          savePersonaBioFlag:data.savePersonaBioFlag, 
          builderType: data.builderType },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);
const putDisablePersona = createAsyncThunk(
  "persona/putDisablePersona",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        buildUrl(routes.persona.disablePath(data.orgId, data.personaId)),
        {
          status: data.status,
          organization_id: data.orgId,
          persona_id: data.personaId,
        },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const postInviteUser = createAsyncThunk(
  "organization/postInviteUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.organization.inviteUser(data.orgId)),
        { ...data.values },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const postUploadFile = createAsyncThunk(
  "content/postUploadFile",
  async (data, { rejectWithValue }) => {
    const formData = new FormData();
    data.forms.map((form, idx) => formData.append(`file_${idx}`, form.file));

    formData.append(
      "json",
      JSON.stringify({
        content: data.forms.map((form) => ({ ...form, file: form.file.name })),
      })
    );
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.organization.uploadFile(data.id)),
        formData,
        {
          headers: {
            Authorization: "Bearer " + data.token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const postContentText = createAsyncThunk(
  "content/postContentText",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.organization.content.uploadContentText(data.id)),
        { ...data.values },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const postCreateFolder = createAsyncThunk(
  "content/postCreateFolder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.organization.content.createFolder(data.id)),
        { ...data.values },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const getChannels = createAsyncThunk(
  "organization/getChannels",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.organization.channels.rootPath(data.id)),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);


const getAudience = createAsyncThunk(
  "audience/getAudience",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.organization.audience.listAudience(data.orgId,data.filter)),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const postCreateAudience = createAsyncThunk(
  "audience/postCreateAudience",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.organization.audience.rootPath(data.id)),
        { ...data.values },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const putUpdateAudience = createAsyncThunk(
  "audience/putUpdateAudience",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        buildUrl(routes.organization.audience.update(data.orgId, data.id )),
        { ...data.values },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const putDisableAudience = createAsyncThunk(
  "audience/putDisableAudience",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        buildUrl(routes.organization.audience.disable(data.orgId, data.id )),
        { ...data.values },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const getOrgUserProfile = createAsyncThunk(
  "organization/getOrgUserProfile",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.organization.orgUserProfile(id)),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const disableOrganization = createAsyncThunk(
  "organization/disableOrganization",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        buildUrl(routes.organization.organizationRootPath(id)),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const disableOrganizationUser = createAsyncThunk(
  "organization/disableOrganizationUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        buildUrl(routes.organization.organizationUser(data.id, data.orgUserId)),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const organizations = {
  getList,
  getOrganizationUsers,
  getPersonas,
  getPersona,
  getChannels,
  postPersona,
  getPersonaExportPDF,
  postOrganization,
  postInviteUser,
  postGenerateAnswer,
  postUploadFile,
  postContentText,
  postCreateFolder,
  putPersona,
  putDisablePersona,
  getAudience,
  postCreateAudience,
  putUpdateAudience,
  putDisableAudience,
  getOrgUserProfile,
  disableOrganization,
  disableOrganizationUser
};
export default organizations;
