import { Button, Stack, Typography } from "@mui/material";
import { AudienceIcon } from "../../../../../icons";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import useCurrentOrganizationHandler from "../../../../../hooks/useCurrentOrganizationHandler";
import { useNavigate } from "react-router-dom";
import { LeftPaneComponent } from "../../../../../components";
import { usePersonaBuilderModeHandler } from "../../../../../hooks/usePersonaBuilderModeHandler";
import { useCurrentPersonaViewer } from "../../../../../hooks/useCurrentPersonaViewer";
import ModalUpsertAudience from "./ModalUpsertAudience";

export default function LeftPaneContent() {
  const navigate = useNavigate();
  const { onSetMode } = usePersonaBuilderModeHandler();
  const { onResetPersona } = useCurrentPersonaViewer();

  const { currentOrganization } = useCurrentOrganizationHandler();

  return (
    <LeftPaneComponent>
      <Stack pt={"70px"} spacing={2}>
        <Stack
          sx={{ maxHeight: "400px", overflow: "auto" }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <AudienceIcon></AudienceIcon>
          <Typography variant="h4">Audience Builder</Typography>
          <Typography variant="body1" textAlign={"left"} fontWeight={100}>
            Audience Builder helps create target audiences by grouping personas.
            Personas represent specific user types, while target audiences are
            broader groups sharing common traits. This tool allows you to
            combine personas into custom audiences, which can be analyzed with
            Subatomic's Audience Analyzer or used in various marketing
            strategies. It's designed to enhance your understanding of who
            you're trying to reach and how to communicate with them effectively.
          </Typography>
        </Stack>
      </Stack>
      <Stack
        spacing={1}
      >
        <ModalUpsertAudience />
        <Button
          variant="contained"
          size="medium"
          color="dark"
          startIcon={<SensorOccupiedIcon />}
          onClick={() => {
            onSetMode("view");
            onResetPersona();
            navigate(`/organization/${currentOrganization.id}/persona`);
          }}
        >
          View & Create Personas
        </Button>
      </Stack>
    </LeftPaneComponent>
  );
}
