import routes from "../routes";
import { buildUrl } from "../helpers/url";
import { getHeaders } from "../helpers/headers";
import { handleResponse } from "../helpers/handleResponse";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../axios";

const getAllUser = createAsyncThunk(
  "admin/getAllUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.admin.getAllUser()),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const putPaymentMethod = createAsyncThunk(
  "admin/putPaymentMethod",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        buildUrl(routes.utility.strapi.payment_method()),
        { ...data },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const putUpdateSubscription = createAsyncThunk(
  "admin/putUpdateSubscription",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        buildUrl(routes.utility.strapi.subscription()),
        { ...data },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const cancelSubscription = createAsyncThunk(
  "admin/cancelSubscription",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        buildUrl(routes.utility.strapi.subscription()),
        {},
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const postGenerateAPIKey = createAsyncThunk(
  "admin/postGenerateAPIKey",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.admin.generateAPIKey()),
       {},
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const getAPIKey = createAsyncThunk(
  "admin/getAPIKey",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.admin.getAPIKey()),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const admin = {
  getAllUser,
  putPaymentMethod,
  putUpdateSubscription,
  cancelSubscription,
  postGenerateAPIKey,
  getAPIKey
};

export default admin;
