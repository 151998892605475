export default function useTextTransform() {
  const camelCaseToTitle = (text) =>
    text
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([&])([A-Z])/g, " $1 $2")
      .replace(/^./, (str) => str.toUpperCase());

  const snakeCaseToTitleCase = (str) =>
    str
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

  return {
    camelCaseToTitle,
    snakeCaseToTitleCase,
  };
}
