import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from "@mui/material";
import {
  InputFileUpload,
  InputText,
  LogoLoader,
  Modal,
} from "../../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Logo } from "../../../icons";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import useAutoIngestionHandler from "../../../hooks/useAutoIngestionHandler";
import ErrorMessageDisplayer from "../../../components/ErrorMessageDisplayer";
import { useState } from "react";

export default function AutoIngestion() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(null);

  
  const {
    onPostAutoIngestion,
    onResetAutoIngestion,
    isError,
    isLoading,
    error,
    isSuccess,
  } = useAutoIngestionHandler();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      name: "",
      last_name: "",
      organizationName: "",
      organizationDescription: "",
      url1: "",
      url2: "",
      url3: "",
      file1: null,
      file2: null,
      file3: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
        .required("Required"),
      name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      organizationName: Yup.string().required("Required"),
      organizationDescription: Yup.string().required("Required"),
      url1: Yup.string().url("Invalid URL format"),
      url2: Yup.string().url("Invalid URL format"),
      url3: Yup.string().url("Invalid URL format"),
    }).test(
      "at-least-one-file-or-url",
      "At least one file or URL is required",
      function (values) {
        const { url1, url2, url3, file1, file2, file3 } = values;
        const isAtLeastOneFilled =
          [url1, url2, url3].some((url) => url && url.trim() !== "") ||
          [file1, file2, file3].some((file) => file instanceof File);

        if (!isAtLeastOneFilled) {
          return this.createError({
            path: "attachment",
            message:
              "At least one content reference is required to be provided in either Step 3 or 4.",
          });
        }
        return true;
      }
    ),
    onSubmit: async (values) => {
      onPostAutoIngestion(values);
    },
  });

  useState(() => {
    formik.validateField("name");
      formik.setFieldTouched("name");
      formik.validateField("last_name");
      formik.setFieldTouched("last_name");
      formik.validateField("email");
      formik.setFieldTouched("email");
    setActiveStep(0);
  }, [])

  const handleNext = () => {
    if (activeStep === 0) {
      formik.validateField("name");
      formik.setFieldTouched("name");
      formik.validateField("last_name");
      formik.setFieldTouched("last_name");
      formik.validateField("email");
      formik.setFieldTouched("email");
    }
    if (activeStep === 1) {
      formik.validateField("organizationName");
      formik.setFieldTouched("organizationName");
      formik.validateField("organizationDescription");
      formik.setFieldTouched("organizationDescription");
    }
    if (activeStep === 2 || activeStep === 3) {
      // formik.validateField("attachment");
      // formik.setFieldTouched("attachment")
      //formik.
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleOnClickStep = (i) => {
    if (i === 0) {
      formik.validateField("name");
      formik.setFieldTouched("name");
      formik.validateField("last_name");
      formik.setFieldTouched("last_name");
      formik.validateField("email");
      formik.setFieldTouched("email");
    }
    if (i === 1) {
      formik.validateField("organizationName");
      formik.setFieldTouched("organizationName");
      formik.validateField("organizationDescription");
      formik.setFieldTouched("organizationDescription");
    }
    setActiveStep(i);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
/*
  const handleReset = () => {
    setActiveStep(0);
  };*/

  const onClose = () => {
    onResetAutoIngestion();
  };
  console.log(formik);
  return (
    <Box>
      <LogoLoader open={isLoading} text={"Processing..."} />
      <Modal
        maxWidth="sm"
        title={isError ? "Auto Ingestion Error" : "Auto Ingestion Sucess"}
        open={isSuccess || isError}
        actions={
          <>
            <Button onClick={onClose} variant="contained" color="error">
              OK
            </Button>
          </>
        }
        body={
          <>
            {isError ? (
              <ErrorMessageDisplayer
                content={{
                  header: "The Auto Ingestion Process Failed",
                  title: error.error,
                  message: error.message,
                }}
              />
            ) : (
              <>
                <Typography variant="overline">
                  Hey!! Your content files where analyzed successfully please
                  check your email
                </Typography>
              </>
            )}
          </>
        }
        handleClose={onClose}
      />
      <Box
        sx={{
          backgroundImage: `url("/auth_wallpaper.png")`,
          height: "100vh",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          filter: "blur(20px)",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          background: "#000000c9",
          width: "100%",
          height: "100vh",
          overflowY: "auto",
          top: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Paper sx={{ width: "80%", maxWidth: "600px", pr: 4, pl: 2, pb: 2 }}>
          <Box
            sx={{
              height: "40px",
              width: "100%",
              p: 1,
              my: 2,
              background: theme.palette.dark.main,
              borderRadius: 3,
            }}
          >
            <Logo width="200px" />
          </Box>
          <Grid xs={12} item>
            <Typography variant="h5"> Welcome to this Free Demo</Typography>
            <Typography variant="body2" fontWeight={200} textAlign={"left"}>
              Create your brand identity and define your audience with our
              self-service platform. Provide essential information through an
              easy-to-use form. Our AI will identify the most likely, targeted
              personas, and evaluate your content's effectiveness using
              Subatomic's six AI-powered metrics. Learn how personas shape
              marketing strategies and create target audiences to strengthen
              your brand.
            </Typography>
            <br></br>
            <Typography variant="body2" fontWeight={200} textAlign={"left"}>
              Please fill in the form below to get started
            </Typography>
            <br></br>
            {Boolean(formik.errors.attachment) && activeStep > 1 && (
              <Typography
                fontSize={10}
                minHeight={15}
                textAlign={"left"}
                color={theme.palette.error.main}
                fontWeight={300}
              >
                {formik.errors.attachment}{" "}
              </Typography>
            )}
          </Grid>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            sx={{
              "& .MuiStepIcon-root": {
                color: theme.palette.dark.main,
              },
              "& .MuiStepIcon-root.Mui-active": {
                color: theme.palette.dark.main,
              },
              "& .MuiStepIcon-root.Mui-completed": {
                color: theme.palette.success.main,
              },
              "& .MuiStepIcon-root.Mui-error": {
                color: theme.palette.error.main,
              },
            }}
            connector={
              <StepConnector
                sx={{
                  "& .MuiStepConnector-line": {
                    minHeight: "10px !important",
                  },
                }}
              ></StepConnector>
            }
          >
            <Step
              key={0}
              completed={
                formik.touched.name &&
                !formik.errors.name &&
                formik.touched.email &&
                !formik.errors.email &&
                formik.touched.last_name &&
                !formik.errors.last_name
              }
              e
            >
              <StepLabel
                error={
                  (formik.errors.name ||
                    formik.errors.email ||
                    formik.errors.last_name) &&
                  activeStep !== 0
                }
                onClick={() => handleOnClickStep(0)}
                optional={
                  <Typography variant="caption">Contact Information</Typography>
                }
              ></StepLabel>
              <StepContent>
                <InputText
                  id="name"
                  name="name"
                  placeholder="First Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name ? formik.errors.name : ""}
                />
                <InputText
                  id="last_name"
                  name="last_name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.last_name && Boolean(formik.errors.last_name)
                  }
                  placeholder="Last Name"
                  helperText={
                    formik.touched.last_name ? formik.errors.last_name : ""
                  }
                />
                <InputText
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  placeholder="Email"
                  helperText={formik.touched.email ? formik.errors.email : ""}
                />
                <ButtonGroup variant="text" aria-label="Basic button group">
                  <Button
                    disableElevation
                    variant="text"
                    color="success"
                    onClick={handleNext}
                    size="small"
                  >
                    {"next"}
                  </Button>
                  <Button onClick={handleBack} size="small" color="dark">
                    Back
                  </Button>
                </ButtonGroup>
              </StepContent>
            </Step>
            <Step
              key={1}
              completed={
                formik.touched.organizationName &&
                !formik.errors.organizationName &&
                formik.touched.organizationDescription &&
                !formik.errors.organizationDescription
              }
            >
              <StepLabel
                error={
                  (formik.errors.organizationName ||
                    formik.errors.organizationDescription) &&
                  activeStep !== 1
                }
                onClick={() => handleOnClickStep(1)}
                optional={
                  <Typography variant="caption">
                    Organization Details
                  </Typography>
                }
              ></StepLabel>
              <StepContent>
                <InputText
                  id="organizationName"
                  name="organizationName"
                  value={formik.values.organizationName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.organizationName &&
                    Boolean(formik.errors.organizationName)
                  }
                  placeholder="Company"
                  helperText={
                    formik.touched.organizationName
                      ? formik.errors.organizationName
                      : ""
                  }
                />
                <InputText
                  id="organizationDescription"
                  name="organizationDescription"
                  value={formik.values.organizationDescription}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.organizationDescription &&
                    Boolean(formik.errors.organizationDescription)
                  }
                  placeholder="Bussines description"
                  helperText={
                    formik.touched.organizationDescription
                      ? formik.errors.organizationDescription
                      : ""
                  }
                />
                <ButtonGroup variant="text" aria-label="Basic button group">
                  <Button
                    disableElevation
                    variant="text"
                    color="success"
                    onClick={handleNext}
                    size="small"
                  >
                    {"next"}
                  </Button>
                  <Button onClick={handleBack} size="small" color="dark">
                    Back
                  </Button>
                </ButtonGroup>
              </StepContent>
            </Step>
            <Step
              key={2}
              completed={
                (formik.values?.url1 ||
                  formik.values?.url2 ||
                  formik.values?.url3) &&
                !(
                  formik.errors.url1 ||
                  formik.errors.url2 ||
                  formik.errors.url3
                )
              }
            >
              <StepLabel
                onClick={() => handleOnClickStep(2)}
                error={
                  activeStep > 1 &&
                  (formik.errors.attachment ||
                    formik.errors.url1 ||
                    formik.errors.url2 ||
                    formik.errors.file3) &&
                  activeStep !== 2
                }
                optional={
                  <>
                    <Typography variant="caption">Web Content URL's</Typography>
                  </>
                }
              ></StepLabel>

              <StepContent>
                <InputText
                  id="url"
                  name="url1"
                  value={formik.values.url1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.url1 && Boolean(formik.errors.url1)}
                  placeholder="URL Content"
                  helperText={formik.touched.url1 ? formik.errors.url1 : ""}
                />
                <InputText
                  id="url"
                  name="url2"
                  value={formik.values.url2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.url2 && Boolean(formik.errors.url2)}
                  placeholder="URL Content"
                  helperText={formik.touched.url2 ? formik.errors.url2 : ""}
                />
                <InputText
                  id="url"
                  name="url3"
                  value={formik.values.url3}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.url3 && Boolean(formik.errors.url3)}
                  placeholder="URL Content"
                  helperText={formik.touched.url3 ? formik.errors.url3 : ""}
                />
                <ButtonGroup variant="text" aria-label="Basic button group">
                  <Button
                    disableElevation
                    variant="text"
                    color="success"
                    onClick={handleNext}
                    size="small"
                  >
                    {"next"}
                  </Button>
                  <Button onClick={handleBack} size="small" color="dark">
                    Back
                  </Button>
                </ButtonGroup>
              </StepContent>
            </Step>
            <Step
              key={3}
              completed={
                formik.values?.file1 ||
                formik.values?.file2 ||
                formik.values?.file3
              }
            >
              <StepLabel
                onClick={() => handleOnClickStep(3)}
                error={
                  activeStep > 1 && formik.errors.attachment && activeStep !== 3
                }
                optional={
                  <>
                    <Typography variant="caption">File Content</Typography>
                  </>
                }
              ></StepLabel>

              <StepContent>
                <Box py={0.5}>
                  <InputFileUpload
                    fullWidth={true}
                    color={theme.palette.primary.main}
                    icon={<BookmarkAddedIcon></BookmarkAddedIcon>}
                    formik={formik}
                    filedName={`file1`}
                  />
                </Box>
                <Box py={0.5}>
                  <InputFileUpload
                    fullWidth={true}
                    color={theme.palette.primary.main}
                    icon={<BookmarkAddedIcon></BookmarkAddedIcon>}
                    formik={formik}
                    filedName={`file2`}
                  />
                </Box>
                <Box py={0.5}>
                  <InputFileUpload
                    fullWidth={true}
                    color={theme.palette.primary.main}
                    icon={<BookmarkAddedIcon></BookmarkAddedIcon>}
                    formik={formik}
                    filedName={`file3`}
                  />
                </Box>

                <ButtonGroup variant="text" aria-label="Basic button group">
                  {" "}
                  <Button
                    disableElevation
                    variant="text"
                    color="success"
                    onClick={handleNext}
                    size="small"
                  >
                    {"next"}
                  </Button>
                  <Button onClick={handleBack} size="small" color="dark">
                    Back
                  </Button>
                </ButtonGroup>
              </StepContent>
            </Step>
            <Step key={4} disabled={!formik.isValid}>
              {console.log(formik)}
              <StepLabel
              sx={{
                color: formik.touched&& formik.isValid ? theme.palette.success.main : "",
              }}
                onClick={() => handleOnClickStep(4)}
                optional={
                  <Typography variant="caption">
                    {formik.touched&& formik.isValid
                      ? "Ready to go!"
                      : "Please check the previous steps"}
                  </Typography>
                }
              ></StepLabel>
              <StepContent>
                <Typography variant="body2" fontWeight={200} textAlign={"left"}>
                  This process can take a couple of minutes, we will continuosly
                  notify you about the process, at the end we will send you an
                  email with the results of your content analysis.
                </Typography>
                <Button
                  onClick={() => formik.submitForm()}
                  variant="contained"
                  color="warning"
                  size="small"
                  focusRipple={activeStep === 4}
                  fullWidth
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  Submit
                </Button>
              </StepContent>
            </Step>
          </Stepper>
        </Paper>
      </Box>
    </Box>
  );
}
