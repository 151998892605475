import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { brandAPIs } from "../api_";
import { selectCurrentOrganization } from "../redux/organizations";
import { selectBrandsData, selectBrandsStatus } from "../redux/brand";

export default function useGetBrandsHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectBrandsStatus(state));
  const data = useSelector((state) => selectBrandsData(state));
  const isUninitialized = status === "idle";
  const isLoading = status === "pending" || status === "idle";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";
  const currentOrganization = useSelector((state) =>
    selectCurrentOrganization(state)
  );

  const onGetBrands = () => {
    dispatch(brandAPIs.getBrands(currentOrganization));
  };

  useEffect(() => {
    if (isUninitialized) {
      dispatch(brandAPIs.getBrands(currentOrganization));
    }
  }, [dispatch, currentOrganization, isUninitialized]);

  return {
    onGetBrands,
    brand: data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
