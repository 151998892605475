import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./extraReducers";
import reducers from "./reducers";

export const contentSlice = createSlice({
  name: "content",
  initialState: {
    uploadFileContent: {
      data: null,
      error: null,
      status: "idle",
    },
    uploadContentText: {
      data: null,
      error: null,
      status: "idle",
    },
    createFolder: {
      data: null,
      status: "idle",
    },
    allFolders: {
      data: null,
      status: "idle",
    },
    navigator: {
      data: null,
      status: "idle",
      currentFolder: "/",
      prevFolder: "/",
    },
    contentMetadata: {
      data: null,
      status: "idle",
    },
    contentUpdateMetadata: {
      status: "idle",
    },
    contentMetadataFolder: {
      data: null,
      status: "idle",
    },
    contentToAnalyze: {
      data: null,
      status: "idle",
    },
    analyzeContent: {
      data: null,
      status: "idle",
    },
    analyzedContent: {
      data: null,
      status: "idle",
    },
    suggestionsContent: {
      data: null,
      status: "idle",
    },
    contentEdits: {
      data: null,
      status: "idle",
      analyze:false,
    },
    analyzeSingleContent: {
      data: null,
      status: "idle",
    },
    brandReaction:{
      data: null,
      status: "idle",
    },
    watchVersion:{
      data: null,
      status: "idle",
    },
    adaptContentChannel:{
      data: null,
      status: "idle",
    },
    deleteContent:{
      data: null,
      status: "idle",
    },
    exportPDFQueue:{
      queue:[],
    },
    generateDraftContent: {
      data: null,
      error: null,
      status: "idle",
    },
  },
  reducers,
  extraReducers,
});

export default contentSlice;
