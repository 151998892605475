import { useSelector } from "react-redux";
import { adminAPIs } from "../api_";
import {
  resetUpdateSubscription,
  selectUpdateSubscriptionData,
  selectUpdateSubscriptionError,
  selectUpdateSubscriptionStatus,
} from "../redux/admin";
import { useAppDispatch } from "../redux/store";

export default function useUpdateSubscriptionHandler() {
  const dispatch = useAppDispatch();

  const status = useSelector((state) => selectUpdateSubscriptionStatus(state));
  const data = useSelector((state) => selectUpdateSubscriptionData(state));
  const error = useSelector((state) => selectUpdateSubscriptionError(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onSubmitForm = (values) => {
    dispatch(adminAPIs.putUpdateSubscription(values));
  };
  const onResetUpdateSubscription = () => {
    dispatch(resetUpdateSubscription());
  };
  return {
    data,
    error,
    onSubmitForm,
    onResetUpdateSubscription,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
