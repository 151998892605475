export const postCreateFolderPending = (state, action) => {
    state.createFolder.status = "pending";
  };
  
  export const postCreateFolderFulfilled = (state, action) => {
    state.createFolder.status = "fulfilled";
    state.data = action.payload;
  };
  
  export const postCreateFolderRejected = (state, action) => {
    state.createFolder.status = "rejected";
  };
  