export const postSuggestionsContentPending = (state, action) => {
  state.suggestionsContent.status = "pending";
};

export const postSuggestionsContentFulfilled = (state, action) => {
  state.suggestionsContent.status = "fulfilled";
  state.suggestionsContent.data = action.payload;
};

export const postSuggestionsContentRejected = (state, action) => {
  state.suggestionsContent.status = "rejected";
  state.suggestionsContent.data = action.payload.response.data;
};
