import { useSelector } from "react-redux";
import { adminAPIs } from "../api_";
import {
  resetCancelSubscription,
  selectCancelSubscriptionData,
  selectCancelSubscriptionError,
  selectCancelSubscriptionStatus,
} from "../redux/admin";
import { useAppDispatch } from "../redux/store";

export default function useCancelSubscriptionHandler() {
  const dispatch = useAppDispatch();

  const status = useSelector((state) => selectCancelSubscriptionStatus(state));
  const data = useSelector((state) => selectCancelSubscriptionData(state));
  const error = useSelector((state) => selectCancelSubscriptionError(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onSubmitForm = () => {
    dispatch(adminAPIs.cancelSubscription());
  };
  const onResetCancelSubscription = () => {
    dispatch(resetCancelSubscription());
  };

  return {
    data,
    error,
    onSubmitForm,
    onResetCancelSubscription,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
