const ContentLibraryIcon = () => (
  <svg
    width="65"
    height="62"
    viewBox="0 0 65 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_266_8832)">
      <path
        d="M57.35 27.9301V49.2801C57.35 51.2501 55.25 52.5601 53.51 51.6301C50.95 50.2601 47.64 49.4301 44.04 49.4301C39.66 49.4301 35.73 50.6501 33.02 52.5801C32.84 52.7101 32.64 52.8201 32.44 52.9201V31.8601C32.44 30.9101 31.67 30.1401 30.72 30.1401C29.77 30.1401 29 30.9101 29 31.8601V52.9201C28.8 52.8201 28.61 52.7101 28.42 52.5801C25.71 50.6501 21.78 49.4301 17.4 49.4301C13.79 49.4301 10.49 50.2601 7.91998 51.6301C6.17998 52.5601 4.07998 51.2501 4.07998 49.2801V18.0501C4.07998 16.3401 4.84998 14.6901 6.22998 13.6701C8.93998 11.6801 12.93 10.4201 17.39 10.4201C20.14 10.4201 22.7 10.9001 24.91 11.7401V7.43008C22.58 6.72008 20.03 6.33008 17.39 6.33008C12.18 6.33008 7.35998 7.77008 3.79998 10.3801C1.40998 12.1401 -0.0200195 15.0101 -0.0200195 18.0601V51.8301C-0.0200195 52.3701 0.10998 52.9101 0.34998 53.4001L1.98998 56.6501C2.57998 57.8201 3.77998 58.5601 5.09998 58.5601H25.62C26.51 58.5601 27.37 59.0201 27.79 59.8001C28.35 60.8401 29.45 61.5401 30.71 61.5401C31.97 61.5401 33.06 60.8401 33.63 59.8001C34.06 59.0101 34.91 58.5601 35.8 58.5601H56.31C57.62 58.5601 58.82 57.8201 59.42 56.6501L61.06 53.4001C61.3 52.9101 61.43 52.3801 61.43 51.8301V27.9301C61.43 27.9301 61.34 27.9301 61.3 27.9301H57.34H57.35Z"
        fill="black"
      />
      <path
        d="M61.3099 0H31.4999C29.7699 0 28.3599 1.41 28.3599 3.14V21.36C28.3599 23.09 29.7699 24.5 31.4999 24.5H35.2399V29.56L40.2999 24.5H61.3199C63.0499 24.5 64.4599 23.09 64.4599 21.36V3.14C64.4599 1.41 63.0499 0 61.3199 0H61.3099ZM49.9099 19.41H34.4499V16.48H49.9099V19.41ZM58.3699 13.44H34.5499V10.51H58.3699V13.44ZM58.3699 7.47H34.5499V4.54H58.3699V7.47Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_266_8832">
        <rect width="64.45" height="61.53" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ContentLibraryIcon;
