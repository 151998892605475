import * as React from "react";
import {
  Box,
  Select,
  CircularProgress,
  Chip,
  ListSubheader,
  MenuItem,
} from "@mui/material";
import useOrganizationChannelsHandler from "../../hooks/useOrganizationChannelsHandler";
import InputText from "../InputText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      marginTop:'17px',
      top:250,
      minWidth:'370px',
      borderRadius:'15px',
      border: '4px solid white'
    },
  },
};
export default function ChannelSelect({ formik, name, value, error }) {
  const { groupedByChannels, channles, onGetChannels,isSuccess, isLoading, isUninitialized } =
    useOrganizationChannelsHandler();
  React.useEffect(() => {
    if (isUninitialized) {
      onGetChannels();
    }
  }, [isUninitialized, onGetChannels]);
  return (
    <Select
      displayEmpty
      sx={{
       // width:'100px'
       '& .MuiSelect-select': {
      paddingRight: 0,
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
   }
      }}
      MenuProps={MenuProps}
      name={name} //
      value={value?channles.find(i=>i.id===value.id):''} //formik?.values?.channel
      error={error} //formik?.touched?.channel && Boolean(formik?.errors?.channel)
      startAdornment={isLoading && <CircularProgress size={"20px"} />}
      input={<InputText  placeholder="Choose channel" />}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      renderValue={(selected) => {
        if (!selected) return <em>{"Choose channel"}</em>;
        return selected ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            <Chip
              sx={{ height: "20px !important" }}
              key={value.channel.id}
              label={`${value.channel} - ${value.subchannel}`}
            />
          </Box>
        ) : (
          <em>{"Choose Channel"}</em>
        );
      }}
      inputProps={{
        sx:{
          padding:0
        }
       }}
    >
      <MenuItem disabled value="">
        <em>Choose Channel</em>
      </MenuItem>
      {isSuccess ? groupedByChannels()?.map((group) => [
        <ListSubheader>{group.channel}</ListSubheader>,
        ...group.items.map((channel, idx) => (
          <MenuItem
            sx={{
              textAlign: "center",
              pl: 5,
            }}
            key={idx}
            value={channel}
          >
            {channel.subchannel}
          </MenuItem>
        )),
      ]):''}
    </Select>
  );
}
