import React, { forwardRef } from "react";
import ReactDOM from "react-dom";
import {
  Typography,
  Box,
  IconButton,
  styled,
  Paper,
} from "@mui/material";

const useStyles = styled((theme) => ({
  button: {
    cursor: "pointer",
    color: (props) =>
      props.reversed
        ? props.active
          ? "white"
          : "#aaa"
        : props.active
        ? "black"
        : "#ccc",
  },
  editorValue: {
    margin: "30px -20px 0",
  },
  header: {
    fontSize: "14px",
    padding: "5px 20px",
    color: "#404040",
    borderTop: "2px solid #eeeeee",
    background: "#f8f8f8",
  },
  content: {
    color: "#404040",
    fontSize: "12px",
    fontFamily: "monospace",
    whiteSpace: "pre-wrap",
    padding: "10px 20px",
    "& div": {
      margin: "0 0 0.5em",
    },
  },
  instruction: {
    whiteSpace: "pre-wrap",
    margin: "0 -20px 10px",
    padding: "10px 20px",
    fontSize: "14px",
    background: "#f8f8e8",
  },
  menu: {
    display: "flex",
    flexDirection: "row",
    "& > *": {
      display: "inline-block",
    },
    "& > * + *": {
      marginLeft: "15px",
    },
  },
  toolbar: {
    position: "relative",
    padding: "1px 18px 17px",
    margin: "0 -20px",
    borderBottom: "2px solid #eee",
    marginBottom: "20px",
  },
}));

export const Button = forwardRef(
  ({ active, reversed, icon, ...props }, ref) => {
    return (
      <IconButton
        {...props}
        ref={ref}
        sx={{
          background: active ? "#e1e1e1" : "white",
          borderRadius: "5px",
          m: "1px",
        }}
      >
        {icon}
      </IconButton>
    );
  }
);

export const EditorValue = forwardRef(({ className, value, ...props }, ref) => {
  const classes = useStyles();
  const textLines = value.document.nodes
    .map((node) => node.text)
    .toArray()
    .join("\n");
  return (
    <Box ref={ref} {...props} className={`${classes.editorValue} ${className}`}>
      <Typography className={classes.header}>Slate's value as text</Typography>
      <Typography className={classes.content}>{textLines}</Typography>
    </Box>
  );
});

export const Icon = forwardRef(({ ...props }, ref) => (
  <IconButton {...props} ref={ref} />
));

export const Instruction = forwardRef(({ className, ...props }, ref) => {
  const classes = useStyles();
  return (
    <Box
      {...props}
      ref={ref}
      className={`${classes.instruction} ${className}`}
    />
  );
});

export const Portal = ({ children }) => {
  return typeof document === "object"
    ? ReactDOM.createPortal(children, document.body)
    : null;
};

export const Toolbar = forwardRef(({...props }, ref) => {
  return (
    <Paper sx={{borderRadius:props?.sx?.borderRadius}}>
     <Box {...props} ref={ref} />
    </Paper>
  );
});
