export const getContentMetadataPending = (state, action) => {
  state.contentMetadata.status = "pending";
};

export const getContentMetadataFulfilled = (state, action) => {
  state.contentMetadata.status = "fulfilled";
  state.contentMetadata.data = action.payload;
};

export const getContentMetadataRejected = (state, action) => {
  state.contentMetadata.status = "rejected";
};
