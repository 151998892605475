import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useRef } from "react";
import useGetStrapiProductHandler from "../../hooks/useGetStrapiProductHandler";
import LogoLoader from "../LogoLoader";

export default function StripeProductsComponent({ formik }) {
  const { isSuccess, isLoading, products } = useGetStrapiProductHandler();
  const theme = useTheme();
  const isMD = useMediaQuery(theme.breakpoints.up("md"));

  // Ref to store the element for the FREE product
  const freeProductRef = useRef(null);

  useEffect(() => {
    // Automatically scroll to the FREE product if found
    if (freeProductRef.current) {
      freeProductRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSuccess]); // Trigger scroll when products are successfully fetched

  const background = (id) => {
    const colorMap = {
      0: theme.palette.red.main,
      1: theme.palette.yellow.main,
      2: theme.palette.green.main,
      3: theme.palette.dark.main,
    };
    return colorMap[id] || theme.palette.grey.main;
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          left: isMD ? "45%" : "40%",
          top: isMD ? "45%" : "40%",
        }}
      >
        <LogoLoader open={isLoading} needBackDrop={false} />
      </Box>
        <Stack
          width={"100%"}
          justifyContent={"center"}
          flexWrap={"wrap"}
          direction={"row"}
          sx={{
            height: isMD ? "initial" : "350px",
            overflow: "auto",
          }}
        >
          {isSuccess &&
            products.map((product, idx) => (
              <Box
                key={product.id}
                ref={
                  product.id === process.env.REACT_APP_STRIPE_PRODUCT_FREE
                    ? freeProductRef // Assign ref to the FREE product
                    : null
                }
                onClick={() => {
                  formik.setFieldValue("productId", product.id);
                  formik.setFieldValue("priceId", product.price.id);
                  if (
                    product.id === process.env.REACT_APP_STRIPE_PRODUCT_FREE
                  ) {
                    formik.setFieldValue("paymentId", "");
                  }
                }}
                sx={{
                  margin: "10px",
                  cursor: "pointer",
                  height: 300,
                  px: 2,
                  width: 200,
                  borderStartEndRadius: "50px",
                  borderEndStartRadius: "50px",
                  color:
                    formik.values.productId === product.id ? "#fff" : "#9d9d9d",
                  background:
                    formik.values.productId === product.id
                      ? background(idx)
                      : "#f7f7f7",
                }}
              >
                <Stack
                  height={"100%"}
                  width={"100%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography variant="h5" fontWeight={500}>
                    {product.name}
                  </Typography>
                  {product.metadata.isFree ? (
                    <Typography variant="h4" fontWeight={"bold"}></Typography>
                  ) : (
                    <>
                      <Typography variant="h4" fontWeight={"bolder"}>
                        ${product.price.unit_amount / 100}
                        <small>/{product.price.recurring.interval}</small>
                      </Typography>
                    </>
                  )}

                  {product.metadata.isFree ? (
                    <Typography variant="body2" fontWeight={200}>
                      <b> {product.metadata.credits}</b> credits,
                      <b> {product.metadata.free_trail_days} days</b>
                    </Typography>
                  ) : (
                    <>
                      <br />
                      <Typography variant="body2" fontWeight={200}>
                        <b>{product.metadata.credits}</b> flat rate credits
                      </Typography>

                      <Typography variant="body2" fontWeight={200}>
                        Addt’l: <b>${product.metadata.unit_usage}</b>
                      </Typography>
                    </>
                  )}
                  <br></br>
                </Stack>
              </Box>
            ))}
        </Stack>
        <Box sx={{ marginTop: "0px !important", width: "85%" }}>
          <Typography color="error" textAlign={"left"}>
            {formik.errors.productId}
          </Typography>
        </Box>
    </>
  );
}
