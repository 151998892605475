import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentAudienceData,
  setCurrentAudience,
  resetCurrentAudience
} from "../redux/audience";

export default function useCurrentAudienceHandler() {
  const dispatch = useDispatch();
  const data = useSelector((state) => selectCurrentAudienceData(state));

  const selectAudience = (audience) => {
    dispatch(setCurrentAudience(audience));
  };

  const onResetCurrentAudience = () => {
    dispatch(resetCurrentAudience());
  };

  return {
    currentAudience: data,
    selectAudience,
    onResetCurrentAudience
  };
}
