import { useDraggable, useDroppable } from "@dnd-kit/core";
import { Box, Stack, Typography, useTheme} from "@mui/material";
import { CSS } from "@dnd-kit/utilities";
import { DragAndDropIcon } from "../../icons";

const Droppable = (props) => {
  const { setNodeRef, isOver } = useDroppable({
    id: "cart-droppable",
  });
  const theme = useTheme();

  return (
    <Box
      ref={setNodeRef}
      sx={{
        display: "flex",
        padding: "3px",
        minHeight: "8rem",
        border: props.error
          ? "3px dashed red"
          : isOver
          ? `3px dashed ${theme.palette.success.main}`
          : "3px dashed #bdbdbd",
        borderRadius: "10px",
        flexWrap: "wrap",
        justifyContent:"center",
        marginTop:'15px',
        transition:'0.2s'
      }}
    >
      {props.items.length === 0 && (
        <Stack justifyContent={"center"} width={'100%'} alignItems={"center"}>
          <DragAndDropIcon />
          <Typography color={"gray"} fontSize={'10px'}>
            Drag the personas you want to add to your audience in here.
          </Typography>
        </Stack>
      )}
      <Stack justifyContent={"center"}
      flexWrap={'wrap'}
      width={'92%'} alignItems={"center"} flexDirection={'row'}>
      {props.items.map((item, idx) => (
        <Box
          key={idx}
          sx={{
            height: "fit-content",
          }}
        >
          <Draggable persona={item}>{item.title}</Draggable>
        </Box>
      ))}
      </Stack>
    </Box>
  );
};

const Draggable = (props) => {
  const { attributes, isDragging,listeners, setNodeRef, transform } =
    useDraggable({
      id: props.children,
      data: { title: props.children, id: props.persona.id },
    });

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{
        transform: CSS.Translate.toString(transform),
        cursor: isDragging ? "grabbing" : "grab",
        zIndex: isDragging ? 10000 : "initial",
      }}
    >
      {props.children}
    </div>
  );
};
const DragNDrop = {
  Droppable,
  Draggable,
}
export default DragNDrop;

/*
export default function DragAndDrop() {
  const { personas, getPersonasActive } = usePersonaListHandler();
  const [boxItems, setboxItems] = useState([]);
  useEffect(() => {
    getPersonasActive();
  }, []);
  const addItemsToBox = (e) => {
    const newItem = e.active.data.current?.title;
    if (e.over?.id !== "cart-droppable" || !newItem) return;
    const temp = [...boxItems];
    if(temp.includes(newItem)) return
    temp.push(newItem);
    setboxItems(temp);
  };

  return (
    <Stack direction={'row'} sx={{ backgroundColor: "red" }}>
      <DndContext onDragEnd={addItemsToBox}>
        {personas.map((persona) => (
          <Draggable key={persona.id}>{persona.name}</Draggable>
        ))}
        <h1>My Cart</h1>
        <Droppable items={boxItems}></Droppable>
      </DndContext>
    </Stack>
  );
}*/
