export const getAllUsersPending = (state, action) => {
  state.allUsers.status = "pending";
};

export const getAllUsersFulfilled = (state, action) => {
  state.allUsers.status = "fulfilled";
  state.allUsers.data = action.payload.result;
};

export const getAllUsersRejected = (state, action) => {
  state.allUsers.status = "rejected";
};
