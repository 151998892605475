import * as React from "react";
import { Typography, Box, Button, Grid, Stack } from "@mui/material";
import PersonaBuilderForm from "./builder";
import { PersonaList } from "./list";
import { AudienceIcon } from "../../../../icons";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import { usePersonaBuilderModeHandler } from "../../../../hooks/usePersonaBuilderModeHandler";
import useCurrentOrganizationHandler from "../../../../hooks/useCurrentOrganizationHandler";
import { useNavigate } from "react-router-dom";
import { LeftPaneComponent } from "../../../../components";
import useGetOrgUserProfile from "../../../../hooks/useGetOrgUserProfileHanlder";

export default function PersonaModule() {
  const { canManagePersona } = useGetOrgUserProfile();
  const { onSetMode, builderMode } = usePersonaBuilderModeHandler();
  const { currentOrganization } = useCurrentOrganizationHandler();
  const navigate = useNavigate();

  const isBuilderMode = ["edit", "create", "fastlane", "builder_option"].includes(builderMode);

  return (
    <Box>
      {isBuilderMode ? (
        <PersonaBuilderForm onBack={() => onSetMode("view", null)} />
      ) : (
        <Grid container sx={{ height: "100vh" }}>
          <LeftPaneComponent>
          <Stack pt={"70px"} justifyContent="center" alignItems="center" spacing={2}>
              <SensorOccupiedIcon sx={{ fontSize: "100px" }} />
              <Typography variant="h4">Persona Builder</Typography>
              <Typography variant="body1" textAlign="left" fontWeight={100}>
                A persona is a fictional character that represents a specific segment
                of a company’s audience. Create as many personas as you can to make
                the best use of Subatomic. Once you create these personas, you can
                categorize them into audiences for even better targeting.
              </Typography>
            </Stack>
            <Button
              fullWidth
              variant="contained"
              disabled={!canManagePersona}
              onClick={() => onSetMode("builder_option", null)}
            >
              + Start a new persona
            </Button>
            <Button
              fullWidth
              color="dark"
              variant="contained"
              onClick={() => navigate(`/organization/${currentOrganization.id}/audience`)}
              startIcon={<AudienceIcon small={true} />}
            >
              VIEW AND CREATE AUDIENCE
            </Button>
          </LeftPaneComponent>
          <Grid item xs={10} sx={{ px: 10, pt: "70px" }}>
            <PersonaList />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
