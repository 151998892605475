import { useDispatch, useSelector } from "react-redux";
import { contentAPIs } from "../api_";
import { selectCurrentOrganization } from "../redux/organizations";
import {
  removeFromAnalyzeExportQueue,
  selectAnalyzeExportData,
  selectAnalyzeExportError,
  selectAnalyzeExportQueue,
  selectAnalyzeExportStatus,
} from "../redux/content";

export default function useGetAnalyzeExportHandler() {
  const dispatch = useDispatch();
  const data = useSelector((state) => selectAnalyzeExportData(state));
  const error = useSelector((state) => selectAnalyzeExportError(state));
  const status = useSelector((state) => selectAnalyzeExportStatus(state));

  const queue = useSelector((state) => selectAnalyzeExportQueue(state));
  const currentOrganization = useSelector((state) =>
    selectCurrentOrganization(state)
  );

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onGetAnalyzeExportPDF = (contentId, versionId) => {
    dispatch(
      contentAPIs.getAnalyzeExportPDF({
        orgId: currentOrganization.id,
        contentId,
        versionId,
      })
    );
  };

  const removeFromQueue = (idx) => dispatch(removeFromAnalyzeExportQueue(idx));

  return {
    data,
    queue,
    error,
    onGetAnalyzeExportPDF,
    removeFromQueue,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
