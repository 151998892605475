import { Avatar, Stack } from "@mui/material";
import { BootstrapTooltip } from "../../../../../components";
import useColorHandler from "../../../../../hooks/useColorHandler";

export default function OwnerAvatar({params}) {
  const { stringAvatar } = useColorHandler();

  return (
    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
      <BootstrapTooltip title={params.value}>
        <Avatar
          sx={{
            width: 35,
            height: 35,
          }}
          {...stringAvatar(params.value)}
        />
      </BootstrapTooltip>
    </Stack>
  );
}
