import { useState, useEffect, useCallback } from "react";
import { Typography, Button } from "@mui/material";
import useGetOrgUserProfile from "../../../../../hooks/useGetOrgUserProfileHanlder";
import useUploadContentTextHandler from "../../../../../hooks/useUploadContentTextHandler";
import { useNavigate } from "react-router-dom";
import useCurrentOrganizationHandler from "../../../../../hooks/useCurrentOrganizationHandler";
import { FoldersSelect, Modal } from "../../../../../components";
import { AddContentIcon } from "../../../../../icons";
import useGenerateDraftContentHandler from "../../../../../hooks/useGenerateDraftContentHandler";

export default function ModalChooseFolder({ formikSaveDraft }) {
  const { canManageOrganizations } = useGetOrgUserProfile();
  const { isLoading, isSuccess } = useUploadContentTextHandler();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { currentOrganization } = useCurrentOrganizationHandler();
  const { data, isLoading: isGenerating } = useGenerateDraftContentHandler();

  const handleClose = useCallback(() => {
    if (!isLoading) {
      setOpen(false);
    }
  }, [isLoading, setOpen]);

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      navigate(`/organization/${currentOrganization.id}/content_library`);
    }
  }, [isSuccess, handleClose, currentOrganization, navigate]);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {canManageOrganizations && (
        <Button
          color="success"
          size="small"
          startIcon={<AddContentIcon></AddContentIcon>}
          variant="contained"
          onClick={handleOpen}
          disabled={!data || isGenerating}
        >
          Save content Library
        </Button>
      )}
      <Modal
        maxWidth="sm"
        title={"Save in Content Library"}
        open={open}
        actions={
          <>
            <Button
              startIcon={<AddContentIcon></AddContentIcon>}
              variant="contained"
              onClick={formikSaveDraft.handleSubmit}
              color="green"
            >
              Save
            </Button>
          </>
        }
        body={
          <>
            <Typography variant="overline">
              To proceed to save it in Content Library please choose a folder
            </Typography>
            <br></br>
            <FoldersSelect
              formik={formikSaveDraft}
              name={`folder`}
              value={formikSaveDraft?.values?.folder}
              error={
                formikSaveDraft.touched.folder &&
                Boolean(formikSaveDraft.errors.folder)
              }
            ></FoldersSelect>
          </>
        }
        handleClose={handleClose}
      />
    </>
  );
}
