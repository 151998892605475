import resetChannelsList from "./cases/resetChannelsList";
import resetCurrentOrganization from "./cases/resetCurrentOrganization";
import resetInviteUser from "./cases/resetInivteUser";
import resetPostOrganization from "./cases/resetPostOrganization";
import resetUsersList from "./cases/resetUsersList";
import setCurrentOrganization from "./cases/setCurrentOrganization";
import resetOrgUserProfile from "./cases/resetOrgUserProfile";
import resetDisableOrganization from "./cases/resetDisableOrganization";
import resetDisableOrganizationUser from "./cases/resetDisableOrganizationUser";
import setPreviousOrganization from "./cases/setPreviousOrganization";
import resetOrgList from "./cases/resetOrgList";


const cases = {
  setCurrentOrganization,
  resetChannelsList,
  resetUsersList,
  resetPostOrganization,
  resetCurrentOrganization,
  resetInviteUser,
  resetOrgUserProfile,
  resetDisableOrganizationUser,
  resetDisableOrganization,
  setPreviousOrganization,
  resetOrgList
};

export default cases;
