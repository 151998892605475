import { useDispatch, useSelector } from "react-redux";
import { contentAPIs } from "../api_";
import {
  resetAnalyzeContent,
  resetAnalyzeSingleContent,
  selectAnalyzeContentData,
  selectAnalyzeContentStatus,
  selectAnalyzeSingleContentStatus,
  selectContentToAnalyzeData,
  setAnalyzeSingleContent,
  setContentToAnalyze,
} from "../redux/content";
import { selectCurrentOrganization } from "../redux/organizations";
import useGetAnalyzedContent from "./useGetAnalyzedContent";
import { useEffect } from "react";
import {
  pushAnalyzeContentQueue,
  selectAnalyzeContentQueue,
} from "../redux/utilities";

export default function useAnalyzeContentHandler(readOnly = false) {
  const dispatch = useDispatch();
  const { onGotToAnalyze, isUninitialized: isUninitializedAnalyzedContent } =
    useGetAnalyzedContent();
  const status = useSelector((state) => selectAnalyzeContentStatus(state));
  const data = useSelector((state) => selectAnalyzeContentData(state));
  const analyzeQueue = useSelector((state) => selectAnalyzeContentQueue(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  const contentToAnalyze = useSelector((state) =>
    selectContentToAnalyzeData(state)
  );
  const analyzeSingleContentStatus = useSelector((state) =>
    selectAnalyzeSingleContentStatus(state)
  );

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onAnalyzeContent = (content) => {
    dispatch(setContentToAnalyze(content));
    dispatch(resetAnalyzeContent());
    if (content) {
      dispatch(
        contentAPIs.postAnalyzeContent({
          orgId: currentOrg.id,
          id: content.id,
        })
      );
    }
  };

  useEffect(() => {
    if (readOnly) return;
    if (isSuccess) {
      if (data?.message === "success" && isUninitializedAnalyzedContent) {
        dispatch(resetAnalyzeContent());
        // onGetAnalyzedContent(data.content_id);
        onGotToAnalyze(data.content_id);
        return;
      }
      if (data?.message === "in queue") {
        if (contentToAnalyze) {
          if (contentToAnalyze.isAnalyze) {
            dispatch(
              setAnalyzeSingleContent({
                orgId: currentOrg.id,
                content_id: contentToAnalyze.id,
                status: data?.message,
                title: contentToAnalyze.title,
              })
            );
            return;
          }
          dispatch(
            pushAnalyzeContentQueue({
              orgId: currentOrg.id,
              content_id: contentToAnalyze.id,
              status: data?.message,
              title: contentToAnalyze.title,
            })
          );
        }
        dispatch(resetAnalyzeContent());
        return;
      }
    }
  }, [
    isSuccess,
    contentToAnalyze,
    currentOrg?.id,
    data?.content_id,
    data?.message,
    dispatch,
    isUninitializedAnalyzedContent,
    onGotToAnalyze,
    readOnly,
  ]);

  const onResetAnalyzeSingleContent = () => {
    dispatch(resetAnalyzeSingleContent());
  };

  const onResetAnalyzeContent = () => {
    dispatch(resetAnalyzeContent());
  };

  return {
    analyzeData: data,
    onAnalyzeContent,
    onResetAnalyzeSingleContent,
    onResetAnalyzeContent,
    isUninitialized,
    analyzeSingleContentStatusLoading:
      analyzeSingleContentStatus === "in queue",
    analyzeSingleContentStatusSuccess: analyzeSingleContentStatus === "success",
    isLoading,
    isError,
    isSuccess,
    analyzeQueue,
  };
}
