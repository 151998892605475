export default function acceptRejectButtons(
    e,
  theme,
  showToolbar,
  darken,
  buttonsContainer,
  value,
  rejectChange,
  acceptChange
) {
  const rect = e.getBoundingClientRect();
  const buttonContainerDiv = document.createElement("div");
  const tooltipContainerRelative = document.createElement("div");
  const tooltipContainer = document.createElement("div");
  tooltipContainer.style.marginTop = `${
    showToolbar ? rect.top - 110 : rect.top - 70
  }px`;
  tooltipContainer.style.left = `20px`;
  tooltipContainer.style.position = "absolute";
  tooltipContainer.style.height = `${rect.height}px`;
  tooltipContainer.style.width = `${rect.width}px`;
  tooltipContainer.style.zIndex = "100";
  tooltipContainer.style.cursor = "pointer";
  tooltipContainer.style.zIndex = "11110";
  tooltipContainerRelative.style.position = "relative";
  tooltipContainerRelative.style.height = `${rect.height}px`;
  tooltipContainerRelative.style.width = `${rect.width}px`;
  const tooltipDiv = document.createElement("div");
  tooltipDiv.style.position = "absolute";
  tooltipDiv.style.top = `${0}px`;
  tooltipDiv.style.left = `0px`;
  tooltipDiv.style.backgroundColor = "#FFF0CE";
  tooltipDiv.style.borderRadius = "5px";
  tooltipDiv.style.padding = "5px";
  tooltipDiv.style.fontSize = "12px";
  tooltipDiv.style.color = darken(theme.palette.warning.main, 0.2);
  tooltipDiv.style.textAlign = "center";
  tooltipDiv.style.boxShadow = "0 0 3px rgba(0, 0, 0, 0.5)";
  tooltipDiv.style.visibility = "hidden";
  tooltipDiv.style.transition = "visibility 0.2s, opacity 0.2s";
  tooltipDiv.style.maxWidth = "200px";
  tooltipDiv.style.opacity = "0";
  tooltipDiv.style.zIndex = "11110";
  value.forEach((node, index) => {
    node.children.forEach((textNode, i) => {
      if (
        textNode?.id?.toString() === e.id &&
        textNode.status === "suggested"
      ) {
        tooltipDiv.innerHTML = `Rationale:${textNode?.rationale}`;
      }
    });
  });
  tooltipContainer.addEventListener("mouseenter", () => {
    tooltipDiv.style.visibility = "visible";
    tooltipDiv.style.opacity = "1";
  });
  tooltipContainer.addEventListener("mousemove", (event) => {
    const offsetX = 20;
    const offsetY = 20;
    tooltipDiv.style.left = `${event.offsetX + offsetX}px`;
    tooltipDiv.style.top = `${event.offsetY + offsetY}px`;
  });
  tooltipContainer.addEventListener("mouseleave", () => {
    tooltipDiv.style.visibility = "hidden";
    tooltipDiv.style.opacity = "0";
  });
  buttonContainerDiv.style.position = "absolute";
  buttonContainerDiv.style.top = `${
    showToolbar ? rect.top - 110 : rect.top - 70
  }px`;
  buttonContainerDiv.style.background = theme.palette.dark.main;
  buttonContainerDiv.style.borderRadius = "10px 10px 10px 0px";
  buttonContainerDiv.style.marginLeft = "6px";
  buttonContainerDiv.style.marginTop = "-5px";
  buttonContainerDiv.style.padding = "4px";
  buttonContainerDiv.style.width = "10px";
  buttonContainerDiv.style.height = "10px";
  buttonContainerDiv.style.display = "flex";
  buttonContainerDiv.style.flexDirection = "row";
  buttonContainerDiv.style.justifyContent = "center";
  buttonContainerDiv.style.cursor = "pointer";

  buttonContainerDiv.style.transition = "0.4s";

  buttonContainerDiv.addEventListener("mouseenter", () => {
    buttonContainerDiv.style.width = "90px";
    buttonContainerDiv.style.height = "20px";

    setTimeout(() => {
      rejectButton.style.opacity = "1";
      acceptButton.style.opacity = "1";
      rejectButton.style.visibility = "visible";
      acceptButton.style.visibility = "visible";
    }, 100);
  });

  buttonContainerDiv.addEventListener("mouseleave", () => {
    buttonContainerDiv.style.width = "10px";
    buttonContainerDiv.style.height = "10px";

    setTimeout(() => {
      rejectButton.style.opacity = "0";
      acceptButton.style.opacity = "0";
      rejectButton.style.visibility = "hidden";
      acceptButton.style.visibility = "hidden";
    }, 0);
  });
  const rejectButton = document.createElement("button");
  rejectButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="${theme.palette.error.main}"><path d="M0 0h24v24H0z" fill="none"/><path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"/></svg>`; // Reemplaza con el ícono deseado
  rejectButton.style.cursor = "pointer";
  rejectButton.style.border = "none";

  rejectButton.style.backgroundColor = "#fff0";
  rejectButton.onclick = () => {
    let pathsToReject = [];
    value.forEach((node, index) => {
      node.children.forEach((textNode, i) => {
        if (
          textNode?.id?.toString() === e.id &&
          textNode.status === "suggested"
        ) {
          tooltipDiv.innerHTML = `Rationale:${textNode?.rationale}`;
          pathsToReject.push([
            [index, i - 1],
            [index, i],
          ]);
        }
      });
    });
    pathsToReject.forEach(([acceptPath, rejectPath]) => {
      rejectChange(acceptPath, rejectPath);
    });
  };

  const acceptButton = document.createElement("button");
  acceptButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="${theme.palette.success.main}"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>`; // Reemplaza con el ícono deseado
  acceptButton.className =
    "MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium";
  acceptButton.style.border = "none";
  acceptButton.style.backgroundColor = "#fff0";
  acceptButton.style.cursor = "pointer";

  acceptButton.onclick = () => {
    let pathsToAccept = [];
    value.forEach((node, index) => {
      node.children.forEach((textNode, i) => {
        if (
          textNode?.id?.toString() === e.id &&
          textNode.status === "to_replace"
        ) {
          pathsToAccept.push([
            [index, i],
            [index, i + 1],
          ]);
        }
      });
    });
    pathsToAccept.forEach(([acceptPath, rejectPath]) => {
      acceptChange(rejectPath, acceptPath);
    });
  };

  rejectButton.style.opacity = "0";
  acceptButton.style.opacity = "0";
  rejectButton.style.visibility = "hidden";
  acceptButton.style.visibility = "hidden";

  buttonContainerDiv.appendChild(rejectButton);
  buttonContainerDiv.appendChild(acceptButton);
  tooltipContainer.appendChild(tooltipDiv);
  buttonsContainer.current.appendChild(tooltipContainer);

  buttonsContainer.current.appendChild(buttonContainerDiv);
}
