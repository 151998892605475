import {
  Box,
  Button,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LogoLoader, Modal } from "../../../../components";
import LoginIcon from "@mui/icons-material/Login";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import useGetStrapiProductHandler from "../../../../hooks/useGetStrapiProductHandler";
import { useSignUpSubscriptionHandler } from "../../../../hooks/useSignupSubscriptionHandler";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StripeProductsComponent from "../../../../components/StripeProductsComponent";
import TabSignUpHeader from "./tabSignUpHeader";
import TermsAndConditions from "./termsAndConditions";

export default function SignupSubscription() {
  const [open, setOpen] = useState(false);
  const [next, setNext] = useState(false);
  const params = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const {
    onSubmitForm,
    isSuccess,
    isLoading: creatingAccount,
    isError,
    error,
  } = useSignUpSubscriptionHandler();

  useEffect(() => {
    if (isSuccess) {
      window.location.reload();
    }
  }, [isSuccess]);
  const {
    isSuccess: productsSuccess,
    isUninitialized,
    products,
    onGetStrapiProducts,
  } = useGetStrapiProductHandler();

  useEffect(() => {
    if (isError) {
      setOpen(isError);
    }
  }, [isError]);

  useEffect(() => {
    if (isUninitialized) {
      onGetStrapiProducts();
    }
  }, [isUninitialized, onGetStrapiProducts]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      productId: null,
      priceId: null,
      paymentId: null,
      accept: false,
    },
    validationSchema: Yup.object({
      priceId: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      onSubmitForm({
        ...values,
        paymentId:
          formik.values.productId === process.env.REACT_APP_STRIPE_PRODUCT_FREE
            ? ""
            : formik.values.paymentId,
      });
    },
  });

  useEffect(() => {
    if (productsSuccess && formik.values.productId === null) {
      const prodId =
        params.product_id || process.env.REACT_APP_STRIPE_PRODUCT_FREE;
      const product = products.find((i) => i.id === prodId);
      formik.setFieldValue("productId", product.id);
      formik.setFieldValue("priceId", product.price.id);
    }
  }, [productsSuccess, formik, params.product_id, products]);

  return (
    <Grid container spacing={1} mb={5}>
      <Modal
        maxWidth="xs"
        open={open}
        title={"Sign Up"}
        handleClose={() => setOpen(false)}
        body={
          <>
            <Typography variant="subtitle1">
              There was an error while processing your data.
            </Typography>
            <Typography variant="subtitle2">Reason: </Typography>
            <Typography variant="caption" sx={{ color: "red !important" }}>
              {error?.error}{" "}
            </Typography>
          </>
        }
      ></Modal>
      <LogoLoader
        open={creatingAccount}
        needBackDrop={true}
        text={"Creating Subscription"}
      ></LogoLoader>
      <Grid
        container
        sx={{
          display: next ? "block" : "none",
        }}
      >
        <Grid item xs={1}>
          <IconButton
            variant="contained"
            color="dark"
            onClick={() => setNext(false)}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <TabSignUpHeader title={"Terms and Conditions"} />
        </Grid>
        <Grid item xs={12}>
          <TermsAndConditions />
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                name={"accept"}
                checked={formik.values.accept}
                onChange={formik.handleChange}
              />
            }
            label="Accept Terms and Conditions"
            labelPlacement="end"
          />
          <Button
            disabled={!formik.values.accept}
            endIcon={<LoginIcon />}
            variant="contained"
            color="success"
            fullWidth
            onClick={formik.handleSubmit}
          >
            Finish
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          display: next ? "none" : "block",
        }}
      >
        <Grid item xs={12}>
          <TabSignUpHeader title={"Subscription Details"} />
        </Grid>
        <Grid item xs={12}>
          <StripeProductsComponent formik={formik} />
        </Grid>

        {
          <Box
            sx={{
              display:
                formik.values.productId ===
                process.env.REACT_APP_STRIPE_PRODUCT_FREE
                  ? "none"
                  : "block",
            }}
          >
            <Grid item xs={12}>
              <TabSignUpHeader title={"Payment Method"} />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  border: "1px solid #c2c2c2",
                  padding: "8px 0px 8px 11px",
                  width: "98%",
                  borderRadius: "50px",
                }}
              >
                <CardElement
                  id="card"
                  onChange={async () => {
                    if (!stripe || !elements) {
                      return;
                    }
                    const cardElement = elements.getElement(CardElement);
                    const { error, paymentMethod } =
                      await stripe.createPaymentMethod({
                        type: "card",
                        card: cardElement,
                      });
                    if (error) {
                      formik.setFieldError("paymentId", error.message);
                      return;
                    }
                    formik.setFieldValue(
                      "paymentId",
                      paymentMethod ? paymentMethod.id : ""
                    );
                  }}
                />
              </Box>
              {formik.errors.paymentId ? (
                <Box
                  sx={{
                    height: 2,
                    mb: 3,
                    width: "100%",
                  }}
                >
                  <Typography
                    color="error"
                    textAlign={"left"}
                    variant="subtitle2"
                  >
                    {" "}
                    {formik.errors.paymentId}
                  </Typography>
                </Box>
              ) : (
                <Typography textAlign={"left"} variant="body1">
                  <Typography
                    fontWeight={100}
                    variant="caption"
                    textAlign={"left"}
                    width={"100%"}
                  >
                    Powered by Stripe
                  </Typography>
                </Typography>
              )}
            </Grid>
          </Box>
        }

        <Grid item xs={12} mt={2}>
          <Button
            endIcon={<LoginIcon />}
            variant="contained"
            color="success"
            fullWidth
            onClick={async () => {
              if (formik.values.priceId && formik.values.productId) {
                if (
                  formik.values.productId !==
                  process.env.REACT_APP_STRIPE_PRODUCT_FREE
                ) {
                  if (!formik.values.paymentId) {
                    formik.setFieldError(
                      "paymentId",
                      "Payment Method is required"
                    );
                    return;
                  }
                }
                setNext(true);
              }
            }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
