import { useDispatch, useSelector } from "react-redux";
import {
  resetGeAPIToken,
  selectGetAPIKeyData,
  selectGetAPIKeyError,
  selectGetAPIKeyStatus,
} from "../redux/admin";
import { adminAPIs } from "../api_";

export default function useAdminGetAPIKeyHandler() {
  const dispatch = useDispatch();
  const data = useSelector((state) => selectGetAPIKeyData(state));
  const status = useSelector((state) => selectGetAPIKeyStatus(state));
  const error = useSelector((state) => selectGetAPIKeyError(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onGetAPIKey = () => {
    dispatch(adminAPIs.getAPIKey());
  };

  const onResetGetAPIKey = () => {
    dispatch(resetGeAPIToken());
  };

  return {
    apiKey: data,
    error,
    onGetAPIKey,
    onResetGetAPIKey,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
