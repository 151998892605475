const AUTH_PATH = "/api/auth";
const ORGANIZATION_PATH = "/api/organizations";
const CONTENT_PATH = "/content";
const ROLE_PATH = "/api/roles";
const AVATAR = "/api/avatar"
const CHANNEL_PATH = "/channels";
const AUDIENCE_PATH = "/audience";
const BRAND_PATH = "/brand";
const STRAPI_PATH = "/api/stripe";
const ADIM_PATH = "/api/admin"

const routes = {
  auth: {
    login: () => `${AUTH_PATH}/login`,
    signup: () => `${AUTH_PATH}/signup`,
    signupAccountOwner: () => `${AUTH_PATH}/signup/account_owner`,
    signupSubscription: () => `${AUTH_PATH}/signup/subscription`,
    accountProfiles: () => `${AUTH_PATH}/accounts`,
    refreshToken: () => `${AUTH_PATH}/refresh_token`,
    resetPassword: () => `${AUTH_PATH}/reset_password`,
    setNewPassword: () => `${AUTH_PATH}/set_new_password`,
    postAutoIngestion: () => `/api/autoingestion`,
  },
  admin:{
    rootPath: () => ADIM_PATH,
    getAllUser: ()=> `${ADIM_PATH}/all_users`,
      generateAPIKey: ()=>`${ADIM_PATH}/api_key`,
      getAPIKey: () =>`${ADIM_PATH}/api_key`
  },
  organization: {
    rootPath: () => ORGANIZATION_PATH,
    organizationRootPath: (id) => `${ORGANIZATION_PATH}/${id}`,
    byUser: (owne_id) => `${ORGANIZATION_PATH}/by_user/${owne_id}`,
    users: (id) => `${ORGANIZATION_PATH}/${id}/users`,
    organizationUser:(id, orgUserId) => `${ORGANIZATION_PATH}/${id}/user/${orgUserId}`,
    uploadFile: (id) => `${ORGANIZATION_PATH}/${id}/import/content/upload`,
    inviteUser:(id) => `${ORGANIZATION_PATH}/${id}/add_user`,
    orgUserProfile:(id) =>`${ORGANIZATION_PATH}/${id}/profile`,
    content:{
      rootPath:(id)=>`${ORGANIZATION_PATH}/${id}/${CONTENT_PATH}`,
      listBlobs:(id, folder, filter)=>`${ORGANIZATION_PATH}/${id}${CONTENT_PATH}?folder=${folder}&filter=${filter}`,
      uploadContentText: (id)=>`${ORGANIZATION_PATH}/${id}/import/text`,
      createFolder: (id)=>`${ORGANIZATION_PATH}/${id}${CONTENT_PATH}/folder`,
      libraryNavigator: (id,folder)=>`${ORGANIZATION_PATH}/${id}${CONTENT_PATH}/navigator?folder=${folder}`,
      metadata: (id,fileId)=>`${ORGANIZATION_PATH}/${id}${CONTENT_PATH}/navigator/file/${fileId}/metadata`,
      updateContentMetadata: (id,fileId)=>`${ORGANIZATION_PATH}/${id}${CONTENT_PATH}/navigator/file/${fileId}`,
      metadataFolder: (id,folderId)=>`${ORGANIZATION_PATH}/${id}${CONTENT_PATH}/navigator/folder/${folderId}/metadata`,
      analyzeContent: (id,contentId)=>`${ORGANIZATION_PATH}/${id}${CONTENT_PATH}/${contentId}/analyze`,
      sugestionsContent: (id,contentId)=>`${ORGANIZATION_PATH}/${id}${CONTENT_PATH}/${contentId}/suggestions`,
      contentEdits: (id,contentId)=>`${ORGANIZATION_PATH}/${id}${CONTENT_PATH}/${contentId}/edit`,
      brandReactionContent: (id,contentId)=>`${ORGANIZATION_PATH}/${id}${CONTENT_PATH}/${contentId}/brand_reaction`,
      setVersion:(id, contentId, versionId)=>`${ORGANIZATION_PATH}/${id}${CONTENT_PATH}/${contentId}/version/${versionId}`,
      adaptChannel:(id, contentId, channelId)=> `${ORGANIZATION_PATH}/${id}${CONTENT_PATH}/${contentId}/channel/${channelId}/adapt`,
      deleteContent:(id, contentId)=> `${ORGANIZATION_PATH}/${id}${CONTENT_PATH}/${contentId}`,
      exportPDF: (id, contentId, versionId)=>`${ORGANIZATION_PATH}/${id}${CONTENT_PATH}/${contentId}/version/${versionId}/analyze/export`,
      generateDraft: (id)=>`${ORGANIZATION_PATH}/${id}${CONTENT_PATH}/generate/draft`,
    },
    audience:{
      rootPath:(id)=>`${ORGANIZATION_PATH}/${id}${AUDIENCE_PATH}`,
      update:(orgId,id)=>`${ORGANIZATION_PATH}/${orgId}${AUDIENCE_PATH}/${id}`,
      disable:(orgId,id)=>`${ORGANIZATION_PATH}/${orgId}${AUDIENCE_PATH}/${id}/disable`,
      listAudience:(id,filter)=>`${ORGANIZATION_PATH}/${id}${AUDIENCE_PATH}?filter=${filter}`,
    },
    brand:{
      rootPath: (id)=>`${ORGANIZATION_PATH}/${id}${BRAND_PATH}`,
      get: (orgId, id)=>`${ORGANIZATION_PATH}/${orgId}${BRAND_PATH}/${id}`,
      update: (orgId,id)=>`${ORGANIZATION_PATH}/${orgId}${BRAND_PATH}/${id}`,
      delete: (orgId,id)=>`${ORGANIZATION_PATH}/${orgId}${BRAND_PATH}/${id}`,
      getAll: (id)=>`${ORGANIZATION_PATH}/${id}${BRAND_PATH}s`,
    },
    channels:{
      rootPath:(id)=>`${ORGANIZATION_PATH}/${id}${CHANNEL_PATH}`
    }
  },
  persona: {
    rootPath: (id,filter) => `${ORGANIZATION_PATH}/${id}/personas?filter=${filter}`,
    answerPath: (id) => `${ORGANIZATION_PATH}/${id}/answer`,
    createPath: (id) => `${ORGANIZATION_PATH}/${id}/persona`,
    editPath: (orgId, personaId) => `${ORGANIZATION_PATH}/${orgId}/persona/${personaId}`,
    getPath: (orgId, personaId) => `${ORGANIZATION_PATH}/${orgId}/persona/${personaId}`,
    exportPDF: (orgId, personaId) => `${ORGANIZATION_PATH}/${orgId}/persona/${personaId}/export`,
    disablePath: (orgId, personaId) => `${ORGANIZATION_PATH}/${orgId}/persona/${personaId}/disable`
  },
  utility:{
    roles:{
      rootPath:()=>`${ROLE_PATH}`
    },
    avatars:{
      rootPath:()=>`${AVATAR}`
    },
    strapi:{
      products:()=>`${STRAPI_PATH}/products`,
      subscription:()=>`${STRAPI_PATH}/subscription`,
      payment_method:()=>`${STRAPI_PATH}/payment_method`
    }
  }
};

export default routes;


