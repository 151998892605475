import { organizationAPIs } from "../../../api_";
import {
  getPersonasPending,
  getPersonasFulfilled,
  getPersonasRejected
} from "./cases/getPersonas";

import {
  postPersonaPending,
  postPersonaFulfilled,
  postPersonaRejected
} from "./cases/postPersona";

import {
  putPersonaPending,
  putPersonaFulfilled,
  putPersonaRejected
} from "./cases/putPersona";

import {
  postGenerateAnswerPending,
  postGenerateAnswerFulfilled,
  postGenerateAnswerRejected
} from "./cases/postGenerateAnswer";

import { 
  putDisablePersonaFulfilled,
  putDisablePersonaPending,
  putDisablePersonaRejected
} from "./cases/putDisablePersona";

import { 
  getPersonaExportPDFFulfilled,
  getPersonaExportPDFPending,
  getPersonaExportPDFRejected
} from "./cases/getPersonaExportPDF";

import { 
  getPersonaFulfilled,
  getPersonaPending,
  getPersonaRejected
} from "./cases/getPersona";

export const extraReducers = (builder) => {
  builder.addCase(organizationAPIs.getPersonas.pending, getPersonasPending);
  builder.addCase(organizationAPIs.getPersonas.fulfilled, getPersonasFulfilled);
  builder.addCase(organizationAPIs.getPersonas.rejected, getPersonasRejected);

  builder.addCase(organizationAPIs.postPersona.pending, postPersonaPending);
  builder.addCase(organizationAPIs.postPersona.fulfilled, postPersonaFulfilled);
  builder.addCase(organizationAPIs.postPersona.rejected, postPersonaRejected);

  builder.addCase(organizationAPIs.putPersona.pending, putPersonaPending);
  builder.addCase(organizationAPIs.putPersona.fulfilled, putPersonaFulfilled);
  builder.addCase(organizationAPIs.putPersona.rejected, putPersonaRejected);

  builder.addCase(organizationAPIs.putDisablePersona.pending, putDisablePersonaPending);
  builder.addCase(organizationAPIs.putDisablePersona.fulfilled, putDisablePersonaFulfilled);
  builder.addCase(organizationAPIs.putDisablePersona.rejected, putDisablePersonaRejected);

  builder.addCase(organizationAPIs.postGenerateAnswer.pending, postGenerateAnswerPending);
  builder.addCase(organizationAPIs.postGenerateAnswer.fulfilled, postGenerateAnswerFulfilled);
  builder.addCase(organizationAPIs.postGenerateAnswer.rejected, postGenerateAnswerRejected);
  
  builder.addCase(organizationAPIs.getPersonaExportPDF.pending, getPersonaExportPDFPending);
  builder.addCase(organizationAPIs.getPersonaExportPDF.fulfilled, getPersonaExportPDFFulfilled);
  builder.addCase(organizationAPIs.getPersonaExportPDF.rejected, getPersonaExportPDFRejected);

  builder.addCase(organizationAPIs.getPersona.pending, getPersonaPending);
  builder.addCase(organizationAPIs.getPersona.fulfilled, getPersonaFulfilled);
  builder.addCase(organizationAPIs.getPersona.rejected, getPersonaRejected);
};
