import { adminAPIs } from "../../../api_";
import {
  getAllUsersPending,
  getAllUsersFulfilled,
  getAllUsersRejected,
} from "./cases/getAllUsers";

import {
  putPaymentMethodPending,
  putPaymentMethodFulfilled,
  putPaymentMethodRejected,
} from "./cases/putPaymentMethod";

import {
  putUpdateSubscriptionPending,
  putUpdateSubscriptionFulfilled,
  putUpdateSubscriptionRejected,
} from "./cases/putUpdateSubscription";

import {
  cancelSubscriptionPending,
  cancelSubscriptionFulfilled,
  cancelSubscriptionRejected,
} from "./cases/cancelSubscription";

import {
  postGenerateAPIKeyPending,
  postGenerateAPIKeyFulfilled,
  postGenerateAPIKeyRejected,
} from "./cases/postGenerateAPIKey";

import {
  getAPIKeyPending,
  getAPIKeyFulfilled,
  getAPIKeyRejected,
} from "./cases/getAPIKey";

export const extraReducers = (builder) => {
  builder.addCase(adminAPIs.getAllUser.pending, getAllUsersPending);
  builder.addCase(adminAPIs.getAllUser.fulfilled, getAllUsersFulfilled);
  builder.addCase(adminAPIs.getAllUser.rejected, getAllUsersRejected);

  builder.addCase(adminAPIs.putPaymentMethod.pending, putPaymentMethodPending);
  builder.addCase(adminAPIs.putPaymentMethod.fulfilled, putPaymentMethodFulfilled);
  builder.addCase(adminAPIs.putPaymentMethod.rejected, putPaymentMethodRejected);

  builder.addCase(adminAPIs.putUpdateSubscription.pending, putUpdateSubscriptionPending);
  builder.addCase(adminAPIs.putUpdateSubscription.fulfilled, putUpdateSubscriptionFulfilled);
  builder.addCase(adminAPIs.putUpdateSubscription.rejected, putUpdateSubscriptionRejected);

  builder.addCase(adminAPIs.cancelSubscription.pending, cancelSubscriptionPending);
  builder.addCase(adminAPIs.cancelSubscription.fulfilled, cancelSubscriptionFulfilled);
  builder.addCase(adminAPIs.cancelSubscription.rejected, cancelSubscriptionRejected);

  builder.addCase(adminAPIs.postGenerateAPIKey.pending, postGenerateAPIKeyPending);
  builder.addCase(adminAPIs.postGenerateAPIKey.fulfilled, postGenerateAPIKeyFulfilled);
  builder.addCase(adminAPIs.postGenerateAPIKey.rejected, postGenerateAPIKeyRejected);

  builder.addCase(adminAPIs.getAPIKey.pending, getAPIKeyPending);
  builder.addCase(adminAPIs.getAPIKey.fulfilled, getAPIKeyFulfilled);
  builder.addCase(adminAPIs.getAPIKey.rejected, getAPIKeyRejected);
};
