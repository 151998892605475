import { Box, Stack, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
export default function PersonaCard({ persona }) {
  return (
    <Box
      borderRadius={2}
      sx={{
        width: "85px",
        height: "150px",
        backgroundColor: '#1c1c1c',//theme.palette.dark.main,
        p: 1,
        m: 1,
      }}
    >
      <Stack justifyContent={"center"} height={"100%"} alignItems={"center"}>
        <Box
          sx={{
            width: "80px",
            height: "100%",
            backgroundImage: persona.avatar_signed_url
              ? `url(${persona.avatar_signed_url})`
              : "",
            backgroundSize: "cover",
            borderRadius: "5px",
          }}
        >
          {!persona.avatar_signed_url && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#6b6b6b",
                borderRadius: "5px",
                color: "#cccccc",
                height: "77px",
              }}
            >
              <AccountCircleIcon fontSize="large" />
            </Box>
          )}
        </Box>
        <Stack height={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Typography sx={{color:'#fff'}} fontSize={"12px"} variant="body1">
            {persona.description_metadata.jobTitle}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
