import useGetPersonaExportHandler from "../../hooks/useGetPersonaExportHandler";
import { CircularProgress, IconButton } from "@mui/material";
import BootstrapTooltip from "../BootstrapTooltipComponent";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

export default function PersonaExportPDFButton({ persona }) {
  const { onGetPersonaExportPDF, queue } = useGetPersonaExportHandler();

  return (
    <>
      {queue.find((q) => q?.personaId === persona.id)?.status === "pending" ? (
        <CircularProgress
        color="success"
          sx={{
            width: "16px !important",
            height: "16px !important",
            position: "relative",
            top: "5px",
            left: "5px",
          }}
        ></CircularProgress>
      ) : (
        <BootstrapTooltip title={"Export persona profile"}>
          <IconButton
            color={"success"}
            onClick={() => onGetPersonaExportPDF(persona.id)}
            size="small"
          >
            <ArrowCircleDownIcon fontSize="small"></ArrowCircleDownIcon>
          </IconButton>
        </BootstrapTooltip>
      )}
    </>
  );
}
