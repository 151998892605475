import resetStatusCreateFolder from "./cases/resetStatusCreateFolder";
import resetStatusUploadContentText from "./cases/resetStatusUploadContentText";
import resetStatusUploadFile from "./cases/resetStatusUploadFile";
import resetContentMetadata from "./cases/resetContentMetadata";
import resetContentMetadataFolder from "./cases/resetContentMetadataFolder";
import setCurrentFolder from "./cases/setCurrentFolder";
import resetUpdateContentMetadata from "./cases/resetUpdateContentMetadata";
import resetAnalyzeContent from "./cases/resetAnalyzeContent";
import resetAnalyzedContent from "./cases/resetAnalyzedContent";
import setAnalyzedContentStatus from "./cases/setAnalyzedContentStatus";
import resetSuggestionsContent from "./cases/resetSuggestionsContent";
import resetBrandReaction from "./cases/resetBrandReaction";
import resetContentEdits from "./cases/resetContentEdits";
import resetWatchVersion from "./cases/resetWatchVersion";
import resetAdaptContentChannel from "./cases/resetAdaptContentChannel";
import setContentToAnalyze from "./cases/setContentToAnalyze";
import setExecuteAnalyze from "./cases/setExecuteAnalyze";
import setAnalyzeSingleContent from "./cases/setAnalyzeSingleContent";
import updateAnalyzeSingleContent from "./cases/updateAnalyzeSingleContent";
import resetAnalyzeSingleContent from "./cases/resetAnalyzeSingleContent";
import resetAllFolders from "./cases/resetAllFoldersList";
import resetDeleteContent from "./cases/resetDeleteContent";
import removeFromAnalyzeExportQueue from "./cases/removeFromAnalyzeExportQueue";
import resetNavigator from "./cases/resetNavigator";
import resetGenerateDraftContent from "./cases/resetGenerateDraftContent";


const cases = {
  resetStatusUploadFile,
  resetNavigator,
  resetStatusUploadContentText,
  resetStatusCreateFolder,
  resetContentMetadata,
  resetContentMetadataFolder,
  resetUpdateContentMetadata,
  setCurrentFolder,
  resetAnalyzeContent,
  resetAnalyzedContent,
  setAnalyzedContentStatus,
  resetSuggestionsContent,
  resetBrandReaction,
  resetContentEdits,
  resetWatchVersion,
  resetAdaptContentChannel,
  setContentToAnalyze,
  setExecuteAnalyze,
  updateAnalyzeSingleContent,
  setAnalyzeSingleContent,
  resetAnalyzeSingleContent,
  resetAllFolders,
  resetDeleteContent,
  removeFromAnalyzeExportQueue,
  resetGenerateDraftContent
};

export default cases;
