import routes from "../routes";
import { buildUrl } from "../helpers/url";
import { getHeaders } from "../helpers/headers";
import { handleResponse } from "../helpers/handleResponse";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../axios";

const postLogin = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.auth.login()),
        { ...data },
        getHeaders(null)
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const postSignup = createAsyncThunk(
  "auth/signup",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.auth.signup()),
        { ...data },
        getHeaders(null)
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const postResetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.auth.resetPassword()),
        { ...data },
        getHeaders(null)
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const postSetNewPassword = createAsyncThunk(
  "auth/setNewPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.auth.setNewPassword()),
        { password:data.password,
          refresh_token:data.refresh_token,
         },
        getHeaders(false,{
          Authorization: "Bearer "+data.access_token,
        })
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const postSignupAccountOwner = createAsyncThunk(
  "auth/signupAccountOwner",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.auth.signupAccountOwner()),
        { ...data },
        getHeaders(null)
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);
const postSignupSubscription = createAsyncThunk(
  "auth/signupSubscription",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        buildUrl(routes.auth.signupSubscription()),
        { ...data },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const getUserAccounts = createAsyncThunk(
  "auth/getUserAccounts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.auth.accountProfiles()),
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const getRefreshToken = async (data) => {
  try {
    const response = await axiosInstance.post(
      buildUrl(routes.auth.refreshToken()),
      data,
      getHeaders(data.token)
    );
    return handleResponse(response);
  } catch (error) {
    console.error(error);
    return error;
  }
};
const putUpdateProfile = createAsyncThunk(
  "auth/putUpdateProfile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        buildUrl(routes.auth.profile()),
        { ...data },
        getHeaders()
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);


const postAutoIngestion = createAsyncThunk(
  "auth/postAutoIngestion",
  async (data, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append('name',data.name)
    formData.append('last_name',data.last_name)
    formData.append('email',data.email)
    formData.append('organizationName',data.organizationName)
    formData.append('organizationDescription',data.organizationDescription)
    formData.append('file1',data.file1)
    formData.append('file2',data.file2)
    formData.append('file3',data.file3)
    formData.append('url1',data.url1)
    formData.append('url2',data.url2)
    formData.append('url3',data.url3)

    try {
      const response = await axiosInstance.post(
        buildUrl(routes.auth.postAutoIngestion()),
        formData,
        {
          headers: {
          //  Authorization: "Bearer " + data.token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return handleResponse(response);
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

const auth = {
  postLogin,
  postSignup,
  postSignupAccountOwner,
  postSignupSubscription,
  postResetPassword,
  postSetNewPassword,
  getUserAccounts,
  getRefreshToken,
  putUpdateProfile,
  postAutoIngestion
};

export default auth;
