const NewFolderIcon = () => (
  <svg
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_445_7762)">
      <path
        d="M24.1431 19.5454C24.9138 19.5454 25.5359 18.9363 25.5359 18.1818V5.4545C25.5359 4.9545 25.1181 4.54541 24.6074 4.54541"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.464355 1.36368C0.464355 0.863681 0.882213 0.45459 1.39293 0.45459H7.89293L9.28578 2.72732H21.8215C22.3322 2.72732 22.7501 3.13641 22.7501 3.63641V18.1819C22.7501 18.9364 23.3722 19.5455 24.1429 19.5455H2.78578C1.50436 19.5455 0.464355 18.5273 0.464355 17.2728V1.36368Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_445_7762">
        <rect width="26" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default NewFolderIcon;
