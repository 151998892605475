export const getAudiencePending = (state, action) => {
  state.listAudience.status = "pending";
};

export const getAudienceFulfilled = (state, action) => {
  state.listAudience.status = "fulfilled";
  state.listAudience.data = action.payload.result;
};

export const getAudienceRejected = (state, action) => {
  state.listAudience.status = "rejected";
};
