import routes from "../routes";
import { buildUrl } from "../helpers/url";
import { getHeaders } from "../helpers/headers";
import { handleResponse } from "../helpers/handleResponse";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../axios";

const getRoles = createAsyncThunk(
  "utility/getRoles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.utility.roles.rootPath()),
        getHeaders()
      );
      return handleResponse(response, rejectWithValue);
    } catch (error) {
      throw error;
    }
  }
);

const getAvatars = createAsyncThunk(
  "utility/getAvatars",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.utility.avatars.rootPath()),
        getHeaders()
      );
      return handleResponse(response, rejectWithValue);
    } catch (error) {
      console.log(error)
      throw error;
    }
  }
);


const getStrapiProducts = createAsyncThunk(
  "utility/getStrapiProducts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        buildUrl(routes.utility.strapi.products()),
        {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      );
      return handleResponse(response, rejectWithValue);
    } catch (error) {
      console.log(error)
      throw error;
    }
  }
);

const utility = {
  getRoles,
  getAvatars,
  getStrapiProducts
};

export default utility;
