import { useDispatch, useSelector } from "react-redux";
import { contentAPIs } from "../api_";
import {
  resetAllFolders,
  selectGetAllFoldersData,
  selectGetAllFoldersStatus,
} from "../redux/content";
import { selectCurrentOrganization } from "../redux/organizations";

export default function useAllFolderHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectGetAllFoldersStatus(state));
  const data = useSelector((state) => selectGetAllFoldersData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onGetAllFolders = () => {
    dispatch(
      contentAPIs.getAllFolders({
        id: currentOrg.id,
        folder: "",
      })
    );
  };

  const onResetAllFoldersList = () => {
    dispatch(resetAllFolders());
  };

  return {
    folders: data,
    onGetAllFolders,
    onResetAllFoldersList,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
