import { organizationAPIs } from "../../../api_";
import {
  getOrganizationsFulfilled,
  getOrganizationsRejected,
  getOrganizationsPending,
} from "./cases/getOrganizations";
import {
  getOrganizationUsersPending,
  getOrganizationUsersFulfilled,
  getOrganizationUsersRejected,
} from "./cases/getOrganizationUsers";

import {
  getOrganizationChannelsPending,
  getOrganizationChannelsFulfilled,
  getOrganizationChannelsRejected,
} from "./cases/getChannels";

import {
  postOrganizationPending,
  postOrganizationFulfilled,
  postOrganizationRejected,
} from "./cases/postOrganization";

import {
  postInviteUserPending,
  postInviteUserFulfilled,
  postInviteUserRejected,
} from "./cases/postInviteUser";

import {
  getOrgUserProfilePending,
  getOrgUserProfileFulfilled,
  getOrgUserProfileRejected,
} from "./cases/getOrgUserProfile";

import {
  disableOrganizationPending,
  disableOrganizationFulfilled,
  disableOrganizationRejected,
} from "./cases/disableOrganization";

import {
  disableOrganizationUserPending,
  disableOrganizationUserFulfilled,
  disableOrganizationUserRejected,
} from "./cases/disableOrganizationUser";

export const extraReducers = (builder) => {
  builder.addCase(organizationAPIs.getList.pending, getOrganizationsPending);
  builder.addCase(organizationAPIs.getList.fulfilled, getOrganizationsFulfilled);
  builder.addCase(organizationAPIs.getList.rejected, getOrganizationsRejected);

  builder.addCase(organizationAPIs.getOrganizationUsers.pending, getOrganizationUsersPending);
  builder.addCase(organizationAPIs.getOrganizationUsers.fulfilled, getOrganizationUsersFulfilled);
  builder.addCase(organizationAPIs.getOrganizationUsers.rejected, getOrganizationUsersRejected);

  builder.addCase(organizationAPIs.getChannels.pending, getOrganizationChannelsPending);
  builder.addCase(organizationAPIs.getChannels.fulfilled, getOrganizationChannelsFulfilled);
  builder.addCase(organizationAPIs.getChannels.rejected, getOrganizationChannelsRejected);

  builder.addCase(organizationAPIs.postInviteUser.pending, postInviteUserPending);
  builder.addCase(organizationAPIs.postInviteUser.fulfilled, postInviteUserFulfilled);
  builder.addCase(organizationAPIs.postInviteUser.rejected, postInviteUserRejected);

  builder.addCase(organizationAPIs.postOrganization.pending, postOrganizationPending);
  builder.addCase(organizationAPIs.postOrganization.fulfilled, postOrganizationFulfilled);
  builder.addCase(organizationAPIs.postOrganization.rejected, postOrganizationRejected);

  builder.addCase(organizationAPIs.getOrgUserProfile.pending, getOrgUserProfilePending);
  builder.addCase(organizationAPIs.getOrgUserProfile.fulfilled, getOrgUserProfileFulfilled);
  builder.addCase(organizationAPIs.getOrgUserProfile.rejected, getOrgUserProfileRejected);

  builder.addCase(organizationAPIs.disableOrganization.pending, disableOrganizationPending);
  builder.addCase(organizationAPIs.disableOrganization.fulfilled, disableOrganizationFulfilled);
  builder.addCase(organizationAPIs.disableOrganization.rejected, disableOrganizationRejected);

  builder.addCase(organizationAPIs.disableOrganizationUser.pending, disableOrganizationUserPending);
  builder.addCase(organizationAPIs.disableOrganizationUser.fulfilled, disableOrganizationUserFulfilled);
  builder.addCase(organizationAPIs.disableOrganizationUser.rejected, disableOrganizationUserRejected);
};
