import { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Stack,
  Typography,
  Divider,
  Alert,
  AlertTitle,
} from "@mui/material";
import Steps from "./components/Steps";
import { usePersonaBuilderQuestionSelectorHandler } from "../../../../../hooks/usePersonaBuilderQuestionSelectorHandler";
import { useFormik } from "formik";
import * as Yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { usePersonaBuilderModeHandler } from "../../../../../hooks/usePersonaBuilderModeHandler";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import ModalImportPersona from "./components/ModalImportPersona";
import {
  BuilderHeaderComponent,
  LogoLoader,
  ModalConfirmationPersonaBuilder,
  QuestionComponent,
} from "../../../../../components";
import ModalSaveGenerateBioConfirmation from "./components/ModalSaveGenerateBioConfirmation";
import { useNavigate } from "react-router-dom";

export default function PersonaBuilderForm({ onBack }) {
  const [generateBio, setGenerateBio] = useState(false);
  const [jumpQuestion, setJumpQuestion] = useState(false);
  const { onSubStepChange, activeSubStep, activeStep, stepsGrouped } =
    usePersonaBuilderQuestionSelectorHandler();
  const navigate = useNavigate();

  const {
    onResetBuilder,
    onResetPersonaBuilderStatus,
    subNext,
    onSetPersonaBuilderSubNext,
    isSuccess,
    isLoading,
    isError,
    onSubmitForm,
    persona,
    personaPayload,
    builderOn,
    builderMode,
    onSetMode,
  } = usePersonaBuilderModeHandler();
  /*useEffect(() => {
    if (generateBio || jumpQuestion) {
      formik.handleSubmit();
    }
  }, [generateBio, jumpQuestion]);*/

  const formik = useFormik({
    initialValues: stepsGrouped
      .flatMap((category) => category.questions.map((question) => question))
      .reduce((acc, field) => {
        // initialize form values
        if (persona?.id) {
          acc[field.inputName] = persona.description[field.inputName];
          return acc;
        }
        acc[field.inputName] = field.defaultValue;
        return acc;
      }, {}),
    validationSchema: Yup.object({
      jobTitle: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      onSubmitForm(values, persona, generateBio);
    },
  });

  const formKeys = Object.keys(formik.values);
  const stepFormKeys = formKeys.filter((k) => k !== "other");
  const formCompleted =
    (stepFormKeys &&
      formik &&
      stepFormKeys.filter(
        (k) => formik.values[k] !== "" && formik.values[k]?.length
      )?.length / stepFormKeys?.length) * 100;
  useEffect(() => {
    if (isError) {
      setJumpQuestion(false);
      setGenerateBio(false);
      onSetPersonaBuilderSubNext(false);
      return;
    }

    if (isSuccess && subNext) {
      onResetPersonaBuilderStatus();
      onSubStepChange(activeSubStep + 1);
      onSetPersonaBuilderSubNext(false);
      return;
    }
    if (isSuccess && jumpQuestion) {
      setJumpQuestion(false);
      onResetPersonaBuilderStatus();
      return;
    }
    if (isSuccess) {
      if (builderMode === "builder_option") {
        //from success to idle to resent the form
        onResetPersonaBuilderStatus();
        setJumpQuestion(false);
        setGenerateBio(false);
        onSetPersonaBuilderSubNext(false);
        // once the import is done redirect to edit/persona builder
        onSetMode("edit", {
          ...persona,
          description_metadata: persona.description,
        });
        // fillout all fields with the json generated
        stepsGrouped
          .flatMap((category) => category.questions.map((question) => question))
          .reduce((acc, field) => {
            formik.setFieldValue(
              field.inputName,
              persona.description[field.inputName]
            );
            return acc;
          }, {});
        return;
      } else if (isSuccess && generateBio) {
        onResetBuilder(null);
        setGenerateBio(false);

        navigate(`${personaPayload.id}`);
        return;
      }
      onResetBuilder(null);
    }
  }, [
    isSuccess,
    subNext,
    isError,
    onSetPersonaBuilderSubNext,
    onResetPersonaBuilderStatus,
    onSubStepChange,
    activeSubStep,
    builderMode,
    formik,
    generateBio,
    jumpQuestion,
    navigate,
    onResetBuilder,
    onSetMode,
    persona,
    personaPayload?.id,
    stepsGrouped,
  ]);

  const builderActions = (
    <Box
      mt={1.2}
      sx={{
        //backgroundColor:theme.palette.dark.main,
        borderRadius: "10px",
        padding: 0.5,
      }}
    >
      <Stack spacing={2} pt={2} mx={5} mb={2}>
        {builderOn && (
          <ModalSaveGenerateBioConfirmation
            formik={formik}
            setGenerateBio={setGenerateBio}
          />
        )}
        <ButtonGroup variant="text" color="success" fullWidth>
          <Button
            size="small"
            color="success"
            onClick={() => {
              onResetBuilder();
              onBack();
            }}
            startIcon={<ArrowBackIcon></ArrowBackIcon>}
          >
            to Personas List
          </Button>
          {builderOn && personaPayload && (
            <Button
              size="small"
              color="success"
              onClick={() => {
                onResetBuilder();
                navigate(`${persona.id}`);
              }}
              startIcon={<SensorOccupiedIcon></SensorOccupiedIcon>}
            >
              to Persona Viewer
            </Button>
          )}
        </ButtonGroup>
      </Stack>
    </Box>
  );

  return (
    <Box px={2}>
      <LogoLoader
        open={isLoading}
        needBackDrop={true}
        text={"Processing Persona"}
      />
      <Grid container mt={10}>
        {builderOn && (
          <>
            <Grid item md={0} lg={1} xl={1}></Grid>
            <Grid item sm={5} md={4} lg={3} xl={3}></Grid>
            <Grid item xs={2} sm={7} md={8} lg={8} xl={8}>
              <Alert severity="info">
                <AlertTitle>Persona Builder </AlertTitle>
                Note: ATOM AI is available to accelerate the Build process in
                most steps; please keep in mind that ATOM AI usage does consume
                existing credit balance
              </Alert>
            </Grid>
          </>
        )}
        <Grid item md={0} lg={1} xl={1}></Grid>
        <Grid item sm={5} md={4} lg={3} xl={3}>
          <Box>
            <BuilderHeaderComponent
              title={"Persona Builder"}
              progress={formCompleted}
              displayProgress={builderOn}
              icon={<SensorOccupiedIcon sx={{ fontSize: 75 }} />}
            />
            <Steps
              formik={formik}
              onJumpQuestion={() => {
                setJumpQuestion(true);
                formik.handleSubmit();
              }}
            />
            <Divider />
            {builderActions}
          </Box>
        </Grid>
        {builderOn && (
          <>
            <Grid item xs={2} sm={7} md={8} lg={8} xl={8}>
              <QuestionComponent
                formik={formik}
                onSubmitToNextQuestion={(e) => {
                  onSetPersonaBuilderSubNext(true);
                  formik.handleSubmit();
                }}
                onNextQuestion={(direction) => {
                  setJumpQuestion(true);
                  formik.handleSubmit();
                  onSubStepChange(
                    direction === "next" ? activeSubStep + 1 : activeSubStep - 1
                  );
                }}
                activeStep={activeStep}
                questions={stepsGrouped}
                currentQuestion={
                  stepsGrouped[activeStep].questions[activeSubStep]
                }
                activeSubStep={activeSubStep}
              />
            </Grid>
          </>
        )}

        {builderMode === "builder_option" && (
          <Grid item xs={2} sm={4} md={5} lg={6} xl={7}>
            <Stack mt={15} alignItems={"center"} spacing={2}>
              <Box width={"67%"}>
                <Stack justifyContent={"center"} spacing={2}>
                  <Typography variant="h6" fontWeight={200}>
                    In order to create great marketing content, you need to know
                    who you're talking to. This is where personas come in.
                    Personas are like detailed profiles of the different types
                    of people a company wants to reach. With Subatomic Personas
                    just got much easier and precise. Throughout your experience
                    use our Atom AI button to collaborate with AI to build out
                    your Personas.
                  </Typography>
                  <ModalConfirmationPersonaBuilder
                    type={"createFull"}
                    persona={null}
                  />
                  <ModalConfirmationPersonaBuilder
                    type={"createFstLane"}
                    persona={null}
                  />
                  <ModalImportPersona></ModalImportPersona>
                </Stack>
              </Box>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
