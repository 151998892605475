// EDIT PERSONA
export const putPersonaPending = (state, action) => {
  state.builder.status = "pending";
};

export const putPersonaFulfilled = (state, action) => {
  state.builder.status = "fulfilled";
  state.builder.payload = action.payload.persona;
};

export const putPersonaRejected = (state, action) => {
  state.builder.status = "rejected";
  state.builder.error = action.payload.response.data;
};
