import { useDispatch, useSelector } from "react-redux";
import {
  resetBrandBuilderStatus,
  selectBrandBuilderData,
  selectBrandBuilderError,
  selectBrandBuilderMode,
  selectBrandBuilderStatus,
  selectBrandBuilderSubNext,
  setBrandBuilderSubNext,
  setMode,
} from "../redux/brand";
import { brandAPIs } from "../api_";
import useCurrentOrganizationHandler from "./useCurrentOrganizationHandler";

export function useBrandBuilderModeHandler() {
  const dispatch = useDispatch();
  const { currentOrganization } = useCurrentOrganizationHandler();
  const mode = useSelector((state) => selectBrandBuilderMode(state));
  const brand = useSelector((state) => selectBrandBuilderData(state));
  const error = useSelector((state) => selectBrandBuilderError(state));
  const status = useSelector((state) => selectBrandBuilderStatus(state));
  const subNext = useSelector((state) => selectBrandBuilderSubNext(state));
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onResetBuilder = (brand) => {
    dispatch(setMode({ mode: "viewer", brand }));
    dispatch(resetBrandBuilderStatus());
  };

  const onResetBrandBuilderStatus = () => dispatch(resetBrandBuilderStatus());
  const onSetBrandBuilderSubNext = (val) =>
    dispatch(setBrandBuilderSubNext(val));

  const onSetMode = (mode, brand) => {
    //dispatch(selectBrandBuilderWizzard({ activeStep: 0 }));
    dispatch(setMode({ mode, brand }));
  };

  const onSubmitForm = (values, brand, generateBio) => {
    if (!brand.id) {
      dispatch(
        brandAPIs.postBrand({
          orgId: currentOrganization.id,
          values: {
            save_brand_summary: generateBio,
            builder_type: "builder",
            description: values,

            /*builder_type:
              mode === "create" || mode === "fastlane" ? "builder" : "import",
            description:
              mode === "create" || mode === "fastlane"
                ? { ...values }
                : values.description,*/
          },
        })
      );
    }
    if (brand.id) {
      dispatch(
        brandAPIs.putBrand({
          orgId: currentOrganization.id,
          id: brand.id,
          values: {
            builder_type: "builder",
            save_brand_summary: generateBio,
            description: { ...values },
          },
        })
      );
    }
  };

  return {
    brand: {
      id: brand?.id,
      description: brand?.description_metadata,
    },
    onResetBuilder,
    onResetBrandBuilderStatus,
    onSetBrandBuilderSubNext,
    onSubmitForm,
    onSetMode,
    error,
    subNext,
    builderMode: mode,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
