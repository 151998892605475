import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./extraReducers";
import reducers from "./reducers";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    data: {},
    authPreLoad: false,
    login: {
      email: "",
      error: null,
      password: "",
      status: "idle",
    },
    signup: {
      data: {},
      error: null,
      status: "idle",
    },
    resetPassword: {
      data: {},
      error: null,
      status: "idle",
    },
    setNewPassword: {
      data: {},
      error: null,
      status: "idle",
    },
    signupAccountOwner: {
      data: null,
      error: null,
      status: "idle",
    },
    signupSubscription: {
      data: null,
      error: null,
      status: "idle",
    },
    profile: {
      data: null,
    },
    userAccounts: {
      data: null,
      error: null,
      status: "idle",
    },
    updateProfile: {
      data: null,
      error: null,
      status: "idle",
    },
    autoIngestion:{
      data:null,
      error:null,
      status: "idle",
    },
  },
  reducers,
  extraReducers,
});

export default authSlice;
