const resetGenerateDraftContent = (state, action) => {
  if(action.payload?.status){
    state.generateDraftContent.status = action.payload.status;
  }
  if(action.payload?.data){
    state.generateDraftContent.data = action.payload.data;
  }
  if(action.payload?.error){
    state.generateDraftContent.error = action.payload.error;
    return
  }
  if(!action.payload){
    state.generateDraftContent = {
      data: null,
      error: null,
      status: "idle",
    };
  }
};
export default resetGenerateDraftContent;
