//Packages Imports
import { useSelector } from "react-redux";
//App Import
import { useAppDispatch } from "../redux/store";
import {
  selectLoginStatus,
  selectAuthData,
  selectLoginError,
  selectAuthPreLoad,
  setAuthPreLoad,
  selectUserAccountsData,
  selectUserAccountsStatus,
  selectUserAccountsError,
  setCurrentProfile,
  selectProfileData,
} from "../redux/auth";
import { authAPIs } from "../api_";
import { useEffect } from "react";

export function useLoginHandler() {
  const dispatch = useAppDispatch();
  const status = useSelector((state) => selectLoginStatus(state));
  const data = useSelector((state) => selectAuthData(state));
  const authPreLoad = useSelector((state) => selectAuthPreLoad(state));
  const accountsStatus = useSelector((state) => selectUserAccountsStatus(state));
  const accountsData = useSelector((state) => selectUserAccountsData(state));
  const accountsError= useSelector((state) => selectUserAccountsError(state));
  const currentProfile = useSelector((state) => selectProfileData(state));


  const error = useSelector((state) => selectLoginError(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onLogin = (values) => {
    dispatch(authAPIs.postLogin(values));
  };
  // derive status booleans for ease of use
  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem("auth", JSON.stringify(data));
      return;
    }
  }, [isSuccess, data]);

  const onGetProfile = () => {
    if (accountsStatus === "idle" && !accountsData) {
      dispatch(authAPIs.getUserAccounts());
    }
  };

  const onRefreshProfile = () => {
    dispatch(authAPIs.getUserAccounts());
  };

  const onSetAuthPreLoad = () => dispatch(setAuthPreLoad(true));
  const onSetCurrentProfile = (data) => dispatch(setCurrentProfile(data));

  return {
    onLogin,
    onGetProfile,
    onRefreshProfile,
    onSetAuthPreLoad,
    onSetCurrentProfile,
    error,
    data,
    accountsData,
    isUninitialized,
    accountsUninitialized: accountsStatus === "idle",
    accountsLoading: accountsStatus === "pending",
    accountsError: accountsStatus === "rejected",
    accountsSuccess: accountsStatus === "fulfilled",
    accountsErrorData: accountsError,
    currentProfile,
    isLoading,
    isError,
    isSuccess,
    authPreLoad,
  };
}
