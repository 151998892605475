// CREATE PERSONA
export const postPersonaPending = (state, action) => {
  state.builder.status = "pending";
};

export const postPersonaFulfilled = (state, action) => {
  state.builder.status = "fulfilled";
  state.builder.payload = action.payload.new_persona;
  state.builder.data = {id:action.payload.new_persona.id, description_metadata:action.payload.new_persona.description_metadata};
};

export const postPersonaRejected = (state, action) => {
  state.builder.status = "rejected";
  state.builder.error = action.payload.response.data;
};
