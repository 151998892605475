import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import constants from "./constants";
import { Login, ResetPassword, SignupAccountOwner } from "./components";
import { Logo } from "../../../icons";
import { LogoLanding } from "../../../components";
import SignupSubscription from "./components/signupSubscription";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useResetPasswordHandler } from "../../../hooks/useResetPasswordHandler";
import SetNewPassword from "./components/setNewPassword";

export default function Auth(props) {
  const { children, type } = props;
  const [option, setOption] = useState(constants.options.LOGIN);
  const { onResetResetPassword } = useResetPasswordHandler();
  const theme = useTheme();
  const isMD = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box>
      <Box
        sx={{
          backgroundImage: `url("/auth_wallpaper.png")`,
          height: "100vh",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          filter: "blur(20px)",
        }}
      ></Box>
      <Box
        sx={{
          position: "absolute",
          background: "#000000c9",
          width: "100%",
          height: "100vh",
          overflowY: "auto",
          top: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          sx={{
            opacity: 1,
            animationName: "menu",
            animationDuration: "3s",
          }}
        >
          {children}
        </Grid>
        {type === constants.options.SIGNUP && <LogoLanding />}

        <Box
          sx={{
            position: "absolute",
            top: type === constants.options.LOGIN ? "25%" : "8%",
            width:
              type === constants.options.LOGIN ? "250px" : isMD ? "50%" : "75%",
            animationName: type === constants.options.SIGNUP ? "body" : "",
            animationDuration: type === constants.options.SIGNUP ? "4s" : "",
          }}
        >
          <Box
            sx={{
              height: "60px",
              width: "250px",
            }}
          >
            <Logo width="100%" />
          </Box>
          <Box
            sx={{
              padding: "10px 20px",
              background: "white",
              borderRadius: "10px",
              height: "100%",
            }}
          >
            <Box my={1}>
              {type === constants.options.LOGIN && (
                <Button
                  color={
                    type === constants.options.LOGIN ? "dark" : "grayLight"
                  }
                  sx={{
                    textTransform: "capitalize",
                    fontSize: 20,
                  }}
                  onClick={() => setOption(constants.options.LOGIN)}
                >
                  Login
                </Button>
              )}
              {type === constants.options.SIGNUP && (
                <Button
                  color={
                    type === constants.options.SIGNUP ? "dark" : "grayLight"
                  }
                  sx={{
                    textTransform: "capitalize",
                    fontSize: 20,
                  }}
                  onClick={() => setOption(constants.options.SIGNUP)}
                >
                  Sign Up
                </Button>
              )}
            </Box>
            {option === constants.options.LOGIN &&
              type === constants.options.LOGIN && (
                <Box>
                  <Login width={"100%"}>
                    <Box
                      sx={{
                        m: "5px 0px !important",
                      }}
                    >
                      <ButtonGroup variant="text">
                        <Button
                          size="small"
                          color="dark"
                          sx={{
                            fontSize: 12,
                            textTransform: "capitalize",
                          }}
                          onClick={() =>
                            setOption(constants.options.RESET_PASS)
                          }
                        >
                          forgot password
                        </Button>
                        <Button
                          size="small"
                          color="dark"
                          sx={{
                            fontSize: 12,
                            textTransform: "capitalize",
                          }}
                          onClick={() =>
                            (window.location.href = `/signup/${process.env.REACT_APP_STRIPE_PRODUCT_FREE}`)
                          }
                        >
                          try free trial
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </Login>
                </Box>
              )}
            {type === constants.options.SIGNUP && (
              <Box>
                <SignupAccountOwner />
              </Box>
            )}

            {type === constants.options.SIGNUP_SUBSCRIPTION && (
              <Box>
                <SignupSubscription />
              </Box>
            )}
            {option === constants.options.RESET_PASS && (
              <Box>
                <Button
                  color="success"
                  startIcon={<ArrowBackIcon></ArrowBackIcon>}
                  onClick={() => {
                    onResetResetPassword();
                    setOption(constants.options.LOGIN);
                  }}
                >
                  Back
                </Button>
                <ResetPassword />
              </Box>
            )}
            {type === constants.options.SET_NEW_PASS && (
              <Box>
                <SetNewPassword />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
