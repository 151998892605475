import { utilityAPIs } from "../../../api_";
import {
  getRolesPending,
  getRolesFulfilled,
  getRolesRejected,
} from "./cases/getRoles";

import {
  getAvatarsPending,
  getAvatarsFulfilled,
  getAvatarsRejected,
} from "./cases/getAvatars";

import {
  getStrapiProductsPending,
  getStrapiProductsFulfilled,
  getStrapiProductsRejected,
} from "./cases/getStrapiProducts";

export const extraReducers = (builder) => {
  builder.addCase(utilityAPIs.getRoles.pending, getRolesPending);
  builder.addCase(utilityAPIs.getRoles.fulfilled, getRolesFulfilled);
  builder.addCase(utilityAPIs.getRoles.rejected, getRolesRejected);
  
  builder.addCase(utilityAPIs.getAvatars.pending, getAvatarsPending);
  builder.addCase(utilityAPIs.getAvatars.fulfilled, getAvatarsFulfilled);
  builder.addCase(utilityAPIs.getAvatars.rejected, getAvatarsRejected);

  builder.addCase(utilityAPIs.getStrapiProducts.pending, getStrapiProductsPending);
  builder.addCase(utilityAPIs.getStrapiProducts.fulfilled, getStrapiProductsFulfilled);
  builder.addCase(utilityAPIs.getStrapiProducts.rejected, getStrapiProductsRejected);
};
