import {
  Box,
  Button,
  LinearProgress,
  Stack,
  useTheme,
} from "@mui/material";
import useAvatarHandler from "../../hooks/useAvatarHandler";
import { useEffect } from "react";

export default function BuilderAvatarComponent({ currentQuestion, formik, onChange }) {
  const {
    onGetAvatars,
    filterAvatars,
    isLoading,
    isSuccess,
    isUninitialized,
  } = useAvatarHandler();
  useEffect(() => {
    if (isUninitialized) {
      onGetAvatars();
    }
  }, [isUninitialized, onGetAvatars]);
  const theme = useTheme();
  return (
    <>
      {isLoading && <LinearProgress></LinearProgress>}
      <Stack
        sx={{
          background: theme.palette.dark.main,
          borderRadius: "10px",
          height: "309px",
          overflow: "auto",
          padding: "10px 5px",
        }}
        flexWrap={"wrap"}
        direction={"row"}
        justifyContent={"center"}
      >
        {isSuccess &&
          filterAvatars(formik.values.gender).map((avatar, idx) => (
            <Box
              key={idx}
              sx={{
                width: "100px",
                height: "100px",
                borderRadius: "100px",
                border:
                  formik.values?.avatarSource === avatar.avatar_blob
                    ? "2px solid #66bf76"
                    : `2px solid ${theme.palette.dark.main}`,
                transition: "0.2s",
              }}
            >
              <Button
                color="success"
                sx={{
                  width: "100px",
                  height: "100px",
                  backgroundImage: `url(${avatar.public_url})`,
                  backgroundSize: "cover",
                  borderRadius: "100px",
                  border:
                    formik.values?.avatarSource === avatar.avatar_blob
                      ? `3px solid ${theme.palette.dark.main}`
                      : `5px solid ${theme.palette.dark.main}`,
                  transition: "0.2s",
                }}
                onClick={() =>
                {formik.setFieldValue(
                  currentQuestion.inputName,
                  avatar.avatar_blob
                )
                onChange()}
                }
              ></Button>
            </Box>
          ))}
      </Stack>
    </>
  );
}
