import { brandAPIs } from "../../../api_";

import {
    getBrandsFulfilled,
    getBrandsPending,
    getBrandsRejected,
} from "./cases/getBrands";

import {
    postBrandFulfilled,
    postBrandPending,
    postBrandRejected,
} from "./cases/postBrand";

import {
    putBrandFulfilled,
    putBrandPending,
    putBrandRejected,
} from "./cases/putBrand";

import {
    deleteBrandPending,
    deleteBrandFulfilled,
    deleteBrandRejected,
} from "./cases/deleteBrand";
  
export const extraReducers = (builder) => {
    builder.addCase(brandAPIs.getBrands.pending, getBrandsPending);
    builder.addCase(brandAPIs.getBrands.fulfilled, getBrandsFulfilled);
    builder.addCase(brandAPIs.getBrands.rejected, getBrandsRejected);

    builder.addCase(brandAPIs.postBrand.pending, postBrandPending);
    builder.addCase(brandAPIs.postBrand.fulfilled, postBrandFulfilled);
    builder.addCase(brandAPIs.postBrand.rejected, postBrandRejected);

    builder.addCase(brandAPIs.putBrand.pending, putBrandPending);
    builder.addCase(brandAPIs.putBrand.fulfilled, putBrandFulfilled);
    builder.addCase(brandAPIs.putBrand.rejected, putBrandRejected);

    builder.addCase(brandAPIs.deleteBrand.pending, deleteBrandPending);
    builder.addCase(brandAPIs.deleteBrand.fulfilled, deleteBrandFulfilled);
    builder.addCase(brandAPIs.deleteBrand.rejected, deleteBrandRejected);
}