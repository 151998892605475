import setPersonaBuilderSubNext from "./cases/setPersonaBuilderSubNext";
import resetPersonaBuilderStatus from "./cases/resetPersonaBuilderStatus";
import setMode from "./cases/setMode";
import setWizzard from "./cases/setWizzard";
import resetGenerateAnswerStatus from "./cases/resetGenerateAnswerStatus";
import resetDisablePersona from "./cases/resetDisablePersona";
import resetPersona from "./cases/resetPersona";
import resetGetPersonaExportPDF from "./cases/resetGetPersonaExportPDF";
import removeFromPersonaExportQueue from "./cases/removeFromPersonaExportQueue";
import resetPersonasList from "./cases/resetPersonasList";

const cases = {
  setMode,
  resetPersonaBuilderStatus,
  setPersonaBuilderSubNext,
  setWizzard,
  resetGenerateAnswerStatus,
  resetDisablePersona,
  resetPersona,
  resetGetPersonaExportPDF,
  removeFromPersonaExportQueue,
  resetPersonasList
};

export default cases;
