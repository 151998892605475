const AdaptIcon = ({color='white'}) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 17 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1203_12821)">
      <path
        d="M7.83143 12H0V4.85498H1.19655V10.818H6.63488V4.85498H7.83143V12Z"
        fill={color}
      />
      <path
        d="M16.9998 12H9.17139V4.85498H10.3679V10.818H15.8033V4.85498H16.9998V12Z"
        fill={color}
      />
      <path
        d="M3.91566 8.99187C3.58661 8.99187 3.31738 8.72593 3.31738 8.40089V4.13691C3.31738 1.8557 5.19597 0 7.50531 0H9.49757C11.8069 0 13.6855 1.8557 13.6855 4.13691V8.01083C13.6855 8.33588 13.4163 8.60182 13.0872 8.60182C12.7582 8.60182 12.4889 8.33588 12.4889 8.01083V4.13691C12.4889 2.50874 11.1458 1.18197 9.49757 1.18197H7.50531C5.85706 1.18197 4.51393 2.50874 4.51393 4.13691V8.40089C4.51393 8.72593 4.24471 8.99187 3.91566 8.99187Z"
        fill={color}
      />
      <path
        d="M13.0871 8.99182C12.9345 8.99182 12.782 8.93272 12.6653 8.81747L10.9572 7.1302C10.7239 6.89972 10.7239 6.52444 10.9572 6.29396C11.1905 6.06347 11.5705 6.06347 11.8038 6.29396L13.0901 7.56458L14.3764 6.29396C14.6097 6.06347 14.9896 6.06347 15.2229 6.29396C15.4563 6.52444 15.4563 6.89972 15.2229 7.1302L13.5148 8.81747C13.3982 8.93272 13.2456 8.99182 13.0931 8.99182H13.0871Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1203_12821">
        <rect width="17" height="12" fill={color} />
      </clipPath>
    </defs>
  </svg>
);
export default AdaptIcon;
