// GENERATE ANSWE
export const postGenerateAnswerPending = (state, action) => {
  state.aiAnswer.status = "pending";
};

export const postGenerateAnswerFulfilled = (state, action) => {
  state.aiAnswer.status = "fulfilled";
  state.aiAnswer.data = action.payload;
};

export const postGenerateAnswerRejected = (state, action) => {
  state.aiAnswer.status = "rejected";
  state.aiAnswer.error = action.payload.response.data;

}; 
