import { Button, IconButton, Stack, Typography, useTheme } from "@mui/material";
import FaceRetouchingNaturalIcon from "@mui/icons-material/FaceRetouchingNatural";
import useGetOrgUserProfile from "../../hooks/useGetOrgUserProfileHanlder";
import { usePersonaBuilderModeHandler } from "../../hooks/usePersonaBuilderModeHandler";
import { useCurrentPersonaViewer } from "../../hooks/useCurrentPersonaViewer";
import Modal from "../Modal";
import { AddContentIcon, AIIcon } from "../../icons";
import BootstrapTooltip from "../BootstrapTooltipComponent";
import { useState } from "react";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import useCurrentOrganizationHandler from "../../hooks/useCurrentOrganizationHandler";
import { useNavigate } from "react-router-dom";

export default function ModalConfirmationPersonaBuilder({ type, persona }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { canManagePersona } = useGetOrgUserProfile();
  const { onSetMode } = usePersonaBuilderModeHandler();
  const navigate = useNavigate();
  const { onResetPersona } = useCurrentPersonaViewer();
  const onClose = () => setOpen(false);
  const { currentOrganization } = useCurrentOrganizationHandler();
  const onClick = () => {
    if (type === "editFullBtn") {
      onSetMode("edit", persona);
      onResetPersona();
      navigate(`/organization/${currentOrganization.id}/persona`);
      return;
    }
    if (type === "editSmllBtn") {
      onSetMode("edit", persona);
      return;
    }
    if (type === "createFull") {
      onSetMode("create", null);
      return;
    }
    if (type === "createFstLane") {
      onSetMode("fastlane", null);
      return;
    }
  };
  return (
    <>
      {type === "editFullBtn" && (
        <Button
          sx={{ fontSize: "12px" }}
          color="primary"
          variant="contained"
          startIcon={<AddContentIcon />}
          //onClick={() => setOpen(true)}
          onClick={onClick}
        >
          Edit Persona
        </Button>
      )}
      {type === "editSmllBtn" && (
        <BootstrapTooltip title="Edit Persona">
          <IconButton size="small" color="yellow" 
          //</BootstrapTooltip>onClick={() => setOpen(true)}
          onClick={onClick}
          
          >
            <FaceRetouchingNaturalIcon fontSize="small" />
          </IconButton>
        </BootstrapTooltip>
      )}
      {type === "createFull" && (
        <Button
          disabled={!canManagePersona}
          //onClick={() => setOpen(true)}
          onClick={onClick}

          variant="contained"
        >
          Build a persona from scratch
        </Button>
      )}
      {type === "createFstLane" && (
        <Button
          disabled={!canManagePersona}
          variant="contained"
          color="dark"
          //onClick={() => setOpen(true)}
          onClick={onClick}

        >
          {" "}
          Start a new persona with fastlane method
        </Button>
      )}
      <Modal
        maxWidth="sm"
        actions={
          <>
            <Button color="error" variant="contained" onClick={onClick}>
              Agree
            </Button>
            <Button color="dark" variant="contained" onClick={onClose}>
              cancel
            </Button>
          </>
        }
        open={open}
        handleClose={onClose}
        title={"Persona Builer Confirmation"}
        body={
          <>
            <Typography variant="overline">do you want to continue?</Typography>
            <br></br>
            <Typography variant="body2">
              the following actions will consume credits
            </Typography>
            <br></br>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography variant="subtitle1" color={"error"}>
                **Clicking on
              </Typography>
              <Button
                mx={1}
                color="dark"
                variant="contained"
                startIcon={<ModelTrainingIcon></ModelTrainingIcon>}
              >
                {" "}
                SAVE AND GENERATE BIO
              </Button>
            </Stack>
            <br></br>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography variant="subtitle1" color={"error"}>
                **Clicking on
              </Typography>
              <IconButton mx={1}>
                <AIIcon color={theme.palette.white.main}></AIIcon>
              </IconButton>
            </Stack>
          </>
        }
      />
    </>
  );
}
