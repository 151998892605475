export const getRolesPending = (state, action) => {
  state.roles.status = "pending";
};

export const getRolesFulfilled = (state, action) => {
  state.roles.status = "fulfilled";
  state.roles.data = action.payload.result;
};

export const getRolesRejected = (state, action) => {
  state.roles.status = "rejected";
};
