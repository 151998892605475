import Modal from "../Modal";
import { useEffect, useState } from "react";
import { Typography, Button } from "@mui/material";
import { AnalyzeIcon, BrandIcon } from "../../icons";
import useGetAnalyzedContent from "../../hooks/useGetAnalyzedContent";
import useGetOrgUserProfile from "../../hooks/useGetOrgUserProfileHanlder";
import ErrorMessageDisplayer from "../ErrorMessageDisplayer";
import useSuggestionsContentHandler from "../../hooks/useSuggestionsContentHandler";
import LogoLoader from "../LogoLoader";
import useBrandReactionHandler from "../../hooks/useBrandReactionHandler";

export default function ModalBrandAligmentConfirmation({ fullWidth }) {
  const [open, setOpen] = useState(false);
  const { analyzedContent } = useGetAnalyzedContent();
  const {
    onGenerateBrandReaction,
    brandReactionData,
    onResetBrandReaction,
    isLoading,
    isSuccess,
    isError,
  } = useBrandReactionHandler();

  const { canExecuteAnalyze } = useGetOrgUserProfile();
  const { isLoading: isGeneratingSuggestions } = useSuggestionsContentHandler();

  useEffect(() => {
    if (isSuccess || isError) {
      setOpen(false);
      onResetBrandReaction();
    }
  }, [isSuccess, isError, setOpen, onResetBrandReaction]);
  const onConfirmation = () => {
    onGenerateBrandReaction();
  };

  const onCancel = () => {
    setOpen(false);
    onResetBrandReaction();
  };

  const onClickGetSuggestEdits = () => {
    if (canExecuteAnalyze) {
      //setOpen(true);
      onConfirmation()
    }
  };

  return (
    <>
      <LogoLoader
        open={isLoading}
        text={"Checking Brand Aligment"}
        needBackDrop={true}
      />
      <Button
        fullWidth={fullWidth}
        disabled={
          !canExecuteAnalyze ||
          !Boolean(analyzedContent.brand.brand_metadata) ||
          !analyzedContent.content.is_current ||
          (!analyzedContent.content.is_current &&
            Boolean(analyzedContent.brand.brand_reaction)) ||
          (analyzedContent.content.is_current &&
            Boolean(analyzedContent.brand.brand_reaction)) ||
          isGeneratingSuggestions ||
          isLoading
        }
        color="warning"
        variant="contained"
        onClick={onClickGetSuggestEdits}
        startIcon={<BrandIcon small={true} color="white" />}
      >
        {" "}
        Check Brand Aligment
      </Button>
      <Modal
        maxWidth="sm"
        title={
          isError ? "Brand Aligment " : "Check Brand Aligment Confirmation"
        }
        open={open}
        actions={
          <>
            {isError ? (
              <Button onClick={onCancel} variant="contained" color="error">
                Close
              </Button>
            ) : (
              <>
                <Button
                  startIcon={<AnalyzeIcon></AnalyzeIcon>}
                  variant="contained"
                  onClick={onConfirmation}
                >
                  Yes
                </Button>
                <Button onClick={onCancel} variant="contained" color="error">
                  No
                </Button>
              </>
            )}
          </>
        }
        body={
          <>
            {isError ? (
              <ErrorMessageDisplayer
                content={{
                  header: "The check brand aligment could not be executed",
                  title: brandReactionData.error,
                  message: brandReactionData.message,
                }}
              />
            ) : (
              <>
                <Typography variant="overline">
                  do you want to check brand aligment?
                </Typography>
                <br></br>
                <Typography variant="subtitle1" color={"error"}>
                  **Check brand aligment will consume credits.
                </Typography>
              </>
            )}
          </>
        }
        handleClose={onCancel}
      />
    </>
  );
}
