export const putUpdateSubscriptionPending = (state, action) => {
  state.updateSubscription.status = "pending";
  state.updateSubscription.error = null;
};

export const putUpdateSubscriptionFulfilled = (state, action) => {
  state.updateSubscription.status = "fulfilled";
  state.updateSubscription.data = action.payload;
};

export const putUpdateSubscriptionRejected = (state, action) => {
  state.updateSubscription.status = "rejected";
  state.updateSubscription.error = action.payload.response.data;
};
