export const getOrganizationsPending = (state, action) => {
  state.list.status = "pending";
};

export const getOrganizationsFulfilled = (state, action) => {
  state.list.status = "fulfilled";
  state.list.data = action.payload.result;
};

export const getOrganizationsRejected = (state, action) => {
  state.list.status = "rejected";
};
