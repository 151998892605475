import { Box, Typography } from "@mui/material";
export default function EmptyTab({ children, icon }) {
  return (
    <Box sx={{ position: "relative", top: "150px", width: '320px' }}>
      <Box
        sx={{
          background: "#fafafa",
          padding: "20px",
          color: "#a5a5a5",
          borderRadius: "10px",
        }}
      >
        {icon}
        <Typography>{children}</Typography>
      </Box>
    </Box>
  );
}
