import { Button, Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../../../../../../components";
import ErrorMessageDisplayer from "../../../../../../components/ErrorMessageDisplayer";
import { usePersonaBuilderModeHandler } from "../../../../../../hooks/usePersonaBuilderModeHandler";
import useGetOrgUserProfile from "../../../../../../hooks/useGetOrgUserProfileHanlder";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";

export default function ModalSaveGenerateBioConfirmation({
  setGenerateBio,
  formik,
}) {
  const { canManagePersona } = useGetOrgUserProfile();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { isError, error, onResetPersonaBuilderStatus } =
    usePersonaBuilderModeHandler();

  const onClose = () => {
    if (isError) {
      onResetPersonaBuilderStatus();
      setGenerateBio(false);
    }
    setOpenConfirmation(false);
  };
  return (
    <>
      <Button
        pt={2}
        fullWidth
        disabled={!canManagePersona}
        //onClick={() => setOpenConfirmation(true)}
        onClick={() => {
          setGenerateBio(true);
          formik.handleSubmit();
          setOpenConfirmation(false);
        }}
        variant="contained"
        color="dark"
        size="medium"
        startIcon={<ModelTrainingIcon></ModelTrainingIcon>}
      >
        save and generate bio
      </Button>
      <Modal
        actions={
          <>
            {isError ? (
              <Button
                pt={2}
                variant="contained"
                color="dark"
                size="medium"
                onClick={onClose}
              >
                Close
              </Button>
            ) : (
              <>
                <Button
                  pt={2}
                  onClick={() => {
                    setGenerateBio(true);
                    formik.handleSubmit();
                    setOpenConfirmation(false);
                  }}
                  variant="contained"
                  color="red"
                  size="medium"
                >
                  Agree
                </Button>
                <Button
                  pt={2}
                  variant="contained"
                  color="dark"
                  size="medium"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </>
            )}
          </>
        }
        maxWidth="sm"
        title={"Persona Builder"}
        open={isError || openConfirmation}
        handleClose={onClose}
        body={
          <>
            {isError ? (
              <ErrorMessageDisplayer
                content={{
                  header: "The persona builder could not be executed",
                  title: error?.error,
                  message: error?.message,
                }}
              />
            ) : (
              <>
                <Typography variant="overline">
                  do you want to processed?
                </Typography>
                <br></br>
                <Typography variant="subtitle1" color={"error"}>
                  **Save and Generate Bio will consume credits.
                </Typography>
              </>
            )}
          </>
        }
      ></Modal>
    </>
  );
}
