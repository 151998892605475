import { InputText, Modal } from "../../../../components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Grid } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useCallback, useEffect, useState } from "react";
import ErrorMessageDisplayer from "../../../../components/ErrorMessageDisplayer";
import { useLoginHandler } from "../../../../hooks/useLoginHandler";
import useUpdateProfileHandler from "../../../../hooks/useUpdateProfileHandler";

export default function ModalUpdateProfile() {
  const [open, setOpen] = useState(false);
  const { onRefreshProfile, currentProfile } = useLoginHandler();
  const { isSuccess, isLoading, isError, onSubmitForm, onResetUpdateProfile } =
    useUpdateProfileHandler();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentProfile.name,
      last_name: currentProfile.last_name,
      email: currentProfile.email,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      email: Yup.string().matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        "Invalid email address"
      ),
    }),
    onSubmit: async (values) => {
      onSubmitForm(values);
    },
  });

  const onClose = useCallback(() => {
    if (isLoading) return;
    setOpen(false);
    onResetUpdateProfile();
    formik.resetForm();
  }, [isLoading, setOpen, onResetUpdateProfile, formik]);
  useEffect(() => {
    if (isSuccess) {
      onClose();
      onRefreshProfile();
    }
  }, [isSuccess, onClose, onRefreshProfile]);

  return (
    <>
      <Button sx={{mt:1}} endIcon={<ModeEditIcon />} variant="outlined"  size={"small"} onClick={() => setOpen(true)}>
        update Profile
      </Button>
      <Modal
        maxWidth="sm"
        actions={
          <>
            {isError ? (
              <Button onClick={onClose} variant="contained" color="error">
                Close
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={formik.handleSubmit}
                >
                  update
                </Button>
                <Button onClick={onClose} variant="contained" color="error">
                  cancel
                </Button>
              </>
            )}
          </>
        }
        handleClose={onClose}
        open={open}
        title={"Personal Details"}
        body={
          <>
            {isError ? (
              <ErrorMessageDisplayer content={"error"}></ErrorMessageDisplayer>
            ) : (
              <Grid container>
                <Grid item xs={12}>
                  <InputText
                    id="name"
                    name="name"
                    placeholder="First Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name ? formik.errors.name : ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputText
                    id="last_name"
                    name="last_name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.last_name &&
                      Boolean(formik.errors.last_name)
                    }
                    placeholder="Last Name"
                    helperText={
                      formik.touched.last_name ? formik.errors.last_name : ""
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputText
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    placeholder="Email"
                    helperText={formik.touched.email ? formik.errors.email : ""}
                  />
                </Grid>
              </Grid>
            )}
          </>
        }
      />
    </>
  );
}
