import { Typography, Stack, Box, useTheme } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
export default function ErrorMessageDisplayer({ content }) {
  const theme = useTheme();
  return (
    <>
      {content.header && <>
        <Typography variant="overline">{content.header}</Typography>
        <br></br>
      </>}
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={{
          background: "#ff000030",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <Box>
          {content.icon ? content.icon : <ErrorIcon
            color="red"
            sx={{
              fontSize: "50px",
            }}
          />}
        </Box>

        <Box
          sx={{
            marginLeft: "5px",
            borderLeft: "1px solid #ff000059",
            paddingLeft: "5px",
          }}
        >
          <Typography variant="subtitle1" color={theme.palette.warning.main}>
            {content.title}
          </Typography>
          <Typography
            variant="subtitle2"
            fontWeight={200}
            color={theme.palette.warning.main}
          >
            {/*true
              ? "You’ve exceeded your monthly AI credit allotment for your plan! We’re glad you are loving Subatomic and during the BETA period we’ll be accommodating requests for extra credits where reasonable.  Please send an email to support@getsubatomic.ai with the subject “Additional Credits” and a brief description of what you need to do within the platform and we’ll review and add additional credits to your account if eligible."
              : content.message*/}
            {content.message}
          </Typography>
        </Box>
      </Stack>
    </>
  );
}
