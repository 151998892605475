import { Box, Stack, Grid, Typography } from "@mui/material";
import { ContentLibraryIcon } from "../../../../icons";
import ModalUploadFile from "./components/ModalUploadFile";
import ModalNewFolder from "./components/ModalNewFolder";
import TableLibrary from "./components/TableLibrary";
import { LeftPaneComponent, LogoLoader } from "../../../../components";
import React from "react";
import useUploadContentFile from "../../../../hooks/useUploadFile";
import useUploadContentTextHandler from "../../../../hooks/useUploadContentTextHandler";
import useCreateFolderHandler from "../../../../hooks/useCreateFolderHandler";
import useDeleteContentHandler from "../../../../hooks/useDeleteContentHandler";
import useContentUpdateMetadataHanlder from "../../../../hooks/useContentUpdateMetadataHanlder";
import useContentNavigatorHandler from "../../../../hooks/useContentNavigatorHandler";
import useGetAnalyzeExportHandler from "../../../../hooks/useGetAnalyzeExportHandler";
import useAnalyzeContentHandler from "../../../../hooks/useAnalyzeContentHandler";

export default function ContentLibraryModule() {
  const [processedQueue, setProcessedQueue] = React.useState([]);
  const { queue, removeFromQueue } = useGetAnalyzeExportHandler();

  const { isSuccess: isSuccessContentUpdated, onResetUpdateContentMetadata } =
    useContentUpdateMetadataHanlder();

  const { onGetContentNavigator, isUninitialized } = useContentNavigatorHandler(
    {
      readOnly: false,
    }
  );

  const { isSuccess: isSuccessContentFile, onResetUploadContentFile } =
    useUploadContentFile();
  const { isSuccess: isSuccessContentText, onResetUploadContentText } =
    useUploadContentTextHandler();
  const { isSuccess: isSuccessFolder, onResetStatusCreateFolder } =
    useCreateFolderHandler();
  const { isSuccess: isContentDeleted, onResetDeleteContent } =
    useDeleteContentHandler();

  const processQueue = React.useCallback(() => {
    let fulfilled = 0;
    queue.forEach((q) => {
      if (q.status === "fulfilled" && !processedQueue.includes(q.contentId)) {
        fulfilled += 1;
        const blob = new Blob([q.data.file.file], { type: "application/pdf" });
        const link = document.createElement("a");
        link.download = q.data.file.filename;
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setProcessedQueue((prev) => [...prev, q.contentId]);
      }
    });
    if (fulfilled > 0) {
      removeFromQueue(0);
      setProcessedQueue([]);
    }
  }, [queue, processedQueue, removeFromQueue]);

  React.useEffect(() => {
    if (isUninitialized) {
      onGetContentNavigator();
    }
  }, [isUninitialized, onGetContentNavigator]);

  React.useEffect(() => {
    processQueue();
  }, [queue, processQueue]);

  React.useEffect(() => {
    const resetActions = () => {
      if (isSuccessFolder) onResetStatusCreateFolder();
      if (isSuccessContentFile) onResetUploadContentFile();
      if (isSuccessContentText) onResetUploadContentText();
      if (isSuccessContentUpdated) onResetUpdateContentMetadata();
      if (isContentDeleted) onResetDeleteContent();

      if (
        isSuccessFolder ||
        isSuccessContentFile ||
        isSuccessContentText ||
        isSuccessContentUpdated ||
        isContentDeleted
      ) {
        onGetContentNavigator();
      }
    };

    resetActions();
  }, [
    isSuccessFolder,
    isSuccessContentFile,
    isSuccessContentText,
    isSuccessContentUpdated,
    isContentDeleted,
    onResetStatusCreateFolder,
    onResetUploadContentFile,
    onResetUploadContentText,
    onResetUpdateContentMetadata,
    onResetDeleteContent,
    onGetContentNavigator,
  ]);
  const { isLoading } = useAnalyzeContentHandler();

  return (
    <>
      <LogoLoader open={isLoading} text={"Preparing Analyze..."}></LogoLoader>
      <Grid container sx={{ height: "100vh" }}>
        <LeftPaneComponent>
          <Stack
            pt={"70px"}
            spacing={2}
            justifyContent={"center"}
            alignItems={"normal"}
          >
            <Box display={"flex"}>
              {" "}
              <ContentLibraryIcon />
            </Box>
            <Typography textAlign={"left"} variant="h4">
              Content Library
            </Typography>
            <ModalUploadFile />
            <ModalNewFolder />
          </Stack>
        </LeftPaneComponent>
        <Grid item xs={6} md={10}>
          <Box
            mx={2}
            sx={{
              px: 10,
              pt: "70px",
            }}
          >
            <TableLibrary />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
