import utilitySlice from "./slice";

// SELECTORS
export const selectSnackbarConfig = (state) => state?.utility?.snackbar;

export const selectRolesData = (state) => state?.utility?.roles?.data;
export const selectRolesStatus = (state) => {
  return state?.utility?.roles?.status;
};

export const selectAvatarsData = (state) => state?.utility?.avatars?.data;
export const selectAvatarsStatus = (state) => state?.utility?.avatars?.status;

export const selectStrapiProductsData = (state) => state?.utility?.strapiProducts?.data;
export const selectStrapiProductsStatus = (state) => state?.utility?.strapiProducts?.status;

export const selectAnalyzeContentQueue = (state) => state?.utility?.contentAnalyzedQueue?.queue;

export const selectPusherNotificationChannel = (state) => state?.utility?.pusherNotifications.channel
export const selectPusherNotificationCredits = (state) => state?.utility?.pusherNotifications.creditsUpdate

// ACTIONS
export const {
  handleSnackbar,
  pushAnalyzeContentQueue,
  pullhAnalyzeContentQueue,
  updateAnalyzeContentQueue,
  setPusherNotificationChannel,
  setPusherNotificationCreditsUpdate
} = utilitySlice.actions;

// REDUCER
export default utilitySlice.reducer;
