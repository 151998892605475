import { useEffect, useState } from "react";
import { Typography, Button } from "@mui/material";

import Modal from "../Modal";
import { AddContentIcon, AnalyzeIcon, GenerateIcon } from "../../icons";
import ErrorMessageDisplayer from "../ErrorMessageDisplayer";
import useHandleContentEdits from "../../hooks/useHandleContentEdits";

export default function ModalSaveEditsConfirmation({
  onSubmit,
  onCancel,
  disabled,
}) {
  const [open, setOpen] = useState(false);
  const {
    onResetContentEdits,
    isSuccess,
    isError,
    editsData,
  } = useHandleContentEdits();

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
    }
  }, [isSuccess, setOpen]);
  const onClose = () => {
    setOpen(false);
    onResetContentEdits();
    onCancel();
  };
  return (
    <>
      <Button
        fullWidth
        startIcon={<AddContentIcon />}
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
        disabled={disabled}
      >
        Save Edited Version
      </Button>
      <Modal
        maxWidth="sm"
        title={isError ? "Suggestions" : "Save Edits"}
        open={open}
        actions={
          <>
            {isError ? (
              <Button variant="contained" color="error" onClick={onClose}>
                Close
              </Button>
            ) : (
              <>
                <Button
                  startIcon={<AnalyzeIcon></AnalyzeIcon>}
                  variant="contained"
                  onClick={() => {
                    onSubmit(true);
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    onSubmit(false);
                  }}
                  startIcon={<GenerateIcon></GenerateIcon>}
                  variant="contained"
                  color="gray"
                >
                  No, only save edits
                </Button>
              </>
            )}
          </>
        }
        body={
          <>
            {isError ? (
              <ErrorMessageDisplayer
                content={{
                  header: "The save edits could not be executed",
                  title: editsData.error,
                  message: editsData.message,
                }}
              />
            ) : (
              <>
                <Typography variant="subtitle1">
                  Pre-Confirmation for new analyze
                </Typography>
                <Typography variant="body2" fontWeight={200}>
                  By saving the changes, a new version will be generated.
                </Typography>
                <br />
                <Typography variant="overline">PLEASE CONFIRM:</Typography>
                <Typography variant="body2">
                  Do you want to re-analyze the content with included changes?
                </Typography>
                <Typography variant="caption" color={"error"}>
                  **Re-analyzing content will consume additional credits.
                </Typography>
              </>
            )}
          </>
        }
        handleClose={onClose}
      />
    </>
  );
}
