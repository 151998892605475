import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./extraReducers";
import reducers from "./reducers";

export const organizationsSlice = createSlice({
  name: "organizations",
  initialState: {
    list: {
      data: [],
      status: "idle",
    },
    createOrganization:{
      data: {},
      status: "idle",
    },
    inviteUser:{
      data: {},
      status: "idle",
    },
    users: {
      data: [],
      status: "idle",
    },
    orgUserProfile:{
      data: null,
      status: "idle",
    },
    channels:{
      data: [],
      status: "idle",
    },
    currentOrganization: null,
    previousOrganization: null,
    uploadFile:{
      file: null,
      status: "idle",
    },
    disableOrganization:{
      data: null,
      status: "idle",
    },
    disableOrganizationUser:{
      data: null,
      status: "idle",
    }
  },
  reducers,
  extraReducers,
});

export default organizationsSlice;
