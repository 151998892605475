import { Box, Typography } from "@mui/material";

import useGetAnalyzedContent from "../../../../../../hooks/useGetAnalyzedContent";
import { GraphicBarComponent } from "../../../../../../components";
import EmptyTab from "./EmptyTab";
import BarChartIcon from "@mui/icons-material/BarChart";
import useContetCurrentVersionHanlder from "../../../../../../hooks/useContentCurrentVersionHandler";

export default function ScoresTab() {
  const { analyzedContent, isLoading:isGettingLast } = useGetAnalyzedContent();
  const { isLoading } = useContetCurrentVersionHanlder();

  const emptyTabText = () => {
    if (
      analyzedContent.content.is_current &&
      !Boolean(analyzedContent.audience_personas_reactions)
    ) {
      return "This current content version is not analyzed yet";
    }
    if (analyzedContent.content.is_current) {
      return "No Suggestions generated yet, please click in SUGGEST EDITS";
    }
    return "This content was not analyzed";
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >{isLoading || isGettingLast?'loading': Boolean(analyzedContent.audience_personas_reactions) ? (
      <Box sx={{ py: 1, width: "100%" }}>
        <Typography variant="h6" fontWeight={"bold"} textAlign={"left"}>
        Audience Content Score (Latest/Prior)
        </Typography>
        <Box>
          {analyzedContent?.audience_personas_scores?.map((score, idx) => (
            <GraphicBarComponent
              key={idx}
              val={score.audience_score}
              oldVal={score.audience_score_old}
              diff={score.audience_diff}
              subChart={score.personas_scores}
              label={score.metric}
            />
          ))}
        </Box>
      </Box>
    ) : (
      <EmptyTab icon={<BarChartIcon />}>{emptyTabText()}</EmptyTab>
    )}
      
    </Box>
  );
}
