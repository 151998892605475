import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";

export default function BuilderOtherComponent({ currentQuestion, formik , handleKeyDown}) {
  return (
    <Box>
      <FormControl fullWidth variant="outlined">
        <TextField
          id="standard-adornment-password"
          multiline
          minRows={5}
          maxRows={5}
          fullWidth
          placeholder="Type your answer"
          name={currentQuestion.inputName}
          value={formik.values[currentQuestion.inputName]}
          onChange={formik.handleChange}
          onKeyDown={handleKeyDown}
          onBlur={formik.handleBlur}
          error={
            formik.touched[currentQuestion.inputName] &&
            Boolean(formik.errors[currentQuestion.inputName])
          }
        />
        <FormHelperText id="standard-weight-helper-text">
          {formik.touched[currentQuestion.inputName]
            ? formik.errors[currentQuestion.inputName]
            : " "}
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
