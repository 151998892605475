import * as React from "react";
import { Box, Stack, Typography, Avatar, useTheme } from "@mui/material";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { EditorComponent, Modal } from "../../../../../components";
import { AddContentIcon, FolderIcon } from "../../../../../icons";
import useCurrentFolderHandler from "../../../../../hooks/useCurrentFolderHandler";

const AvatarIcon = ({ isFile, onClick }) => (
  <Avatar
    onClick={onClick}
    sx={{ width: 35, height: 35, background: "#e8e8e8" }}
  >
    {isFile ? <FilePresentIcon color="dark" /> : <FolderIcon />}
  </Avatar>
);

const ContentDisplayer = ({ openmodal, handleClose }) => {
  const theme = useTheme();
  return (
    <Modal
      maxWidth="lg"
      handleClose={handleClose}
      actions={<></>}
      title={
        <Stack direction="row" alignItems="center">
          <AddContentIcon />
          <Typography variant="h6" ml={1}>
            Content
          </Typography>
        </Stack>
      }
      body={
        <Box>
          <Stack direction="row" alignItems="center">
            <Typography>
              <ArrowForwardIosRoundedIcon
                sx={{
                  fontSize: "40px",
                  fill: theme.palette.yellow.main,
                }}
              />
            </Typography>
            <Typography variant="h4">{openmodal?.title}</Typography>
          </Stack>
          <Box height={500} sx={{ overflowY: "auto", mt: 2 }}>
           {openmodal?.content_text && <EditorComponent 
            formik={null}
            height={500}
            initialValue={openmodal?.content_text}
            showToolbar={false}
            isSuggestionMode={false}
            readOnly={true}
            >

            </EditorComponent>}
          </Box>
        </Box>
      }
      open={Boolean(openmodal)}
    />
  );
};

export default function ContentModal({ params }) {
  const [openmodal, setOpenmodal] = React.useState(null);
  const { onSetCurrentFolder } = useCurrentFolderHandler();

  const handleAvatarClick = React.useCallback(() => {
    if (params.row.isFile) {
      setOpenmodal(params.row);
    } else {
      onSetCurrentFolder(params.row.blob);
    }
  }, [params.row, onSetCurrentFolder]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0.5}
        sx={{ cursor: "pointer" }}
      >
        <AvatarIcon isFile={params.row.isFile} onClick={handleAvatarClick} />
        <Typography variant="caption" onClick={handleAvatarClick}>
          {params.row.blob}
        </Typography>
      </Stack>
      <ContentDisplayer
        openmodal={openmodal}
        handleClose={() => setOpenmodal(null)}
      />
    </>
  );
}
