import { Box, Button, Divider, useTheme } from "@mui/material";
import { Logo } from "../../../icons";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ErrorMessageDisplayer from "../../../components/ErrorMessageDisplayer";
import { useNavigate } from "react-router-dom";
import SecurityIcon from "@mui/icons-material/Security";

export default function NoInvitedToOrg() {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          backgroundImage: `url("/auth_wallpaper.png")`,
          height: "100vh",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          filter: "blur(20px)",
        }}
      ></Box>
      <Box
        sx={{
          position: "absolute",
          background: "#000000c9",
          width: "100%",
          height: "100vh",
          overflowY: "auto",
          top: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box>
          <Logo width={460}></Logo>
        </Box>
        <Box width={460} py={2.2}>
          <Divider sx={{ background: theme.palette.grey[600] }}></Divider>
        </Box>
        <Box width={460} textAlign={"left"}>
          <ErrorMessageDisplayer
            content={{
              header: "",
              icon: (
                <SecurityIcon
                  sx={{
                    fontSize: "50px",
                    color: theme.palette.warning.main,
                  }}
                ></SecurityIcon>
              ),
              title: "Access Denied",
              message: "You do not have permission to interact with this organization. Please contact the organization admin to grant access.",
            }}
          ></ErrorMessageDisplayer>
          <Button
            fullWidth
            onClick={() => navigate("/organizations")}
            sx={{ mt: 1 }}
            startIcon={<ArrowBackIcon></ArrowBackIcon>}
            color="success"
          >
            Back home
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
