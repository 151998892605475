import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  Divider,
  Avatar,
} from "@mui/material";
import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useCurrentOrganizationHandler from "../../../hooks/useCurrentOrganizationHandler";
import Logo from "../../../icons/Logo";
import { AddContentIcon, AudienceIcon, BrandIcon } from "../../../icons";
import useGetAnalyzedContent from "../../../hooks/useGetAnalyzedContent";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SettingsIcon from "@mui/icons-material/Settings";
import LoginIcon from "@mui/icons-material/Login";
import { useLoginHandler } from "../../../hooks/useLoginHandler";
import { BootstrapTooltip } from "../../../components";
import { useBrandBuilderModeHandler } from "../../../hooks/useBrandBuilderModeHandler";
import usePusherNotificationChannel from "../../../hooks/usePusherNotificationChannel";
import { externalResources } from "../../../constants";
import useAudienceByOrganizationHandler from "../../../hooks/useAudienceByOrganizationHandler";
import usePersonaListHandler from "../../../hooks/usePersonaListHandler";
import useContentNavigatorHandler from "../../../hooks/useContentNavigatorHandler";
import useColorHandler from "../../../hooks/useColorHandler";
import DrawIcon from "@mui/icons-material/Draw";
import useGenerateDraftContentHandler from "../../../hooks/useGenerateDraftContentHandler";

export function NavigationMenu({ isPublicPage }) {
  const [tabs1, setTabs1] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const { onResetNavigator } = useContentNavigatorHandler({ readOnly: true });
  const { credits } = usePusherNotificationChannel();
  const { currentOrganization, onSetPreviousOrganization } =
    useCurrentOrganizationHandler();
  const { onResetGetAnalyzedContent } = useGetAnalyzedContent();
  const { onResetBuilder, onResetBrandBuilderStatus } =
    useBrandBuilderModeHandler();
  const { onResetAudienceList } = useAudienceByOrganizationHandler();
  const { onResetPersonasList } = usePersonaListHandler();
  const { onResetGenerateDraftContent } = useGenerateDraftContentHandler();
  const location = useLocation();
  const theme = useTheme();
  const isMD = useMediaQuery(theme.breakpoints.up("lg"));

  const isSM = useMediaQuery(theme.breakpoints.only("sm"));
  const isXS = useMediaQuery(theme.breakpoints.only("xs"));

  const { stringAvatar } = useColorHandler();
  const { currentProfile } = useLoginHandler();

  useEffect(() => {
    if (isPublicPage) return;
    if (
      location.pathname === "/organizations" ||
      location.pathname === "/profile"
    ) {
      return;
    }
    if (!currentOrganization) {
      navigate("/organizations");
    }
  }, [
    isPublicPage, location, currentOrganization, navigate]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isPublicPage) return;
    if (!currentOrganization) return;
    const route = location.pathname.replace(
      `/organization/${currentOrganization.id}/`,
      ""
    );
    if (!route.includes("analyze")) {
      onResetGetAnalyzedContent();
    }
    if (route !== "content_library") {
      onResetNavigator();
    }
    if (route !== "content/draft") {
      onResetGenerateDraftContent();
    }
  }, [
    location,
    isPublicPage,
    currentOrganization,
    onResetGetAnalyzedContent,
    onResetNavigator,
    onResetGenerateDraftContent,
  ]);

  const resetHover = React.useCallback(() => {
    if (!currentOrganization) return;
    const route = location.pathname.replace(
      `/organization/${currentOrganization.id}/`,
      ""
    );

    const routeToTabMap = {
      "content/draft": 0,
      audience: 1,
      brand: 2,
      content_library: 3,
      persona: 4,
    };
    if (route in routeToTabMap) {
      setTabs1(routeToTabMap[route]);
    } else {
      setTabs1(null);
    }
  }, [currentOrganization, location]);

  useEffect(() => {
    resetHover();
  }, [location, resetHover]);

  const renderMenu = () => {
    if (currentOrganization !== null) {
      return (
        <>
          {isMD ? (
            <Grid item xs={2}>
              <Box
                sx={{
                  bgcolor: theme.palette.dark.main,
                  height: "42px",
                  borderRadius: "0px 0px 8px 0px",
                }}
              >
                <Button
                  onClick={() => {
                    onSetPreviousOrganization();
                    navigate(`/organizations`);
                  }}
                  fullWidth
                  sx={{
                    height: "42px",
                    borderRadius: "0px 0px 8px 0px",
                  }}
                  color="white"
                >
                  <Logo width={"50%"} />
                </Button>
              </Box>
            </Grid>
          ) : (
            <Grid item xs={1}>
              <Box
                sx={{
                  bgcolor: theme.palette.dark.main,
                  py: 1.3,
                  mr: "2px",
                  borderRadius: "0px 0px 8px 0px",
                }}
              >
                <svg
                  width="22"
                  height="19"
                  viewBox="0 0 22 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.66621 9.40465C8.44731 6.3866 12.8962 6.3866 14.6773 9.40465C16.4584 12.4227 14.2339 16.2029 10.6717 16.2029C7.10954 16.2029 4.88511 12.4227 6.66621 9.40465Z"
                    fill="white"
                  />
                  <path
                    d="M2.65998 18.8094C4.12905 18.8094 5.31996 17.6425 5.31996 16.2029C5.31996 14.7634 4.12905 13.5964 2.65998 13.5964C1.19091 13.5964 0 14.7634 0 16.2029C0 17.6425 1.19091 18.8094 2.65998 18.8094Z"
                    fill="#66BF76"
                  />
                  <path
                    d="M18.6815 18.8094C20.1505 18.8094 21.3414 17.6425 21.3414 16.2029C21.3414 14.7634 20.1505 13.5964 18.6815 13.5964C17.2124 13.5964 16.0215 14.7634 16.0215 16.2029C16.0215 17.6425 17.2124 18.8094 18.6815 18.8094Z"
                    fill="#E3B549"
                  />
                  <path
                    d="M10.6717 5.213C12.1408 5.213 13.3317 4.04603 13.3317 2.6065C13.3317 1.16697 12.1408 0 10.6717 0C9.20263 0 8.01172 1.16697 8.01172 2.6065C8.01172 4.04603 9.20263 5.213 10.6717 5.213Z"
                    fill="#EF3937"
                  />
                </svg>
              </Box>
            </Grid>
          )}
          <Grid
            container
            item
            //xs={6} sm={5} md={4} lg={3} xl={2}
            xs={10}
            sm={8}
            md={6}
            lg={5}
            xl={5}
          >
            <Grid
              item
              px={0.5}
              xs={3}
              sx={{ cursor: "pointer" }}
              onMouseEnter={() => setTabs1(0)}
              onMouseLeave={resetHover}
            >
              <Button
                disableElevation
                sx={{
                  width: "100%",
                  height: "72%",
                  borderRadius: "0px 0px 8px 8px",
                  fontSize: "12px",
                  padding: "6px 0px",
                  m: 0,
                  "&:hover": {
                    background: theme.palette.blue.main,
                  },
                }}
                disableRipple
                onClick={() => {
                  onResetBuilder();
                  onResetBrandBuilderStatus();
                  setTabs1(0);
                  navigate(
                    `/organization/${currentOrganization.id}/content/draft`
                  );
                }}
                color="blue"
                variant="contained"
                startIcon={<DrawIcon sx={{ fontSize: "40px" }} />}
              >
                Draft Content
              </Button>
              <Box
                sx={{
                  backgroundImage: `url("/ActionMenuBlue.png")`,
                  width: "100%",
                  height: tabs1 === 0 ? "33%" : "0%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "bottom",
                  margin: "0px 0px",
                  transition: "0.2s",
                  position: "relative",
                  top: "-5px",
                }}
              ></Box>
            </Grid>
            <Grid
              item
              pr={0.5}
              xs={3}
              sx={{
                cursor: "pointer",
              }}
              onMouseEnter={() => setTabs1(1)}
              onMouseLeave={resetHover}
            >
              <Button
                disableElevation
                disableRipple
                sx={{
                  width: "100%",
                  height: "72%", //74
                  borderRadius: "0px 0px 8px 8px",
                  fontSize: "12px",
                  m: 0,
                  padding: "6px 0px",
                  "&:hover": {
                    background: theme.palette.red.main,
                  },
                }}
                color="error"
                onClick={() => {
                  setTabs1(1);
                  onResetAudienceList();
                  onResetPersonasList();
                  navigate(`/organization/${currentOrganization.id}/audience`);
                }}
                variant="contained"
                startIcon={<AudienceIcon small={true} />}
              >
                Audience
              </Button>

              <Box
                sx={{
                  backgroundImage: `url("/ActionMenuRed.png")`,
                  width: "100%",
                  height: tabs1 === 1 ? "33%" : "0%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "bottom",
                  margin: "0px 0px",
                  transition: "0.2s",
                  position: "relative",
                  top: "-5px",
                }}
              ></Box>
              <Box
                sx={{
                  position: "relative",
                  height: "24px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    height: "24px",
                    position: "absolute",
                    width: "260px",
                    top: tabs1 === 1 ? "-5px" : "-100px",
                  }}
                >
                  {/*<Button
                    sx={{
                      color: "black",
                      width: "50%",
                      fontSize: "12px",
                      borderRadius: 0,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50px",
                    }}
                    onClick={() =>
                      navigate(`/organization/${currentOrganization.id}/brand`)
                    }
                  >
                    Brand
                  </Button>
                  <Divider
                    sx={{
                      mx: 2,
                      borderRightWidth: "medium",
                    }}
                    color={"gray"}
                    orientation="vertical"
                  ></Divider>
                  <Button
                    sx={{
                      color: "black",
                      width: "50%",
                      fontSize: "12px",
                      borderRadius: 0,
                      textAlign: "left",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50px",
                    }}
                    onClick={() =>
                      navigate(
                        `/organization/${currentOrganization.id}/audience`
                      )
                    }
                  >
                    Audience
                  </Button>*/}
                </Stack>
              </Box>
            </Grid>

            <Grid
              item
              pr={0.5}
              xs={3}
              sx={{ cursor: "pointer" }}
              onMouseEnter={() => setTabs1(2)}
              onMouseLeave={resetHover}
            >
              <Button
                disableElevation
                sx={{
                  width: "100%",
                  height: "72%", //74
                  borderRadius: "0px 0px 8px 8px",
                  fontSize: "12px",
                  padding: "6px 0px",
                  m: 0,
                  "&:hover": {
                    background: theme.palette.warning.main,
                  },
                }}
                disableRipple
                /*onClick={() => {
                  if (analyzedContent) {
                    // navigate(`/organization/${currentOrganization.id}/analyze`);
                  }
                }}*/
                onClick={() => {
                  onResetBuilder();
                  onResetBrandBuilderStatus();
                  setTabs1(2);
                  navigate(`/organization/${currentOrganization.id}/brand`);
                }}
                color="warning"
                variant="contained"
                startIcon={
                  <BrandIcon small={true} color={theme.palette.white.main} />
                }
              >
                Brand
              </Button>
              <Box
                sx={{
                  backgroundImage: `url("/ActionMenuYellow.png")`,
                  width: "100%",
                  height: tabs1 === 2 ? "33%" : "0%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "bottom",
                  margin: "0px 0px",
                  transition: "0.2s",
                  position: "relative",
                  top: "-5px",
                }}
              ></Box>
            </Grid>
            <Grid
              item
              pr={0.5}
              xs={3}
              sx={{
                cursor: "pointer",
              }}
              onMouseEnter={() => setTabs1(4)}
              onMouseLeave={resetHover}
            >
              <Button
                disableElevation
                disableRipple
                p={0}
                sx={{
                  width: "100%",
                  height: "72%", //74
                  borderRadius: "0px 0px 8px 8px",
                  fontSize: "12px",
                  padding: "6px 0px",
                  m: 0,
                  "&:hover": {
                    background: theme.palette.green.main,
                  },
                }}
                color="success"
                onClick={() => {
                  setTabs1(4);
                  navigate(
                    `/organization/${currentOrganization.id}/content_library`
                  );
                }}
                variant="contained"
                startIcon={<AddContentIcon></AddContentIcon>}
              >
                Content Library
              </Button>

              <Box
                sx={{
                  backgroundImage: `url("/ActionMenuGreen.png")`,
                  width: "100%",
                  height: tabs1 === 4 ? "33%" : "0%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "bottom",
                  margin: "0px 0px",
                  transition: "0.2s",
                  position: "relative",
                  top: "-5px",
                }}
              ></Box>
              <Box
                sx={{
                  position: "relative",
                  height: "24px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    height: "24px",
                    position: "absolute",
                    width: "260px",
                    top: tabs1 === 4 ? "-5px" : "-100px",
                  }}
                >
                  {/*<Button
                    sx={{
                      color: "black",
                      width: "50%",
                      fontSize: "12px",
                      borderRadius: 0,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50px",
                    }}
                    onClick={() =>
                      navigate(`/organization/${currentOrganization.id}/brand`)
                    }
                  >
                    Brand
                  </Button>
                  <Divider
                    sx={{
                      mx: 2,
                      borderRightWidth: "medium",
                    }}
                    color={"gray"}
                    orientation="vertical"
                  ></Divider>
                  <Button
                    sx={{
                      color: "black",
                      width: "50%",
                      fontSize: "12px",
                      borderRadius: 0,
                      textAlign: "left",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50px",
                    }}
                    onClick={() =>
                      navigate(
                        `/organization/${currentOrganization.id}/audience`
                      )
                    }
                  >
                    Audience
                  </Button>*/}
                </Stack>
              </Box>
            </Grid>
          </Grid>
          {/*isMD && (
            <Grid item lg={2} xl={4}>
              <Box
                sx={{
                  bgcolor: theme.palette.dark.main,
                  height: "42px",
                  borderRadius: "0px 0px 8px 8px",
                  fontSize: "12px",
                }}
              ></Box>
            </Grid>
          )*/}
          {/*<Grid
            item
            xs={3}
            sm={4}
            md={3}
            lg={3}
            xl={2}
            sx={{ cursor: "pointer" }}
            onMouseEnter={() => setTabs1(3)}
            onMouseLeave={resetHover}
            px={0.5}
          >
            <Button
              disableElevation
              sx={{
                width: "100%",
                height: "85%",
                borderRadius: "0px 0px 8px 8px",
                fontSize: "12px",
                m: 0,
                "&:hover": {
                  background: theme.palette.success.main,
                },
              }}
              color="success"
              onClick={() => {
                setTabs1(3);
                navigate(
                  `/organization/${currentOrganization.id}/content_library`
                );
              }}
              variant="contained"
            >
              + Content Library
            </Button>
            <Box
              sx={{
                backgroundImage: `url("/ActionMenuCLgreen.png")`,
                width: "100%",
                height: tabs1 === 3 ? "33%" : "0%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "bottom",
                margin: "0px 0px",
                transition: "0.2s",
                position: "relative",
                top: "-5px",
              }}
            ></Box>
          </Grid> */}
          <Grid item xl={5} lg={5} md={5} sm={3} xs={1}>
            <Box
              sx={{
                bgcolor: theme.palette.dark.main,
                borderRadius: "0px 0px 0px 8px",
                display: "flex",
                height: "42px",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {currentProfile && !isXS && (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={1}
                  color={"#fff"}
                >
                  <Avatar
                    {...stringAvatar(currentProfile?.name, null, "#fff", {
                      width: 30,
                      height: 30,
                    })}
                  />
                  {!isSM && (
                    <Typography variant="overline" fontWeight={200}>
                      {currentProfile?.name} {currentProfile?.last_name}
                    </Typography>
                  )}

                  <Box>
                    <Divider
                      sx={{ height: "25px" }}
                      color={"white"}
                      orientation="vertical"
                    ></Divider>
                  </Box>
                  <Typography variant="overline" fontWeight={200}>
                    {currentOrganization?.name} {currentOrganization?.last_name}
                  </Typography>
                  <Box>
                    <Divider
                      sx={{ height: "25px" }}
                      color={"white"}
                      orientation="vertical"
                    ></Divider>
                  </Box>
                  {credits && (
                    <Stack>
                      <BootstrapTooltip title={"Credits Monthly Consumption"}>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "100",
                          }}
                        >
                          BASE <b>{credits.flat_rate_credits} </b> | OVERAGE{" "}
                          <b>{credits.usage_credits}</b>
                        </Typography>
                      </BootstrapTooltip>
                    </Stack>
                  )}

                  <Box>
                    <Divider
                      sx={{ height: "25px" }}
                      color={"white"}
                      orientation="vertical"
                    ></Divider>
                  </Box>
                </Stack>
              )}

              <Box
                sx={{
                  bgcolor: open ? "#E9E8E3" : "",
                  p: 0.8,
                  width: "50px",
                }}
                onClick={handleClick}
              >
                <SettingsIcon
                  style={{ color: open ? "black" : "white" }}
                ></SettingsIcon>
              </Box>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                  sx: {
                    bgcolor: open ? "#E9E8E3" : "#5C5A52",
                    left: 0,
                  },
                }}
                slotProps={{
                  elevation: 0,
                  paper: {
                    elevation: 1,
                    sx: {
                      left: "88% !important",
                      width: "14.3rem",
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    px: 1,
                    py: 0.5,
                    fontSize: "12px",
                    fontWeight: "bolder",
                    my: 1,
                    bgcolor: "white",
                  }}
                >
                  SETTINGS
                </Typography>
                <MenuItem
                  sx={{ fontSize: "12px" }}
                  onClick={() => {
                    handleClose();
                    navigate("/profile");
                  }}
                >
                  Profile
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: "12px" }}
                  onClick={() => {
                    handleClose();
                    navigate("/organizations");
                  }}
                >
                  Organization
                </MenuItem>
                <Typography
                  sx={{
                    px: 1,
                    py: 0.5,
                    fontSize: "12px",
                    fontWeight: "bolder",
                    my: 1,
                    bgcolor: "white",
                  }}
                >
                  RESOURCES
                </Typography>
                <MenuItem sx={{ fontSize: "12px" }} onClick={handleClose}>
                  <a
                    style={{
                      color: "black",
                      textDecoration: "none",
                    }}
                    href={externalResources.HELP_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Quick help
                  </a>
                </MenuItem>
                <Box py={2}></Box>
                <MenuItem sx={{ fontSize: "12px" }} onClick={handleClose}>
                  <a
                    style={{
                      color: "black",
                      textDecoration: "none",
                    }}
                    href={externalResources.CONTACT_US_URL}
                  >
                    Contact
                  </a>
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: "12px", color: "red" }}
                  onClick={() => {
                    window.location.href = "/login";
                    localStorage.clear();
                    localStorage.clear();
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </>
      );
    }
    return isMD ? (
      <>
        <Grid item xs={2}>
          <Box
            sx={{
              bgcolor: theme.palette.dark.main,
              height: "42px",
              borderRadius: "0px 0px 8px 0px",
              mr: "2px",
            }}
          >
            <Button
              onClick={() => {
                onSetPreviousOrganization();
                navigate(isPublicPage ? "/" : "/organizations");
              }}
              fullWidth
              sx={{
                height: "42px",
                borderRadius: "0px 0px 8px 0px",

                border: "1px solid #6b6b6b",
                borderTop: 0,
                borderLeft: 0,
              }}
              color="white"
            >
              <Logo width={"50%"} />
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={10}
          sx={{
            bgcolor: theme.palette.dark.main,
            height: "42px",
            borderRadius: "0px 0px 8px 8px",
            border: "1px solid #6b6b6b",
            borderTop: 0,
          }}
        >
          {isPublicPage && location.pathname !== "/login" && (
            <Stack height={"100%"} alignItems={"end"}>
              <Button
                sx={{ height: "42px" }}
                color="white"
                onClick={() => navigate("/login")}
                startIcon={<LoginIcon></LoginIcon>}
              >
                Login
              </Button>
            </Stack>
          )}
        </Grid>
      </>
    ) : (
      <Grid item xs={12}>
        <Box
          sx={{
            bgcolor: theme.palette.dark.main,
            py: 1.3,
            mr: "2px",
            borderRadius: "0px 0px 8px 0px",
          }}
        >
          <svg
            width="22"
            height="19"
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.66621 9.40465C8.44731 6.3866 12.8962 6.3866 14.6773 9.40465C16.4584 12.4227 14.2339 16.2029 10.6717 16.2029C7.10954 16.2029 4.88511 12.4227 6.66621 9.40465Z"
              fill="white"
            />
            <path
              d="M2.65998 18.8094C4.12905 18.8094 5.31996 17.6425 5.31996 16.2029C5.31996 14.7634 4.12905 13.5964 2.65998 13.5964C1.19091 13.5964 0 14.7634 0 16.2029C0 17.6425 1.19091 18.8094 2.65998 18.8094Z"
              fill="#66BF76"
            />
            <path
              d="M18.6815 18.8094C20.1505 18.8094 21.3414 17.6425 21.3414 16.2029C21.3414 14.7634 20.1505 13.5964 18.6815 13.5964C17.2124 13.5964 16.0215 14.7634 16.0215 16.2029C16.0215 17.6425 17.2124 18.8094 18.6815 18.8094Z"
              fill="#E3B549"
            />
            <path
              d="M10.6717 5.213C12.1408 5.213 13.3317 4.04603 13.3317 2.6065C13.3317 1.16697 12.1408 0 10.6717 0C9.20263 0 8.01172 1.16697 8.01172 2.6065C8.01172 4.04603 9.20263 5.213 10.6717 5.213Z"
              fill="#EF3937"
            />
          </svg>
        </Box>
      </Grid>
    );
  };

  return (
    <Box>
      <Box>
        <Grid
          sx={{
            position: "fixed",
            top: 0,
            zIndex: 1,
          }}
          container
        >
          {renderMenu()}
        </Grid>
      </Box>
    </Box>
  );
}
