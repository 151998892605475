export const getUserAccountsPending = (state, action) => {
  state.userAccounts.status = "pending";
};

export const getUserAccountsFulfilled = (state, action) => {
  state.userAccounts.status = "fulfilled";
  state.userAccounts.data = action.payload;
};

export const getUserAccountsRejected = (state, action) => {
  state.userAccounts.status = "rejected";
};
