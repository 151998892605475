//Packages Imports
import { useSelector } from "react-redux";
//App Import
import { useAppDispatch } from "../redux/store";
import {
  selectResetPasswordData,
  selectResetPasswordStatus,
  selectResetPasswordError,
  resetResetPassword,
} from "../redux/auth";
import { authAPIs } from "../api_";

export function useResetPasswordHandler() {
  const dispatch = useAppDispatch();
  const status = useSelector((state) => selectResetPasswordStatus(state));
  const data = useSelector((state) => selectResetPasswordData(state));
  const error = useSelector((state) => selectResetPasswordError(state));

  const isUninitialized = status === undefined;
  const isLoading = status === "pending" || status === undefined;
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onResetPassword = (values) => {
    dispatch(authAPIs.postResetPassword(values));
  };

  const onResetResetPassword = () => {
    dispatch(resetResetPassword());
  };


  return {
    onResetPassword,
    onResetResetPassword,
    error,
    data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
