import { Box, Grid, Typography } from "@mui/material";
import EditorComponent from "../EditorComponent";
import ContentTitlePreview from "../ContentTitlePreview";
import useContentAdaptChannelHandler from "../../hooks/useContentAdaptChannelHandler";
export default function AdaptedContentForm({formikSaveAdaptedContent, data, isAdapted,loaded}) {
    const {
        contentAdapted
      } = useContentAdaptChannelHandler();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box px={2} mb={5}>
          <Typography variant="caption" ml={1}>
            SELECTED CONTENT TITLE:
          </Typography>
          <ContentTitlePreview
            isDark={true}
            formik={formikSaveAdaptedContent}
          ></ContentTitlePreview>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box px={2}>
          <Typography variant="caption" ml={1}>
            <b>ORIGINAL</b> /{" "}
            {`${data?.metadata?.channel} - ${data?.metadata?.subchannel}`.toUpperCase()}{" "}
            :
          </Typography>
          <EditorComponent
            readOnly={true}
            height={380}
            isSuggestionMode={false}
            initialValue={data?.metadata?.content_text}
            isDark={true}
          ></EditorComponent>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box>
          <Typography variant="caption" ml={1}>
            <b>ADAPTATION</b> /{" "}
            {`${contentAdapted.channel.channel} - ${contentAdapted.channel.subchannel}`.toUpperCase()}{" "}
            :
          </Typography>
          <Box my={5}>
            {isAdapted &&
              loaded &&
              formikSaveAdaptedContent.values.contentText.length && (
                <EditorComponent
                  height={300}
                  isSuggestionMode={false}
                  initialValue={formikSaveAdaptedContent.values.contentText}
                  isDark={false}
                  showToolbar={true}
                  readOnly={false}
                  formik={formikSaveAdaptedContent}
                  onHandleChange={(value) =>
                    formikSaveAdaptedContent.setFieldValue(`contentText`, value)
                  }
                  onHandleBlur={() => formikSaveAdaptedContent.handleBlur()}
                />
              )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
