import { Box, Button, Divider } from "@mui/material";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText, LogoLoader } from "../../../../components";
import { useSetNewPasswordHandler } from "../../../../hooks/useSetNewPasswordHandler";

export default function SetNewPassword() {
  const { onSetNewPassword, isLoading, isSuccess, data, error } =
    useSetNewPasswordHandler();

  const formik = useFormik({
    initialValues: { password: "", access_token: "", refresh_token: "" },
    validationSchema: Yup.object({
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      onSetNewPassword(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      window.location.href = "/";
    }
  }, [isSuccess]);

  useEffect(() => {
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    if (
      hashParams &&
      formik.values.access_token === "" &&
      formik.values.refresh_token === ""
    ) {
      if (hashParams.get("access_token") && hashParams.get("refresh_token")) {
        formik.setFieldValue("access_token", hashParams.get("access_token"));
        formik.setFieldValue("refresh_token", hashParams.get("refresh_token"));
        return;
      }
    }
  }, [formik]);

  return (
    <Box>
      <LogoLoader open={isLoading}></LogoLoader>
      <Divider
        textAlign="left"
        sx={{
          fontWeight: 200,
        }}
      >
        New Password
      </Divider>
      <br />
      <InputText
        placeholder="password"
        name={"password"}
        type={"password"}
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={isSuccess ? data.message : error?.error}
      />

      <Button color="dark" variant="contained" onClick={formik.handleSubmit}>
        Change Password
      </Button>
    </Box>
  );
}
