import { useDispatch, useSelector } from "react-redux";
import { selectCurrentOrganization } from "../redux/organizations";
import { selectDisableAudienceStatus, selectDisableAudienceData, resetDisableAudience } from "../redux/audience";
import { organizationAPIs } from "../api_";

export default function useDisableAudienceHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectDisableAudienceStatus(state));
  const data = useSelector((state) => selectDisableAudienceData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));

  
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onResetDisableAudience = ()=>{
    dispatch(resetDisableAudience())
  }

  const onDisableAudience = (audience_id, disable) => {
    dispatch(
      organizationAPIs.putDisableAudience({
        values:{status:disable, audience_id},
        id:audience_id,
        orgId: currentOrg.id
      })
    );
  };

  return {
    audienceList:data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onDisableAudience,
    onResetDisableAudience
  };
}
