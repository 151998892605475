export const postBrandReactionPending = (state, action) => {
  state.brandReaction.status = "pending";
};

export const postBrandReactionFulfilled = (state, action) => {
  state.brandReaction.status = "fulfilled";
  state.brandReaction.data = action.payload;
};

export const postBrandReactionRejected = (state, action) => {
  state.brandReaction.status = "rejected";
  state.brandReaction.data = action.payload.response.data;

};
