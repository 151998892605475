import contentSlice from "./slice"

// SELECTORS
export const selectUploadFileData = (state) => state?.content?.uploadFileContent?.data;
export const selectUploadFileStatus = (state) => state?.content?.uploadFileContent?.status
export const selectUploadFileError = (state) => state?.content?.uploadFileContent?.error

export const selectUploadContentTextData = (state) => state?.content?.uploadContentText?.data;
export const selectUploadContentTextStatus = (state) => state?.content?.uploadContentText?.status
export const selectUploadContentTextError = (state) => state?.content?.uploadContentText?.error

export const selectCreateFolderData = (state) => state?.content?.createFolder?.data;
export const selectCreateFolderStatus = (state) => state?.content?.createFolder?.status

export const selectGetAllFoldersData = (state) => state?.content?.allFolders?.data;
export const selectGetAllFoldersStatus = (state) => state?.content?.allFolders?.status

export const selectAnalyzeContentData = (state) => state?.content?.analyzeContent?.data;
export const selectAnalyzeContentStatus = (state) => state?.content?.analyzeContent?.status

export const selectSuggestionsContentData = (state) => state?.content?.suggestionsContent?.data;
export const selectSuggestionsContentStatus = (state) => state?.content?.suggestionsContent?.status

export const selectHandleContentEditsData = (state) => state?.content?.contentEdits?.data;
export const selectHandleContentEditsIsAnalyze = (state) => state?.content?.contentEdits?.analyze;
export const selectHandleContentEditsStatus = (state) => state?.content?.contentEdits?.status

export const selectBrandReactionData = (state) => state?.content?.brandReaction?.data;
export const selectBrandReactionStatus = (state) => state?.content?.brandReaction?.status

export const selectWatchVersionData = (state) => state?.content?.watchVersion?.data;
export const selectWatchVersionStatus = (state) => state?.content?.watchVersion?.status

export const selectAnalyzedContentData = (state) => state?.content?.analyzedContent?.data;
export const selectAnalyzedContentStatus = (state) => state?.content?.analyzedContent?.status

export const selectAdaptContentChannelData = (state) => state?.content?.adaptContentChannel?.data;
export const selectAdaptContentChannelStatus = (state) => state?.content?.adaptContentChannel?.status

export const selectGetContentNavigatorData = (state) => state?.content?.navigator?.data;
export const selectNavigatorCurrentFolder = (state) => state?.content?.navigator?.currentFolder;
export const selectNavigatorPrevFolder = (state) => state?.content?.navigator?.prevFolder;

export const selectGetContentNavigatorStatus = (state) => state?.content?.navigator?.status

export const selectGetContentMetadataData = (state) => state?.content?.contentMetadata?.data;
export const selectGetContentMetadataStatus = (state) => state?.content?.contentMetadata?.status
export const selectGetContentUpdateMetadataStatus = (state) => state?.content?.contentUpdateMetadata?.status

export const selectGetContentMetadataFolderData = (state) => state?.content?.contentMetadataFolder?.data;
export const selectGetContentMetadataFolderStatus = (state) => state?.content?.contentMetadataFolder?.status

export const selectContentToAnalyzeData = (state) => state?.content?.contentToAnalyze?.data;
export const selectContentToAnalyzeStatus = (state) => state?.content?.contentToAnalyze?.status

export const selectAnalyzeSingleContentData = (state) => state?.content?.analyzeSingleContent?.data;
export const selectAnalyzeSingleContentStatus = (state) => state?.content?.analyzeSingleContent?.status

export const selectDeleteContentData = (state) => state?.content?.deleteContent?.data;
export const selectDeleteContentStatus = (state) => state?.content?.deleteContent?.status

export const selectAnalyzeExportData = (state) =>  state?.content?.exportPDF?.data
export const selectAnalyzeExportStatus = (state) => state?.content?.exportPDF?.status
export const selectAnalyzeExportError = (state) => state?.content?.exportPDF?.error
export const selectAnalyzeExportQueue = (state) => state?.content?.exportPDFQueue.queue

export const selectGenerateDraftContentData = (state) =>  state?.content?.generateDraftContent?.data
export const selectGenerateDraftContentStatus = (state) => state?.content?.generateDraftContent?.status
export const selectGenerateDraftContentError = (state) => state?.content?.generateDraftContent?.error

// ACTIONS
export const { 
    resetStatusUploadFile,
    resetStatusUploadContentText,
    resetStatusCreateFolder,
    resetContentMetadata,
    resetContentMetadataFolder,
    resetUpdateContentMetadata,
    resetAnalyzeContent,
    setCurrentFolder,
    resetAnalyzedContent,
    setAnalyzedContentStatus,
    resetSuggestionsContent,
    resetBrandReaction,
    resetContentEdits,
    resetWatchVersion,
    resetAdaptContentChannel,
    setContentToAnalyze,
    setExecuteAnalyze,
    updateAnalyzeSingleContent,
    setAnalyzeSingleContent,
    resetAnalyzeSingleContent,
    resetAllFolders,
    resetDeleteContent,
    removeFromAnalyzeExportQueue,
    resetNavigator,
    resetGenerateDraftContent
} = contentSlice.actions;

// REDUCER
export default contentSlice.reducer

