export default function useColorHandler() {
  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };
  const stringAvatar = (name, backgroundColor, fontColor,extraCss) => {
    const nameParts = name.split(" ");
    const hasTwoWords = nameParts.length >= 2;
  
    return {
      sx: {
        bgcolor: `${backgroundColor ? backgroundColor : stringToColor(name)} !important`,
        color:fontColor?fontColor:'initial',
        ...extraCss,
      },
      children: hasTwoWords
        ? `${nameParts[0][0]}${nameParts[1][0]}`.toUpperCase()
        : `${nameParts[0][0]}`.toUpperCase(),
    };
  };

  return { stringToColor, stringAvatar };
}
