import { styled } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.dark.main,
    color: "#fff",
    maxWidth: "500px !important",
    padding: "10px",
    //border: "1px solid #dadde9",
    fontWeight: "300",
    fontSize: "15px!important",
    "MozBoxShadow": "0px -12px 9px -12px rgb(0 0 0 / 37%)",
    "boxShadow": "0px -12px 9px -12px rgb(0 0 0 / 37%)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.dark.main,
  },
}));

export default BootstrapTooltip;
