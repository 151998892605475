import { useDispatch, useSelector } from "react-redux";
import {
  resetGenerateAnswerStatus,
  selectGenerateAnswerData,
  selectGenerateAnswerError,
  selectGenerateAnswerStatus,
} from "../redux/persona";
import { organizationAPIs } from "../api_";
import useCurrentOrganizationHandler from "./useCurrentOrganizationHandler";

export function useGenerateAnswerHandler() {
  const dispatch = useDispatch();

  const { currentOrganization } = useCurrentOrganizationHandler();
  const data = useSelector((state) => selectGenerateAnswerData(state));
  const error = useSelector((state) => selectGenerateAnswerError(state));
  const status = useSelector((state) => selectGenerateAnswerStatus(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onGenerateAnswer = (values) => {
    dispatch(
      organizationAPIs.postGenerateAnswer({
        values:values,
        orgId:currentOrganization.id
      })
    );
  };
  const onResetGenerateAnswerStatus = ()=>dispatch(resetGenerateAnswerStatus())

  return {
    onGenerateAnswer,
    onResetGenerateAnswerStatus,
    data,
    error,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
