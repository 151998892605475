import * as React from "react";
import {
  Box,
  Stack,
  IconButton,
  Typography,
  LinearProgress,
} from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import {
  AnalyzeConfirmationModal,
  AnalyzeExportPDFButton,
  BootstrapTooltip,
  ModalAdaptContent,
} from "../../../../../components";
import ModalDeleteContent from "./ModalDeleteContent";
import LayersRoundedIcon from "@mui/icons-material/LayersRounded";
import useGetAnalyzedContent from "../../../../../hooks/useGetAnalyzedContent";
import useAnalyzeContentHandler from "../../../../../hooks/useAnalyzeContentHandler";
import useContentMetadataHandler from "../../../../../hooks/useContentMetadataHandler";
import useContentMetadataFolderHandler from "../../../../../hooks/useContentMetadataFolderHandler";

export default function ContentActions({ params, toggleDrawer }) {
  const { onResetGetAnalyzedContent } = useGetAnalyzedContent();
  
  const { analyzeQueue} = useAnalyzeContentHandler(true);
  const { onGetContentMetadata } =
    useContentMetadataHandler();
  const { onGetContentMetadataFolder } =
    useContentMetadataFolderHandler();
  
  return (
    <>
      {analyzeQueue.find(
        (record) => record.content_id === params.row.content_id
      )?.status === "in queue" ? (
        <Stack height={"100%"} justifyContent={"center"}>
          <Box>
            <Typography fontWeight={200} textAlign={"center"} fontSize={10}>
              Analyzing
            </Typography>
            <LinearProgress
              color="dark"
              sx={{
                height: "1px",
                background: "white",
              }}
            ></LinearProgress>
          </Box>
        </Stack>
      ) : (
        <>
          <AnalyzeConfirmationModal
            btnType="small"
            has_analytics={params?.row?.has_analytics}
            fullWidth={true}
            content={{
              id: params.row.content_id,
              title: params.row.title,
              // isAnalyze: true,
            }}
          ></AnalyzeConfirmationModal>
          <ModalAdaptContent btnType="small" content={params.row} />
          <BootstrapTooltip title="Open Details">
            <IconButton
              variant={"contained"}
              color={"dark"}
              size="small"
              onClick={() => {
                toggleDrawer(true, params);
                if (params.row.isFile) {
                  onResetGetAnalyzedContent();
                  onGetContentMetadata(params.row.content_id);
                } else {
                  onGetContentMetadataFolder(params.row.folder_id);
                }
              }}
            >
              <TuneIcon fontSize="small" />
            </IconButton>
          </BootstrapTooltip>
          <BootstrapTooltip
            title={
              params.row.parent_content_id === null
                ? "No Parent content"
                : "View Parent Content"
            }
          >
            <span>
              <IconButton
                variant={"contained"}
                disabled={params.row.parent_content_id === null}
                color={"yellow"}
                size="small"
                onClick={() => {
                  toggleDrawer(true, params);
                  if (params.row.isFile) {
                    onResetGetAnalyzedContent();
                    onGetContentMetadata(params.row.parent_content_id);
                  }
                }}
              >
                <LayersRoundedIcon fontSize="small" />
              </IconButton>
            </span>
          </BootstrapTooltip>
          <ModalDeleteContent contentId={params.row.content_id} />
          <AnalyzeExportPDFButton
            has_analytics={params?.row?.current_has_analytics}
            contentId={params.row.content_id}
            versionId={params.row.version_number}
          />
        </>
      )}
    </>
  );
}
