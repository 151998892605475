export const putContentMetadataPending = (state, action) => {
  state.contentUpdateMetadata.status = "pending";
};

export const putContentMetadataFulfilled = (state, action) => {
  state.contentUpdateMetadata.status = "fulfilled";
  state.contentMetadata.data = action.payload;
};

export const putContentMetadataRejected = (state, action) => {
  state.contentUpdateMetadata.status = "rejected";
};
