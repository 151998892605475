import { Typography } from "@mui/material";

export default function DateFormatted({ params }) {
    if (!params.value) return null;
    const date = new Date(params.value * 1000);
    const dateString = date.toLocaleDateString();
    const timeString = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    const formattedDate = `${dateString} ${timeString}`;
    return (
      <>
        <Typography variant="caption">{formattedDate}</Typography>
      </>
    );
}
