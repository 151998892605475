import { useDispatch, useSelector } from "react-redux";
import { contentAPIs } from "../api_";
import {
  selectWatchVersionStatus,
  selectWatchVersionData,
  resetWatchVersion,
} from "../redux/content";

export default function useContetCurrentVersionHanlder() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectWatchVersionStatus(state));
  const data = useSelector((state) => selectWatchVersionData(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onGetWatchVersion = (id, version, organizationId) => {
    dispatch(
      contentAPIs.getWatchVersion({
        orgId: organizationId,
        id: id,
        versionId: version,
      })
    );
  };

  const onResetWatchVersion = () => {
    dispatch(resetWatchVersion());
  };

  return {
    brandReactionData: data,
    onGetWatchVersion,
    onResetWatchVersion,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
