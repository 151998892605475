export const cancelSubscriptionPending = (state, action) => {
  state.cancelSubscription.status = "pending";
  state.cancelSubscription.error = null;
};

export const cancelSubscriptionFulfilled = (state, action) => {
  state.cancelSubscription.status = "fulfilled";
  state.cancelSubscription.data = action.payload;
};

export const cancelSubscriptionRejected = (state, action) => {
  state.cancelSubscription.status = "rejected";
  state.cancelSubscription.error = action.payload.response.data;
};
