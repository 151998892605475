import {
  Box,
  Button,
  MenuItem,
  Select,
  CircularProgress,
  Chip,
  Typography,
  Grid,
  Stack,
  Autocomplete,
  Paper,
  Input,
  useTheme,
} from "@mui/material";
import useRoleHandler from "../../../../hooks/useRoleHandler";
import useOrganizationListHandler from "../../../../hooks/useOrganizationListHandler";
import useInviteUserHandler from "../../../../hooks/useInviteUserHandler";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "../../../../components";
import useAdminAllUsersHandler from "../../../../hooks/useAdminAllUsersHandler";
import { useEffect, useState } from "react";
import useCurrentOrganizationHandler from "../../../../hooks/useCurrentOrganizationHandler";

export default function useInviteExistentUser() {
  const { roles } = useRoleHandler();
  const theme = useTheme();
  const { organizations, isLoading } = useOrganizationListHandler();
  const { currentOrganization } = useCurrentOrganizationHandler();
  const {
    users,
    isUninitialized,
    isSuccess: isGettingUsersDone,
    onGetAdminAllUsers,
  } = useAdminAllUsersHandler();
  const { onSubmitForm } = useInviteUserHandler();
  const [inputValue, setInputValue] = useState("");

  const formik = useFormik({
    initialValues: {
      selectedUser: null,
      organization_id: currentOrganization?.id,
      role_id: "",
    },
    validationSchema: Yup.object({
      selectedUser: Yup.object().required("Required"),
      organization_id: Yup.string().required("Required"),
      role_id: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      onSubmitForm({
        user_profile_id: values.selectedUser.id,
        organization_id: values.organization_id,
        role_id: values.role_id,
      });
    },
  });

  useEffect(() => {
    if (isUninitialized) {
      formik.resetForm();
      onGetAdminAllUsers();
    }
  }, [isUninitialized, onGetAdminAllUsers, formik]);

  return {
    reset: () => {
      formik.resetForm();
    },
    actions: (
      <Button variant="contained" onClick={formik.handleSubmit}>
        Submit
      </Button>
    ),
    body: (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4">Invite new user</Typography>
        </Grid>
        <Grid xs={6} item>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={
              isGettingUsersDone && inputValue.length >= 3
                ? users
                    .filter((i) => i.name && i.last_name)
                    .map((i, idx) => ({
                      label: `${idx + 1} - ${i.name} ${i.last_name}`,
                      email: i.email,
                      id: i.id,
                    }))
                : []
            }
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            value={formik.values.selectedUser}
            onChange={(event, newValue) => {
              formik.setFieldValue("selectedUser", newValue);
            }}
            renderOption={(props, option) => {
              const { key, ...otherProps } = props;
              return (
                <Box key={key} {...otherProps}>
                  <Stack
                    justifyContent={"flex-start"}
                    alignContent={"flex-start"}
                    direction="column"
                  >
                    <Box>
                      <Typography variant="body1" textAlign="left">
                        {option.label}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" textAlign="left">
                        {option.email}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              );
            }}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <Paper
                  variant={"outlined"}
                  sx={{
                    width: "98.5%",
                    background: "white",
                    borderRadius: "50px",
                    padding: "3px",
                    maxHeight: "24px",
                  }}
                >
                  <Input
                    disableUnderline={true}
                    autoComplete="off"
                    size="small"
                    placeholder="Search user"
                    inputProps={{
                      ...params.inputProps,
                      sx: {
                        padding: "2px 4px 3px 4px !important",
                        maxHeight: "14px",
                      },
                    }}
                    sx={{
                      fontSize: "14px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "50px",
                      outline: "red",
                      padding: "2px",
                      color:
                        formik?.touched?.selectedUser &&
                        Boolean(formik?.errors?.selectedUser)
                          ? "#e25353"
                          : theme.palette.dark.main,
                      border:
                        formik?.touched?.selectedUser &&
                        Boolean(formik?.errors?.selectedUser)
                          ? "1px solid #ff6262"
                          : "1px solid white",
                      transition: "0.2s",
                      ":focus-within": {
                        border: "1px solid #00cbff",
                        color: "#00708d",
                        transition: "0.2s",
                      },
                    }}
                  />
                </Paper>
                <Typography
                  fontSize={10}
                  minHeight={15}
                  pl={2}
                  color={
                    formik?.touched?.selectedUser &&
                    Boolean(formik?.errors?.selectedUser)
                      ? "#e25353"
                      : "#686868"
                  }
                  fontWeight={300}
                >
                  {formik?.errors?.selectedUser}{" "}
                </Typography>
              </div>
            )}
          />
          <Box></Box>
        </Grid>
        <Grid xs={6} item>
          {" "}
          <Select
            displayEmpty
            SelectDisplayProps={{
              style: {
                padding: 0,
              },
            }}
            name={"role_id"} //
            value={
              formik.values.role_id
                ? roles.find((i) => i.id === formik.values.role_id).id
                : ""
            } //formik?.values?.channel
            error={formik?.touched?.role_id && Boolean(formik?.errors?.role_id)}
            startAdornment={isLoading && <CircularProgress size={"20px"} />}
            input={
              <InputText
                placeholder="Choose Role"
                helperText={formik.touched.role_id ? formik.errors.role_id : ""}
              />
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            renderValue={(selected) => {
              if (!selected) return <em>{"Choose Role"}</em>;
              return selected ? (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  <Chip
                    sx={{ height: "20px !important" }}
                    key={formik.values.role_id}
                    label={
                      roles.find((i) => i.id === formik.values.role_id).role
                    }
                  />
                </Box>
              ) : (
                <em>{"Choose Role"}</em>
              );
            }}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem disabled value="">
              <em>Choose Role</em>
            </MenuItem>
            {roles.map((role, idx) => (
              <MenuItem
                sx={{
                  textAlign: "center",
                  pl: 5,
                }}
                key={idx}
                value={role.id}
              >
                {role.role}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid xs={6} item>
          <Select
            displayEmpty
            SelectDisplayProps={{
              style: {
                padding: 0,
              },
            }}
            name={"organization_id"} //
            value={
              formik.values.organization_id
                ? organizations.find(
                    (i) => i.id === formik.values.organization_id
                  ).id
                : ""
            } //formik?.values?.channel
            error={
              formik?.touched?.organization_id &&
              Boolean(formik?.errors?.organization_id)
            }
            startAdornment={isLoading && <CircularProgress size={"20px"} />}
            input={
              <InputText
                placeholder="Choose Organization"
                helperText={
                  formik.touched.organization_id
                    ? formik.errors.organization_id
                    : ""
                }
              />
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            renderValue={(selected) => {
              if (!selected) return <em>{"Choose Organization"}</em>;
              return selected ? (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  <Chip
                    sx={{ height: "20px !important" }}
                    key={formik.values.organization_id}
                    label={
                      organizations.find(
                        (i) => i.id === formik.values.organization_id
                      ).name
                    }
                  />
                </Box>
              ) : (
                <em>{"Choose Organization"}</em>
              );
            }}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem disabled value="">
              <em>Choose Organization</em>
            </MenuItem>
            {organizations.map((organization, idx) => (
              <MenuItem
                sx={{
                  textAlign: "center",
                  pl: 5,
                }}
                key={idx}
                value={organization.id}
              >
                {organization.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    ),
  };
}
