const AudienceIcon = ({ small }) =>
  small ? (
    <svg
      width="39"
      height="23"
      viewBox="0 0 39 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.5967 15.0972C29.416 14.7331 30.3264 14.5337 31.2801 14.5337C34.9518 14.5337 37.93 17.5119 37.93 21.1836"
        stroke="white"
        strokeWidth="1.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M31.2803 11.7984C33.4399 11.7984 35.1905 10.0478 35.1905 7.88823C35.1905 5.72868 33.4399 3.97803 31.2803 3.97803C29.1208 3.97803 27.3701 5.72868 27.3701 7.88823C27.3701 10.0478 29.1208 11.7984 31.2803 11.7984Z"
        stroke="white"
        strokeWidth="1.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.4434 21.1839C11.4434 16.7578 15.0328 13.1641 19.4632 13.1641C23.8936 13.1641 27.483 16.7535 27.483 21.1839"
        stroke="white"
        strokeWidth="1.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M19.4636 10.433C22.0684 10.433 24.1801 8.32138 24.1801 5.71652C24.1801 3.11166 22.0684 1 19.4636 1C16.8587 1 14.7471 3.11166 14.7471 5.71652C14.7471 8.32138 16.8587 10.433 19.4636 10.433Z"
        stroke="white"
        strokeWidth="1.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M1 21.1836C1 17.5119 3.97817 14.5337 7.64994 14.5337C8.60365 14.5337 9.514 14.7331 10.3333 15.0972"
        stroke="white"
        strokeWidth="1.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.64555 11.7984C9.80509 11.7984 11.5558 10.0478 11.5558 7.88823C11.5558 5.72868 9.80509 3.97803 7.64555 3.97803C5.48601 3.97803 3.73535 5.72868 3.73535 7.88823C3.73535 10.0478 5.48601 11.7984 7.64555 11.7984Z"
        stroke="white"
        strokeWidth="1.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  ) : (
    <svg
      width="90"
      height="51"
      viewBox="0 0 90 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1354_7658)">
        <path
          d="M65.7305 34.59C67.6205 33.75 69.7205 33.29 71.9205 33.29C80.3905 33.29 87.2605 40.16 87.2605 48.63"
          stroke="black"
          strokeWidth="4.13"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M71.9204 26.9799C76.902 26.9799 80.9404 22.9415 80.9404 17.9599C80.9404 12.9783 76.902 8.93994 71.9204 8.93994C66.9388 8.93994 62.9004 12.9783 62.9004 17.9599C62.9004 22.9415 66.9388 26.9799 71.9204 26.9799Z"
          stroke="black"
          strokeWidth="4.13"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M26.1602 48.6301C26.1602 38.4201 34.4402 30.1301 44.6602 30.1301C54.8802 30.1301 63.1602 38.4101 63.1602 48.6301"
          stroke="black"
          strokeWidth="4.13"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M44.6603 23.8301C50.6691 23.8301 55.5403 18.9589 55.5403 12.9501C55.5403 6.94121 50.6691 2.07007 44.6603 2.07007C38.6514 2.07007 33.7803 6.94121 33.7803 12.9501C33.7803 18.9589 38.6514 23.8301 44.6603 23.8301Z"
          stroke="black"
          strokeWidth="4.13"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M2.07031 48.63C2.07031 40.16 8.94031 33.29 17.4103 33.29C19.6103 33.29 21.7103 33.75 23.6003 34.59"
          stroke="black"
          strokeWidth="4.13"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M17.3999 26.9799C22.3815 26.9799 26.4199 22.9415 26.4199 17.9599C26.4199 12.9783 22.3815 8.93994 17.3999 8.93994C12.4183 8.93994 8.37988 12.9783 8.37988 17.9599C8.37988 22.9415 12.4183 26.9799 17.3999 26.9799Z"
          stroke="black"
          strokeWidth="4.13"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1354_7658">
          <rect width="89.32" height="50.7" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
export default AudienceIcon;
