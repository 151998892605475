import { useSelector } from "react-redux";
import { authAPIs } from "../api_";
import { useAppDispatch } from "../redux/store";
import {
  resetUpdateProfile,
  selectUpdateProfileData,
  selectUpdateProfileError,
  selectUpdateProfileStatus,
} from "../redux/auth";

export default function useUpdateProfileHandler() {
  const dispatch = useAppDispatch();

  const status = useSelector((state) => selectUpdateProfileStatus(state));
  const data = useSelector((state) => selectUpdateProfileData(state));
  const error = useSelector((state) => selectUpdateProfileError(state));

  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onSubmitForm = (values) => {
    dispatch(authAPIs.putUpdateProfile(values));
  };
  const onResetUpdateProfile = () => {
    dispatch(resetUpdateProfile());
  };
  return {
    data,
    error,
    onSubmitForm,
    onResetUpdateProfile,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
}
