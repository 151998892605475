import { useDispatch, useSelector } from "react-redux";
import { contentAPIs } from "../api_";
import {
  resetContentEdits,
  selectAnalyzedContentData,
  selectHandleContentEditsData,
  selectHandleContentEditsStatus,
  selectHandleContentEditsIsAnalyze,
  setExecuteAnalyze,
} from "../redux/content";
import { selectCurrentOrganization } from "../redux/organizations";

export default function useHandleContentEdits() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectHandleContentEditsStatus(state));
  const data = useSelector((state) => selectHandleContentEditsData(state));
  const analyzedContentData = useSelector((state) => selectAnalyzedContentData(state));
  const isAnalyze = useSelector((state)=> selectHandleContentEditsIsAnalyze(state))
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onHandleEdits = (values) => {
    if(values.executeAnalyze){
      dispatch(setExecuteAnalyze(values.executeAnalyze))
    }
    dispatch(
      contentAPIs.putHandleContentEdits({
        values:values,
        orgId: currentOrg.id,
        id: analyzedContentData.content.content_id
      })
    );
  };

  const onResetContentEdits = ()=>{
    dispatch(resetContentEdits())
  }

  return {
    editsData: data,
    onHandleEdits,
    onResetContentEdits,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    isAnalyze
  };
}
