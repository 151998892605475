import { authAPIs } from "../../../api_";
import {
  postLoginFulfilled,
  postLoginPending,
  postLoginRejected,
} from "./cases/postLogin";

import {
  postSignupPending,
  postSignupFulfilled,
  postSignupRejected
} from "./cases/postSignup";

import {
  postResetPasswordPending,
  postResetPasswordFulfilled,
  postResetPasswordRejected
} from "./cases/postResetPassword";

import {
  postSetNewPasswordFulfilled,
  postSetNewPasswordPending,
  postSetNewPasswordRejected 
} from "./cases/postSetNewPassword";

import {
  postSignupAccountOwnerPending,
  postSignupAccountOwnerFulfilled,
  postSignupAccountOwnerRejected
} from "./cases/postSignupAccountOwner";

import {
 postSignupSubscriptionPending,
 postSignupSubscriptionFulfilled,
 postSignupSubscriptionRejected
} from "./cases/postSignupSubscription";

import {
  getUserAccountsPending,
  getUserAccountsFulfilled,
  getUserAccountsRejected
} from "./cases/getUserAccounts"

import {
  putUpdateProfilePending,
  putUpdateProfileFulfilled,
  putUpdateProfileRejected
} from "./cases/putUpdateProfile"

import {
  postAutoIngestionPending,
  postAutoIngestionFulfilled,
  postAutoIngestionRejected
} from "./cases/postAutoIngestion"

export const extraReducers = (builder) => {
  builder.addCase(authAPIs.postLogin.pending, postLoginPending);
  builder.addCase(authAPIs.postLogin.fulfilled, postLoginFulfilled);
  builder.addCase(authAPIs.postLogin.rejected, postLoginRejected);

  builder.addCase(authAPIs.postSignup.pending, postSignupPending);
  builder.addCase(authAPIs.postSignup.fulfilled, postSignupFulfilled);
  builder.addCase(authAPIs.postSignup.rejected, postSignupRejected);

  builder.addCase(authAPIs.postResetPassword.pending, postResetPasswordPending);
  builder.addCase(authAPIs.postResetPassword.fulfilled, postResetPasswordFulfilled);
  builder.addCase(authAPIs.postResetPassword.rejected, postResetPasswordRejected);

  builder.addCase(authAPIs.postSetNewPassword.pending, postSetNewPasswordPending);
  builder.addCase(authAPIs.postSetNewPassword.fulfilled, postSetNewPasswordFulfilled);
  builder.addCase(authAPIs.postSetNewPassword.rejected, postSetNewPasswordRejected);

  builder.addCase(authAPIs.postSignupAccountOwner.pending, postSignupAccountOwnerPending);
  builder.addCase(authAPIs.postSignupAccountOwner.fulfilled, postSignupAccountOwnerFulfilled);
  builder.addCase(authAPIs.postSignupAccountOwner.rejected, postSignupAccountOwnerRejected);

  builder.addCase(authAPIs.postSignupSubscription.pending, postSignupSubscriptionPending);
  builder.addCase(authAPIs.postSignupSubscription.fulfilled,postSignupSubscriptionFulfilled);
  builder.addCase(authAPIs.postSignupSubscription.rejected, postSignupSubscriptionRejected);

  builder.addCase(authAPIs.getUserAccounts.pending, getUserAccountsPending);
  builder.addCase(authAPIs.getUserAccounts.fulfilled, getUserAccountsFulfilled);
  builder.addCase(authAPIs.getUserAccounts.rejected, getUserAccountsRejected);

  builder.addCase(authAPIs.putUpdateProfile.pending, putUpdateProfilePending);
  builder.addCase(authAPIs.putUpdateProfile.fulfilled, putUpdateProfileFulfilled);
  builder.addCase(authAPIs.putUpdateProfile.rejected, putUpdateProfileRejected);

  builder.addCase(authAPIs.postAutoIngestion.pending, postAutoIngestionPending);
  builder.addCase(authAPIs.postAutoIngestion.fulfilled, postAutoIngestionFulfilled);
  builder.addCase(authAPIs.postAutoIngestion.rejected, postAutoIngestionRejected);
};
