import { useDispatch, useSelector } from "react-redux";
import {
  selectNavigatorCurrentFolder,
  selectNavigatorPrevFolder,
  setCurrentFolder,
} from "../redux/content";

export default function useCurrentFolderHandler() {
  const dispatch = useDispatch();
  const currentFolder = useSelector((state) =>
    selectNavigatorCurrentFolder(state)
  );
  const prevFolder = useSelector((state) => selectNavigatorPrevFolder(state));

  const onSetCurrentFolder = (val) => {
    dispatch(setCurrentFolder(val));
  };
  return {
    prevFolder,
    currentFolder,
    onSetCurrentFolder,
  };
}
