import { Input, Paper,Typography, useTheme } from "@mui/material";
export default function InputText(props) {
  const { notched: _,helperText, ...remainingProps } = props;
  const theme = useTheme()
  return (
   <>
    <Paper
      variant={"outlined"}
      sx={{
        width: "98.5%",
        background: props.isDark?theme.palette.dark.main:"white",
        borderRadius: props.multiline ? "10px" : "50px",
        padding: "3px",
        maxHeight: props.maxHeight ? 300 * 4.5 + 8 : 48 * 4.5 + 8,
      }}
    >
      <Input
        disableUnderline={true}
        autoComplete="off"
        inputProps={{
          sx: {
            padding: 0,
            maxHeight: props.maxHeight ? 300 * 4.5 + 8 : 48 * 4.5 + 8,
            ...props.type
          },
        }}
        {...remainingProps}
        sx={{
          fontSize: "14px",
          width: "100%",
          backgroundColor: "white",
          borderRadius: props.multiline ? "10px" : "50px",
          outline: "red",
          padding: "1px 10px",
          color: props.error ? "#e25353" : props.isDark?"#fff":theme.palette.dark.main,
          border: props.error ? "1px solid #ff6262" : "1px solid white",
          transition: "0.2s",
          ":focus-within": {
            border: "1px solid #00cbff",
            color: props.isDark ? "#fff":"#00708d",
            transition: "0.2s",
          },
        }}
      />
    </Paper>
      <Typography 
      fontSize={10}
      minHeight={15}
      pl={2}
      color={props.error ? "#e25353" : "#686868"}
      fontWeight={300}>{props.helperText}{" "}</Typography>
   </>
  );
}
