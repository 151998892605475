export const getPersonaExportPDFPending = (state, action) => {
  /*const index = state.exportPDFQueue.findIndex(
    (item) => item.id === action.payload.id
  );
  if (index !== -1) {
    state.exportPDFQueue[index].status = 'pending';
  }*/
  state.exportPDFQueue.queue.push({...action.meta.arg, file:null, error:null, status : 'pending'});
};

export const getPersonaExportPDFFulfilled = (state, action) => {
  const index = state.exportPDFQueue.queue.findIndex(
    (item) => item.personaId === action.meta.arg.personaId
  );
  if (index !== -1) {
    state.exportPDFQueue.queue[index].status = 'fulfilled';
    state.exportPDFQueue.queue[index].data = state.exportPDFQueue.queue[index].data || {};
    state.exportPDFQueue.queue[index].data.file = action.payload;
  }
};

export const getPersonaExportPDFRejected = (state, action) => {
  const index = state.exportPDFQueue.queue.findIndex(
    (item) =>{ 
      return item.personaId === action.meta.arg.personaId
      //item.id === action.meta.arg.personaId
      }
  );

  if (index !== -1) {
    state.exportPDFQueue.queue[index].status = 'rejected';
    state.exportPDFQueue.queue[index].data = state.exportPDFQueue.queue[index].data || {};
    state.exportPDFQueue.queue[index].data.error = action.payload;
  }
};
