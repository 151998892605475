import { useEffect, useState, useCallback } from "react";
import { Box, Grid, Typography, Button, Stack } from "@mui/material";
import { useFormik } from "formik";
import { useLocation, useParams } from "react-router-dom";
import AudienceHeader from "./components/AudienceHeader";
import TabsAnalyzedContentDataTabs from "./components/TabsAnalyzedContentDataTabs";
import Actions from "./components/Actions";
import ContentHistory from "./components/ContentHistory";
import SupportingContent from "./components/SupportingContent";
import { AnalyzeIcon, GenerateIcon } from "../../../../icons";
import useGetAnalyzedContent from "../../../../hooks/useGetAnalyzedContent";
import useBrandReactionHandler from "../../../../hooks/useBrandReactionHandler";
import useAnalyzeContentHandler from "../../../../hooks/useAnalyzeContentHandler";
import useSuggestionsContentHandler from "../../../../hooks/useSuggestionsContentHandler";
import useContetCurrentVersionHanlder from "../../../../hooks/useContentCurrentVersionHandler";
import {
  EditorComponent,
  LeftPaneComponent,
  LogoLoader,
  Modal,
} from "../../../../components";
import * as Yup from "yup";
import useHandleContentEdits from "../../../../hooks/useHandleContentEdits";

export default function Analyze() {
  const [value, setValue] = useState(0);
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [editCopy, setEditCopy] = useState(false);
  const [suggestMode, setSuggestMode] = useState(false);

  const location = useLocation();

  const {
    analyzedContent,
    onGetAnalyzedContent,
    isLoading,
    isUninitialized,
    isSuccess: isGetAnalyzedContentSuccess,
  } = useGetAnalyzedContent();

  const {
    analyzeSingleContentStatusLoading,
    analyzeSingleContentStatusSuccess,
    onResetAnalyzeSingleContent,
    onAnalyzeContent,
  } = useAnalyzeContentHandler();

  const {
    onGetWatchVersion,
    isSuccess: isGetVersionSuccess,
    isLoading: isGettingVersion,
  } = useContetCurrentVersionHanlder();

  const { isSuccess: isBrandReactionGenerated, onResetBrandReaction } =
    useBrandReactionHandler();

  const {
    onHandleEdits,
    onResetContentEdits,
    isSuccess,
    isAnalyze,
    isLoading: isHandleEditsLoading,
  } = useHandleContentEdits();

  const { isSuccess: generatedSuggestions, onResetSuggestionsContent } =
    useSuggestionsContentHandler();

  const formik = useFormik({
    initialValues: {
      status_list: [],
      content_text: [],
      executeAnalyze: false,
    },
    validationSchema: Yup.object({
      content_text: Yup.array().of(
        Yup.object({
          children: Yup.array().of(
            Yup.object({
              status: Yup.string().notOneOf(
                ["suggested"],
                "Status cannot be 'suggested'"
              ),
            })
          ),
        })
      ),
    }),
    onSubmit: async (values) => {
      setEditCopy(false);
      setSuggestMode(false);
      onHandleEdits(values);
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (isSuccess && !isAnalyze) {
      onResetContentEdits();
      onGetAnalyzedContent(analyzedContent.content.content_id);
      setEditCopy(false);
      setSuggestMode(false);
    } else if (isSuccess && isAnalyze) {
      onAnalyzeContent({
        id: analyzedContent.content.content_id,
        title: analyzedContent.content.title,
        isAnalyze,
      });
      onResetContentEdits();
      setEditCopy(false);
      setSuggestMode(false);
    }
  }, [
    isSuccess,
    isAnalyze,
    analyzedContent,
    onResetContentEdits,
    onGetAnalyzedContent,
    onAnalyzeContent,
  ]);

  const afterGeneratedSuggestionRefresh = useCallback(() => {
    onResetSuggestionsContent();
    onResetBrandReaction();
    onGetAnalyzedContent(analyzedContent?.content?.content_id);
  }, [
    analyzedContent?.content?.content_id,
    onResetSuggestionsContent,
    onResetBrandReaction,
    onGetAnalyzedContent,
  ]);

  const afterAnalyzeSingleContentRefresh = useCallback(() => {
    if (analyzeSingleContentStatusSuccess) {
      onResetContentEdits();
      onResetAnalyzeSingleContent();
      //onGotToAnalyze(analyzedContent?.content?.content_id);
      onGetAnalyzedContent(analyzedContent?.content?.content_id);
    }
  }, [
    analyzedContent?.content?.content_id,
    onResetContentEdits,
    onResetAnalyzeSingleContent,
    analyzeSingleContentStatusSuccess,
    onGetAnalyzedContent,
  ]);

  useEffect(() => {
    if (generatedSuggestions || isBrandReactionGenerated) {
      afterGeneratedSuggestionRefresh();
    }
  }, [
    generatedSuggestions,
    isBrandReactionGenerated,
    afterGeneratedSuggestionRefresh,
  ]);

  useEffect(() => {
    if (analyzeSingleContentStatusSuccess) {
      afterAnalyzeSingleContentRefresh();
      setValue(0);
      formik.setFieldValue("status_list", []);
      setEditCopy(false);
      setSuggestMode(false);
    }
  }, [
    analyzeSingleContentStatusSuccess,
    formik,
    afterAnalyzeSingleContentRefresh,
  ]);
  useEffect(() => {
    if (isUninitialized) {
      if (params.content_id && params.version_id !== "latest") {
        onGetWatchVersion(params.content_id, params.version_id, params.id);
        return;
      }
      onGetAnalyzedContent(params.content_id, params.version_id);
    }
  }, [params, isUninitialized, onGetWatchVersion, onGetAnalyzedContent]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    const tabMapping = {
      scores: 0,
      reactions: 1,
      suggestions: 2,
      brand_alignment: 3,
    };
    setValue(tabMapping[tab] ?? 0);
  }, [location.search]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    if (newValue !== 2) {
      formik.setFieldValue("status_list", []);
      setEditCopy(false);
      setSuggestMode(false);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (suggestMode) {
      formik.setFieldValue(
        `content_text`,
        analyzedContent?.audience_personas_suggestions?.copy_suggestions
      );
      setTimeout(() => {
        formik.validateForm();
      }, 0);
    } else if (editCopy) {
      formik.setFieldValue(
        `content_text`,
        analyzedContent?.content?.content_text
      );
      setTimeout(() => {
        formik.validateForm();
      }, 0);
    }
  }, [
    suggestMode,
    editCopy,
    analyzedContent?.audience_pqersonas_suggestions?.copy_suggestions,
    analyzedContent?.content?.content_text,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const getLoadingText = ({
    isLoading,
    isGettingVersion,
    isHandleEditsLoading,
    analyzeSingleContentStatusLoading,
    isAnalyze,
  }) => {
    if (isLoading) return "Getting content analysis...";
    if (isGettingVersion) return "Getting version...";
    if (isHandleEditsLoading) return "Processing edits...";
    if (analyzeSingleContentStatusLoading) return "Analyzing...";
    if (isAnalyze) return "Preparing for analyze...";
    return "Loading";
  };

  return (
    <>
      <LogoLoader
        open={
          isLoading ||
          isGettingVersion ||
          isHandleEditsLoading ||
          isAnalyze ||
          analyzeSingleContentStatusLoading
        }
        text={getLoadingText({
          isLoading,
          isGettingVersion,
          isHandleEditsLoading,
          analyzeSingleContentStatusLoading,
          isAnalyze,
        })}
        needBackDrop={true}
      />
      ;
      {analyzedContent !== null && (
        <Grid container sx={{ height: "100vh" }}>
          <Modal
            maxWidth="sm"
            title={"Save Edits"}
            open={open}
            actions={
              <>
                <Button
                  startIcon={<AnalyzeIcon></AnalyzeIcon>}
                  variant="contained"
                  onClick={() => {
                    setOpen(false);
                    formik.setFieldValue("executeAnalyze", true);
                    formik.handleSubmit();
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    setOpen(false);
                    formik.setFieldValue("executeAnalyze", false);
                    formik.handleSubmit();
                  }}
                  startIcon={<GenerateIcon></GenerateIcon>}
                  variant="contained"
                  color="gray"
                >
                  No, only save edits
                </Button>
              </>
            }
            body={
              <>
                <Typography variant="subtitle1">
                  Pre-Confirmation for new analyze
                </Typography>
                <Typography variant="body2" fontWeight={200}>
                  By saving the changes, a new version will be generated.
                </Typography>
                <br />
                <Typography variant="overline">PLEASE CONFIRM:</Typography>
                <Typography variant="body2">
                  Do you want to re-analyze the content with included changes?
                </Typography>
                <Typography variant="caption" color={"error"}>
                  **Re-analyzing content will consume additional credits.
                </Typography>
              </>
            }
            handleClose={() => setOpen(false)}
          />
          <LeftPaneComponent>
            <Stack
              pt={"70px"}
              justifyContent={"center"}
              alignItems={"normal"}
            >
               <Typography variant="h5" textAlign={"left"} fontWeight={"bold"} sx={{
              wordWrap: 'break-word'
            }}>
              {analyzedContent.content.title}
            </Typography>
            </Stack>
            
            <Box pt={5}>
              <Typography
                variant="subtitle1"
                textAlign={"left"}
                fontWeight={"bold"}
              >
                Channel:
              </Typography>
              <Typography variant="subtitle1" textAlign={"left"}>
                {`${analyzedContent.content.channel} - ${analyzedContent.content.subchannel}`}
              </Typography>
            </Box>
            <ContentHistory />
            <SupportingContent />
          </LeftPaneComponent>
          <Grid item xs={5}>
            {isLoading ||
            isHandleEditsLoading ||
            isGettingVersion ||
            isAnalyze ||
            analyzeSingleContentStatusLoading ? (
              "Loading"
            ) : (
              <Box
                mx={2}
                sx={{
                  pt: "70px",
                }}
              >
                {suggestMode &&
                  isGetAnalyzedContentSuccess &&
                  analyzedContent?.audience_personas_suggestions
                    ?.copy_suggestions && (
                    <>
                      <EditorComponent
                        showToolbar={true}
                        isSuggestionsMode={true}
                        formik={formik}
                        height={"88vh"}
                        isSuggestionMode={true}
                        initialValue={
                          analyzedContent?.audience_personas_suggestions
                            ?.copy_suggestions
                        }
                        onHandleChange={(value) =>
                          formik.setFieldValue(`content_text`, value)
                        }
                        onHandleBlur={() => {}}
                      ></EditorComponent>
                    </>
                  )}
                {!suggestMode &&
                  editCopy &&
                  (isGetAnalyzedContentSuccess || isGetVersionSuccess) && (
                    <EditorComponent
                      showToolbar={true}
                      height={"88vh"}
                      isSuggestionMode={false}
                      readOnly={false}
                      initialValue={analyzedContent?.content?.content_text}
                      onHandleChange={(value) =>
                        formik.setFieldValue(`content_text`, value)
                      }
                      onHandleBlur={() => {}}
                    ></EditorComponent>
                  )}
                {!suggestMode &&
                  !editCopy &&
                  (isGetAnalyzedContentSuccess || isGetVersionSuccess) && (
                    <EditorComponent
                      showToolbar={false}
                      height={"88vh"}
                      isSuggestionMode={false}
                      readOnly={true}
                      initialValue={analyzedContent?.content?.content_text}
                      onHandleChange={(value) =>
                        formik.setFieldValue(`contentText`, value)
                      }
                      onHandleBlur={() => {}}
                    ></EditorComponent>
                  )}
              </Box>
            )}
          </Grid>
          <Grid item xs={5} sx={{ height: "100%" }}>
            <Box sx={{ padding: " 0px 30px" }}>
              <AudienceHeader />
              <TabsAnalyzedContentDataTabs
                value={value}
                handleChange={handleChange}
              />
              <Actions
                formik={formik}
                setMode={(isEditMode, isSuggestMode) => {
                  setSuggestMode(isSuggestMode);
                  setEditCopy(isEditMode);
                }}
                setOpen={(val) => setOpen(val)}
                isEditting={editCopy || suggestMode}
                setTab={(value) => setValue(value)}
              ></Actions>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}
