import {
  Avatar,
  AvatarGroup,
  Grid,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { BootstrapTooltip, LogoLoader, Table } from "../../../../components";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import usePersonaListHandler from "../../../../hooks/usePersonaListHandler";
import useDisableAudienceHandler from "../../../../hooks/useDisableAudienceHandler";
import useAudienceByOrganizationHandler from "../../../../hooks/useAudienceByOrganizationHandler";

import LeftPaneContent from "./components/leftPaneContent";
import ModalUpsertAudience from "./components/ModalUpsertAudience";
import NoPersonasBtn from "./components/NoPersonasBtn";
import useUpdateAudienceHandler from "../../../../hooks/useUpdateAudienceHandler";
import useUpsertAudienceHandler from "../../../../hooks/useUpsertAudienceHandler";
import useGetOrgUserProfile from "../../../../hooks/useGetOrgUserProfileHanlder";

export default function Audience() {
  const { canManageAudience } = useGetOrgUserProfile();
  
  const { personas, isSuccess: personasLoaded, getPersonasAll, isUninitialized:isGetPersonasUnitialized } = usePersonaListHandler();
  const {
    isSuccess: isDisabled,
    onResetDisableAudience,
    onDisableAudience,
  } = useDisableAudienceHandler();
  const { isSuccess: isUpdated, onResetUpdateAudience } = useUpdateAudienceHandler();
  const { isSuccess: isCreated, onResetCreateAudience } = useUpsertAudienceHandler();

  const {
    data: audienceList,
    onGetAudience,
    isLoading: isGettingAudience,
    isUninitialized,
    isSuccess: audienceLoaded,
  } = useAudienceByOrganizationHandler();

  useEffect(() => {
    if (isUninitialized) {
      onGetAudience();
    }
  }, [isUninitialized, onGetAudience]);

  useEffect(()=>{
    if(isGetPersonasUnitialized){
      getPersonasAll();
    }
  },[isGetPersonasUnitialized,
    getPersonasAll])

  useEffect(() => {
    if (isCreated || isUpdated || isDisabled) {
      onGetAudience();
      onResetCreateAudience();
      onResetUpdateAudience();
      onResetDisableAudience();
    }
  }, [isCreated, isUpdated, isDisabled, onGetAudience, onResetCreateAudience, onResetUpdateAudience, onResetDisableAudience]);

  const columns = [
    {
      field: "name",
      headerName: "Audience",
      width: 300,
      display: "flex",
      "align-items": "center",
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Switch
            onChange={() => onDisableAudience(params.row?.id, !params.row?.disable)}
            checked={!params.row?.disable}
            aria-label={params.row?.disable ? "Enable Audience" : "Disable Audience"}
          />
          <Typography variant="caption">{params.value}</Typography>
        </Stack>
      ),
    },
    {
      field: "personas",
      headerName: "Personas",
      width: 350,
      display: "flex",
      "align-items": "center",
      renderCell: (params) => (
        <AvatarGroup max={8}>
          {params.value.map((persona) => (
            <BootstrapTooltip
              key={persona.id}
              title={persona.description_metadata?.jobTitle || "Unknown"}
            >
              <Avatar src={persona.avatar_signed_url} />
            </BootstrapTooltip>
          ))}
        </AvatarGroup>
      ),
    },
    {
      field: "name_created_by",
      headerName: "Created by",
      width: 200,
      display: "flex",
      "align-items": "center",
      renderCell: (params) => (
        <Typography variant="caption">{params.value}</Typography>
      ),
    },
    {
      field: "id",
      headerName: "Actions",
      width: 100,
      display: "flex",
      "align-items": "center",
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <ModalUpsertAudience params={params} smallBtn />
          <BootstrapTooltip title="Disable Audience">
            <IconButton
              disabled={!canManageAudience}
              onClick={() => onDisableAudience(params.row?.id, !params.row?.disable)}
              size="small"
              color="error"
              aria-label={params.row?.disable ? "Enable Audience" : "Disable Audience"}
            >
              {params.row?.disable ? (
                <RestartAltIcon fontSize="small" />
              ) : (
                <HighlightOffOutlinedIcon fontSize="small" />
              )}
            </IconButton>
          </BootstrapTooltip>
        </Stack>
      ),
    },
  ];

  return (
    <Grid container sx={{ height: "100vh" }}>
      <LeftPaneContent />
      <Grid item xs={10} sx={{ px: 10, pt: "70px" }}>
        <LogoLoader open={isGettingAudience} text="Loading Audiences" />
        {!personasLoaded || !personas.length ? (
          <NoPersonasBtn />
        ) : (
          <>
            <Typography variant="h5" textAlign="left">
              Audiences
            </Typography>
            {audienceLoaded && (
              <Table
                initialState={{
                  sorting: {
                    sortModel: [{ field: "timestamp", sort: "desc" }],
                  },
                }}
                rows={audienceList}
                columns={columns}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
