const Logo = ({width}) => (
  <svg
    width={width}
    height="100%"
    viewBox="0 0 145 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_445_8068)">
      <path
        d="M31.0875 18.9848C30.3019 19.0381 29.5164 18.9848 28.7153 18.84C27.922 18.6876 27.1753 18.4589 26.4753 18.1541C25.7753 17.8492 25.1842 17.4834 24.6942 17.0642L27.1442 14.2443C27.5797 14.6178 28.0931 14.8998 28.6764 15.0979C29.2597 15.2961 29.8664 15.3723 30.4964 15.3342C30.7842 15.3189 31.0097 15.2656 31.1653 15.1741C31.3208 15.0903 31.3986 14.976 31.3831 14.8464C31.3675 14.6101 31.2119 14.4501 30.9242 14.351C30.6364 14.2519 30.2708 14.1681 29.8275 14.0919C29.3842 14.0233 28.9175 13.9242 28.4197 13.7947C27.922 13.6651 27.4475 13.4822 26.9886 13.2383C26.5298 12.9944 26.1409 12.6286 25.8375 12.1561C25.5342 11.6835 25.3553 11.0586 25.3009 10.2965C25.2464 9.53433 25.4253 8.80268 25.8375 8.16249C26.2498 7.5223 26.8564 6.99642 27.6575 6.58487C28.4586 6.17332 29.4075 5.92944 30.4964 5.86084C31.5853 5.79225 32.6897 5.90657 33.7475 6.2038C34.813 6.50104 35.6764 7.00405 36.3375 7.69759L33.8641 10.5175C33.4053 10.0754 32.9153 9.79346 32.3942 9.67151C31.873 9.54957 31.4453 9.49622 31.1031 9.51909C30.7608 9.54195 30.5586 9.60292 30.4342 9.702C30.3097 9.80108 30.2553 9.9154 30.2631 10.0526C30.2786 10.2355 30.4186 10.3803 30.6986 10.4794C30.9786 10.5785 31.3364 10.6623 31.7797 10.7385C32.223 10.8071 32.6819 10.9138 33.1719 11.051C33.6619 11.1882 34.1286 11.3939 34.5875 11.6607C35.0386 11.9351 35.4197 12.3085 35.7308 12.781C36.0341 13.2535 36.213 13.8861 36.2675 14.6711C36.353 15.8905 35.9175 16.8889 34.9841 17.6663C34.043 18.4437 32.7442 18.8933 31.0719 19L31.0875 18.9848Z"
        fill="white"
      />
      <path
        d="M51.2941 18.5655V1.16602H56.3107V9.25988L55.494 12.2398L56.1863 15.2503V18.5579H51.3018L51.2941 18.5655Z"
        fill="white"
      />
      <path
        d="M75.1172 18.5655V15.2578L75.5916 12.2474L75.1172 9.26742V6.05884H79.7916V18.5655H75.1172Z"
        fill="white"
      />
      <path
        d="M80.8338 10.0677V6.05892H91.2171V10.0677H80.8338ZM83.5171 18.5655V1.16602H88.5337V18.5655H83.5171Z"
        fill="white"
      />
      <path
        d="M105.785 18.5656V6.23428H110.801V18.5656H105.785ZM113.539 18.5656V11.493C113.539 11.0738 113.415 10.7461 113.158 10.5022C112.901 10.2583 112.575 10.1364 112.186 10.1364C111.914 10.1364 111.672 10.1898 111.455 10.2965C111.245 10.4031 111.081 10.5632 110.972 10.7614C110.864 10.9595 110.809 11.2034 110.809 11.4854L108.865 10.7842C108.865 9.7782 109.09 8.92461 109.541 8.20058C109.992 7.48418 110.607 6.92782 111.369 6.54675C112.139 6.16568 113.002 5.96753 113.975 5.96753C114.83 5.96753 115.6 6.16568 116.292 6.56962C116.985 6.97355 117.537 7.5299 117.941 8.23869C118.346 8.94747 118.556 9.78582 118.556 10.7614V18.558H113.539V18.5656ZM121.294 18.5656V11.493C121.294 11.0738 121.169 10.7461 120.912 10.5022C120.656 10.2583 120.329 10.1364 119.94 10.1364C119.668 10.1364 119.427 10.1898 119.209 10.2965C118.999 10.4031 118.836 10.5632 118.727 10.7614C118.618 10.9595 118.564 11.2034 118.564 11.4854L115.624 11.5082C115.624 10.3574 115.857 9.36665 116.324 8.53592C116.79 7.70519 117.444 7.07262 118.284 6.63059C119.116 6.18855 120.072 5.96753 121.146 5.96753C122.149 5.96753 123.043 6.17331 123.821 6.59248C124.599 7.01165 125.206 7.61374 125.649 8.39874C126.092 9.18373 126.31 10.1288 126.31 11.2339V18.5504H121.294V18.5656Z"
        fill="white"
      />
      <path
        d="M129.896 5.11387C129.165 5.11387 128.55 4.86998 128.068 4.3746C127.586 3.87921 127.337 3.27712 127.337 2.56072C127.337 1.84431 127.578 1.2346 128.068 0.746838C128.55 0.251451 129.165 0.00756836 129.896 0.00756836C130.627 0.00756836 131.257 0.251451 131.739 0.746838C132.213 1.24223 132.455 1.84431 132.455 2.56072C132.455 3.27712 132.213 3.88683 131.739 4.3746C131.265 4.86998 130.65 5.11387 129.896 5.11387Z"
        fill="white"
      />
      <path d="M132.408 6.26465H127.391V18.596H132.408V6.26465Z" fill="white" />
      <path
        d="M98.217 18.7332C94.577 18.7332 91.6215 15.8371 91.6215 12.2703C91.6215 8.70348 94.577 5.80737 98.217 5.80737C101.857 5.80737 104.812 8.70348 104.812 12.2703C104.812 15.8371 101.857 18.7332 98.217 18.7332ZM100.317 12.2703C100.317 11.1347 99.3758 10.2125 98.217 10.2125C97.0581 10.2125 96.117 11.1347 96.117 12.2703C96.117 13.4058 97.0581 14.328 98.217 14.328C99.3758 14.328 100.317 13.4058 100.317 12.2703Z"
        fill="white"
      />
      <path
        d="M50.3217 12.2703C50.3217 15.8371 47.3662 18.7332 43.7262 18.7332C40.0863 18.7332 37.1307 15.8371 37.1307 12.2703V6.22656H41.774V12.4151C41.774 13.4668 42.6451 14.3204 43.7185 14.3204C44.7918 14.3204 45.6629 13.4668 45.6629 12.4151V6.22656H50.3062V12.2703H50.3217Z"
        fill="white"
      />
      <path
        d="M72.4105 18.7332C68.7705 18.7332 65.815 15.8371 65.815 12.2703C65.815 8.70348 68.7705 5.80737 72.4105 5.80737C76.0505 5.80737 75.8871 8.70348 75.8871 12.2703C75.8871 15.8371 76.0505 18.7332 72.4105 18.7332ZM74.5105 12.2703C74.5105 11.1347 73.5694 10.2125 72.4105 10.2125C71.2516 10.2125 70.3105 11.1347 70.3105 12.2703C70.3105 13.4058 71.2516 14.328 72.4105 14.328C73.5694 14.328 74.5105 13.4058 74.5105 12.2703Z"
        fill="white"
      />
      <path
        d="M58.6673 18.7332C55.0274 18.7332 55.1907 15.8371 55.1907 12.2703C55.1907 8.70348 55.0274 5.80737 58.6673 5.80737C62.3073 5.80737 65.2628 8.70348 65.2628 12.2703C65.2628 15.8371 62.3073 18.7332 58.6673 18.7332ZM60.7673 12.2703C60.7673 11.1347 59.8262 10.2125 58.6673 10.2125C57.5084 10.2125 56.5673 11.1347 56.5673 12.2703C56.5673 13.4058 57.5084 14.328 58.6673 14.328C59.8262 14.328 60.7673 13.4058 60.7673 12.2703Z"
        fill="white"
      />
      <path
        d="M139.89 14.3661C138.731 14.3661 137.79 13.4439 137.79 12.3084C137.79 11.1728 138.731 10.2506 139.89 10.2506C140.341 10.2506 140.753 10.3954 141.096 10.624L144.603 7.7889C143.406 6.59235 141.741 5.84546 139.89 5.84546C136.25 5.84546 133.294 8.74157 133.294 12.3084C133.294 15.8751 136.25 18.7713 139.89 18.7713C141.951 18.7713 143.787 17.8414 145 16.3934L141.033 14.0308C140.707 14.2366 140.318 14.3661 139.898 14.3661H139.89Z"
        fill="white"
      />
      <path
        d="M6.66548 9.40465C8.44657 6.3866 12.8954 6.3866 14.6765 9.40465C16.4576 12.4227 14.2332 16.2029 10.671 16.2029C7.10881 16.2029 4.88438 12.4227 6.66548 9.40465Z"
        fill="white"
      />
      <path
        d="M2.65998 18.8094C4.12905 18.8094 5.31996 17.6425 5.31996 16.2029C5.31996 14.7634 4.12905 13.5964 2.65998 13.5964C1.19091 13.5964 0 14.7634 0 16.2029C0 17.6425 1.19091 18.8094 2.65998 18.8094Z"
        fill="#66BF76"
      />
      <path
        d="M18.6821 18.8094C20.1511 18.8094 21.3421 17.6425 21.3421 16.2029C21.3421 14.7634 20.1511 13.5964 18.6821 13.5964C17.213 13.5964 16.0221 14.7634 16.0221 16.2029C16.0221 17.6425 17.213 18.8094 18.6821 18.8094Z"
        fill="#E3B549"
      />
      <path
        d="M10.6711 5.213C12.1402 5.213 13.3311 4.04603 13.3311 2.6065C13.3311 1.16697 12.1402 0 10.6711 0C9.20202 0 8.01111 1.16697 8.01111 2.6065C8.01111 4.04603 9.20202 5.213 10.6711 5.213Z"
        fill="#EF3937"
      />
    </g>
    <defs>
      <clipPath id="clip0_445_8068">
        <rect width="145" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Logo;
