import { useDispatch, useSelector } from "react-redux";
import {
    resetDisablePersona,
  selectDisablePersonaData,
  selectDisablePersonaStatus,
} from "../redux/persona";
import { organizationAPIs } from "../api_";
import { selectCurrentOrganization } from "../redux/organizations";

export default function useDisablePersonaHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectDisablePersonaStatus(state));

  const data = useSelector((state) => selectDisablePersonaData(state));
  const currentOrganization = useSelector((state) =>
    selectCurrentOrganization(state)
  );

  const isUninitialized = status === "idle";
  const isLoading = status === "pending" || status === "idle";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onSubmitForm = (status, personaId) => {
    dispatch(
      organizationAPIs.putDisablePersona({
        status,
        personaId,
        orgId: currentOrganization.id,
      })
    );
  };

  const onResetDisablePersona = ()=>dispatch(resetDisablePersona())

  return {
    onSubmitForm,
    onResetDisablePersona,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    data
  };
}
