import { LogoLoader, Modal } from "../../../components";
import { useLoginHandler } from "../../../hooks/useLoginHandler";
import useOrganizationListHandler from "../../../hooks/useOrganizationListHandler";
import useGetOrgUserProfile from "../../../hooks/useGetOrgUserProfileHanlder";
import { useEffect } from "react";
import useCurrentOrganizationHandler from "../../../hooks/useCurrentOrganizationHandler";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  darken,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Logo } from "../../../icons";

export default function Landing({ children }) {
  const params = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    onGetProfile,
    accountsUninitialized,
    accountsSuccess,
    onSetAuthPreLoad,
    onSetCurrentProfile,
    accountsData,
    currentProfile,
    authPreLoad: authPreLoadDone,
  } = useLoginHandler();
  const {
    organizations,
    isSuccess: orgsByUserDone,
    isUninitialized,
    onRefreshOrgList,
  } = useOrganizationListHandler();

  const {
    isLoading: loadingOrgUserProfile,
    isSuccess: orgAccountsSuccess,
    isUninitialized: isOrgProfileUninitialized,
    onGetOrgUserProfile,
    profile: orgProfile,
  } = useGetOrgUserProfile();

  const { selectOrganization, currentOrganization, previousOrganization } =
    useCurrentOrganizationHandler();

  useEffect(() => {
    if (accountsUninitialized) {
      onGetProfile();
    }
  }, [onGetProfile, accountsUninitialized]);

  useEffect(() => {
    if (accountsSuccess && isUninitialized && currentProfile !== null) {
      onRefreshOrgList();
    }
  }, [accountsSuccess, isUninitialized, onRefreshOrgList, currentProfile]);

  useEffect(() => {
    if (accountsSuccess && currentProfile !== null && orgsByUserDone) {
      if (previousOrganization === null) {
        if (params?.id) {
          const organization = organizations.find((o) => o.id === params.id);
          if (organization) {
            selectOrganization(organization);
            return;
          }
          navigate("/not_invited_to_organization");
          return;
        }
        selectOrganization(organizations[0]);
      }
    }
  }, [
    accountsSuccess,
    orgsByUserDone,
    navigate,
    organizations,
    currentProfile,
    params.id,
    previousOrganization,
    selectOrganization,
  ]);

  useEffect(() => {
    if (
      currentOrganization &&
      orgProfile === null &&
      accountsSuccess &&
      accountsData.length > 0 &&
      isOrgProfileUninitialized
    ) {
      onGetOrgUserProfile();
    }
  }, [
    currentOrganization,
    accountsData,
    accountsSuccess,
    onGetOrgUserProfile,
    isOrgProfileUninitialized,
    orgProfile,
  ]);

  useEffect(() => {
    if (
      accountsSuccess &&
      currentProfile !== null &&
      orgsByUserDone &&
      orgAccountsSuccess
    ) {
      onSetAuthPreLoad();
    }
  }, [
    accountsSuccess,
    onSetAuthPreLoad,
    currentProfile,
    orgsByUserDone,
    orgAccountsSuccess,
  ]);
  useEffect(() => {
    if (accountsSuccess && currentProfile === null) {
      const accountIdParam = new URLSearchParams(window.location.search).get(
        "account"
      );
      if (accountIdParam) {
        const account = accountsData.find(
          (account) => account.owner_id === Number(accountIdParam)
        );
        if (account) {
          onSetCurrentProfile(account);
          return;
        }
      }

      const ownerAccount = accountsData.find(
        (account) => account.owner_current_account
      );
      if (ownerAccount) {
        onSetCurrentProfile(ownerAccount);
        return;
      }

      onSetCurrentProfile(accountsData[0]);
    }
  }, [accountsSuccess, accountsData, currentProfile, onSetCurrentProfile]);
  /**
   * 
   * 
   *   React.useEffect(()=>{
    if(isSuccess){
     // if(profile.org_has_content){
        if(organizations && organizations.length > 0){
          if(previousOrganization === null){
        //    navigate(`/organization/${organizations[0].id}/content_library`);
        //    selectOrganization(organizations[0])
            if(!orgProfile.org_has_brand || signupData !== null){
              navigate(`/organization/${organizations[0].id}/brand`);
            }else{
              navigate(`/organization/${organizations[0].id}/content_library`);
            }
          }else{
            //selectOrganization(organizations[0])
          }
        }
    //  }else{
    //    navigate(`/organization/${organizations[0].id}/brand`);
    //  }
    }
  },[organizations, isSuccess])
   */
  return (
    <>
      <LogoLoader
        open={authPreLoadDone && loadingOrgUserProfile}
        text={"Switching organization"}
      ></LogoLoader>
      <Modal
        open={
          accountsData?.length > 1 &&
          currentProfile === null &&
          accountsSuccess &&
          window.location.pathname.includes("/home")
        }
        body={
          <>
            <Box
              sx={{
                height: "60px",
                width: "250px",
              }}
            >
              <Logo width="100%" />
            </Box>
            <Typography variant="h6">
              Hey! Welcome, we notice that you have more than one account,
              please select one to continue, later you can switch.
            </Typography>
            <Stack
              spacing={1}
              flexDirection={"row"}
              justifyContent={"space-around"}
              alignItems="baseline"
              flexWrap={"wrap"}
            >
              {accountsSuccess &&
                accountsData.map((account, idx) => (
                  <Box key={idx}>
                    <Card
                      variant="elevation"
                      raised={true}
                      elevation={5}
                      sx={{
                        background: darken(theme.palette.dark.main, 0.4),
                        color: theme.palette.dark.contrastText,
                        width: 275,
                        height: 180,
                      }}
                    >
                      <CardContent>
                        <Typography
                          gutterBottom
                          sx={{ color: theme.palette.red.main, fontSize: 14 }}
                        >
                          Account {idx + 1}
                        </Typography>
                        <Typography variant="h5" component="div">
                          Profile Type:{" "}
                          {account.owner_current_account ? "Owner" : "Member"}
                        </Typography>
                        <Typography
                          sx={{ color: theme.palette.yellow.main, mb: 1.5 }}
                        >
                          Account Owner:{" "}
                          {account.owner_current_account
                            ? "Me"
                            : account.owner_name +
                              " " +
                              account.owner_last_name}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="contained"
                          fullWidth
                          color={"dark"}
                          size="small"
                          onClick={() => onSetCurrentProfile(account)}
                        >
                          Enter
                        </Button>
                      </CardActions>
                    </Card>
                  </Box>
                ))}
            </Stack>
          </>
        }
      ></Modal>
      {authPreLoadDone && children}
    </>
  );
}
