import {
  Typography,
  Stack,
  Box,
  Paper,
  Button,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  LinearProgress,
} from "@mui/material";

import KeyIcon from "@mui/icons-material/Key";
import SecurityIcon from "@mui/icons-material/Security";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import VpnLockIcon from "@mui/icons-material/VpnLock";
import SpeedIcon from "@mui/icons-material/Speed";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { useState, useEffect } from "react";
import { useSnackbarHandler } from "../../../../hooks/useSnackbarHandler";
import useAdminGenerateAPIKeyHandler from "../../../../hooks/useAdminGenerateAPIKeyHandler";
import useAdminGetAPIKeyHandler from "../../../../hooks/useAdminGetAPIKeyHandler";
import { BootstrapTooltip } from "../../../../components";

const guidelines = [
  {
    guideline: "Keep the API Key Secure",
    icon: <SecurityIcon></SecurityIcon>,
    details:
      "Store the API key in a secure location. Avoid exposing it in public repositories, client-side code, or any location that could be accessed by unauthorized users.",
  },
  {
    guideline: "Do Not Share the API Key",
    icon: <GppMaybeIcon></GppMaybeIcon>,
    details:
      "This key is linked to your account and provides access to sensitive resources. Never share the API key with anyone, including colleagues, third-party services, or external developers, unless explicitly authorized.",
  },
  {
    guideline: "Limit Usage to Secure Environments",
    icon: <VpnLockIcon></VpnLockIcon>,
    details:
      "Only use the API key in server-side environments or secure, trusted back-end systems. Avoid using it directly in client-side applications such as web browsers or mobile apps, as this can expose it to potential attacks.",
  },
  {
    guideline: "Rate Limits",
    icon: <SpeedIcon></SpeedIcon>,
    details:
      "Keep in mind that usage of the API key is subject to rate limiting. Exceeding the allowed number of requests could lead to temporary or permanent suspension of API access.",
  },
];
export default function DeveloperInfoTab() {
  const { openSnackbar } = useSnackbarHandler();
  const [data, setData] = useState();

  const {
    onGenerateAPIKey,
    isLoading,
    isSuccess,
    apiKey,
  } = useAdminGenerateAPIKeyHandler();

  const {
    onGetAPIKey,
    isUninitialized,
    isLoading: isGettingAPIKey,
    isSuccess: isDoneAPIKey,
    apiKey: dataAPIKey,
  } = useAdminGetAPIKeyHandler();

  useEffect(() => {
    if (isUninitialized) {
      onGetAPIKey();
    }
  }, [isUninitialized, onGetAPIKey]);

  useEffect(() => {
    if (isDoneAPIKey) {
      setData(dataAPIKey);
    }
  }, [isDoneAPIKey, dataAPIKey]);

  useEffect(() => {
    if (isSuccess) {
      setData(apiKey);
    }
  }, [isSuccess, apiKey]);

  return (
    <Stack spacing={2}>
      <Paper
        sx={{
          width: "100%",
          textAlign: "initial",
        }}
        variant="elevation"
        elevation={0}
      >
        <Box p={1}>
          <Typography variant="h5" fontWeight={"100"}>
            Developers
          </Typography>
          <Typography variant="body2">
            This API key is intended exclusively for use with our API. It is a
            sensitive piece of information that grants access to your date in
            our system's resources. To ensure the security and integrity of your
            data, please adhere to the following guidelines:
          </Typography>
          <List dense={true}>
            {guidelines.map((item, idx) => (
              <ListItem key={idx}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "red" }}>{item.icon}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={item.guideline}
                  secondary={item.details}
                />
              </ListItem>
            ))}
          </List>
          <Divider></Divider>

          <br />
          <Typography variant="body2">API Key</Typography>
          {(isLoading || isGettingAPIKey) && <LinearProgress></LinearProgress>}
          {data?.api_key && (
            <BootstrapTooltip title={"Copy API Key"}>
              <Chip
                sx={{
                  mt: 1,
                  mb: 1,
                  borderColor: "black !important",
                }}
                color="secondary"
                size="small"
                label={data?.api_key}
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(data?.api_key);
                    openSnackbar({
                      text: "Copied",
                      duration: 2000,
                      type: "success",
                    });
                  } catch (err) {
                    console.error("Error copying text: ", err);
                  }
                }}
                onDelete={() => {}}
                deleteIcon={<ContentCopyIcon />}
                variant="filled"
              />
            </BootstrapTooltip>
          )}
          <Button
            variant="contained"
            elevation={0}
            color="dark"
            startIcon={<KeyIcon></KeyIcon>}
            size="small"
            fullWidth
            onClick={() => onGenerateAPIKey()}
          >
            Generate
          </Button>
        </Box>
      </Paper>
    </Stack>
  );
}
