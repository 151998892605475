import {
  Grid,
  Stack,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Divider,
  Box,
  Typography,
  CardActions,
  CardContent,
  Card,
  Button,
  darken,
  useTheme,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SupportIcon from "@mui/icons-material/Support";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import useOrganizationListHandler from "../../hooks/useOrganizationListHandler";
import { useEffect, useState } from "react";
import { useLoginHandler } from "../../hooks/useLoginHandler";
import LeftPaneComponent from "../LeftPaneComponent";
import useAvatarHandler from "../../hooks/useAvatarHandler";
import { externalResources } from "../../constants";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import useCurrentOrganizationHandler from "../../hooks/useCurrentOrganizationHandler";
import Modal from "../Modal";
import { Logo } from "../../icons";
import LogoLoader from "../LogoLoader";

export function HomeMenuComponent({ children }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [openSwitchAccount, setOpenSwitchAccount] = useState(false);
  const [isSwitching, setSwitching] = useState(false);

  const { selectOrganization } =
    useCurrentOrganizationHandler();
  const {
    onResetOrgList,
    isUninitialized:isOrgListIdle,
    isSuccess:isOrgRefreshed,
    isLoading: isRefreshingOrgs,
    organizations,
  } = useOrganizationListHandler();

  const { onGetProfile, currentProfile, onSetCurrentProfile } =
    useLoginHandler();
  const [preAccount, setPreAccount] = useState(currentProfile);
  const { onGetAvatars, isUninitialized } = useAvatarHandler();
  const { accountsData, accountsSuccess } = useLoginHandler();

  useEffect(() => {
    if (!currentProfile) {
      onGetProfile();
    }
  }, [currentProfile, onGetProfile]);

  useEffect(() => {
    if (currentProfile && isUninitialized) {
      onGetAvatars();
    }
  }, [currentProfile, onGetAvatars, isUninitialized]);

  useEffect(() => {
    if (preAccount !== currentProfile && isOrgListIdle) {
      setPreAccount(currentProfile);
      setOpenSwitchAccount(false);
    }
  }, [preAccount, currentProfile, setPreAccount, isOrgListIdle]);

  useEffect(() => {
    if (isOrgRefreshed && isSwitching) {
      selectOrganization(organizations[0]);
      setSwitching(false);
    }
  }, [
    isOrgRefreshed,
    isSwitching,
    selectOrganization,
    organizations,
    setSwitching,
  ]);
  return (
    <>
      {isRefreshingOrgs ? (
        ""
      ) : (
        <Grid container sx={{ height: "100vh" }}>
          <LeftPaneComponent space={false}>
            <Stack pt="70px" justifyContent={"center"} alignItems={"center"}>
              <SettingsIcon sx={{ fontSize: 80 }} />
            </Stack>
            <List component="nav">
              <ListItemButton
                onClick={() => {
                  navigate("/profile");
                }}
              >
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Account" />
              </ListItemButton>
              <Divider></Divider>
              {accountsData.length > 1 && (
                <>
                  <ListItemButton
                    onClick={() => {
                      setOpenSwitchAccount(true);
                    }}
                  >
                    <ListItemIcon>
                      <TransferWithinAStationIcon />
                    </ListItemIcon>
                    <ListItemText primary="Switch Account" />
                  </ListItemButton>
                  <Divider></Divider>
                </>
              )}

              <ListItemButton
                onClick={() => {
                  navigate("/organizations");
                }}
              >
                <ListItemIcon>
                  <ApartmentIcon />
                </ListItemIcon>
                <ListItemText primary="Organizations" />
              </ListItemButton>
              <Divider></Divider>

              <ListItemButton href={externalResources.HELP_URL} target="_blank">
                <ListItemIcon>
                  <SupportIcon />
                </ListItemIcon>
                <ListItemText primary="Quick Help" />
              </ListItemButton>
              <Divider></Divider>

              <ListItemButton
                href={externalResources.CONTACT_US_URL}
                target="_blank"
              >
                <ListItemIcon>
                  <SupportAgentIcon />
                </ListItemIcon>
                <ListItemText primary="Contact" />
              </ListItemButton>
              <Divider></Divider>

              <ListItemButton
                onClick={() => {
                  window.location.href = "/login";
                  localStorage.clear();
                }}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
              <Divider></Divider>
            </List>
          </LeftPaneComponent>
          <Grid item xs={9} mt={"80px"}>
            {children}
          </Grid>
        </Grid>
      )}
      <Modal
        fullScreen={isRefreshingOrgs}
        open={isRefreshingOrgs}
        body={
          <LogoLoader
            open={true}
            text={`Switching to ${
              currentProfile.owner_current_account
                ? "Your"
                : `${currentProfile.owner_name} ${currentProfile.owner_last_name}'s`
            } Account`}
          />
        }
      ></Modal>
      <Modal
        open={openSwitchAccount}
        handleClose={() => setOpenSwitchAccount(false)}
        fullScreen={openSwitchAccount && isRefreshingOrgs}
        body={
          <>
            <Box
              sx={{
                height: "60px",
                width: "250px",
              }}
            >
              <Logo width="100%" />
            </Box>
            <Typography variant="h6">
              Hey! Welcome, we notice that you have more than one account,
              please select one to continue, later you can switch.
            </Typography>
            <Stack
              spacing={1}
              flexDirection={"row"}
              justifyContent={"space-around"}
              alignItems="baseline"
              flexWrap={"wrap"}
            >
              {accountsSuccess &&
                accountsData.map((account, idx) => (
                  <Box key={idx}>
                    <Card
                      variant="elevation"
                      raised={true}
                      elevation={5}
                      sx={{
                        background: darken(theme.palette.dark.main, 0.4),
                        color: theme.palette.dark.contrastText,
                        width: 275,
                        height: 165,
                      }}
                    >
                      <CardContent
                        sx={{
                          height: 85,
                        }}
                      >
                        <Typography
                          gutterBottom
                          sx={{ color: theme.palette.red.main, fontSize: 14 }}
                        >
                          Account ID {account.owner_id}
                        </Typography>
                        <Typography variant="subtitle2" component="div">
                          Profile Type:{" "}
                          {account.owner_current_account
                            ? "Account Owner"
                            : "Member"}
                        </Typography>
                        <Typography
                          sx={{ color: theme.palette.yellow.main, mb: 1.5 }}
                        >
                          Account Owner:{" "}
                          {account.owner_current_account
                            ? "Me"
                            : account.owner_name +
                              " " +
                              account.owner_last_name}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="contained"
                          fullWidth
                          color={
                            currentProfile.owner_id !== account.owner_id
                              ? "dark"
                              : "success"
                          }
                          size="small"
                          onClick={() => {
                            if (currentProfile.owner_id !== account.owner_id) {
                              setSwitching(true);
                              setPreAccount(currentProfile);
                              onSetCurrentProfile(account);
                              onResetOrgList()
                            }
                          }}
                        >
                          {currentProfile.owner_id !== account.owner_id
                            ? "Enter"
                            : "Selected"}
                        </Button>
                      </CardActions>
                    </Card>
                  </Box>
                ))}
            </Stack>
          </>
        }
      ></Modal>
    </>
  );
}
