const BackIcon = () => (
  <svg
    width="6"
    height="15"
    viewBox="0 0 6 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.01361e-07 7.5L6 0.138784L6 14.8612L1.01361e-07 7.5Z"
      fill="#66BF76"
    />
  </svg>
);

export default BackIcon;
