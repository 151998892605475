import contentSlice from "./slice"

// SELECTORS
export const selectOrganizationListData = (state) => state?.organizations?.list?.data;
export const selectOrganizationListStatus = (state) => state?.organizations?.list?.status;

export const selectOrganizationUsersData = (state) => state?.organizations?.users?.data;
export const selectOrganizationUsersStatus = (state) => state?.organizations?.users?.status;

export const selectOrganizationChannelsData = (state) => state?.organizations?.channels?.data;
export const selectOrganizationChannelsStatus = (state) => state?.organizations?.channels?.status;

export const selectCurrentOrganization = (state) => state?.organizations?.currentOrganization
export const selectPreviousOrganization = (state) => state?.organizations?.previousOrganization

export const selectCreateOrganizationData = (state) => state?.organizations?.createOrganization?.data;
export const selectCreateOrganizationStatus = (state) => state?.organizations?.createOrganization?.status;

export const selectInviteUserData = (state) => state?.organizations?.inviteUser?.data;
export const selectInviteUserStatus = (state) => state?.organizations?.inviteUser?.status;

export const selectNotificationChannel = (state) =>state?.organizations?.notificationChannel

export const selectOrgUserProfileData = (state) => state?.organizations?.orgUserProfile?.data;
export const selectOrgUserProfileStatus = (state) => state?.organizations?.orgUserProfile?.status

export const selectDisableOrganizationData = (state) => state?.organizations?.disableOrganization?.data;
export const selectDisableOrganizationStatus = (state) => state?.organizations?.disableOrganization?.status


export const selectDisableOrganizationUserData = (state) => state?.organizations?.disableOrganizationUser?.data;
export const selectDisableOrganizationUserStatus = (state) => state?.organizations?.disableOrganizationUser?.status

// ACTIONS
export const { 
    setCurrentOrganization,
    resetChannelsList,
    resetUsersList,
    resetOrgList,
    resetPostOrganization,
    resetCurrentOrganization,
    resetInviteUser,
    resetOrgUserProfile,
    resetDisableOrganizationUser,
    resetDisableOrganization,
    setPreviousOrganization
} = contentSlice.actions;

// REDUCER
export default contentSlice.reducer

