import {
  Box,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";

export default function BuilderHeaderComponent(props) {
  //    const theme = useTheme()
  const getColor = () => {
    if (props.progress === 0) return "gray";
    if (props.progress === 100) return "success";
    if (props.progress > 85) return "success";
    if (props.progress > 60) return "yellow";
    return "red";
  };
  return (
    <Box
      sx={{
        //background:"#f0f0f0",
        borderRadius: "10px",
        marginBottom: "10px",
      }}
    >
      <Box
        direction="column"
        justifyContent={"center"}
        alignItems={"center"}
        py={1}
      >
        <Stack direction="row"
        spacing={2}
        alignItems={"center"}>
        <Typography>
        {props.icon}
        </Typography>
        <Typography
          variant="h4"
          fontWeight={"bold"}
        >
           {props.title}
        </Typography>
        </Stack>
        {props.displayProgress && (
          <Box>
            <LinearProgress
              size={75}
              thickness={1}
              color={getColor(props.progress)}
              variant="determinate"
              value={props.progress === 0 ? 100 : props.progress}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
