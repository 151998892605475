import { Typography, Stack, Box, Paper, Divider } from "@mui/material";
import { useLoginHandler } from "../../../../hooks/useLoginHandler";
import ModalUpdateProfile from "./ModalUpdateProfile";
import * as React from "react";

export default function PersonalInfoTab() {
  const { currentProfile } = useLoginHandler();

  return (
    <Stack spacing={2}>
      <Paper
        sx={{
          width: "100%",
          textAlign: "initial",
        }}
        variant="elevation"
        elevation={0}
      >
        <Box p={1}>
          <Typography variant="h5" fontWeight={"100"}>
            Personal Details
          </Typography>
          <br />
          <Typography variant="body2">Name</Typography>
          <Typography variant="body2" fontWeight={"200"}>
            {`${currentProfile.name} ${currentProfile.last_name}`}
          </Typography>
          <br />
          <Typography variant="body2">Email</Typography>
          <Typography variant="body2" fontWeight={"200"}>
            {currentProfile.email}
          </Typography>
          <ModalUpdateProfile />
        </Box>
      </Paper>
      <Divider></Divider>
      <Paper
        sx={{
          width: "100%",
          textAlign: "initial",
        }}
        variant="elevation"
        elevation={0}
      >
        <Box p={1}>
          <Typography variant="h5" fontWeight={"100"}>
            Login Details
          </Typography>
          <br />
          <Typography variant="body2">{currentProfile.email}</Typography>
          <br />
          <Typography variant="body2">Password</Typography>
          <Typography variant="body2" fontWeight={"200"}>
            *********
          </Typography>
        </Box>
      </Paper>
    </Stack>
  );
}
