import { LogoLoader, Modal } from "../../../../components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useCallback, useEffect, useState } from "react";
import ErrorMessageDisplayer from "../../../../components/ErrorMessageDisplayer";
import { useLoginHandler } from "../../../../hooks/useLoginHandler";
import useGetStrapiProductHandler from "../../../../hooks/useGetStrapiProductHandler";
import useUpdateSubscriptionHandler from "../../../../hooks/useUpdateSubscriptionHandler";
import StripeProductsComponent from "../../../../components/StripeProductsComponent";
import ModalCancelSubscription from "./ModalCancelSubscription";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { Stack } from "@mui/material";

export default function ModalUpdateSubscription() {
  const [open, setOpen] = useState(false);
  const stripe = useStripe();
  const theme = useTheme();
  const elements = useElements();
  const { onRefreshProfile, currentProfile } = useLoginHandler();
  const { onGetStrapiProducts, isUninitialized } = useGetStrapiProductHandler();

  const {
    error,
    isSuccess,
    isLoading,
    isError,
    onSubmitForm,
    onResetUpdateSubscription,
  } = useUpdateSubscriptionHandler();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      productId: currentProfile.subscription_data.stripe_product.id,
      priceId: "",
      paymentId: "",
    },
    validationSchema: Yup.object({
      productId: Yup.string().required("Required"),
      priceId: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      if (formik.errors.paymentId) return;
      onSubmitForm(values);
    },
  });

  const onClose = useCallback(() => {
    if (isLoading) return;
    setOpen(false);
    onResetUpdateSubscription();
    formik.resetForm();
  }, [isLoading, setOpen, onResetUpdateSubscription, formik]);

  useEffect(() => {
    if (isSuccess) {
      onClose();
      onRefreshProfile();
    }
  }, [isSuccess, onClose, onRefreshProfile]);

  useEffect(() => {
    if (open && isUninitialized) {
      onGetStrapiProducts();
    }
  }, [open, isUninitialized, onGetStrapiProducts]);

  return (
    <>
      <Button
        endIcon={<ModeEditIcon />}
        variant={"outlined"}
        onClick={() => setOpen(true)}
        size="small"
      >
        Edit Subscription
      </Button>
      <Modal
        maxWidth="lg"
        actions={
          <>
            {isError ? (
              <Button onClick={onClose} variant="contained" color="error">
                Close
              </Button>
            ) : (
              <>
                <ModalCancelSubscription></ModalCancelSubscription>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (
                      currentProfile.subscription_data.stripe_product.id !==
                      formik.values.productId
                    ) {
                      if (
                        currentProfile.subscription_data.stripe_payment
                          .cardNum === "Not available" &&
                        formik.values.paymentId === ""
                      ) {
                        formik.setFieldError(
                          "paymentId",
                          "Please enter a valid card"
                        );
                        return;
                      }
                      if (formik.errors.paymentId) return;
                      formik.handleSubmit();
                    } else {
                      formik.setFieldError(
                        "productId",
                        "Please select a different plan"
                      );
                    }
                  }}
                >
                  update
                </Button>
                <Button onClick={onClose} variant="contained" color="error">
                  Close
                </Button>
              </>
            )}
          </>
        }
        handleClose={onClose}
        open={open}
        title={"Subscription Upgrade"}
        body={
          <>
            <LogoLoader open={isLoading} />
            {isError ? (
              <ErrorMessageDisplayer
                content={{
                  header: "Subatomic Subscription",
                  message: error.error,
                }}
              ></ErrorMessageDisplayer>
            ) : (
              <StripeProductsComponent formik={formik} />
            )}
            <Stack alignItems="center" spacing={2} sx={{ height: "60px" }}>
              {currentProfile.subscription_data.stripe_product.id !==
                formik.values.productId &&
                currentProfile.subscription_data.stripe_payment.cardNum ===
                  "Not available" && (
                  <>
                    <Box
                      sx={{
                        padding: "10px",
                        border: "1px solid #5e5e5e",
                        borderRadius: "10px",
                        width: "84%",
                      }}
                    >
                      <CardElement
                        id="card"
                        options={{
                          style: {
                            complete: {
                              color: theme.palette.green.main,
                            },
                            base: {
                              fontSize: "16px",
                              color: "white",
                              "::placeholder": {
                                color: "white",
                              },
                            },
                            invalid: {
                              color: theme.palette.red.main,
                            },
                          },
                        }}
                        onChange={async () => {
                          if (!stripe || !elements) {
                            return;
                          }
                          const cardElement = elements.getElement(CardElement);
                          const { error, paymentMethod } =
                            await stripe.createPaymentMethod({
                              type: "card",
                              card: cardElement,
                            });
                          if (error) {
                            formik.setFieldError("paymentId", error.message);
                            return;
                          }
                          formik.setFieldValue(
                            "paymentId",
                            paymentMethod ? paymentMethod.id : ""
                          );
                        }}
                      />
                    </Box>
                    <Box sx={{ marginTop: "0px !important", width: "85%" }}>
                      <Typography color="error" textAlign={"left"}>
                        {" "}
                        {formik.errors.paymentId}
                      </Typography>
                    </Box>
                  </>
                )}
            </Stack>
          </>
        }
      />
    </>
  );
}
