import { CircularProgress, IconButton } from "@mui/material";
import BootstrapTooltip from "../BootstrapTooltipComponent";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import useGetAnalyzeExportHandler from "../../hooks/useGetAnalyzeExportHandler";
import useAnalyzeContentHandler from "../../hooks/useAnalyzeContentHandler";

export default function AnalyzeExportPDFButton({
  contentId,
  versionId,
  has_analytics,
}) {
  const { onGetAnalyzeExportPDF, queue } = useGetAnalyzeExportHandler();
  const { analyzeQueue } = useAnalyzeContentHandler();

  const isPending =
    queue.find((q) => q?.contentId === contentId)?.status === "pending";

  const hasExportableAnalytics =
    has_analytics ||
    analyzeQueue.find((record) => record.content_id === contentId)?.status ===
      "success";

  return (
    <>
      {isPending ? (
        <CircularProgress
          color="success"
          sx={{
            width: "16px !important",
            height: "16px !important",
            position: "relative",
            top: "5px",
            left: "5px",
          }}
        />
      ) : (
        <BootstrapTooltip
          title={
            hasExportableAnalytics
              ? "Export Analysis as PDF"
              : "No Analysis Results to Export"
          }
        >
          <span>
            <IconButton
              disabled={!hasExportableAnalytics}
              color={"success"}
              onClick={() => onGetAnalyzeExportPDF(contentId, versionId)}
              size="small"
            >
              <ArrowCircleDownIcon fontSize="small" />
            </IconButton>
          </span>
        </BootstrapTooltip>
      )}
    </>
  );
}
