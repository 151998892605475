import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function InputFileUpload({
  formik,
  filedName,
  fullWidth = false,
}) {
  const allowedFormats = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "text/plain",
    "application/pdf",
    "application/vnd.oasis.opendocument.text",
  ];

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && allowedFormats.includes(file.type)) {
      formik.setFieldValue(filedName, file);
    } else {
      alert(
        "Invalid file format. Please select a file with an accepted format (DOC, DOCX, PPTX, TXT, PDF, ODT)."
      );
      formik.setFieldValue(filedName, null);
    }
  };

  return (
    <div>
      <Button
        fullWidth={fullWidth}
        onClick={() => {
          formik.setFieldTouched(filedName, true)
          formik.setFieldValue(filedName, null);

        }}
        component="label"
        role={undefined}
        variant="contained"
        color={formik.values[filedName] ? "primary" : "success"}
        tabIndex={-1}
        sx={{ borderRadius: "50px" }}
        startIcon={
          formik.values[filedName] ? (
            <BookmarkAddedIcon></BookmarkAddedIcon>
          ) : (
            <CloudUploadIcon />
          )
        }
      >
        {formik.values[filedName] ? "Ready" : "Select file"}
        <VisuallyHiddenInput
          accept=".doc, .docx, .pptx, .txt, .pdf, .odt"
          type="file"
          onChange={handleFileChange}
        />
      </Button>
    </div>
  );
}
