import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPusherNotificationChannel,
  selectPusherNotificationCredits,
  setPusherNotificationChannel,
  setPusherNotificationCreditsUpdate,
  updateAnalyzeContentQueue,
} from "../redux/utilities";
import { selectProfileData, selectUserAccountsStatus } from "../redux/auth";
import { updateAnalyzeSingleContent } from "../redux/content";
import { selectCurrentOrganization } from "../redux/organizations";

export default function usePusherNotificationChannel() {
  const channel = useSelector((state) =>
    selectPusherNotificationChannel(state)
  );
  const credits = useSelector((state) =>
    selectPusherNotificationCredits(state)
  );
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  const accountStatus = useSelector((state) => selectUserAccountsStatus(state));
  const currentAccount = useSelector((state) => selectProfileData(state));

  const dispatch = useDispatch();

  useEffect(() => {
    if (accountStatus === "fulfilled" && currentAccount !== null) {
      dispatch(setPusherNotificationChannel(currentAccount.channel));
      dispatch(
        setPusherNotificationCreditsUpdate({
          flat_rate_credits: currentAccount.subscription_flat_rate_credits,
          usage_credits: currentAccount.subscription_usage_credits,
        })
      );
    }
  }, [accountStatus, currentAccount, dispatch]);

  const onSetPusherNotificationChannel = () => {
    dispatch(setPusherNotificationChannel(currentAccount.channel));
    dispatch(
      setPusherNotificationCreditsUpdate({
        flat_rate_credits: currentAccount.subscription_flat_rate_credits,
        usage_credits: currentAccount.subscription_usage_credits,
      })
    );
  };

  useEffect(() => {
    if (currentOrg) {
      if (channel !== null) {
        channel.bind("credits_update", (data) => {
          dispatch(
            setPusherNotificationCreditsUpdate({
              flat_rate_credits: data.subscription_flat_rate_credits,
              usage_credits: data.subscription_usage_credits,
            })
          );
        });
        channel.bind(`analyze_update_${currentOrg.id}`, (data) => {
          dispatch(updateAnalyzeSingleContent(data));
          dispatch(updateAnalyzeContentQueue(data));
        });
      }
    }
  }, [channel, currentOrg, dispatch]);

  return {
    credits,
    onSetPusherNotificationChannel,
  };
}
