import { useDispatch, useSelector } from "react-redux";
import { contentAPIs } from "../api_";
import {
  resetContentMetadata,
  selectGetContentMetadataData,
  selectGetContentMetadataStatus,
} from "../redux/content";
import { selectCurrentOrganization } from "../redux/organizations";

export default function useContentMetadataHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectGetContentMetadataStatus(state));
  const data = useSelector((state) => selectGetContentMetadataData(state));
  const currentOrg = useSelector((state) => selectCurrentOrganization(state));
  const isUninitialized = status === "idle";
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  const onResetCurrentContent = (id)=>{
    dispatch(
      resetContentMetadata(id)
    );
  }
  const onGetContentMetadata = (id)=>{
    dispatch(
      contentAPIs.getContentMetadata({
        id: currentOrg.id,
        blobId: id,
      })
    );
  }
  
  return {
    data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onGetContentMetadata,
    onResetCurrentContent
  };
}
